import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {ErrorToaster} from "../../../../shared/toaster";
import {IoMdClose} from "react-icons/io";
import {Field} from "../../../../components/fouladyar/field/field";
import {ConvertFilterObjectToUrlParam} from "../../../../redux/store/shared/shared";
import {toFarsiNumber} from "../../../../shared/toFarsiNumber";
import {TbNotes} from "react-icons/tb";
import {RiCheckboxCircleLine} from "react-icons/ri";
import {LoadingState} from "../../../../components/fouladyar/loading-state/loadingState";
import {EmptyState} from "../../../../components/fouladyar/empty-state/emptyState";
import {IoAddOutline} from "react-icons/io5";

export function AddPostToSeriesModal({isEditing, _bot, onClose, onAdd, items}) {
    const auth = useSelector((state) => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('')
    const [result, setResult] = useState([
        {
            _id: '8898999577394566',
            title: 'شیننی ینشسب یم بشمسی',
            category: 'نیینن نشکنینیشس '
        }
    ])
    const [pagination, setPagination] = useState({
        itemPerPage: 20,
        currentPage: 1,
        totalItems: 0,
        lastUpdateBy: ''
    })

    async function loadData() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/post?limit=${pagination.itemPerPage}&page=${pagination.currentPage}&title=${search}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('data', res)

            if (!res) {
                ErrorToaster({message: 'شما مجوز دسترسی به این بخش را ندارید!'}, t)
            } else if (res.data.statusCode === 200 || !res.data.statusCode) {
                setResult(res.data.data.posts?.map(item => {
                    const date = new Date(item.createdAt);
                    const formattedDate = date.toLocaleDateString();
                    return {
                        ...item,
                        createdAt: formattedDate
                    }
                }))
                setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            } else {

            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    useEffect(()=>{
        loadData()
        console.log('items', items);
    },[])

    const checkIfIdExists = (_id) => {
        console.log('_id', _id, items?.some(item => item._id === _id))
        return items?.some(item => item._id === _id);
    };
    async function onSubmit() {
        try {
            const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/bot`, bot, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (newCardResponse.data.statusCode === 200) {
                setIsSubmitting(false);
                onClose();

            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }
    }

    function onSelectItem(item) {
        onAdd(
            {
                title: item.title,
                _id: item._id
            }
        )
    }


    function PostItem({item}) {
        return (
            <div className="post-item">
                <div className="post-item-image">
                    <TbNotes size={30} color={"#4f9d81"}/>
                </div>

                <div className="post-title">
                    <h5>{item.title}</h5>
                    <h6>{item?.categoryName}</h6>

                </div>
                <div className="bot-action">
                    {
                        checkIfIdExists(item._id) ?
                            <RiCheckboxCircleLine style={{marginLeft: 4}} size={25} color={"#4f9d81"}/> :
                            <button className="btn small-button grid-action-btn" type="button" onClick={() => {
                                onSelectItem(item)
                            }}>
                                <IoAddOutline size={17} color={"#49e7ad"}/>
                            </button>
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="modal-container">
            <div className="close-modal-btn" onClick={() => onClose()}>
                <IoMdClose size={20} color={"#FAF4F4"}/>
            </div>
            <h4 className="modal-title">افزودن مقاله</h4>

            <div className='modal-form'>
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"عنوان مقاله"}
                    id={"name"}
                    name={"name"}
                    type={'text'}
                    value={search}
                    onChange={(e) => {
                        setSearch(e)
                    }}
                    onBlur={async () => {
                        await loadData()
                    }}
                />
            </div>
            {
                isLoading ?
                    <LoadingState/> :
                    (
                        result.length > 0 ?
                            result.map(item => {
                                return (
                                    <PostItem
                                        item={item}
                                    />
                                )
                            }) :
                            <EmptyState/>
                    )
            }

        </div>
    )
}