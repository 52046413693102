import React, {useEffect, useState} from "react";
import Form, {FormIsLoading} from "../../../components/fouladyar/form";
import {useDispatch, useSelector} from "react-redux";
import {formStatics, formStructure} from "./index";
import axios from "axios";
import {convertDate} from "../../../shared/shared";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";


function InventoryAdd({...props}) {

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const location = useLocation();
    const isEditing = location.pathname.includes("inventory-edit");
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [isLoading, setIsloading] = useState(isEditing ? true : false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [data, setData] = useState({
        "productId": "",
        "username": "",
        "password": "",
        "server": "",
        "balance": "",
        "broker": "",
        "investor": "",
        "type": "",
        "status": ""
    });

    async function loadData() {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/inventory/${id}`, {
            headers: {authorization: `Bearer ${auth.token}`}
        })
        console.log('loaddata', res.data)
        if (res.data.statusCode === 200) {

            setData((prevData) => ({
                ...prevData,
                "productId": res.data.data.productId,
                "username": res.data.data.specification.username,
                "password": res.data.data.specification.password,
                "server": res.data.data.specification.server,
                "balance": res.data.data.specification.balance,
                "investor": res.data.data.specification.investor,
                "broker": res.data.data.specification.broker,
                "type": res.data.data.specification.type,
                "status": res.data.data.status,
            }));
            setIsloading(false);
            return data;
        }

        return {};

    }


    useEffect(() => {
        if (isEditing)
            loadData();
    }, []);


    async function onCreate() {
        try {
            setIsSubmitting(true)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/inventory`,
                {
                    ...data,
                    productId: id,
                    generateTradingAccountAutomatic: data.generateTradingAccountAutomatic === 'active'
                },
                {
                    headers: {authorization: `Bearer ${auth.token}`}
                })

            if (res.data.statusCode === 200) {
                navigate(`/app/ecommerce/inventory-list/${id}`);
            }

            setIsSubmitting(false)

        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    async function onUpdate() {
        try {

            setIsSubmitting(true)
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/inventory/${id}`,
                {
                    ...data,
                }, {
                    headers: {
                        "authorization": `Bearer ${auth.token}`
                    }
                }
            );

            if (res.data.statusCode === 200) {
                navigate(`/app/ecommerce/inventory-list/${isEditing ? data.productId : id}`);
            }


            setIsSubmitting(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    function handleOnFieldChange(change) {
        setData((prevData) => ({
            ...prevData,
            ...change
        }));
    }

    async function handleOnSubmit() {
        !isEditing ? await onCreate() : await onUpdate();

    }

    return (

        <>
            {
                isLoading ?
                    <FormIsLoading
                        statics={formStatics}
                        isEditing={isEditing}
                    />
                    :
                    <Form
                        form={data}
                        isEditing={isEditing}
                        fields={formStructure}
                        statics={formStatics}
                        isloading={isSubmitting || data.status === 'sold'}
                        submitButtonText={"ایجاد موجودی جدید"}
                        onFieldChange={handleOnFieldChange}
                        onFormSubmit={handleOnSubmit}
                    />
            }
        </>


    );
};

export default InventoryAdd;
