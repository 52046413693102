import {ErrorToaster, SuccessToaster} from "./toaster";
import {useTranslation} from "react-i18next";

export function copyToClipboard(value) {
    // Check if the Clipboard API is supported
    if (navigator.clipboard) {
        navigator.clipboard.writeText(value).then(() => {
            console.log('Text copied to clipboard');
            SuccessToaster({message: "مقدار مورد نظر کپی شد"})
        }).catch(err => {
            console.error('Failed to copy text: ', err);
            ErrorToaster({message: "مقدار مورد نظر کپی نشد"})
        });
    } else {
        // Fallback method for older browsers
        const textArea = document.createElement('textarea');
        textArea.value = value;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand('copy');
            console.log('Text copied to clipboard');
            SuccessToaster({message: "مقدار مورد نظر کپی شد"})
        } catch (err) {
            console.error('Failed to copy text: ', err);
            ErrorToaster({message: "مقدار مورد نظر کپی نشد"})
        }
        document.body.removeChild(textArea);
    }
}