import React, { useEffect, useMemo, useState } from "react";
import Table from "../../../components/fouladyar/table";
import { useDispatch, useSelector } from "react-redux";
import {EmptyStateObject, filterStructure, tableStatics, tableStructure} from "./index";
import { ConvertFilterObjectToUrlParam } from "../../../redux/store/shared/shared";

import { getItems } from "../../../redux/store/services/mrpropfund/product/store";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import AddOnImage from "../../../assets/images/mrpropfund/add-on-background.png";
import {IoAddOutline} from "react-icons/io5";




const InventoryList = () => {

  const { t, i18n } = useTranslation();
  const {id} = useParams();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  const [pagination, setPagination] = useState({
    itemPerPage: 7,
    currentPage: 1,
    totalItems: 0,
    lastUpdateBy: ''
  })

  const [filter, setFilter] = useState({})
  async function initializeData() {


    try {
      setIsLoading(true)
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/inventory/product/${id}`, {
        headers: {authorization: `Bearer ${auth.token}`}
      })


      console.log('data', res.data)


     if(res.data.statusCode === 200 || !res.data.statusCode){
        setData(res.data.data.inventory)
        setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
      }

      setIsLoading(false)
    }catch (e) {
      ErrorToaster(e, t)
      setIsLoading(false)
    }
  }

  async function fetchData(updater) {


    try {
      setIsLoading(true)
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/inventory/product/${id}`, {
        headers: {authorization: `Bearer ${auth.token}`}
      })



      if(res.data.statusCode === 200){
        setData(res.data.data.inventory)
        if(updater === 'filter')
          setPagination({
            ...pagination,
            totalItems: res.data.data.totalItems,
            currentPage: 1,
            lastUpdateBy: updater
          })
      }

      setIsLoading(false)
    }catch (e) {
      ErrorToaster(e, t)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initializeData();
  }, []);


  useEffect(() => {
    if(pagination.lastUpdateBy === "pagination")
        fetchData('pagination');

  }, [pagination]);



  useEffect(() => {
    fetchData('filter');
  }, [filter]);



  return (
    <React.Fragment>

      <div className="grid-header">
        <h3>موجودی کالا</h3>
        <div className="grid-actions">
          <button className="btn small-button grid-action-btn" type="button" onClick={() => {
            navigate(`/app/ecommerce/inventory-add/${id}`)
          }}>
            <IoAddOutline size={17} color={"#49e7ad"}/>
          </button>
        </div>
      </div>
      <Table
        loading={isLoading}
        filter={filter}
        tableData={data}
        pagination={pagination}
        tableHeading={tableStatics}
        tableStructure={tableStructure}
        filterStructure={filterStructure}
        tableActionsStructure={[
          {
            title: "ایجاد موجودی",
            description: "با اضافه کردن حساب های از پیش ساخته شده به عنوان موجودی های محصولات خود، فرایند خرید حساب سریع تر خواهد شد",
            image: AddOnImage,
            link: `/app/ecommerce/inventory-add/${id}`
          }
        ]}
        emptystate={{
          title: "موجودی فعال یافت نشد",
          content: "از این بخش میتوانید به اقدام به ایجاد یا ویرایش موجودی ها کنید.",
          buttonText: "ایجاد موجودی جدید",
          buttonOnClickUrl: `/app/ecommerce/inventory-add/${id}`
        }}
        onItemPerPageChange={(itemPerPage, currentPage)=> {
          setPagination({...pagination, itemPerPage: itemPerPage, currentPage: currentPage, lastUpdateBy: 'pagination'})
        }}
        onCurrentPageChange={(currentPage)=> {
          setPagination({...pagination, currentPage: currentPage, lastUpdateBy: 'pagination'})
        }}
        onFilterSubmit={ (e)=>{
          setFilter(e);
        }}
        onDeleteComplete={async (e)=>{
          await fetchData('filter');
        }}
      />
    </React.Fragment>
  );
};

export default InventoryList;
