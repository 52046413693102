import React from 'react';
import './styles.css';
import {toFarsiNumber} from "../../../../../../shared/toFarsiNumber";
import {MoneySeparator} from "../../../../../../shared/moneySeparator";

export const ManagerCourseItem = ({ item, onEdit, onDelete }) => {
    const { title, content, teacher, price, videoCount, image, updatedAt } = item;

    return (
        <div className="manager-course-item-container">
            {/* First Line */}
            <div className="manager-course-item-first-line">
                {/* Left Side Buttons */}
                <div className="manager-course-item-left-buttons">
                    <button onClick={onEdit} className="manager-course-item-edit-button">ویرایش</button>
                    <button onClick={onDelete} className="manager-course-item-delete-button">حذف</button>
                </div>

                {/* Middle Content */}
                <div className="manager-course-item-middle-content">
                    <h3 className="manager-course-item-title">{title}</h3>
                    <p className="manager-course-item-content">{content}</p>
                </div>

                {/* Right Image */}
                <div className="manager-course-item-image-container">
                    <img src={image} alt="Course" className="manager-course-item-image" />
                </div>
            </div>

            {/* Divider */}
            <hr className="manager-course-item-divider" />

            {/* Second Line with Key-Value Pairs */}
            <div className="manager-course-item-key-value-pairs">
                <div className="manager-course-item-key-value-pair web">
                    <span className="manager-course-item-value">تاریخ آخرین بروزرسانی : {toFarsiNumber(new Date(updatedAt).toLocaleDateString())}</span>
                    <span className="manager-course-item-key">استاد راهنما {teacher}</span>

                </div>
                <div className="manager-course-item-key-value-pair">
                    <span className="manager-course-item-value">قیمت نهایی :‌ {toFarsiNumber(MoneySeparator(price))} تومان </span>
                    <span className="manager-course-item-key">{toFarsiNumber(videoCount)} ویدیو</span>
                </div>
            </div>
        </div>
    );
};

export default ManagerCourseItem;
