export const tableStatics = {
    title: "ربات ها",
    description: "",
    addNewItemButtonLink: '/'
}
export const formStatics = {
    title: "ایجاد ربات",
    editTitle: "ویرایش ربات",
    description: "از اینجا میتوانید اقدام به خرید ربات کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش ربات کنید",
    submitText: "خرید ربات",
    editSubmitText: "ویرایش ربات",
}


export const EmptyStateObject = {
    title: "ربات فعال یافت نشد",
    content: "جهت استفاده از سرویس پراب فرم باید از بخش خرید ربات اقدام به خرید یک ربات متناسب با بودجه خود کنید.",
    buttonText: "خرید ربات جدید",
    buttonOnClickUrl: "/plans"
}


export const TableActionsStructure = [
    {
        title: "خرید اکانت جدید",
        link: '/plans'
    }
]
export const filterStructure = [
    {
        title: "نام کاربری",
        slug: 'username',
        type: "text",
    },
    {
        title: "پسورد",
        slug: 'password',
        disabled: true,
        type: "text",
    },
    {
        title: "انوستور",
        slug: 'investor',
        disabled: true,
        type: "text",
    },
    {
        title: "سرور",
        slug: 'server',
        disabled: true,
        type: "text",
    },
    {
        title: "بالانس",
        slug: 'balance',
        disabled: true,
        type: "text",
    },
    {
        title: "وضعیت",
        slug: 'status',
        disabled: true,
        type: "select",
        options: [
            {label: 'فعال', value: 'beginner'},
            {label: 'غیر فعال', value: 'professional'},
        ],
    },
]

export const tableStructure = [
    {
        title: "نام",
        slug: 'firstName',
    },
    {
        title: "نام خانوادگی",
        slug: 'lastName',
    },
    {
        title: "حساب",
        slug: 'accountNumber',
        useFarsiNumber: true
    },
    {
        title: "بروکر",
        slug: 'broker',
    },
    {
        title: "تاریخ انقضا",
        slug: 'expirationDate',
        useFarsiNumber: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "کپی اطلاعات",
                slug: "copy-to-clipboard",
                //
                // useRoute: true,
                // route: "/product-edit/:id"
            },
            {
                title: "لایسنس ها",
                slug: "bot-licenses",
                route: "/app/services/license-list/:ـid",
                useRoute: true,
            },
            // {
            //   title: "حذف ربات",
            //   slug: 'delete-product',
            //   useYesOrNoModal: true,
            //   modalCancelText: 'بستن پنجره',
            //   modalSubmitText: 'حذف ربات',
            //   modalTitle: 'حذف ربات',
            //   modalContent: 'ایا از حذف این ربات اطمینان دارید؟ با حذف این ربات داده ها دیگر در دسترس نخواهند بود',
            //   modalType: "delete",
            //   modalPath: '/api/products/:_id',
            // },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "اسم",
            slug: 'name',
            type: "text",
            placeholder: "نام ربات را وارد کنید",
            isRequired: true,
        },
        {
            title: "شماره رجیستر",
            slug: 'registerNumber',
            type: "text",
            placeholder: "شماره رجییستر ربات را وارد کنید",
            isRequired: true,
        },
    ],
    [
        {
            title: "سال ساخت",
            slug: 'buildDate',
            type: "date",
            isJalali: true,
            placeholder: "سال ساخت ربات را وارد کنید",
            isRequired: true,
        },
        {
            title: "ساعت پرواز",
            slug: 'flightHours',
            type: "number",
            placeholder: "نام ربات را وارد کنید",
            isRequired: true,
        },
    ],
    [
        {
            title: "تاریخ اورهال موتور",
            slug: 'engineOverhaulDate',
            type: "date",
            isJalali: true,
            placeholder: "تاریخ اورهال موتور را وارد کنید",
            isRequired: true,
        },
        {
            title: "ساعت اورهال موتور",
            slug: 'engineOverhaulHours',
            type: "number",
            placeholder: "ساعت اورهال موتور را وارد کنید",
            isRequired: true,
        },
    ],
    [
        {
            title: "تاریخ اورهال ملخ",
            slug: 'propellerOverhaulDate',
            type: "date",
            isJalali: true,
            placeholder: "تاریخ اورهال ملخ را وارد کنید",
            isRequired: true,
        },
        {
            title: "ساعت اورهال ملخ",
            slug: 'propellerOverhaulHours',
            type: "number",
            placeholder: "ساعت اورهال ملخ را وارد کنید",
            isRequired: true,
        }
    ],
    [
        {
            title: "مجوز پرواز",
            slug: 'flightPermitDate',
            type: "date",
            isJalali: true,
            placeholder: "مجوز پرواز را وارد کنید",
            isRequired: true,
        },
        {
            title: "ساعت سرویس بعدی",
            slug: 'overallServicesHours',
            type: "number",
            isJalali: true,
            placeholder: "ساعت سرویس کلی را وارد کنید",
            isRequired: true,
        },

    ],
    [
        {
            title: "شماره بیمه",
            slug: 'insuranceNumber',
            type: "text",
            placeholder: "شماره بیمه را وارد کنید",
            isRequired: true,
        },
        {
            title: "تاریخ بیمه",
            slug: 'insuranceDate',
            type: "date",
            isJalali: true,
            placeholder: "تاریخ بیمه را وارد کنید",
            isRequired: true,
        },
    ],
    [
        {
            title: "توضیحات",
            slug: 'description',
            type: "textarea",
            placeholder: "توضیحات ربات را وارد کنید",
        }
    ],
    [
        {
            title: "تصویر اجازه نامه پرواز",
            slug: 'flightPermitImagePreview',
            type: "image-preview",
            placeholder: "تصویر اجازه نامه پرواز را انتخاب کنید",
        },
        {
            title: "تصویر بیمه نامه",
            slug: 'insuranceImagePreview',
            type: "image-preview",
            placeholder: "تصویر بیمه نامه را انتخاب کنید",
        },
    ],
    [
        {
            title: "تصویر اجازه نامه پرواز",
            slug: 'flightPermitImage',
            type: "file-upload",
            placeholder: "تصویر اجازه نامه پرواز را انتخاب کنید",
        },
        {
            title: "تصویر بیمه نامه",
            slug: 'insuranceImage',
            type: "file-upload",
            placeholder: "تصویر بیمه نامه را انتخاب کنید",
        },
    ]
]
