import {addDays, endOfMonth, format, startOfMonth, subDays} from "date-fns";
import {Bar, Line} from "react-chartjs-2";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Field} from "../../../../components/fouladyar/field/field";
import annotationPlugin from "chartjs-plugin-annotation";
import {CategoryScale, Chart as ChartJS, Legend,BarController, LinearScale,BarElement, LineElement, PointElement, Tooltip} from "chart.js";

ChartJS.register(BarElement,BarController, LineElement, CategoryScale,annotationPlugin, LinearScale, PointElement, Tooltip, Legend);

export default function MaximumDrawdownInSingleDayLineChartComponent ({trades, initialCapital}) {


    const {t, i18n} = useTranslation();

    const [period, setPeriod] = useState('month'); //week | month

    function getLast7Days() {
        const days = [];

        for (let i = 6; i >= 0; i--) {
            const day = subDays(new Date(), i);
            const dayName =format(day, 'EEEE');
            days.push(t(dayName)); // 'EEEE' gives the full day name (e.g., 'Monday')
        }

        return days;
    }
    function getCurrentMonthDays() {
        const days = [];
        const today = new Date();
        const start = startOfMonth(today);
        const end = endOfMonth(today);

        for (let day = start; day <= end; day = addDays(day, 1)) {
            const dayNumber = format(day, 'd'); // Get the day of the month (1, 2, 3, ...)
            days.push(parseInt(dayNumber));
        }

        return days;
    }


    function processData(dataArray) {
        const result = {};

        dataArray.forEach(item => {
            const date = new Date(item.entryTime).toISOString().split('T')[0]; // Extract the date in YYYY-MM-DD format

            if (!result[date]) {
                result[date] = {
                    totalPnl: item.pnl,
                    lowestValue: item.pnl > 0 ? 0 : item.pnl
                };
            }else{
                result[date].totalPnl += item.pnl;
            }


            if (result[date].lowestValue > result[date].totalPnl) {
                result[date].lowestValue = result[date].totalPnl;
            }
        });

        return result;
    }
    const dailySummary = processData(trades);


    function getLast7DaysLowestDrawdown(trades) {
        const lowestDrawdownWeek = [];
        const lowestDrawdownOverallWeek = [];
        let drawdownOverall = 0;
        let totalOfDay = 0
        for (let i = 6; i >= 0; i--) {
            const day = subDays(new Date(), i);
            let drawdown = 0;
            const dayString = format(day, 'yyyy-MM-dd');

            // Filter trades for this day
            const tradesForDay = trades.filter(trade =>
                format(new Date(trade.entryTime), 'yyyy-MM-dd') === dayString
            );

            // Sum the profits for this day
            const profitForDay = tradesForDay.reduce((sum, trade) => {
                drawdown = drawdown < (sum + trade.pnl) ? drawdown : sum + trade.pnl;

                totalOfDay = totalOfDay + trade.pnl;
                drawdownOverall = drawdownOverall > totalOfDay ? totalOfDay : drawdownOverall

                return  sum + trade.pnl
            }, 0);
            // Add the sum to the profits array
            lowestDrawdownOverallWeek.push(drawdownOverall);
            lowestDrawdownWeek.push(drawdown);
        }


        return {lowestDrawdownWeek, lowestDrawdownOverallWeek};
    }
    function getLast30DaysLowestDrawdown(trades) {
        const lowestDrawdownMonth = [];
        const lowestDrawdownOverallMonth = [];
        let drawdownOverall = 0;
        let totalOfDay = 0;

        for (let i = 29; i >= 0; i--) {
            const day = subDays(new Date(), i);
            let drawdown = 0;
            const dayString = format(day, 'yyyy-MM-dd');

            // Filter trades for this day
            const tradesForDay = trades.filter(trade =>
                format(new Date(trade.entryTime), 'yyyy-MM-dd') === dayString
            );

            // Sum the profits for this day
            const profitForDay = tradesForDay.reduce((sum, trade) => {
                drawdown = drawdown < (sum + trade.pnl) ? drawdown : sum + trade.pnl;

                totalOfDay = totalOfDay + trade.pnl;
                drawdownOverall = drawdownOverall > totalOfDay ? totalOfDay : drawdownOverall;

                return sum + trade.pnl;
            }, 0);

            // Add the sum to the drawdown arrays
            lowestDrawdownOverallMonth.push(drawdownOverall);
            lowestDrawdownMonth.push(drawdown);
        }

        return { lowestDrawdownMonth, lowestDrawdownOverallMonth };
    }


    const week = getLast7Days();
    const month = getCurrentMonthDays();

    const {lowestDrawdownWeek, lowestDrawdownOverallWeek} = getLast7DaysLowestDrawdown(trades)
    const {lowestDrawdownMonth, lowestDrawdownOverallMonth} = getLast30DaysLowestDrawdown(trades)

    const data = {
        labels: period === 'month' ? month : (period === 'week' ? week : []),
        datasets: [
            {
                type: 'bar', // Bar dataset
                label: 'ماکزیمم دراداون روز',
                data: period === 'month' ? lowestDrawdownMonth.map(item => (item * 100 / initialCapital) * -1) : (period === 'week' ? lowestDrawdownWeek.map(item => (item * 100 / initialCapital) * -1) : []),
                backgroundColor: '#DA6B6B', // Red color
                stack: 'Stack 2',
                barThickness: period === 'month' ? 10 : 20, // Adjust bar thickness
            },
            {
                type: 'line', // Line dataset
                label: 'ماکزیمم دراداون کل',
                data: period === 'month' ? lowestDrawdownOverallMonth.map(item => (item * 100 / initialCapital) * -1) : (period === 'week' ? lowestDrawdownOverallWeek.map(item => (item * 100 / initialCapital) * -1) : []),
                // data: period === 'month' ? maxDrawdownOfDay_month : (period === 'week' ? maxDrawdownOfDay_week : []), // Red segment data
                backgroundColor: 'rgba(75, 192, 192, 1)',
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
                borderWidth: 2,
                tension: 0.3, // Adjust this value to make the line curved

                pointRadius: 0,          // Hide the dots
                pointHoverRadius: 0,     // Hide the dots on hover
                pointHitRadius: 10

            },
        ],
    };

    const annotation = {
        annotations: {
            line0: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: 'rgba(61,61,61,0.65)',
                borderWidth: 1,
            },
            line1: {
                type: 'line',
                yMin: 5,
                yMax: 5,
                borderColor: 'rgba(68,68,68,0.65)',
                borderWidth: 1,
            },
            line2: {
                type: 'line',
                yMin: 12,
                yMax: 12,
                borderColor: 'rgba(68,68,68,0.65)',
                borderWidth: 1,
            }
        }
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            annotation,
            legend: {
                labels: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Hide vertical grid lines
                },
                title: {
                    stacked: true,
                }
            },
            y: {
                title: {
                    stacked: true,
                }
            },
        },
    };


    return(
        <table border="1" cellPadding="10" className="w-100 mb-3">
            <thead>
            <tr>
                <th className="d-flex flex-row justify-content-between align-center">

                    <span>نمودار ماکزیمم دراودان (روزانه/هفتگی)</span>
                    <div className="w-auto">
                        <Field
                            className="analytics-select-style"
                            id={"period"}
                            name={"period"}
                            type={'select'}
                            options={[
                                {label: 'هفتگی', value: 'week'},
                                {label: 'ماهانه', value: 'month'},
                            ]}
                            value={period}
                            onChange={(e) => {
                                setPeriod(e)
                            }}
                        />
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <div style={{width: '100%', height: '400px'}}>
                    <Line  data={data} options={options}/>
                </div>
            </tr>

            </tbody>
        </table>
    )

}
