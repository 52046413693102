import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import "./image-file-upload.css"
import {MdOutlineAddPhotoAlternate} from "react-icons/md";
import {GrDocumentVideo} from "react-icons/gr";
import {IoClose} from "react-icons/io5";
import {TfiFiles} from "react-icons/tfi";

export const VideoFileUploadV2 = ({multiple = false, onChange}) => {
    const [files, setFiles] = useState([]);

    // Notify parent component of file changes
    useEffect(() => {
        onChange(files);
    }, [files]);

    // Handle file drops
    const onDrop = useCallback(
        (acceptedFiles) => {
            if (multiple) {
                setFiles((prevFiles) => [
                    ...prevFiles,
                    ...acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    ),
                ]);
            } else {
                setFiles(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                );
            }
        },
        [multiple]
    );

    // Remove a file from the list
    const removeFile = (fileName) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    // Render preview for video files
    const renderPreview = (file) => {
        if (file.type.startsWith("video/")) {
            return (
                <video
                    src={file.preview}
                    alt={file.name}
                    controls
                />
            );
        } else {
            return <p>Unsupported file type</p>;
        }
    };

    // Dropzone setup
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: ["video/mp4", "video/avi", "video/mkv"], // Accept video files
        // multiple, // Whether to allow multiple files
    });

    return (
        <>
            {
                files.length > 0 ? '' :
                    <div style={{textAlign: 'center'}} {...getRootProps()} className="video-file-upload-container">
                        <input {...getInputProps()} />
                        <GrDocumentVideo size={60} color={"#3b987d"} stroke={"1.5"}/>
                        {isDragActive ? (
                            <p>Drop the Video here...</p>
                        ) : (
                            <p>
                                Drag and drop a Video, or <a href={"#"}>Browse</a> <br/>
                                <span>Max 100MB per video</span>
                            </p>
                        )}
                    </div>
            }

            {files.length > 0 && (
                <div style={{marginTop: "20px"}}>
                    <ul className="video-file-upload-container-ul">
                        {files.map((file, index) => (
                            <li
                                key={index}
                                className="video-file-container"
                            >
                                <div className="remove-image-btn" onClick={() => {
                                    setFiles([])
                                }}>
                                    <IoClose size={20} color={"#fff"}/>
                                </div>
                                {renderPreview(file)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export const ImageFileUploadV2 = ({multiple = false, onChange}) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        onChange(files);
    }, [files])
    // When files are dropped, this callback handles them.
    const onDrop = useCallback((acceptedFiles) => {
        // Map through the files and set a preview URL for each image
        if (multiple) {
            setFiles((prevFiles) => [
                ...prevFiles,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                ),
            ]);
        } else {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        }
    }, [multiple]);

    // Remove the file by filtering it out from the state
    const removeFile = (fileName) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    // Only render image previews
    const renderPreview = (file) => {
        return <img src={file.preview} alt={file.name}/>;
    };

    // Dropzone setup: Accept only image files and allow multiple file upload
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: ['image/*'], // Accept only image files
        multiple, // Whether multiple files are allowed
    });

    return (
        <div style={{width: "100%"}}>
            <div
                {...getRootProps()}
                className={'image-file-upload-container'}
            >
                <input {...getInputProps()} />
                <MdOutlineAddPhotoAlternate size={60} color={"#38AF84"}/>
                {isDragActive ? (
                    <p>drop selected images here</p>
                ) : (
                    <p>
                        Drag and drop an image, or <a href={'#'}>Browse</a> <br/>
                        <span>Max 5MB each (12MB for videos)</span>
                        {/*{multiple ? '' : ' (1 image only)'}.*/}
                    </p>
                )}
            </div>

            {files.length > 0 && (
                <div style={{marginTop: '20px'}}>
                    <ul className={"image-file-upload-container-ul"}>
                        {files.map((file, index) => (
                            <li key={index} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                                {renderPreview(file)}
                                <div
                                    onClick={() => removeFile(file.name)}
                                >
                                    حذف
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export const FileUploadV2 = ({multiple = false, onChange}) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        onChange(files);
    }, [files])
    // When files are dropped, this callback handles them.
    const onDrop = useCallback((acceptedFiles) => {
        // Map through the files and set a preview URL for each image
        if (multiple) {
            setFiles((prevFiles) => [
                ...prevFiles,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                ),
            ]);
        } else {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        }
    }, [multiple]);

    // Remove the file by filtering it out from the state
    const removeFile = (fileName) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    // Only render image previews
    const renderPreview = (file) => {
        return <img src={file.preview} alt={file.name}/>;
    };

    // Dropzone setup: Accept only image files and allow multiple file upload
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: "", // Accept only image files
        multiple, // Whether multiple files are allowed
    });

    return (
        <div style={{width: "100%"}}>
            <div
                {...getRootProps()}
                className={'image-file-upload-container'}
            >
                <input {...getInputProps()} />
                <TfiFiles size={60} color={"#38AF84"}/>
                {isDragActive ? (
                    <p>drop selected Files here</p>
                ) : (
                    <p>
                        Drag and drop Files, or <a href={'#'}>Browse</a> <br/>
                        <span>Max 5MB each (12MB for Videos)</span>
                        {/*{multiple ? '' : ' (1 image only)'}.*/}
                    </p>
                )}
            </div>

            {files.length > 0 && (
                <div style={{marginTop: '20px'}}>
                    <ul className={"image-file-upload-container-ul"}>
                        {files.map((file, index) => (
                            <li key={index} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                                {renderPreview(file)}
                                <div
                                    onClick={() => removeFile(file.name)}
                                >
                                    حذف
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ImageFileUploadV2;
