import React, {useCallback, useEffect, useMemo, useState} from "react";
import CourseModule from "../components/CourseModule";

export const Step3 = ({data, onChange}) => {

    const memoizedData = useMemo(() => data, [data]);
    // Memoize the onChange callback
    const memoizedOnChange = useCallback((updatedData) => {
        onChange(updatedData);
    }, [onChange]);
    return (
        <div>
            <div className="container-fluid select-plan-container">
                <h1>{"فصل ها"}</h1>
                <div className="spacer"/>
                <h3 style={{border: 'unset'}}>
                    در این صفحه اقدام به افزودن فصل ها و قسمت های مربوط به دوره خود کنید.
                </h3>
                <p>
                    نکته:
                    در هر بار اضافه کردن فایل دوره شما به صورت Draft آپدیت خواهد شد و میتوانید با خیال راحت به کامل کردن اطلاعات دوره خود بپردازید.
                </p>
                <p>
                    نکته:
                    لطفا برای تمامی درس های خود یک توضیح کوتاه بنویسید تا کاربر شما به صورت کامل متوجه شود که در درس شما چه مواردی را فرا خواهد گرفت
                </p>
            </div>
            <div>
                <CourseModule newData={memoizedData} onChange={memoizedOnChange} />
            </div>
        </div>
    );
};


