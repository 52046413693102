export function SignalCodeTemplate(url, botId, templateId) {
    return `
void OnTradeTransaction(const MqlTradeTransaction &trans,const MqlTradeRequest &request, const MqlTradeResult &result)
  {
// Check if the transaction involves a new deal
   if(trans.type == TRADE_TRANSACTION_DEAL_ADD)
     {
      Print("Transaction Type: ", trans.type); // Debug: Log the transaction type
      Print("Deal ID: ", trans.deal);         // Debug: Log the deal ID

      // Ensure the deal is valid and selected
      if(trans.deal == 0 || !HistoryDealSelect(trans.deal))
        {
         Print("Invalid or unselectable deal ID: ", trans.deal);
         return; // Exit if the deal is not valid
        }

      string botId = "${botId}"; // Example bot ID
      long accountNumber = AccountInfoInteger(ACCOUNT_LOGIN); // Gets the current account number  
      
      // Fetch deal details
      string symbol         = HistoryDealGetString(trans.deal, DEAL_SYMBOL);
      double volume         = HistoryDealGetDouble(trans.deal, DEAL_VOLUME);
      double price          = HistoryDealGetDouble(trans.deal, DEAL_PRICE);
      int ticket            = (int)HistoryDealGetInteger(trans.deal, DEAL_TICKET);
      int deal_entry_type   = (int)HistoryDealGetInteger(trans.deal, DEAL_ENTRY);

      // Ensure it's a new position entry
      if(deal_entry_type == DEAL_ENTRY_IN) // Entry type: IN for opening a new position
        {
         // Log to Experts tab
         Print("New position opened:");


         // Construct JSON string
         string json = "{";
         json += "\\"symbol\\":\\""     + symbol + "\\",";
         json += "\\"volume\\":"        + DoubleToString(volume, 2) + ",";
         json += "\\"price\\":"         + DoubleToString(price, 2) + ",";
         json += "\\"account\\":\\""    + accountNumber + "\\",";
         json += "\\"botId\\":\\""      + botId + "\\",";
         json += "\\"templateId\\":\\"" + "${templateId}" + "\\",";
         json += "\\"ticket\\":"        + IntegerToString(ticket);
         json += "}";

         // Send JSON to server
         string server_url = "${url}"; // Replace with your endpoint
         uchar post_data[];
         StringToCharArray(json, post_data, 0, StringLen(json), CP_UTF8);

         string serverHeaders;
         string requestHeaders = "Content-Type: application/json; charset=utf-8\\r\\nConnection: Keep-Alive";

         // Retry logic parameters
         int maxRetries = 3;  // Set your desired retry count
         int retryCount = 0;
         char result[];
         int response_code;


         int res = WebRequest("POST",server_url, requestHeaders, 10, post_data, result, serverHeaders);

         if(res==-1)
           {
               Print("Error in WebRequest. Error code  =",GetLastError());
   
               //--- Perhaps the URL is not listed, display a message about the necessity to add the address
               MessageBox("Add the address '"+server_url+"' to the list of allowed URLs on tab 'Expert Advisors'","Error",MB_ICONINFORMATION);
           }
         else
           {
           if (res == 200)
               {
                   // Inform the user of successful data submission
                   Print("Your data has been successfully sent to the FxPilot Webhook.");
               }
               else
               {
                   PrintFormat("Failed to send data to '%s', HTTP response code: %d", server_url, res);
               }
           }
        }
     }
  }
        `
}


export function SignalBotIdAndAccountNumber(botId){
    return(
`
string botId = "${botId}"; // Example bot ID
long accountNumber = AccountInfoInteger(ACCOUNT_LOGIN); // Gets the current account number  
`
    )
}