import React, {useState} from "react";
import "./style.css";
import FlagsIcons from "../../utils/flagsIcons";
import ExchangeIcons from "../../utils/exchangeIcons";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {IoMdArrowDropdown} from "react-icons/io";
import {MdMoreVert} from "react-icons/md";
import {useNavigate} from "react-router-dom";


const IDentifyCard = ({
                      targetCountry,
                      uniqueNumber,
                      userImage,
                      fullName,
                      birthdate,
                      fatherName,
                      status,
                      exchange,
                      _id,
                      onDelete
                  }) => {

    const navigate = useNavigate();



    return (
        <div className="identify-card">
            {/* Line 1 */}
            <div className="line">
                <img src={FlagsIcons(targetCountry)} alt="Flag" className="flag" />
                <div className="d-flex flex-row">

                    <UncontrolledDropdown>
                        <DropdownToggle
                            tag="a"
                            href="#more"
                            onClick={(ev) => ev.preventDefault()}
                            className="dropdown-toggle btn btn-icon btn-trigger p-1"
                        >
                            <MdMoreVert size={30} color={"#c7c7c7"}/>
                        </DropdownToggle>
                        <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                                <li key={`kyc-item-edit-order-${uniqueNumber}`}>
                                    <DropdownItem
                                        tag="a"
                                        onClick={(ev) => {
                                            navigate(`/identify/edit/${_id}`)
                                        }}
                                    >
                                        <span>ویرایش سفارش</span>
                                    </DropdownItem>
                                </li>
                                <li key={`kyc-item-cancel-order-${uniqueNumber}`}>
                                    <DropdownItem
                                        tag="a"
                                        onClick={(ev) => {
                                            onDelete(_id)
                                        }}
                                    >
                                        <span>حذف سفارش</span>
                                    </DropdownItem>
                                </li>
                            </ul>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <span className="number">{uniqueNumber}</span>
                </div>
            </div>

            {/* Line 2 */}
            <div className="line">
                <img src={`${process.env.REACT_APP_FXPLT_LIARA_STORAGE}/${userImage}`} alt="User" className="user-image" />
                <div className="user-info-container">
                    {/* User Info */}
                    <div className="specifications">
                        <div className="specification">
                            <small className="label">Status</small>
                            <div className="value">{status}</div>
                        </div>
                        <div className="specification">
                            <small className="label">[a] Name/Surname/Nom</small>
                            <div className="value">{fullName || '-'}</div>
                        </div>
                        <div className="specification">
                            <small className="label">Father's Name</small>
                            <div className="value">{fatherName || '-'}</div>
                        </div>
                        <div className="specification">
                            <small className="label">Date of Birth</small>
                            <div className="value">{birthdate ? birthdate.split("T")[0] : '-'}</div>
                        </div>
                    </div>

                    {/* Exchange Logo */}
                    <div className="exchange-logo">
                        <img src={ExchangeIcons(exchange)} alt="Exchange Logo" className="logo" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IDentifyCard;