export const tableStatics = {
    title: "دسته بندی ها",
    description: "",
    addNewItemButtonLink: '/product-add'
}
export const formStatics = {
    title: "ایجاد دسته بندی",
    editTitle: "ویرایش دسته بندی",
    description: "از اینجا میتوانید اقدام به ایجاد دسته بندی کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش دسته بندی کنید",
    submitText: "ایجاد دسته بندی",
    editSubmitText: "ویرایش دسته بندی",
}
export const EmptyStateObject = {
    title: "دسته بندی یافت نشد",
    content: "لطفا از طریق لینک زیر وارد صفحه اضافه کردن دسته بندی شده و دسته بندی ها مورد نظر خود را اضافه کنید",
    buttonText: "افزودن دسته بندی جدید",
    buttonOnClickUrl: "/post-add"
}

import CampaignImage from "../../../assets/images/mrpropfund/campaign-background.png";

export const TableActionsStructure = [
    {
        title: "افزودن دسته بندی",
        description: "دسته بندی ها مورد نظر خود را با توجه به دسته بندی های ایجاد شده، اضافه و کامل کنید.",
        image: CampaignImage,
        link: '/post-add'
    }
]

export const filterStructure = [
    {
        title: "اسم",
        slug: 'name',
        type: "text",
    },
    {
        title: "شناسه",
        slug: 'slug',
        type: "text",
    },
    {
        title: "بخش",
        slug: 'section',
        disabled: true,
        type: "text",
    },
    {
        title: "تاریخ ایجاد",
        slug: 'createdAt',
        type: "date",
    },
/*    {
        title: "وضعیت",
        slug: 'status',
        type: "select",
        options: [
            {label: 'منتشر شده', value: 'published'},
            {label: 'در دست انتشار', value: 'waiting_for_publish'},
        ],
    },*/
]

export const tableStructure = [
    {
        title: "اسم",
        slug: 'name',
        useFarsiNumber: true
    },
    {
        title: "شناسه",
        slug: 'slug',
        useFarsiNumber: true
    },
    {
        title: "بخش",
        slug: 'section',
        disabled: true,
        useFarsiNumber: true
    },
    {
        title: "تاریخ ایجاد",
        slug: 'createdAt',
        useFarsiNumber: true
    },
/*    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true
    },*/

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "ویرایش دسته بندی",
                slug: "edit-category",
                useRoute: true,
                route: "/app/ecommerce/category-edit/:_id"
            },
            {
                title: "حذف دسته بندی",
                slug: 'delete-category',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف دسته بندی',
                modalTitle: 'حذف دسته بندی',
                modalContent: 'ایا از حذف این دسته بندی اطمینان دارید؟ با حذف این دسته بندی داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/categories/:_id',
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "عنوان دسته بندی",
            slug: 'name',
            type: "text",
            description: "عنوان دسته بندی را وارد کنید",
            isRequired: true,
        }
    ],
    [
        {
            title: "شناسه دسته بندی",
            slug: 'slug',
            type: "text",
            description: "شناسه دسته بندی باید حتما با حروف انگلیسی وارد شده باشد.",
            isRequired: true,
        }
    ],
    [
        {
            title: "توضیحات دسته بندی",
            slug: 'description',
            type: "textarea",
            description: "توضیحات دسته بندی را وارد کنید",
            isRequired: true,
        }
    ],
    [
        {
            title: "انتخاب شاخه والد",
            slug: 'node',
            type: "tree",
            treeSection: 'product',
            treeMode: 'category',
            description: "در صورتی که این دسته بندی زیر مجموعه دسته دیگر است آن را انتخاب کنید.",
            isRequired: true,
        }
    ],
    [
        {
            title: "بخش/گروه",
            slug: 'section',
            type: "text",
            disabled: true,
            description: "دسته بندی مورد نظر شما در کدام بخش/گروه خواهد بود. لطفا این بخش با حروف انگلیسی وارد شود",
            isRequired: true,
        }
    ],

]
