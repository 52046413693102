import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  PreviewCard,
  Row
} from "../../../components/Component";
import { useTranslation } from "react-i18next";
import { Field } from "../field/field";
import DuotoneSpace from "react-syntax-highlighter/dist/cjs/styles/prism/duotone-space";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "reactstrap";
import Icon from "../../icon/Icon";
import axios from "axios";
import { convertDate } from "../../../shared/shared";
import { useSelector } from "react-redux";
import { FormUtils, loadOptionsFromApi } from "../../../shared/forms";


function Form ({ form, fields, statics, isEditing, isloading, additionalComponent, componentBeforeForm, componentAfterForm,  onFieldChange, onFormSubmit }) {
  const { t, i18n } = useTranslation();

  const auth = useSelector((state) => state.auth);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState()
  const classes = useStyles();
  const [data, setData] = useState(form)
  const [_cols, set_cols] = useState([])
  const [_rows, set_rows] = useState([])

  useEffect(()=>{
    setData(form)
  },[form])







  useEffect(() => {
    set_cols([])
    set_rows([])

    async function fetchDataAndRender() {
      const rows = [];
      for (const [rowIndex, row] of fields.entries()) {

        for (const [colIndex, col] of row.entries()) {
          const selectOptions = col.chooseOptionsFromApi ?
            (await FormUtils.loadOptionsFromApi(auth, col.path, col.key, col.chooseOptionsLabelFrom, col.chooseOptionsValueFrom) || null) :
            (col.options || null)

          set_cols(prevState => ([
            ...prevState,
            {
              ...col,
              options: selectOptions,
              rowLength: row.length,
              rowIndex: rowIndex
            }
          ]))
        }


        set_rows(prevState => ([
          ...prevState,
          {
            rowIndex: rowIndex,
            rowLength: row.length
          }
        ]))
      }



    }

    fetchDataAndRender();
  }, []);

  return (
    <Content page="component">
      <PreviewCard>
        <div className="form-container">
          {componentBeforeForm}

          {
            !_rows ? '' :
                _rows.map((row, rIndex)=>{

                  return(
                      <Row key={`row-${rIndex}`} className={`field-item-container`}>
                        {
                          !_cols ? '' :
                              _cols.map((col, cIndex) => {
                                return(
                                    <>
                                      {
                                        rIndex !== col.rowIndex ? '' :
                                            (
                                                <Col  className={`field-item ${col.type}`} key={`col-${cIndex}-${col.rowIndex}`}>
                                                  <div className="field-item-title-and-desc">
                                                    <div className="field-item-title">
                                                      {col.title}
                                                    </div>
                                                    <div className="field-item-description">
                                                      {col.description}
                                                    </div>
                                                  </div>
                                                  <div className="field-item-input">
                                                    <Field
                                                        id={col.slug}
                                                        name={col.slug}
                                                        isRequired={col.isRequired}
                                                        disabled={col.disabled || false}
                                                        isJalali={col.isJalali}
                                                        options={col.options}
                                                        format={col.format}
                                                        treeSection={col.treeSection}
                                                        treeMode={col.treeMode}
                                                        onChange={(value) => onFieldChange({ [col.slug]: value })}
                                                        value={form[col.slug]}
                                                        type={col.type}
                                                        className={'post-image-uploader'}
                                                        fileUploadType={'post'}
                                                        multiple={false}
                                                    />
                                                  </div>

                                                </Col>
                                            )

                                      }
                                    </>

                                )
                              })
                        }
                      </Row>
                  )
                })
          }



        </div>
      </PreviewCard>

      {additionalComponent}

      <div className="form-button-container">
        <button type={"button"} className="btn btn-primary form-submit-btn" disabled={isloading} color="primary" size="lg" onClick={()=>{
          onFormSubmit()
        }}>
          {isEditing ? statics.editSubmitText : (statics.submitText || 'ایجاد')}
        </button>
      </div>

    </Content>
  );
};

export default Form;

export function FormIsLoading(){
  return(
      <div className="align-center justify-center w-100">
        در حال بارگذاری...
      </div>
  )
}


const useStyles = makeStyles((theme) => ({
    formRow: {
      marginTop: "0px",
    }
  })
);

