
import React, {useEffect, useState} from "react";
import {Field} from "../../../../../../../components/fouladyar/field/field";
import ImageFileUpload from "../../../../../../components/fxpilot/imageUploaderV2";


export const Step2 = ({data, onChange}) => {


    const [files, setFiles] = useState();
    const [courseImageInfo, setCourseImageInfo] = useState({
        image: '',
        imageCaption: '',
        imageTags: '' //comma separator
    });


    useEffect(() => {
        setCourseImageInfo({
            image: data.image,
            imageCaption: data.imageCaption || '',
            imageTags: data.imageTags || '',
        })
    }, [])

    useEffect(() => {
        onChange(courseImageInfo)
    }, [courseImageInfo])


    return (
        <div>
            <div className="container-fluid select-plan-container">

                <h1>{"آپلود عکس"}</h1>


                <div className="spacer"/>
                <h3 style={{border: 'unset'}}>
                    در این بخش متناسب با سرمایه و ریسک خود پلن مورد نظر را انتخاب کنید
                </h3>
                <p>
                    لطفا در انتخاب طرح مورد نظر دقت کافی داشته باشید، چرا که بعد از انجام عملیات پرداخت، امکان تغییر
                    طرح وجود نداشته و مسئولیت آن تماما بر عهده خریدار خواهد بود. لذا در بررسی طرح مورد نظر خود را
                    متناسب با نیاز خود تهیه کنید.
                </p>
            </div>
            <div className="course-image-upload">
                <ImageFileUpload
                    onChange={(e) => {
                        setFiles(e);
                        console.log(e)
                    }}
                />
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"کپشن عکس"}
                    id={"imageCaption"}
                    name={"imageCaption"}
                    type={'text'}
                    defaultValue={courseImageInfo.imageCaption}
                    onChange={(e) => {
                        setCourseImageInfo(prevState => {
                            return{
                                ...prevState,
                                imageCaption: ''
                            }
                        })
                    }}
                    onBlur={(e)=> {
                    }}
                />
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"تگ ها"}
                    id={"imageTags"}
                    name={"imageTags"}
                    type={'text'}
                    defaultValue={courseImageInfo.imageTags}
                    onChange={(e) => {
                        setCourseImageInfo(prevState => {
                            return{
                                ...prevState,
                                imageTags: ''
                            }
                        })
                    }}
                    onBlur={(e)=> {
                    }}
                />
            </div>

        </div>
    );
};