import React from "react";

export const Footer = () => {
    return(
        <footer style={{
            padding: '10px',
            textAlign: 'center',
            fontSize: '13px',
            borderTop: '1px solid rgb(81 81 81)',
            marginTop: "100px",
            fontFamily: 'dana, sans-serif',
            color: "#8f8f8f"
        }}>
            &copy; {new Date().getFullYear()}{` کلیه حقوق محفوظ است.طراحی و توسعه توسط تیم  ${process.env.APPLICATION === 'fxpilot' ?  'اف ایکس پایلت': 'مستر پراپ فاند'} `}
        </footer>
    )
}