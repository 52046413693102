import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {useTranslation} from "react-i18next";

import BannerIcon from "../../../assets/images/fxpilot/home-banner-icon.png";
import {FxPilotMenu} from "../../../layout/menu/MenuData";
import Banner from "../../../components/fouladyar/banner";
import {Field} from "../../../components/fouladyar/field/field";

const FxpltHome = () => {
    const auth = useSelector((state) => state.auth);

    const {id} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('tab1');
    const profileState = useSelector((state) => state.profile);
    const [modalComponent, setModalComponent] = useState(<div> empty </div>);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ideas, setIdeas] = useState({
        fullname: '',
        platform: '',
        phone: '',
        email: '',
        description: ''
    });


    useEffect(() => {

    }, []);


    function Services() {
        function ServiceItem({item}) {
            return (
                <div className="fxplt-home-service-item" onClick={() => {
                    navigate(item.link)
                }}>
                    <div className="fxplt-home-service-item-title">
                        <div className={`fxplt-logo  ${item.className ? (item.className + ' active') : ''}`}>
                            {item.icon}
                        </div>
                        <h3>{item.text}</h3>
                    </div>
                    <div className="fxplt-home-service-item-description">
                        <p>{item.description}</p>
                    </div>
                    <div className="fxplt-home-service-item-tags">
                        {
                            item.hashtags?.map(i => {
                                return (
                                    <div className="fxplt-home-service-item-tag-item">
                                        <span># {i}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }

        return (
            <div className="fxplt-home-service-container">
                {
                    FxPilotMenu[0]?.subMenu?.filter(i => i.useInHome)?.map(item => {
                        return (
                            <ServiceItem item={item}/>
                        )
                    })
                }
            </div>
        )
    }


    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <div className="fxplt-home-container ">
                <h5 className="morabba-font fxplt-home-title">
                    خانه
                </h5>
                <Banner
                    title={"جتسجو اکسپرت های متاتریدر"}
                    description={"شسیبش شسنیمبک شسنب شسکبنشسبت شسنیب کشسب شسبتشس)"}
                    image={<img src={BannerIcon} alt={"asdfasd"}/>}
                    link={"/profile/tradingview"}
                />
                <Services/>
            </div>

            <div className="divider"/>

            <div className="fxplt-idea-form">
                <h1>
                    به زودی در این بخش قابلیت های جدیدی هم اضافه خواهند شد
                </h1>
                <h3>
                    در صورتی که نیاز یا ایده ای دارید، میتوانید از طریق فرم زیر با تیم ما درمیان بگذارید. در صورتی که
                    ایده یا نیاز مورد تایید تیم قرار گیرد در اسرع وقت در پنل ایجاد و در اختیار کاربران قرار خواهد گرفت
                </h3>
                <div>
                    <div className="kyc-form-row">
                        <Field
                            label={"نام و نام خانوادگی"}
                            id={"fullName"}
                            name={"fullName"}
                            type={'text'}
                            value={ideas.fullname}
                            onChange={(e) => {
                                setIdeas(prevState => {
                                    return {
                                        ...prevState,
                                        fullname: e
                                    }
                                })
                            }}
                        />
                        <Field
                            label={"پلتفرم"}
                            id={"platform"}
                            name={"platform"}
                            type={'text'}
                            value={ideas.platform}
                            onChange={(e) => {
                                setIdeas(prevState => {
                                    return {
                                        ...prevState,
                                        platform: e
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className="kyc-form-row">
                        <Field
                            label={"تلفن"}
                            id={"phone"}
                            name={"phone"}
                            type={'text'}
                            value={ideas.phone}
                            onChange={(e) => {
                                setIdeas(prevState => {
                                    return {
                                        ...prevState,
                                        phone: e
                                    }
                                })
                            }}
                        />
                        <Field
                            label={"ایمیل"}
                            id={"email"}
                            name={"email"}
                            type={'text'}
                            options={ideas.email}
                            value={ideas.city}
                            onChange={(e) => {
                                setIdeas(prevState => {
                                    return {
                                        ...prevState,
                                        platform: e
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className="kyc-form-row">
                        <Field
                            label={"توضیحات"}
                            id={"description"}
                            name={"description"}
                            type={'textarea'}
                            value={ideas.description}
                            onChange={(e) => {
                                setIdeas(prevState => {
                                    return {
                                        ...prevState,
                                        description: e
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className="kyc-form-row">

                        <button className="btn green-button kyc-submit-button w-100" type="button" onClick={() => {

                        }}>
                            ارسال
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
};

export default FxpltHome;
