export const fetchCourseData = async () => {
    const response = await fetch('/api/course');
    return response.json();
};

export const saveDraft = async (data) => {
    await fetch('/api/course/draft', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });
};
