import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from "react-icons/md";
import React from "react";


export default function ExpandableReadMoreBox({open, content, setOpen, className}){
    return(
        <div className={`fxplt-expandable-text-tutorial ${className} ${open ? 'open' : ''}`}>
            <div className={`black ${open ? 'hidden' : ''}`}/>
            {content}
            <button className="btn expand-btn dana-font mb-4" type="button" onClick={() => {setOpen()}}>
                {
                    open ?  "مطالعه کمتر" : "مطالعه بیشتر"
                }
                {
                    open ?
                        <MdOutlineKeyboardArrowUp size={24} color={"#fff"}/>:
                        <MdOutlineKeyboardArrowDown size={24} color={"#fff"}/>
                }
            </button>
        </div>
    )
}