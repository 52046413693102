import React, {useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Lesson from './Lesson';
import {RiArrowDropDownFill, RiArrowDropUpFill, RiMenuFill} from "react-icons/ri";
import {Field} from "../../../../../../../components/fouladyar/field/field";
import {toFarsiNumber} from "../../../../../../../shared/toFarsiNumber";

const Chapter = ({chapter, onUpdateField, onLessonsChange}) => {
    const [lessons, setLessons] = useState(chapter.lessons);
    // State to track which chapter is expanded
    const [expandedLessons, setExpandedLessons] = useState({});

    useEffect(() => {
        console.log('لسن آپدیت شد')
        if (lessons)
            onLessonsChange(lessons);
    }, [lessons])

    const handleToggleLesson = (lessonId) => {
        setExpandedLessons((prev) => ({
            ...prev,
            [lessonId]: !prev[lessonId],
        }));
    };
    const handleOnDragEnd = (result) => {
        const {source, destination} = result;

        // Dropped outside the list
        if (!destination) return;

        // Reorder lessons
        const items = Array.from(lessons);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);

        setLessons(items); // Update the lessons order
    };

    const addNewLesson = (courseId) => {
        // Define a new empty lesson object
        const newLesson = {
            id: `ls${Date.now()}`, // Unique ID
            title: `عنوان درس ${toFarsiNumber(lessons.length + 1)}`,
            order: 0, // Adjust order as needed
            lastModify: new Date().toISOString(),
            content: {
                description: "",
                type: "",
                url: null,
                attachments: []
            },
        };

        // Update the state
        setLessons((prevLessons) => {
            return [
                ...prevLessons,
                newLesson
            ]
        });
    };

    return (
        <div className="edit-chapter">
            <div>
                <div className="container-fluid select-plan-container">
                    <h1>{"پیش نمایش"}</h1>
                    <div className="spacer"/>
                    <h3 style={{border: 'unset'}}>
                        {chapter.title}
                    </h3>
                    <p className={"chapter-preview"}>
                        {
                            !chapter.description ? '' : <div dangerouslySetInnerHTML={{__html: chapter.description}}/>
                        }
                    </p>
                </div>
                <div className="kyc-form-row">
                    <Field
                        label={"عنوان فصل"}
                        id={"chapter-title"}
                        name={"chapter-title"}
                        type={'text'}
                        defaultValue={chapter.title}
                        onChange={(e) => {
                        }}
                        onBlur={(e) => {
                            // onChange({shortDescription: e.target.value})
                            onUpdateField(chapter.id, 'title', e.target.value);
                        }}
                    />
                </div>
                <div className="kyc-form-row">
                    <Field
                        className="w-100"
                        label={"توضیحات فصل"}
                        id={"chapter-description"}
                        name={"chapter-description"}
                        type={'editor'}
                        value={chapter.description}
                        onChange={(value) => {
                            onUpdateField(chapter.id, 'description', value);
                        }}
                        onBlur={(e) => {
                            // console.log('editor', e)
                            // onUpdateField(chapter.id, 'description', e.target.value);
                        }}
                    />
                </div>
            </div>
            {/* Drag-and-Drop context */}
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="edit-lessons" direction="vertical">
                    {(provided) => (
                        <div
                            className="edit-lessons"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {lessons.map((lesson, index) => (
                                <Draggable key={lesson.id} draggableId={lesson.id} index={index}>
                                    {(provided) => (
                                        <div
                                            className="edit-lesson"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <div className="edit-lesson-header">
                                                {/* Drag Handle (Only the button or icon to drag) */}
                                                <div
                                                    className="drag-handle"
                                                    {...provided.dragHandleProps}
                                                >
                                                    <RiMenuFill color={"#A0A0A0"} size={18}/>
                                                </div>


                                                <h2>{lesson?.title}</h2>
                                                {/* Expand/Collapse Button */}
                                                {/* Expand/Collapse Button */}
                                                <div className="d-flex flex-row justify-content-between align-items-center ">

                                                    <div className="delete-lesson" onClick={() => {
                                                        setLessons([
                                                            ...lessons.filter(l => {
                                                                if (l.id !== lesson.id)
                                                                    return l
                                                            })
                                                        ])
                                                    }}>
                                                        حذف درس
                                                    </div>
                                                    <div
                                                        className="expand-collapse-btn"
                                                        onClick={() => handleToggleLesson(lesson.id)}
                                                    >


                                                        {expandedLessons[lesson.id] ?
                                                            <RiArrowDropUpFill color={"#fff"} size={24}/> :
                                                            <RiArrowDropDownFill color={"#fff"} size={24}/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Chapter Content (Only shown if expanded) */}
                                            {expandedLessons[lesson.id] && (
                                                <Lesson
                                                    lesson={lesson}
                                                    onChange={(lesson) => {
                                                        setLessons([
                                                            ...lessons.map(l => {
                                                                if (l.id === lesson.id)
                                                                    return (lesson)

                                                                return l
                                                            })
                                                        ])
                                                    }}/>
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}

                            <div className="saving-draft-btn" onClick={() => {
                                addNewLesson(chapter.id)
                            }}>
                                + ایجاد درس
                            </div>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default Chapter;
