export const tableStatics = {
    title: "کمپین ها",
    description: "",
    addNewItemButtonLink: '/campaign-add'
}
export const formStatics = {
    title: "ایجاد کمپین",
    editTitle: "ویرایش کمپین",
    description: "از اینجا میتوانید اقدام به ایجاد کمپین کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش کمپین کنید",
    submitText: "ایجاد کمپین",
    editSubmitText: "ویرایش کمپین",
}


export const EmptyStateObject = {
    title: "کمپین فعال یافت نشد",
    content: "از این بخش میتوانید به اقدام به ایجاد یا ویرایش کمپین ها کنید.",
    buttonText: "ایجاد کمپین جدید",
    buttonOnClickUrl: "/campaign-add"
}




import CampaignImage from "../../../assets/images/mrpropfund/campaign-background.png";

export const TableActionsStructure = [
    {
        title: "ایجاد کمپین",
        description: "با ایجاد یک کمپین جدید، هدایا و یا تخفیف های مختلفی را به مشتریان خود اعطا کنید",
        image: CampaignImage,
        link: '/campaign-add'
    },
]

export const filterStructure = [
    {
        title: "عنوان کمپین",
        slug: 'title',
        type: "text",
    },
    {
        title: "نوع کمپین",
        slug: 'type',
        type: "select",
        chooseOptionsFromApi: true,
        path: '/api/categories?section=campaign',
        key: 'categories',
        chooseOptionsLabelFrom: 'name',
        chooseOptionsValueFrom: 'slug',
        options: [],
    },
    {
        title: "تاریخ انقضا",
        slug: 'expirationDate',
        disabled: true,
        type: "date",
    },
    {
        title: "وضعیت",
        slug: 'status',
        type: "select",
        options: [
            {label: 'فعال', value: 'active'},
            {label: 'غیر فعال', value: 'not_active'},
        ],
    },
]

export const tableStructure = [
    {
        title: "عنوان کمپین",
        slug: 'title',
    },
    {
        title: "نوع کمپین",
        slug: 'type',
        useTranslate: true
    },
    {
        title: "تاریخ انقضا",
        slug: 'expirationDate',
        useJalaliFormat: true,
        useFarsiNumber: true
    },
    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "ویرایش کمپین",
                slug: "edit-campaign",
                useRoute: true,
                route: "/app/ecommerce/campaign-edit/:_id"
            },
            {
                title: "حذف کمپین",
                slug: 'delete-campaign',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف کمپین',
                modalTitle: 'حذف کمپین',
                modalContent: 'ایا از حذف این کمپین اطمینان دارید؟ با حذف این کمپین داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/campaigns/:_id',
            },
            {
                title: "غیرفعال کردن",
                slug: 'deactivate-campaign',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'غیرفعال کردن',
                modalTitle: 'غیر فعالسازی کمپین',
                modalContent: 'با غیر فعال کردن این کمپین، دیگر در صفحه تسویه حساب به کاربر نمایش داده نخواهد شد',
                modalType: "put",
                modalPath: '/api/campaigns/deactivate/:_id',
            },
            {
                title: "فعال کردن",
                slug: 'activate-campaign',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'فعال کردن',
                modalTitle: 'غیر فعالسازی کمپین',
                modalContent: 'با فعال کردن این کمپین، این آیتم در صفحه تسویه حساب به کاربر نمایش داده خواهد شد',
                modalType: "put",
                modalPath: '/api/campaigns/activate/:_id',
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "عنوان کمپین",
            description: "عنوان کمپین باید شامل نوع کمپین (تخیف  یا ضریب)  و دلیل کمپین (اعیاد یا مناسبت) باشد",
            slug: 'title',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "توضیحات",
            description: "در صورتی که شرایط و قواعدی جهت شرکت در این کمپین وجود دارد لطفا در این بخش عنوان کنید\n",
            slug: 'description',
            type: "textarea",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "دسته بندی",
            description: "دسته بندی کمپین",
            slug: 'categoryId',
            type: "select",
            options: [
                {label: 'اعمال ضریب', value: '667d374e6a8ff41756eefc55'},
                {label: 'اعمال تخفیف', value: '667d37586a8ff41756eefc58'},
            ],
            // chooseOptionsFromApi: true,
            // path: '/api/categories?section=campaign',
            // key: 'categories',
            // chooseOptionsLabelFrom: 'name',
            // chooseOptionsValueFrom: '_id',
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "مقدار",
            description: "مقداری که این کمپین با توجه به نوع آن قرار است به کاربران اعطا کند. به عنوان مثال ۱۰ درصد تخیف یا ضریب اکانت ۲",
            slug: 'amount',
            type: "number",
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "تاریخ انقضا",
            description: "با استپفاده از این تاریخ، کمیپن شما غیر فعال خواهد شد. شما قادر خواهید بود آن را مجدد فعال و تاریخ جدید ثب کنید",
            slug: 'expirationDate',
            type: "date",
            placeholder: "",
            isRequired: true,
        },
    ],
    // [
    //
    //     {
    //         title: "وضعیت",
    //         description: "قبل از ارسال این کمپین میتوانید وضعیت آن را مشخص کنید",
    //         slug: 'status',
    //         type: "select",
    //         placeholder: "",
    //         options: [
    //             {label: 'فعال', value: 'beginner'},
    //             {label: 'غیر فعال', value: 'professional'},
    //         ],
    //         isRequired: true,
    //     },
    // ],
]
