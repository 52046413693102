import axios from "axios";

export async function validateToken(token) {
  try {


    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/check`, {
      headers: { "authorization": `Bearer ${token}` }
    });

    console.log('auth', res);
    if (res.data.statusCode === 200 ) {
      console.log("you already loggedIn");
      return  true;
    }
    return  false
  }catch (e){
    return  false;
  }

}
