import React, {useEffect, useState} from "react";
import Form, {FormIsLoading} from "../../../components/fouladyar/form";
import {useDispatch, useSelector} from "react-redux";
import {formStatics, formStructure} from "./index";
import axios from "axios";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";

function CategoryAdd({section, callback}) {
    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const location = useLocation();
    const isEditing = location.pathname.includes("category-edit");
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [isLoading, setIsloading] = useState(isEditing ? true : false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [data, setData] = useState({
        node: {},
        "name": "",
        "slug": "",
        "description": "",
        "section": section
    });

    async function loadData() {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories/${id}`, {
            headers: {authorization: `Bearer ${auth.token}`}
        })
        console.log('loaddata', res.data)
        if (res.data.statusCode === 200) {

            setData((prevData) => ({
                ...prevData,
                ...res.data.data,
                "name": res.data.data.name,
                "slug": res.data.data.slug,
                "description": res.data.data.description || '',
                "section": res.data.data.section
            }));
            setIsloading(false);
            return data;
        }

        return {};

    }


    useEffect(() => {
        if (isEditing)
            loadData();
    }, []);


    async function onCreate() {
        try {
            setIsSubmitting(true)
            console.log('data',data);
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/categories`,
                {
                    name: data.name,
                    slug: data.slug,
                    section: data.section,
                    parentId: data.node._id ? data.node._id : null,
                },
                {
                    headers: {authorization: `Bearer ${auth.token}`}
                })

            if (res.data.statusCode === 200) {
                navigate(callback);
            }

            setIsSubmitting(false)

        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    async function onUpdate() {
        try {

            setIsSubmitting(true)
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/categories/${id}`,
                    data,
                {
                    headers: {
                        "authorization": `Bearer ${auth.token}`
                    }
                }
            );

            if (res.data.statusCode === 200) {
                navigate(callback);
            }

            setIsSubmitting(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }

    function handleOnFieldChange(change) {
        setData((prevData) => ({
            ...prevData,
            ...change
        }));
    }

    async function handleOnSubmit() {
        !isEditing ? await onCreate() : await onUpdate();

    }
    return (

        <>
            {
                isLoading ?
                    <FormIsLoading
                        statics={formStatics}
                        isEditing={isEditing}
                    />
                    :
                    <Form
                        form={data}
                        isEditing={isEditing}
                        fields={formStructure}
                        statics={formStatics}
                        isloading={isSubmitting}
                        submitButtonText={"ایجاد دسته بندی"}
                        onFieldChange={handleOnFieldChange}
                        onFormSubmit={handleOnSubmit}
                    />
            }
        </>

    );
};

export default CategoryAdd;
