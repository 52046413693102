
import React from "react";
import CourseTemporary from "../../../../../assets/images/fxpilot/course-temporary.png";

import {useNavigate} from "react-router-dom";
import {MoneySeparator} from "../../../../../shared/moneySeparator";
import {toFarsiNumber} from "../../../../../shared/toFarsiNumber";
import Progress from "../../../../../components/progress/Progress";

export function CourseItem({item}) {
    const navigate = useNavigate();
    const courseId = 'xx'
    return (
        <div className="fxplt-course-item">
            <div className="fxplt-course-item-image" onClick={() => {
                navigate(`/course/overview/${courseId}`);
            }}>
                <img src={CourseTemporary} alt="" />
            </div>

            <div className="fxplt-course-item-title-and-content">
                <h2>{item.title}</h2>
                <h5>{item.content}</h5>
            </div>
            <Progress className={"fxplt-course-item-progress-bar"} value="18" />
            <div className="fxplt-course-item-creator-and-price">
                <h5>{item.creator}</h5>
                <h3>{toFarsiNumber(MoneySeparator(item.price))} تومان </h3>
            </div>
        </div>
    );
}

