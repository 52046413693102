import React, {useEffect, useState} from "react";
import Form, {FormIsLoading} from "../../../components/fouladyar/form";
import {useDispatch, useSelector} from "react-redux";
import {formStatics, formStructure} from "./index";
import axios from "axios";
import {convertDate} from "../../../shared/shared";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";


function OrderAdd({...props}) {

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const location = useLocation();
    const isEditing = location.pathname.includes("plane-edit");
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const path = '/Planes/Plane'
    const dispatch = useDispatch();
    const [isLoading, setIsloading] = useState(isEditing ? true : false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [data, setData] = useState({
        "title": "تخفیف عید سعید فطر",
        "description": "با استفاده از این کمپین قادر به دریافت ۱۰ درصد تخفیف روی پرداخت خود خواهید بود",
        "amount": "15",
        "type": "discount",
        "target": [],
        "expirationDate": "2024-07-18T10:16:12.362+00:00"
    });

    async function loadData() {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/campaigns/${id}`, {
            headers: {authorization: `Bearer ${auth.token}`}
        })
        console.log('loaddata', res.data)
        if (res.data.statusCode === 200) {

            setData((prevData) => ({
                ...prevData,
                ...res.data.data,
                "title": res.data.data.title,
                "description": res.data.data.description,
                "amount": res.data.data.amount,
                "type": res.data.data.type,
                "target": res.data.data.target,
                "expirationDate": new Date(res.data.data.expirationDate)
            }));
            setIsloading(false);
            return data;
        }

        return {};

    }


    useEffect(() => {
        if (isEditing)
            loadData();
    }, []);


    async function onCreate() {
        try {
            setIsSubmitting(true)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/campaigns/`,
                data,
                {
                    headers: {authorization: `Bearer ${auth.token}`}
                })

            if (res.data.statusCode === 200) {
                navigate(`/campaign-list`);
            }

            setIsSubmitting(false)

        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    async function onUpdate() {
        try {

            setIsSubmitting(true)
            let _data = {...data}
            delete _data.insuranceImage;
            delete _data.flightPermitImage;
            delete _data.insuranceImagePreview;
            delete _data.flightPermitImagePreview;
            const res = await axios.put(`${process.env.REACT_APP_API_URL}${path}`,
                {
                    ..._data,
                    "engineOverhaulDate": data.birthDate === '' ? null : convertDate(data.engineOverhaulDate),
                    "propellerOverhaulDate": data.birthDate === '' ? null : convertDate(data.propellerOverhaulDate),
                    "flightPermitDate": data.birthDate === '' ? null : convertDate(data.flightPermitDate)
                }, {
                    headers: {
                        "authorization": `bearer ${auth.token}`
                    }
                }
            );

            if (res.data.statusCode === 200) {

                if (data.flightPermitImage.length > 0) {
                    await uploadImage({
                        file: data.flightPermitImage[0].file,
                        user: res.data.data.user.id,
                        type: 3
                    })
                }

                if (data.insuranceImage.length > 0) {
                    await uploadImage({
                        file: data.insuranceImage[0].file,
                        user: res.data.data.user.id,
                        type: 2
                    })

                }
                navigate(`/plane-list`);
            }


            setIsSubmitting(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    function handleOnFieldChange(change) {
        setData((prevData) => ({
            ...prevData,
            ...change
        }));
    }

    async function handleOnSubmit() {
        !isEditing ? await onCreate() : await onUpdate();

    }

    return (

        <>
            {
                isLoading ?
                    <FormIsLoading
                        statics={formStatics}
                        isEditing={isEditing}
                    />
                    :
                    <Form
                        form={data}
                        isEditing={isEditing}
                        fields={formStructure}
                        statics={formStatics}
                        isloading={isSubmitting}
                        submitButtonText={"ایجاد کمپین جدید"}
                        onFieldChange={handleOnFieldChange}
                        onFormSubmit={handleOnSubmit}
                    />
            }
        </>


    );
};

export default OrderAdd;
