import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {ImBookmark} from "react-icons/im";
import {TfiBookmark} from "react-icons/tfi";
import getFileIcon from "../utils/getFileIcon";
import {AiOutlineMore} from "react-icons/ai";

const FileGridView = ({ files, onToggleFavorite }) => {
    return (
        <div className="grid-view">
            {files.map((file) => (
                <div key={file.id} className="file-card">
                    <div className="file-image-container">

                        {file.preview ? <img src={file.preview} alt={file.name} /> : getFileIcon(file.name.split(".").pop())}

                        {file.isFavorite ? (
                            <ImBookmark  className={'favorite-file'} color={"#38AF84"} onClick={() => onToggleFavorite(file.id)} />
                        ) : (
                            <TfiBookmark className={'favorite-file'} color={"#fff"}  onClick={() => onToggleFavorite(file.id)} />
                        )}
                    </div>
                    <div className="file-details">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="file-name">{file.name}</div>
                            <AiOutlineMore  color={"#fff"}/>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                            <div className="file-meta">
                                <div>{file.createdAt}</div>
                                |
                                <div>{file.size}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FileGridView;
