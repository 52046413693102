import {TiHomeOutline} from "react-icons/ti";
import {RiRobot2Line, RiShieldKeyholeLine} from "react-icons/ri";
import {
    MdOutlineAccountBalance,
    MdOutlineCandlestickChart,
    MdOutlineKey,
    MdOutlinePodcasts,
    MdOutlineReceipt,
    MdOutlineSupportAgent
} from "react-icons/md";
import {AiOutlineSetting} from "react-icons/ai";
import {BOOK, TradingView} from "../../shared/svg";
import React from "react";
import {LiaFileSignatureSolid} from "react-icons/lia";
import {FaRegFolderOpen} from "react-icons/fa";

const fontSize = 19;
const color = "#6e82a5";

export const FxpilotMenu = [
    // {
    //   heading: "منو فولادیار",
    // },

    // {
    //   heading: "مدرسه پرواز آسو"
    // },
    {
        text: "خانه",
        link: "/fxpilot/home",
        roles: ['admin', 'customer'],
        dashboard: true
    },
    {
        text: "پشتیبانی",
        link: "/fxpilot/support",
        roles: ['admin'],
        dashboard: true
    },
    {
        text: "تیکت ها",
        link: "/fxpilot/ticket-list",
        roles: ['customer'],
        dashboard: true
    }
];
export const menu = [
    // {
    //   heading: "منو فولادیار",
    // },

    // {
    //   heading: "مدرسه پرواز آسو"
    // },
    {
        text: "داشبورد",
        link: "/dashboard",
        roles: ['admin', 'customer'],
        dashboard: true
    },
    {
        text: "فروشگاه",
        link: "/app/ecommerce",
        roles: ['admin'],
        dashboard: true
    },
    {
        text: "وبسایت",
        link: "/app/website",
        roles: ['admin'],
        dashboard: true
    },
    {
        text: "فضای ابری",
        link: "/app/cloud-storage",
        roles: ['admin'],
        applications: ['fxpilot'],
        dashboard: true
    },
    /*    {
            text: "خدمات",
            link: "/app/services",
            roles: ['admin'],
            dashboard: true
        },*/
    {
        text: "حساب ها",
        link: "/account-list",
        roles: ['customer'],
        dashboard: true
    },
    {
        text: "پنل آنالیز",
        link: "/analytics",
        roles: ['customer'],
        dashboard: true
    },
    {
        text: "تسویه حساب ها",
        link: "/withdraw-list",
        roles: ['customer'],
        dashboard: true
    },
    {
        text: "پشتیبانی",
        link: "/app/support",
        roles: ['admin'],
        dashboard: true
    },
    {
        text: "تیکت ها",
        link: "/ticket-list",
        roles: ['customer'],
        dashboard: true
    }
];

export const SupportAppMenu = [
    {
        "text": "پشتیبانی",
        "roles": ["admin", "customer"],
        "active": false,
        "subMenu": [
            {
                "text": "تیکت ها",
                "link": "/app/support/ticket-list",
                "roles": ["admin"]
            },
            {
                "text": "دسته بندی",
                "link": "/app/support/category-list",
                "roles": ["admin"]
            },
            {
                "text": "تسویه حساب ها",
                "link": "/app/support/withdraw-list",
                "roles": ["admin"]
            },
            /*            {
                            "text": "سری مقالات",
                            "link": "/app/website/post-series-list",
                            "roles": ["admin"]
                        },
                        {
                            "text": "نظرات",
                            "link": "/app/website/comment-list",
                            "roles": ["admin"]
                        }*/
        ]
    },
    /*    {
            "text": "تنظیمات",
            "roles": ["admin"],
            "active": false,
        }*/
]

export const WebsiteAppMenu = [
    {
        "text": "مقالات",
        "roles": ["admin", "customer"],
        "active": false,
        "subMenu": [
            {
                "text": "مقالات",
                "link": "/app/website/post-list",
                "roles": ["admin"]
            },
            {
                "text": "سری مقالات",
                "link": "/app/website/post-series-list",
                "roles": ["admin"],
                "applications": ["fxpilot"],
            },
            {
                "text": "دسته بندی",
                "link": "/app/website/category-list",
                "roles": ["admin"]
            },
            /*            {
                            "text": "سری مقالات",
                            "link": "/app/website/post-series-list",
                            "roles": ["admin"]
                        },
                        {
                            "text": "نظرات",
                            "link": "/app/website/comment-list",
                            "roles": ["admin"]
                        }*/
        ]
    },
    /*    {
            "text": "تنظیمات",
            "roles": ["admin"],
            "active": false,
        }*/
]

export const EcommerceAppMenu = [
    {
        "text": "فروشگاه",
        "roles": ["admin"],
        "active": false,
        "subMenu": [
            {
                "text": "محصولات",
                "link": "/app/ecommerce/product-list",
                "roles": ["admin"]
            },
            {
                "text": "دسته بندی",
                "link": "/app/ecommerce/category-list",
                "roles": ["admin"]
            },
            {
                "text": "مشتریان",
                "link": "/app/ecommerce/customer-list",
                "roles": ["admin"]
            },
            {
                "text": "تنوع کالا",
                "link": "/app/ecommerce/variety-list",
                "roles": ["admin"]
            },
            process.env.APPLICATION === 'fxpilot' ?
                {
                    "text": "دوره ها",
                    "link": "/app/ecommerce/lms/course/manage-courses",
                    "roles": ["admin"]
                }: '',
            /*            {
                            "text": "الگوها",
                            "link": "/app/ecommerce/patterns",
                            "roles": ["admin"]
                        },*/
            {
                "text": "سفارشات",
                "link": "/app/ecommerce/order-list",
                "roles": ["admin"]
            },
            {
                "text": "افزونه ها",
                "link": "/app/ecommerce/add-on-list",
                "roles": ["admin"]
            },
            {
                "text": "کمپین ها",
                "link": "/app/ecommerce/campaign-list",
                "roles": ["admin"]
            },
            {
                "text": "تخفیف ها",
                "link": "/app/ecommerce/discount-list",
                "roles": ["admin"]
            },
            /*            {
                            "text": "نظرات",
                            "link": "/app/ecommerce/comment-list",
                            "roles": ["admin"]
                        }*/
        ]
    },
    /*    {
            "text": "تنظیمات",
            "roles": ["admin"],
            "active": false,
        }*/
]

export const ServicesAppMenu = [
    {
        "text": "خدمات",
        "roles": ["admin"],
        "active": false,
        "subMenu": [
            {
                "text": "احراز هویت صرافی",
                "link": "/app/services/kyc-list",
                "roles": ["admin"]
            },
            {
                "text": "سفارش احراز هویت",
                "link": "/app/services/kyc-add",
                "roles": ["admin"]
            },
            {
                "text": "لایسنس ها",
                "link": "/app/services/license-list",
                "roles": ["admin"]
            },
            {
                "text": "ربات ها",
                "link": "/app/services/bot-list",
                "roles": ["admin"]
            },
            {
                "text": "پنل آنالیز",
                "link": "/app/services/add-on-list",
                "roles": ["admin"]
            },
            {
                "text": "پنل Tradingview",
                "link": "/app/services/add-on-list",
                "roles": ["admin"]
            },
        ]
    },
    /*    {
            "text": "تنظیمات",
            "roles": ["admin"],
            "active": false,
        }*/
]


const fxpilotMenuPath = process.env.FXPILOT_BASE_URL
export const FxPilotMenu = [
    {
        "text": "خدمات",
        "roles": ["admin"],
        "active": false,
        "subMenu": [
            {
                "text": "خانه",
                "link": `${fxpilotMenuPath}/home`,
                "icon": <TiHomeOutline size={20} color={"#797979"}/>,
                "roles": ["customer"],
            },
            {
                "text": "ربات ها",
                "link": `${fxpilotMenuPath}/bots`,
                "description": "با استفاده از آدرس زیر قادر خواهید بود با خیال راحت کد ا بود با خیال راحت کد اکسپرت ",
                "icon": <RiRobot2Line size={20} color={"#797979"}/>,
                "roles": ["customer"],
                "useInHome": true,
                "hashtags": [
                    "مدیریت سرمایه"
                ]
            },
            {
                "text": "لایسنس ها",
                "link": `${fxpilotMenuPath}/licenses`,
                "description": "با استفاده از آدرس زیر قادر خواهید بود با خیال راحت کد ا بود با خیال راحت کد اکسپرت ",
                "icon": <MdOutlineKey size={20} color={"#797979"}/>,
                "roles": ["customer"],
                "useInHome": true,
                "hashtags": [
                    "مدیریت سرمایه"
                ]
            },
            {
                "text": "پنل سیگنال",
                "link": `${fxpilotMenuPath}/signal`,
                "description": "به راحتی سیگنال های خود را روی تلگرام دریافت کنید",
                "icon": <MdOutlinePodcasts size={20} color={"#797979"}/>,
                "roles": ["customer"],
                "badge": "بزودی",
                "useInHome": true,
                "hashtags": [
                    "سیگنال",
                    "تلگرام"
                ]
            },
            {
                "text": "پنل آنالیز(متاتریدر)",
                "link": `${fxpilotMenuPath}/license`,
                "description": "با استفاده از پنل آنالیز به راحتی معاملات معامله گران و یا شاگردان خود را مدیریت کنید",
                "icon": <MdOutlineCandlestickChart size={20} color={"#797979"}/>,
                "roles": ["customer"],
                "badge": "بزودی",
                "useInHome": true,
                "hashtags": [
                    "اساتید",
                    "آنالیز",
                    "متاتریدر"
                ]
            },
            {
                "text": "پنل Tradingview",
                "link": `${fxpilotMenuPath}/tradingview`,
                "description": "شسیبش شسنیمبک شسنب شسکبنشسبت شسنیب کشسب شسبتشس",
                "icon": <div dangerouslySetInnerHTML={{__html: TradingView}}/>,
                "roles": ["customer"],
                "className": "remove-path-bg",
                "useInHome": true,
                "hashtags": [
                    "اساتید",
                    "آنالیز",
                    "متاتریدر"
                ]
            },
            {
                "text": "احراز هویت صرافی",
                "link": `${fxpilotMenuPath}/identify`,
                "description": "با ثبت سفارش درخواست احراز هویت در صرافی های رمز ارز را داده و پس از احراز هزینه را پرداخت کنید. ",
                "icon": <RiShieldKeyholeLine size={20} color={"#797979"}/>,
                "roles": ["customer"],
                "useInHome": true,
                "hashtags": [
                    "صرافی",
                    "احرازهویت",
                    "رمز ارز",
                ]
            },
            {
                "text": "دوره های من",
                "link": `${fxpilotMenuPath}/course/mine`,
                "description": "دوره های آموزشی خود را از طریق این بخش مشاهده کنید ",
                "icon": <div dangerouslySetInnerHTML={{__html: BOOK}}/>,
                "className": "remove-path-bg",
                "useInHome": true,
                "roles": ["customer"],
                "hashtags": [
                    "آموزش",
                ]
            },
            {
                "text": "کیف پول",
                "link": `${fxpilotMenuPath}/wallet`,
                "icon": <MdOutlineAccountBalance size={20} color={"#797979"}/>,
                "roles": ["customer"],
            },
            {
                "text": "صورتحساب",
                "link": `${fxpilotMenuPath}/invoices`,
                "icon": <MdOutlineReceipt size={20} color={"#797979"}/>,
                "roles": ["customer"],
            },
/*            {
                "text": "پشتیبانی",
                "link": `${fxpilotMenuPath}/support`,
                "icon": <MdOutlineSupportAgent size={20} color={"#797979"}/>,
                "roles": ["customer"],
            },*/
/*            {
                "text": "تنظیمات",
                "link": `${fxpilotMenuPath}/support`,
                "icon": <AiOutlineSetting size={20} color={"#797979"}/>,
                "roles": ["customer"],
            },*/
        ]
    },
    /*    {
            "text": "تنظیمات",
            "roles": ["admin"],
            "active": false,
        }*/
]

export default menu;
