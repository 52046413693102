import axios from "axios";
import {ErrorToaster} from "../toaster";

export const handleUpload = async (file) => {

    try {
        // const file = files[0];


        const formData = new FormData();
        if (!file) {
            alert("Please select a file");
            return;
        }

        formData.append('file', file);
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/file/multer/upload`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',

                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    console.log(`Upload Progress: ${progress}%`);
                    // Optionally update UI with progress
                    // e.g., setUploadProgress(progress)
                },
            });

        console.log('prev-res', res.data)
        if(res.status === 200){
            return res.data.data;
        }


    } catch (error) {
        console.log('handleUpload Error: ',error)
        throw error

    }
};