
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import axios from "axios";
import {SettingItem} from "./settingItem";
import {ErrorToaster, SuccessToaster} from "../../../../../shared/toaster";



export function Settings({botId, onUpdateSettings}) {

    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState([])
    const [signalId, setSignalId] = useState('');
    const [emptySettings, setEmptySettings] = useState([
        {
                   name: 'telegram',
                   channels: [
                       {
                           username: '@username',
                           title: '',
                           description: ''
                       },
                   ]
               },
               {
                   name: 'mql5',
                   fields: [
                       [
                           {
                               title: "عنوان حساب",
                               slug: 'mql_account_name',
                               type: "text",
                               isRequired: true,
                           }
                       ],
                       [
                           {
                               title: "شماره حساب",
                               slug: 'mql_account_number',
                               type: "text",
                               isRequired: true,
                           },
                       ],
                   ]
               },
    ])
    const [settings, setSettings] = useState([

    ]);

    async function initializeData() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/signal/${botId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                if(res.data?.data?.settings.length === 0){
                    setSettings(emptySettings)
                }else{
                    setSettings(res.data?.data?.settings)
                }
                if(res.data.data?.settings?.length > 0){
                    setIsOpen(res.data?.data?.settings.map(i => {
                        return false
                    }))
                }
                setSignalId(res.data?.data?._id)
            }

            setIsLoading(false)
        } catch (e) {
            // ErrorToaster(e, t)
            setIsLoading(false)
            if(e.response.data.statusCode === 404){
                setSettings(emptySettings)
            }

        }
    }

    useEffect(() => {
        console.log('botId', botId)
        if (botId) {
            initializeData();
        }

    }, [botId]);





    return (
        <div className="fxplt-signal-container" style={{gap: '30px'}}>
            {
                settings?.map((item, index) => {
                    return(
                        <SettingItem
                            index={index}
                            item={item}
                            botId={botId}
                            onTelegramChannelFieldUpdate={(newChannelArray) => {
                                setSettings(settings.map((i,inx) => {
                                    if(i.name === 'telegram')
                                        return {
                                            ...i,
                                            channels: newChannelArray
                                        }

                                    return i
                                }))
                            }}
                            onTelegramAddChannel={(newChannelArray)=> {
                                setSettings(settings.map((i,inx) => {
                                    if(i.name === 'telegram')
                                        return {
                                            ...i,
                                            channels: newChannelArray
                                        }

                                    return i
                                }))
                            }}
                            onRemoveTelegramChannel={(newChannelArray)=> {
                                setSettings(settings.map((i,inx) => {
                                    if(i.name === 'telegram')
                                        return {
                                            ...i,
                                            channels: newChannelArray
                                        }

                                    return i
                                }))
                            }}
                        />
                    )
                })
            }


            <button
                style={{fontSize: "14px", justifyContent: 'center', padding: "12px 0px"}}
                className="btn green-bg-color dana-font mb-4" type="button"
                onClick={ () => {
                    onUpdateSettings(settings)
                }}
            >
                بروز رسانی
            </button>
        </div>
    );
}