import {Line} from "react-chartjs-2";
import React from "react";
import {useTranslation} from "react-i18next";
import annotationPlugin from "chartjs-plugin-annotation";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    BarElement,
    PointElement,
    Tooltip,
    BarController
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, annotationPlugin,BarController, LinearScale, PointElement, Tooltip, Legend);

export default function TradesByMonthLineChartComponent({trades}) {


    const {t, i18n} = useTranslation();

    function countTradesByMonth() {
        // Step 1: Initialize the object with all months from 0 to 11, each set to 0
        const tradesByMonth = {};
        for (let month = 0; month < 12; month++) {
            tradesByMonth[month] = 0;
        }

        // Step 2: Iterate through the trades and count them by month
        trades.forEach(trade => {
            const month = new Date(trade.exitTime).getUTCMonth();
            tradesByMonth[month]++;
        });

        return {tradesByMonth};
    }

    function getMonthName(monthValue) {
        // Array mapping numeric values to month names
        const monthNames = [
            'January',   // 0
            'February',  // 1
            'March',     // 2
            'April',     // 3
            'May',       // 4
            'June',      // 5
            'July',      // 6
            'August',    // 7
            'September', // 8
            'October',   // 9
            'November',  // 10
            'December'   // 11
        ];

        // Check if the monthValue is within the valid range (0-11)
        if (monthValue < 0 || monthValue > 11) {
            throw new Error('Invalid month value. It must be between 0 and 11.');
        }

        // Return the corresponding month name
        return monthNames[monthValue];
    }


    const {tradesByMonth} = countTradesByMonth();


    const data = {
        labels: Object.keys(tradesByMonth).map(item => parseInt(item) + 1),
        datasets: [
            {
                type: 'bar', // Bar dataset
                label: 'تعداد معاملات',
                data: Object.values(tradesByMonth),
                backgroundColor: '#3FC1C3', // Red color
                stack: 'Stack 0',
                barThickness: 4, // Adjust bar thickness
            },
        ],
    };


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Hide vertical grid lines
                },
                title: {
                    stacked: true,
                }
            },
            y: {
                title: {
                    stacked: true,
                }
            },
        },
    };


    return (
        <table border="1" cellPadding="10" className="w-100 mb-3">
            <thead>
            <tr>
                <th className="d-flex flex-row justify-content-between align-center">

                    <span>تعداد معاملات (براساس ماه میلادی)</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <div style={{width: '100%', height: '290px'}}>
                    <Line data={data} options={options}/>
                </div>
            </tr>

            </tbody>
        </table>
    )

}
