

import {Card, Col, Row} from "reactstrap";
import React from "react";
import {Field} from "../../field/field";
import {useTranslation} from "react-i18next";
import { Button, Icon } from "../../../Component";

export function YesOrNoModal({heading, title, content, submitMode, submitText, cancelText, onClose, onCancel, onSubmit}) {
    const { t, i18n } = useTranslation();

    const style = {
        buttons: {
            padding: "0.5em"
        },
        danaFont: {
          fontFamily: "dana !important",
          margin: "auto"
        },
        divider: {
          height: 1,
          background: "#1a1a1a",
          width: "100%"
        }
    }

    function Divider(){
      return(
        <div style={style.divider}></div>
      )
    }

    return (
        <div className="yes-or-no">
            <Button onClick={() => onClose(false)}>
                <Icon name="cross"></Icon>
            </Button>
            <h4 style={{padding: "0px 20px"}}>{heading}</h4>
            <Divider/>

            <div className="modal-content" style={{padding: "14px 20px"}}>
                <h4>{title}</h4>
                <p >{content}</p>

            </div>
            <Divider/>
            <Row className="nk-kycfm-control-list" style={{margin: "10px"}}>
              <Col sm="6" lg="6" xxl="6" xs="12" style={style.buttons}>
                <Button className={`btn btn-outline yes-or-no-btn w-100 ${submitMode === 'green' ? 'green-bg-color' : (submitMode === 'red' ? 'red-bg-color' : (submitMode === 'yellow' ? 'yellow-bg-color' : ''))}`} style={{margin: "10px 0px 0px 0px"}} onClick={onSubmit}>
                  <span className="yes-or-no-btn-txt" >{submitText}</span>
                </Button>
              </Col>
                <Col sm="6" lg="6" xxl="6" xs="12" style={style.buttons}>
                    <Button className="btn btn-light yes-or-no-btn btn-outline w-100" style={{margin: "10px 0px 0px 0px"}} onClick={onCancel}>
                        <span className="yes-or-no-btn-txt" >{cancelText}</span>
                    </Button>
                </Col>
            </Row>

        </div>
    )
}
