import React, {useEffect, useState} from "react";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../../shared/toaster";
import {IoMdClose} from "react-icons/io";
import {Field} from "../../../../components/fouladyar/field/field";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export function AddNode({node, isEditing, setIsModalClose, onComplete}) {
    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState({
        _id:  '',
        name: '',
        slug: '',
        description: '',
        section: 'product'
    })

    useEffect(()=>{
        if(node){
            if(isEditing){
                setData({
                    _id: node._id,
                    name: node.name,
                    slug: node.slug,
                    description: node.description,
                    section: node.section
                })
            }else{

                setData({
                    _id: node._id,
                    name: "",
                    slug: "",
                    description: "",
                    section: node.section
                })
            }
        }
    },[node])

    async function onSubmit() {
        try {
            setIsSubmitting(true);
            const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/categories`, {
                parentId: node._id,
                name: data.name,
                slug: data.slug,
                description: data.description,
                section: data.section,
            }, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (newCardResponse.data.statusCode === 200) {
                SuccessToaster({message: 'شاخه جدید با موفقیت ایجاد شد'}, t)
                setIsSubmitting(false);
                onComplete();
            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }
    }

    async function onEdit() {
        try {
            const res = (await axios.put(`${process.env.REACT_APP_API_URL}/api/categories/${data._id}`, {
                name: data.name,
                slug: data.slug,
                description: data.description,
                section: data.section,
            }, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (res.data.statusCode === 200) {
                SuccessToaster({message: 'شاخه مورد نظر با موفقیت ویرایش شد'}, t)
                setIsSubmitting(false);
                onComplete();
            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }
    }

    async function onDelete() {
        try {
            const res = (await axios.delete(`${process.env.REACT_APP_API_URL}/api/categories/${data._id}`, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (res.data.statusCode === 200) {
                SuccessToaster({message: 'شاخه مورد نظر با موفقیت حذف شد'}, t)
                setIsSubmitting(false);
                onComplete()
            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }
    }

    return (
        <div className="modal-container">
            <div className="close-modal-btn" onClick={() => setIsModalClose()}>
                <IoMdClose size={20} color={"#FAF4F4"}/>
            </div>
            <h4 className="modal-title">{isEditing ? "ویرایش شاخه" : "افزودن شاخه جدید"}</h4>


            <div className="modal-note">
                نکته:‌ دقت کنید شناسه شاخه باید با حروف انگلیسی وارد شود
            </div>
            <div className='modal-form'>
                <Field
                    label={"شاخه اصلی"}
                    id={"parentId"}
                    disabled={true}
                    name={"parentId"}
                    type={'text'}
                    value={data._id}
                />
                <Field
                    label={"عنوان"}
                    id={"name"}
                    name={"name"}
                    type={'text'}
                    value={data.name}
                    onChange={(e) => {
                        setData({
                            ...data,
                            name: e
                        })
                    }}
                />
                <Field
                    label={"شناسه"}
                    id={"slug"}
                    name={"slug"}
                    type={'text'}
                    value={data.slug}
                    onChange={(e) => {
                        setData({
                            ...data,
                            slug: e
                        })
                    }}
                />
                <Field
                    label={"توضیحات"}
                    id={"description"}
                    name={"description"}
                    type={'textarea'}
                    value={data.description}
                    onChange={(e) => {
                        setData({
                            ...data,
                            description: e
                        })
                    }}
                />
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"بخش"}
                    id={"section"}
                    disabled={true}
                    name={"section"}
                    type={'text'}
                    value={data.section}
                    onChange={(e) => {
                        setData({
                            ...data,
                            section: e
                        })
                    }}
                />

            </div>

            <div className="form-submit-btn" style={{opacity: isSubmitting ? 0.5 : 1}} onClick={async () => {
                if (!isSubmitting) {
                    setIsSubmitting(true);
                    isEditing ? await onEdit() : await onSubmit()
                }
            }}>
                {isEditing ? "ویرایش شاخه" : "ایجاد شاخه"}

            </div>

            {
                isEditing ?
                    <div className="form-submit-btn remove-card-btn" style={{opacity: isSubmitting ? 0.5 : 1}}
                         onClick={async () => {
                             if (!isSubmitting) {
                                 await onDelete()
                             }
                         }}>
                        حذف شاخه

                    </div> :
                    <></>
            }

        </div>
    )
}