import {Field} from "../../../../components/fouladyar/field/field";
import {provinces} from "../../../../shared/array/city";
import {countries} from "../../../../shared/array/countries";
import {Brokers} from "../../../../shared/array/broker";
import React, {useEffect, useState} from "react";

export const Step1 = ({data, onChange}) => {

    const [baseInfo, setBaseInfo] = useState({
        fullName: '',
        birthdate: '',
        phoneNumber: '',
        exchange: '',
        city: '',
        targetCountry: '',
        description: '',
    })

    async function initializeData() {
        setBaseInfo({
            fullName: data.fullName || '',
            birthdate: data.birthdate || '',
            phoneNumber: data.phoneNumber || '',
            exchange: data.exchange || '',
            city: data.city || '',
            targetCountry: data.targetCountry || '',
            description: data.description || '',
        })
    }

    useEffect(() => {
        console.log('data', data)
        initializeData()
    }, [])


    useEffect(() => {
        onChange(baseInfo)
    }, [baseInfo])


    useEffect(() => {
        initializeData()
    }, [data])

    return (
        <div>
            <div className="container-fluid select-plan-container">
                <h1>{"اطلاعات پایه"}</h1>
                <h3 style={{border: 'unset'}}>
                    در این بخش متناسب اطلاعات مورد نیاز جهت صدور مدارک فیزیکی را وارد کنید
                </h3>
                <p>
                    در وارد کردن اطلاعات زیر دقت کافی را داشته باشید چرا که پس از ثبت نهایی فرم و صدور مدارک، اطلاعات وارد شده قابل ویرایش نیستند
                </p>
                <div className="spacer"/>
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"نام و نام خانوادگی"}
                    id={"fullName"}
                    name={"fullName"}
                    type={'text'}
                    value={baseInfo.fullName}
                    onChange={(e) => {
                        setBaseInfo(prevState => {
                            return{
                                ...prevState,
                                fullName: e
                            }
                        })
                    }}
                />
                <Field
                    label={"تارخ تولد"}
                    id={"birthdate"}
                    name={"birthdate"}
                    type={'date'}
                    value={baseInfo.birthdate}
                    onChange={(e) => {
                        setBaseInfo(prevState => {
                            return{
                                ...prevState,
                                birthdate: e
                            }
                        })
                    }}
                />
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"شماره تماس"}
                    id={"phone"}
                    name={"phone"}
                    type={'text'}
                    value={baseInfo.phoneNumber}
                    onChange={(e) => {
                        setBaseInfo(prevState => {
                            return{
                                ...prevState,
                                phoneNumber: e
                            }
                        })
                    }}
                />
                <Field
                    label={"شهر محل سکونت"}
                    id={"city"}
                    name={"city"}
                    type={'select'}
                    options={provinces}
                    value={baseInfo.city}
                    onChange={(e) => {
                        setBaseInfo(prevState => {
                            return{
                                ...prevState,
                                city: e
                            }
                        })
                    }}
                />
            </div>
            <div className=" kyc-form-row">
                <Field
                    label={"صرافی مورد نظر"}
                    id={"exchange"}
                    name={"exchange"}
                    type={'select'}
                    options={Brokers}
                    value={baseInfo.exchange}
                    onChange={(e) => {
                        setBaseInfo(prevState => {
                            return{
                                ...prevState,
                                exchange: e
                            }
                        })
                    }}
                />
                <Field
                    label={"کشور مورد نظر"}
                    id={"targetCountry"}
                    name={"targetCountry"}
                    type={'select'}
                    options={countries}
                    value={baseInfo.targetCountry}
                    onChange={(e) => {
                        setBaseInfo(prevState => {
                            return{
                                ...prevState,
                                targetCountry: e.toLowerCase()
                            }
                        })
                    }}
                />
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"توضیحات تکمیلی"}
                    id={"description"}
                    name={"description"}
                    type={'textarea'}
                    value={baseInfo.description}
                    onChange={(e) => {
                        setBaseInfo(prevState => {
                            return{
                                ...prevState,
                                description: e
                            }
                        })
                    }}
                />
            </div>
        </div>
    );
};