export const countries = [
    {value: "afghanistan", label: "افغانستان"},
    {value: "albania", label: "آلبانی"},
    {value: "algeria", label: "الجزایر"},
    {value: "andorra", label: "آندورا"},
    {value: "angola", label: "آنگولا"},
    {value: "argentina", label: "آرژانتین"},
    {value: "armenia", label: "ارمنستان"},
    {value: "australia", label: "استرالیا"},
    {value: "austria", label: "اتریش"},
    {value: "azerbaijan", label: "آذربایجان"},
    {value: "bahrain", label: "بحرین"},
    {value: "bangladesh", label: "بنگلادش"},
    {value: "belarus", label: "بلاروس"},
    {value: "belgium", label: "بلژیک"},
    {value: "belize", label: "بلیز"},
    {value: "benin", label: "بنین"},
    {value: "bhutan", label: "بوتان"},
    {value: "bolivia", label: "بولیوی"},
    {value: "bosnia-and-herzegovina", label: "بوسنی و هرزگوین"},
    {value: "botswana", label: "بوتسوانا"},
    {value: "brazil", label: "برزیل"},
    {value: "brunei", label: "برونئی"},
    {value: "bulgaria", label: "بلغارستان"},
    {value: "burkina-faso", label: "بورکینافاسو"},
    {value: "burundi", label: "بوروندی"},
    {value: "cambodia", label: "کامبوج"},
    {value: "cameroon", label: "کامرون"},
    {value: "canada", label: "کانادا"},
    {value: "cape-verde", label: "کیپ ورد"},
    {value: "central-african-republic", label: "جمهوری آفریقای مرکزی"},
    {value: "chad", label: "چاد"},
    {value: "chile", label: "شیلی"},
    {value: "china", label: "چین"},
    {value: "colombia", label: "کلمبیا"},
    {value: "comoros", label: "کومور"},
    {value: "costa-rica", label: "کاستاریکا"},
    {value: "croatia", label: "کرواسی"},
    {value: "cuba", label: "کوبا"},
    {value: "cyprus", label: "قبرس"},
    {value: "czech-republic", label: "جمهوری چک"},
    {value: "democratic-republic-of-the-congo", label: "جمهوری دموکراتیک کنگو"},
    {value: "denmark", label: "دانمارک"},
    {value: "djibouti", label: "جیبوتی"},
    {value: "dominica", label: "دومینیکا"},
    {value: "dominican-republic", label: "جمهوری دومینیکن"},
    {value: "ecuador", label: "اکوادور"},
    {value: "egypt", label: "مصر"},
    {value: "el-salvador", label: "السالوادور"},
    {value: "equatorial-guinea", label: "گینه استوایی"},
    {value: "eritrea", label: "اریتره"},
    {value: "estonia", label: "استونی"},
    {value: "eswatini", label: "اسواتینی"},
    {value: "ethiopia", label: "اتیوپی"},
    {value: "fiji", label: "فیجی"},
    {value: "finland", label: "فنلاند"},
    {value: "france", label: "فرانسه"},
    {value: "gabon", label: "گابن"},
    {value: "gambia", label: "گامبیا"},
    {value: "georgia", label: "گرجستان"},
    {value: "germany", label: "آلمان"},
    {value: "ghana", label: "غنا"},
    {value: "greece", label: "یونان"},
    {value: "grenada", label: "گرنادا"},
    {value: "guatemala", label: "گواتمالا"},
    {value: "guinea", label: "گینه"},
    {value: "guinea-bissau", label: "گینه بیسائو"},
    {value: "guyana", label: "گویان"},
    {value: "haiti", label: "هائیتی"},
    {value: "honduras", label: "هندوراس"},
    {value: "hungary", label: "مجارستان"},
    {value: "iceland", label: "ایسلند"},
    {value: "india", label: "هند"},
    {value: "indonesia", label: "اندونزی"},
    {value: "iran", label: "ایران"},
    {value: "iraq", label: "عراق"},
    {value: "ireland", label: "ایرلند"},
    {value: "israel", label: "اسرائیل"},
    {value: "italy", label: "ایتالیا"},
    {value: "ivory-coast", label: "ساحل عاج"},
    {value: "jamaica", label: "جامائیکا"},
    {value: "japan", label: "ژاپن"},
    {value: "jordan", label: "اردن"},
    {value: "kazakhstan", label: "قزاقستان"},
    {value: "kenya", label: "کنیا"},
    {value: "kiribati", label: "کیریباتی"},
    {value: "kosovo", label: "کوزوو"},
    {value: "kuwait", label: "کویت"},
    {value: "kyrgyzstan", label: "قرقیزستان"},
    {value: "laos", label: "لائوس"},
    {value: "latvia", label: "لتونی"},
    {value: "lebanon", label: "لبنان"},
    {value: "lesotho", label: "لسوتو"},
    {value: "liberia", label: "لیبریا"},
    {value: "libya", label: "لیبی"},
    {value: "liechtenstein", label: "لیختن‌اشتاین"},
    {value: "lithuania", label: "لیتوانی"},
    {value: "luxembourg", label: "لوکزامبورگ"},
    {value: "madagascar", label: "ماداگاسکار"},
    {value: "malawi", label: "مالاوی"},
    {value: "malaysia", label: "مالزی"},
    {value: "maldives", label: "مالدیو"},
    {value: "mali", label: "مالی"},
    {value: "malta", label: "مالت"},
    {value: "marshall-islands", label: "جزایر مارشال"},
    {value: "mauritania", label: "موریتانی"},
    {value: "mauritius", label: "موریس"},
    {value: "mexico", label: "مکزیک"},
    {value: "micronesia", label: "میکرونزی"},
    {value: "moldova", label: "مولداوی"},
    {value: "monaco", label: "موناکو"},
    {value: "mongolia", label: "مغولستان"},
    {value: "montenegro", label: "مونته‌نگرو"},
    {value: "morocco", label: "مراکش"},
    {value: "mozambique", label: "موزامبیک"},
    {value: "myanmar", label: "میانمار"},
    {value: "namibia", label: "نامیبیا"},
    {value: "nauru", label: "نائورو"},
    {value: "nepal", label: "نپال"},
    {value: "netherlands", label: "هلند"},
    {value: "new-zealand", label: "نیوزیلند"},
    {value: "nicaragua", label: "نیکاراگوئه"},
    {value: "niger", label: "نیجر"},
    {value: "nigeria", label: "نیجریه"},
    {value: "north-korea", label: "کره شمالی"},
    {value: "north-macedonia", label: "مقدونیه شمالی"},
    {value: "norway", label: "نروژ"},
    {value: "oman", label: "عمان"},
    {value: "pakistan", label: "پاکستان"},
    {value: "palau", label: "پالائو"},
    {value: "panama", label: "پاناما"},
    {value: "papua-new-guinea", label: "پاپوآ گینه نو"},
    {value: "paraguay", label: "پاراگوئه"},
    {value: "peru", label: "پرو"},
    {value: "philippines", label: "فیلیپین"},
    {value: "poland", label: "لهستان"},
    {value: "portugal", label: "پرتغال"},
    {value: "qatar", label: "قطر"},
    {value: "romania", label: "رومانی"},
    {value: "russia", label: "روسیه"},
    {value: "rwanda", label: "رواندا"},
    {value: "saint-kitts-and-nevis", label: "سنت کیتس و نویس"},
    {value: "saint-lucia", label: "سنت لوسیا"},
    {value: "saint-vincent-and-the-grenadines", label: "سنت وینسنت و گرنادین‌ها"},
    {value: "samoa", label: "ساموآ"},
    {value: "san-marino", label: "سن مارینو"},
    {value: "sao-tome-and-principe", label: "سائوتومه و پرنسیپ"},
    {value: "saudi-arabia", label: "عربستان سعودی"},
    {value: "senegal", label: "سنگال"},
    {value: "serbia", label: "صربستان"},
    {value: "seychelles", label: "سیشل"},
    {value: "sierra-leone", label: "سیرالئون"},
    {value: "singapore", label: "سنگاپور"},
    {value: "slovakia", label: "اسلواکی"},
    {value: "slovenia", label: "اسلوونی"},
    {value: "solomon-islands", label: "جزایر سلیمان"},
    {value: "somalia", label: "سومالی"},
    {value: "south-africa", label: "آفریقای جنوبی"},
    {value: "south-korea", label: "کره جنوبی"},
    {value: "south-sudan", label: "سودان جنوبی"},
    {value: "spain", label: "اسپانیا"},
    {value: "sri-lanka", label: "سریلانکا"},
    {value: "sudan", label: "سودان"},
    {value: "suriname", label: "سورینام"},
    {value: "sweden", label: "سوئد"},
    {value: "switzerland", label: "سوئیس"},
    {value: "syria", label: "سوریه"},
    {value: "taiwan", label: "تایوان"},
    {value: "tajikistan", label: "تاجیکستان"},
    {value: "tanzania", label: "تانزانیا"},
    {value: "thailand", label: "تایلند"},
    {value: "togo", label: "توگو"},
    {value: "tonga", label: "تونگا"},
    {value: "trinidad-and-tobago", label: "ترینیداد و توباگو"},
    {value: "tunisia", label: "تونس"},
    {value: "turkey", label: "ترکیه"},
    {value: "turkmenistan", label: "ترکمنستان"},
    {value: "tuvalu", label: "تووالو"},
    {value: "uganda", label: "اوگاندا"},
    {value: "ukraine", label: "اوکراین"},
    {value: "united-arab-emirates", label: "امارات متحده عربی"},
    {value: "united-kingdom", label: "بریتانیا"},
    {value: "united-states", label: "ایالات متحده آمریکا"},
    {value: "uruguay", label: "اروگوئه"},
    {value: "uzbekistan", label: "ازبکستان"},
    {value: "vanuatu", label: "وانواتو"},
    {value: "vatican-city", label: "واتیکان"},
    {value: "venezuela", label: "ونزوئلا"},
    {value: "vietnam", label: "ویتنام"},
    {value: "yemen", label: "یمن"},
    {value: "zambia", label: "زامبیا"},
    {value: "zimbabwe", label: "زیمبابوه"}
]