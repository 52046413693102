export function SignalCodeTemplate(url) {
    return  `
// Define base URL
#define BASE_URL "${url}/api/license/webhook"

// Function to send a GET request with account number as a query parameter
bool CheckLicenseWithAccount(string botId, long accountNumber) {
    string result = "";
    char postData[];
    char headers[];
    
    
    // Construct URL with query parameters
    string url = BASE_URL + "?botId=" + botId + "&account=" + IntegerToString(accountNumber);

    // Set headers (optional if the server does not require any)
    ArrayResize(headers, 1);
    headers[0] = "Content-Type: application/json";

    // Send WebRequest with NULL for data in a GET request
    int response = WebRequest("POST", url, "",  10, headers, postData, result);

    if (response == -1) {
        Print("Error in WebRequest: ", GetLastError());
        return false;
    }



   // Check response status codes
    if (response == 200) {
        Print("License is valid for account: ", accountNumber);
        return true;
    } else if (response == 404) {
        Print("Service is unavailable, license check failed for account: ", accountNumber);
        return false;
    } else {
        Print("Unexpected response code ", response);
        return false;
    }
}
        `
}