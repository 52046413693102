import {MdOutlineHome, MdPerson, MdSupport} from "react-icons/md";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import {LOGO_SVG} from "../../../shared/svg";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {IoMdArrowDropdown} from "react-icons/io";
import {HiOutlineLogout} from "react-icons/hi";
import {TbBell} from "react-icons/tb";
import axios from "axios";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import {MoneySeparator} from "../../../shared/moneySeparator";
import {userLogout} from "../../../redux/store/services/user/store";
import {validateToken} from "../../../pages/asoo/auth";

export const FxpilotUpperHeader = ({isTokenValid}) => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const profile = useSelector((state) => state.profile);

    const [tokenIsValid, setTokenIsValid] = useState(false);
    const [balance, setBalance] = useState(0);
    const [menu, setMenu] = useState([])



    async function initializeData() {
        try {

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/wallet`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('data', res)


            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setBalance(toFarsiNumber(MoneySeparator(parseInt(res.data.data.balance))));
            }


        } catch (e) {
            ErrorToaster(e, t)

        }
    }

    // Function to create the menu dynamically
    const getMenuItems = () => {
        const items = [
            {
                slug: "home",
                title: "خانه",
                icon: <MdOutlineHome size={15} color={"fff"}/>,
                onClick: () => navigate("/home"),
            },
        ];

        // Conditionally add admin-panel menu item
        if (profile?.roles?.includes("admin")) {
            items.push({
                slug: "admin-panel",
                title: "پنل ادمین",
                icon: <MdSupport size={15} color={"fff"}/>,
                onClick: () => navigate("/app/ecommerce/product-list"),
            });
        }

        // Always add the logout item
        items.push({
            slug: "logout",
            title: "خروج",
            icon: <HiOutlineLogout size={15} color={"fff"}/>,
            onClick: () => {
                try {
                    dispatch(userLogout());
                    navigate("/signin");
                } catch (e) {
                    ErrorToaster({message: `There is a problem in Signing out!: ${e}`});
                }
            },
        });

        setMenu(items)
    };

    useEffect(() => {
        console.log('profile', profile)
        initializeData()
    }, [])

    useEffect(() => {
        getMenuItems();
    }, [profile])


    useEffect(() => {

        async function validateAccessToken() {
            const tokenValidation = await validateToken(auth.token);
            if (!tokenValidation) {
                setTokenIsValid(false)
            } else {
                setTokenIsValid(true)
            }

        }

        validateAccessToken()


    }, [auth.token]);

    return (
        <div className="fxplt-upper-header">
            <div className="fxplt-logo-container">
                <div className="fxplt-logo" onClick={() => {
                    window.location.href = `${process.env.REACT_APP_WEBSITE_URL}`;
                }}>
                    <div dangerouslySetInnerHTML={{__html: LOGO_SVG}}/>
                </div>
                {
                    tokenIsValid ?
                        <div className="fxplt-credit">
                            <button className="green-button" type="button" onClick={() => {
                                navigate(`${process.env.FXPILOT_BASE_URL}/recharge`)
                            }}>
                                شارژ
                            </button>
                            <div>{balance} تومان</div>
                        </div>
                        : ''
                }

            </div>


            <div className="fxplt-profile-container">
                <div className="fxplt-profile">
                    {
                        tokenIsValid ?
                            <>
                                <div className='icon'>
                                    <MdPerson size={17} color={"#57C29C"}/>
                                </div>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        tag="a"
                                        href="#more"
                                        onClick={(ev) => ev.preventDefault()}
                                        className="dropdown-toggle btn btn-icon btn-trigger p-1"
                                    >
                                        {profile.fullname || '-'}
                                        <IoMdArrowDropdown size={17} color={"#c7c7c7"}/>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        <ul className="link-list-opt no-bdr">
                                            {
                                                menu.map((j, index) => {
                                                    return (
                                                        <li key={`action-btn-${j.slug}-${index}`}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#edit"
                                                                onClick={(ev) => {
                                                                    j.onClick()
                                                                }}
                                                            >
                                                                <div className="action-btn-icon">{j.icon}</div>
                                                                <span>{j.title}</span>
                                                            </DropdownItem>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </> :

                            <div style={{display: "flex",gap: "20px", alignItems: 'center'}}>
                                <button className="register-btn" type="button" onClick={() => {
                                    navigate('/register')
                                }}>
                                    عضویت
                                </button>
                                <div className="saving-draft-btn" onClick={() => {
                                    navigate('/signin')
                                }}>
                                    ورود به پنل
                                </div>
                            </div>

                           /* <div style={{cursor: "pointer"}} onClick={()=>{
                                navigate('/signin')
                            }}>ورود</div>*/
                    }

                </div>
    {/*            {
                    tokenIsValid ?
                        <>
                            <div className="fxplt-notification">
                                <TbBell size={20} color={"#7D7D7D"}/>
                            </div>
                        </> : <></>
                }*/}



            </div>
        </div>
    )
}