import React, {useEffect, useState} from "react";
import {ImageSelector, TutorSelector} from "../signals";
import ExpandableReadMoreBox from "../../../../components/fxpilot/ExpandableReadMoreBox";
import {FormGenerator} from "../../components/FormGenerator";
import {TelegramChannelItem} from "./telegramChannelItem";


export function SettingItem({
    index,
    item,
    botId,
    onTelegramAddChannel,
    onRemoveTelegramChannel,
    onTelegramChannelFieldUpdate
}) {
    const [tutorExpand, setTutorExpand] = useState(false);
    const [isOpen, setIsOpen] = useState([])

    const emptyChannelObject = {
        username: "@username",
        title: "",
        description: "",
    }

    useEffect(() => {
        console.log('settingItem---', index, item)
        setIsOpen(item?.channels?.map(i => {
            return false
        }))

    }, []);

    useEffect(() => {
        if (item?.channels?.length > 0) {
            // setIsOpen(item?.channels?.map(i => {
            //     return false
            // }))
        }
    }, [item?.channels]);


    useEffect(() => {
        console.log('isOpen', isOpen)
    }, [isOpen]);


    return (
        <div className="fxplt-signal-item">
            <div className="d-flex flex-row justify-content-start w-100 align-center" style={{gap: "10px"}}>
                <div className="fxplt-signal-item-app">
                    <div className="fxplt-signal-item-app-img">
                        <img src={ImageSelector(item?.name)} alt={item?.name}/>
                    </div>
                </div>

                <span className="fxplt-signal-setting-app-title">{item?.name}</span>
            </div>


            <div className={`fxplt-signal-item-content`}>

                <ExpandableReadMoreBox
                    className="inner-card"
                    open={tutorExpand}
                    setOpen={() => {
                        setTutorExpand(!tutorExpand)
                    }}
                    content={TutorSelector(item.name, botId)}
                />


                {
                    item.channels?.length > 0 ?
                        <div style={{paddingTop: '30px'}}>
                            {
                                item.channels?.map((channel, index) => {
                                    return (
                                        <TelegramChannelItem
                                            item={channel}
                                            index2={index}
                                            onRemoveTelegramChannel={(index) => {
                                                onRemoveTelegramChannel(
                                                    item.channels.filter((j, inx) => {
                                                        if (index !== inx)
                                                            return j;
                                                    })
                                                )
                                            }}
                                            onChange={(newValues) => {
                                                onTelegramChannelFieldUpdate(item.channels.map((j, inx) => {
                                                    if (index === inx)
                                                        return newValues;

                                                    return j
                                                }))
                                            }}
                                            setIsOpen={setIsOpen}
                                            isOpen={isOpen}
                                        />
                                    )
                                })
                            }
                        </div> : ''
                }
                {
                    item.name === 'telegram' ?
                        <button
                            style={{fontSize: "14px", justifyContent: 'center', padding: "12px 0px"}}
                            className="btn dark-bg-color dana-font mt-4 mb-4" type="button"
                            onClick={() => {
                                onTelegramAddChannel([
                                    ...item.channels,
                                    emptyChannelObject
                                ])
                                setIsOpen([
                                    ...isOpen,
                                    false
                                ])
                            }}
                        >
                            افزودن کانال
                        </button> :
                        ''
                }

                {
                    item?.fields ?
                        <>
                            <FormGenerator fields={item.fields}/>
                        </> : ''
                }
            </div>
        </div>
    )
}