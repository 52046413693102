import React, {useLayoutEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import Layout from "../layout/Index";

import StudentList from "../pages/asoo/student/studentList";
import TransactionList from "../pages/asoo/transactions/transactionList";
import Error404Classic from "../pages/asoo/error/404-classic";
import Error504Classic from "../pages/asoo/error/504-classic";
import Settings from "../pages/asoo/settings";
import ProductList from "../pages/mrpropfund/product/productList";
import ProductAdd from "../pages/mrpropfund/product/productAdd";
import Profile from "../pages/mrpropfund/profile";
import AccountList from "../pages/mrpropfund/accounts/accountList";
import Plans from "../pages/mrpropfund/plans";
import Checkout from "../pages/mrpropfund/checkout";
import TicketList from "../pages/mrpropfund/tickets/ticketList";
import TicketChat from "../pages/mrpropfund/tickets/ticketChat";
import CampaignList from "../pages/mrpropfund/campign/campaignList";
import CampaignAdd from "../pages/mrpropfund/campign/campaignAdd";
import {CustomLogin} from "../pages/mrpropfund/login";
import OrderList from "../pages/mrpropfund/orders/orderList";
import OrderAdd from "../pages/mrpropfund/orders/orderAdd";
import {Register} from "../pages/mrpropfund/register";
import UserList from "../pages/mrpropfund/users/userList";
import UserAdd from "../pages/mrpropfund/users/userAdd";
import InventoryList from "../pages/mrpropfund/inventory/inventoryList";
import InventoryAdd from "../pages/mrpropfund/inventory/inventoryAdd";
import WithdrawList from "../pages/mrpropfund/withdraw/withdrawList";
import WithdrawAdd from "../pages/mrpropfund/withdraw/withdrawAdd";
import AddOnList from "../pages/mrpropfund/add-on/addOnList";
import AddOnAdd from "../pages/mrpropfund/add-on/addOnAdd";
import Analytics from "../pages/mrpropfund/analytics";
import PaymentSuccess from "../pages/mrpropfund/payment/PaymentSuccess";
import PaymentFailed from "../pages/mrpropfund/payment/PaymentFailed";
import PostList from "../pages/mrpropfund/post/postList";
import PostAdd from "../pages/mrpropfund/post/postAdd";
import AppLayout from "../layout/Index-app";
import CategoryList from "../pages/mrpropfund/category/categoryList";
import CategoryAdd from "../pages/mrpropfund/category/categoryAdd";
import UserIDentifyCards from "../pages/fxpilot/identify/userIDentifyCards";
import ApplicationForm from "../pages/fxpilot/identify/applicationForm";
import LicenseList from "../pages/fxpilot/license/license-list";
import BotList from "../pages/fxpilot/bot/bot-list";
import VarietyList from "../pages/mrpropfund/variety/varietyList";
import VarietyAdd from "../pages/mrpropfund/variety/varietyAdd";
import History from "../pages/mrpropfund/history";
import OrderDetail from "../pages/mrpropfund/order-detail";
import DiscountList from "../pages/mrpropfund/discount/discountList";
import DiscountAdd from "../pages/mrpropfund/discount/discountAdd";
import Kyc from "../pages/mrpropfund/profile/kyc";
import FxpilotAppLayout from "../layout/Index-app-fxpilot";
import Home from "../pages/fxpilot/home";
import Wallet from "../pages/fxpilot/wallet";
import Recharge from "../pages/fxpilot/recharge";
import WithdrawDetail from "../pages/mrpropfund/withdraw/withdrawDetail";
import WithdrawListAdmin from "../pages/mrpropfund/withdraw/withdrawListAdmin";
import WithdrawConfirmation from "../pages/mrpropfund/withdraw/withdrawCompfirmation";
import {ForgotPassword2} from "../pages/mrpropfund/forgot-password";
import PostSeriesList from "../pages/mrpropfund/postSeries/postSeriesList";
import PostSeriesAdd from "../pages/mrpropfund/postSeries/postSeriesAdd";
import Index from "../pages/fxpilot/signal";
import {ResetPassword} from "../pages/mrpropfund/reset-password";
import ManageCourses from "../pages/fxpilot/lms/courses/Admin-facing/ManageCourses";
import EditCourse from "../pages/fxpilot/lms/courses/Admin-facing/EditCourse";
import FxpilotAppLayoutWithSidebar from "../layout/Index-app-fxpilot-with-sidebar";
import CloudStorage from "../pages/fxpilot/cloud-storage";
import SignalTabs from "../pages/fxpilot/signal";
import {useSelector} from "react-redux";
import AppLayoutWithSidebar from "../layout/Index-app-with-sidebar";
import MyCourses from "../pages/fxpilot/lms/courses/User-facing/MyCourses";
import CourseOverview from "../pages/fxpilot/lms/courses/User-facing/CourseOverview";


const Pages = () => {
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const profileState = useSelector((state) => state.profile);

    return (
        <Routes>
            {/*Fxpilot*/}
            {
                process.env.APPLICATION === 'fxpilot' ?
                    <Route path="" element={<FxpilotAppLayout/>} >
                        {/*<Route index element={<ProductList/>}></Route>*/}


                        <Route element={<FxpilotAppLayoutWithSidebar/>}>
                            <Route path=""                      element={<Home/>}           ></Route>
                            <Route path="home"                  element={<Home/>}           ></Route>
                            <Route path="wallet"                element={<Wallet/>}         ></Route>
                            <Route path="recharge"              element={<Recharge/>}       ></Route>
                            <Route path="licenses"              element={<LicenseList/>}    ></Route>
                            <Route path="course/mine"           element={<MyCourses/>}      ></Route>
                            <Route path="course/overview/:id"   element={<CourseOverview/>} ></Route>
                            <Route path="identify"              element={<UserIDentifyCards/>}></Route>
                            <Route path="identify/add"               element={<ApplicationForm/>}></Route>
                            <Route path="identify/edit/:id"               element={<ApplicationForm/>}></Route>
                            <Route path="signal"                element={<SignalTabs/>}     ></Route>
                            <Route path="licenses/:id"          element={<LicenseList/>}    ></Route>
                            <Route path="tradingview"           element={<LicenseList/>}    ></Route>
                            {/*<Route path="signal/:id"         element={<SignalAdd/>}      ></Route>*/}
                            <Route path="bots"                  element={<BotList/>}        ></Route>
                        </Route>




                    </Route> : (
                        process.env.APPLICATION === 'mrpropfund' ?
                            <Route path="" element={<Layout/>}>
                                {/*<Route index element={<Homepage />}></Route>*/}
                                <Route index element={<Profile/>}></Route>

                                {/*Asoo*/}
                                <Route>
                                    <Route path="settings" element={<Settings/>}></Route>
                                </Route>

                                {/*Asoo*/}
                                <Route path="dashboard" element={<Profile/>}></Route>
                                <Route path="profile/:id" element={<Profile/>}></Route>
                                <Route path="kyc" element={<Kyc/>}></Route>
                                <Route path="kyc/:id" element={<Kyc/>}></Route>
                                <Route path="analytics" element={<Analytics/>}></Route>
                                <Route path="analytics/:id" element={<Analytics/>}></Route>
                                <Route path="history" element={<History/>}></Route>
                                <Route path="order-detail/:id" element={<OrderDetail/>}></Route>
                                <Route path="withdraw-confirmation/:id" element={<WithdrawConfirmation/>}></Route>

                                <Route path="account-list" element={<AccountList/>}></Route>
                                <Route path="plans" element={<Plans/>}></Route>
                                <Route path="checkout" element={<Checkout/>}></Route>
                                <Route path="payment/success/:id" element={<PaymentSuccess/>}></Route>
                                <Route path="payment/failed/:id" element={<PaymentFailed/>}></Route>


                                <Route>
                                    <Route path="withdraw-list" element={<WithdrawList/>}></Route>
                                    <Route path="withdraw-add" element={<WithdrawAdd/>}></Route>
                                    <Route path="withdraw-edit/:id" element={<WithdrawAdd/>}></Route>
                                    <Route path="withdraw-detail/:id" element={<WithdrawDetail/>}></Route>
                                </Route>

                                <Route>
                                    <Route path="user-list" element={<UserList/>}></Route>
                                    <Route path="user-add" element={<UserAdd/>}></Route>
                                    <Route path="user-edit/:id" element={<UserAdd/>}></Route>
                                </Route>


                                <Route>
                                    <Route path="ticket-list" element={<TicketList/>}></Route>
                                    <Route path="ticket-chat/:id" element={<TicketChat/>}></Route>
                                </Route>
                                {/*Asoo*/}
                                <Route>
                                    <Route path="profile/:role/:id" element={<Profile/>}></Route>
                                </Route>

                                {/*Asoo*/}
                                <Route>
                                    <Route path="transaction-list" element={<TransactionList/>}></Route>
                                    <Route path="recharge-wallet" element={<TransactionList/>}></Route>

                                    <Route path="recharge" element={<Recharge/>}></Route>
                                </Route>

                                {/*Asoo*/}
                                <Route>
                                    <Route path="add-student-to-course/:courseId" element={<StudentList/>}></Route>
                                </Route>
                            </Route>
                            : <></>
                    )

            }


            {
                profileState?.roles?.includes('admin') ?
                    <Route path="app" element={<AppLayout/>}>

                        <Route element={<AppLayoutWithSidebar/>}>
                            {/* Website Modules*/}
                            <Route path={'website'}>
                                <Route index element={<PostList/>}></Route>

                                <Route>
                                    <Route path="post-list" element={<PostList/>}></Route>
                                    <Route path="post-add" element={<PostAdd/>}></Route>
                                    <Route path="post-edit/:id" element={<PostAdd/>}></Route>
                                </Route>

                                {
                                    process.env.APPLICATION === 'fxpilot' ?
                                        <Route>
                                            <Route path="post-series-list" element={<PostSeriesList/>}></Route>
                                            <Route path="post-series-add" element={<PostSeriesAdd/>}></Route>
                                            <Route path="post-series-edit/:id" element={<PostSeriesAdd/>}></Route>
                                        </Route> :
                                        <></>
                                }

                                <Route>
                                    <Route path="category-list" element={<CategoryList section={"post"} createRoutePath={"/app/website/category-add"}/>}></Route>
                                    <Route path="category-add" element={<CategoryAdd section={"post"} callback={"/app/website/category-list"}/>}></Route>
                                    <Route path="category-edit/:id" element={<CategoryAdd section={"post"} callback={"/app/website/category-list"}/>}></Route>
                                </Route>

                            </Route>


                            {/* Website Modules*/}
                            <Route path={'support'}>
                                <Route index element={<TicketList/>}></Route>

                                <Route>
                                    <Route path="category-list" element={<CategoryList section={"ticket"} createRoutePath={"/app/support/category-add"}/>}></Route>
                                    <Route path="category-add" element={<CategoryAdd section={"ticket"} callback={"/app/support/category-list"}/>}></Route>
                                    <Route path="category-edit/:id" element={<CategoryAdd section={"ticket"} callback={"/app/support/category-list"}/>}></Route>
                                </Route>

                                <Route>
                                    <Route path="ticket-list" element={<TicketList/>}></Route>
                                    <Route path="ticket-chat/:id" element={<TicketChat/>}></Route>
                                </Route>


                                <Route>
                                    <Route path="withdraw-list" element={<WithdrawListAdmin/>}></Route>
                                </Route>

                            </Route>


                            {/* Ecommerce Modules*/}
                            <Route path={'ecommerce'}>
                                <Route index element={<ProductList/>}></Route>

                                <Route>
                                    <Route path="product-list" element={<ProductList/>}></Route>
                                    <Route path="product-add" element={<ProductAdd/>}></Route>
                                    <Route path="product-edit/:id" element={<ProductAdd/>}></Route>
                                </Route>

                                <Route>
                                    <Route path="order-list" element={<OrderList/>}></Route>
                                    <Route path="order-add" element={<OrderAdd/>}></Route>
                                    <Route path="order-edit/:id" element={<OrderAdd/>}></Route>
                                </Route>

                                <Route>
                                    <Route path="customer-list" element={<UserList/>}></Route>
                                    <Route path="customer-edit/:id" element={<UserAdd/>}></Route>
                                </Route>


                                {
                                    process.env.APPLICATION === 'fxpilot' ?
                                        <Route path="lms">
                                            <Route path="course">
                                                <Route path="manage-courses" element={<ManageCourses/>}></Route>
                                                <Route path="new-courses" element={<EditCourse/>}></Route>
                                                <Route path="edit-courses/:id" element={<ManageCourses/>}></Route>
                                            </Route>
                                            {/*<Route path="course-detail/:id" element={<Index/>}></Route>*/}
                                        </Route> : <></>
                                }





                                <Route>
                                    <Route path="category-list" element={<CategoryList section={"product"} createRoutePath={"/app/ecommerce/category-add"}/>}></Route>
                                    <Route path="category-add" element={<CategoryAdd section={"product"} callback={"/app/ecommerce/category-list"}/>}></Route>
                                    <Route path="category-edit/:id" element={<CategoryAdd section={"product"} callback={"/app/ecommerce/category-list"}/>}></Route>
                                </Route>

                                <Route>
                                    <Route path="variety-list" element={<VarietyList section={"product-variety"} createRoutePath={"/app/ecommerce/variety-add"}/>}></Route>
                                    <Route path="variety-add" element={<VarietyAdd section={"product-variety"} callback={"/app/ecommerce/variety-list"}/>}></Route>
                                    <Route path="variety-edit/:id" element={<VarietyAdd section={"product-variety"} callback={"/app/ecommerce/variety-list"}/>}></Route>
                                </Route>

                                <Route>
                                    <Route path="add-on-list" element={<AddOnList/>}></Route>
                                    <Route path="add-on-add" element={<AddOnAdd/>}></Route>
                                    <Route path="add-on-edit/:id" element={<AddOnAdd/>}></Route>
                                </Route>

                                <Route>
                                    <Route path="inventory-list/:id" element={<InventoryList/>}></Route>
                                    <Route path="inventory-add/:id" element={<InventoryAdd/>}></Route>
                                    <Route path="inventory-edit/:id" element={<InventoryAdd/>}></Route>
                                </Route>

                                <Route>
                                    <Route path="campaign-list" element={<CampaignList/>}></Route>
                                    <Route path="campaign-add" element={<CampaignAdd/>}></Route>
                                    <Route path="campaign-edit/:id" element={<CampaignAdd/>}></Route>
                                </Route>

                                <Route>
                                    <Route path="discount-list" element={<DiscountList/>}></Route>
                                    <Route path="discount-add" element={<DiscountAdd/>}></Route>
                                    <Route path="discount-edit/:id" element={<DiscountAdd/>}></Route>
                                </Route>
                            </Route>
                        </Route>


                        {
                            process.env.APPLICATION === 'fxpilot' ?
                                <Route>
                                    <Route path="cloud-storage" element={<CloudStorage/>}></Route>
                                </Route> :
                                <></>
                        }



                    </Route> : <></>
            }



            {/*asoo*/}
            <Route path="signin" element={<CustomLogin/>}></Route>
            <Route path="register" element={<Register/>}></Route>
            <Route path="forgot-password" element={<ForgotPassword2/>}></Route>
            <Route path="reset-password" element={<ResetPassword/>}></Route>
            <Route path="errors">
                <Route path="404-classic" element={<Error404Classic/>}></Route>
                <Route path="504-classic" element={<Error504Classic/>}></Route>
            </Route>
            <Route path="*" element={<Error404Classic/>}></Route>


        </Routes>
    );
};
export default Pages;
