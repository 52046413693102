import {AiOutlineQuestionCircle} from "react-icons/ai";

const FlagsIcons = (flag) => {
    switch (flag?.toLowerCase()) {
        case "turkey":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/flags/turkey.png';
        case "malaysia":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/flags/malaysia.png';
        case "emirates":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/flags/emirates.png';
        default:
            return <AiOutlineQuestionCircle />; // Default icon for unknown exchanges
    }
};

export default FlagsIcons;