import React, {useEffect, useState} from "react";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../../shared/toaster";
import {IoMdClose} from "react-icons/io";
import {Field} from "../../../../components/fouladyar/field/field";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {buildTree, TreeNode} from "../index";

export function SelectableTreeModal({setIsModalClose, section, onSelectNode }) {
    const {t, i18n} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const auth = useSelector((state) => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tree, setTree] = useState([]);
    const [treeData, setTreeDate] = useState(null);

    async function initializeTree() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories?section=${section || ''}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('data', res)

            if (!res) {
                ErrorToaster({message: 'شما مجوز دسترسی به این بخش را ندارید!'}, t)
            } else if (res.data.statusCode === 200 || !res.data.statusCode) {
                setTree(res.data.data.categories?.map(item => {
                    const date = new Date(item.createdAt);
                    const formattedDate = date.toLocaleDateString();
                    return {
                        ...item,
                        createdAt: formattedDate
                    }
                }))
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }


    useEffect(()=>{
        initializeTree()
    },[])


    useEffect(() => {
        if (tree) {
            const result = buildTree(tree);
            setTreeDate(result) // Build the tree from flat data
        }
    }, [tree])
    return (
        <div className="modal-container">
            <div className="close-modal-btn" onClick={() => setIsModalClose()}>
                <IoMdClose size={20} color={"#FAF4F4"}/>
            </div>
            <h4 className="modal-title">انتخاب شاخه</h4>


            <div className="modal-note">
                در صورت پیدا کردن شاخه مورد نظر خود، روی دکمه انتخاب مقابل آن کلیک کنید.
            </div>

            <div className="tree">
                {treeData?.map((node) => (
                    <TreeNode
                        key={node._id}
                        node={node}
                        selectMode={true}
                        onSelectNode={(e) => {
                            onSelectNode(e)
                        }}
                    />
                ))}
            </div>

        </div>
    )
}