import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Field} from "../../../components/fouladyar/field/field";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {MdOutlineAccountBalance} from "react-icons/md";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import {MoneySeparator} from "../../../shared/moneySeparator";


const Recharge = () => {

    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [data, setData] = useState([

    ]);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [isLoading, setIsLoading] = useState(false);
    const [userWallet, setUserWallet] = useState({
        balance: 0,
        status: '',
    });
    const [fiat, setFiat] = useState('dollar');
    const [amount, setAmount] = useState(2);
    const [IRRUSD, setIRRUSD] = useState(80000);
    const [selectedPaymentGateway, setSelectedPaymentGateway] = useState('');
    const [paymentGateways, setPaymentGateways] = useState([]);
    const [rialAmounts, setRialAmounts] = useState([
        {
            amount: 100000,
            selected: true
        },
        {
            amount: 300000,
            selected: false
        },
        {
            amount: 500000,
            selected: false
        },
        {
            amount: 1000000,
            selected: false
        },
    ]);
    const [dollarAmounts, setDollarAmounts] = useState([
        {
            amount: 2,
            selected: true
        },
        {
            amount: 5,
            selected: false
        },
        {
            amount: 10,
            selected: false
        },
        {
            amount: 20,
            selected: false
        },
    ]);



    async function getUserWalletBalance() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/wallet`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('wallet', res.data )
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setUserWallet({
                    balance: res.data.data.balance
                })

            }
            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }


    async function getPaymentGateways() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/payment-gateways`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('wallet', res.data )
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setPaymentGateways(res.data.data.paymentGateways)
                setSelectedPaymentGateway(res.data.data.paymentGateways?.filter(i => i.currency === 'USDT')[0].name)

            }
            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }



    useEffect(() => {
        getUserWalletBalance();
        getPaymentGateways();
    },[])

    function ChargeValueItem({amount, previewAmount, selected, onSelect}) {
        return (
            <div className={`fxplt-charge-value-item ${selected ? "selected" : ''}`} onClick={() => {
                onSelect(amount)
            }}>
                <h5>{toFarsiNumber(MoneySeparator(amount))} {fiat === 'rial' ? 'تومان' : (fiat === 'dollar' ? 'دلار' : '')}</h5>
                {
                    previewAmount ?
                        <h6 className="dollar-to-rial-preview">{toFarsiNumber(MoneySeparator(previewAmount))} {'تومان'}</h6> : ''
                }
            </div>
        )
    }



    function PaymentGatewayItem({name, logo, selected, onSelect}) {
        return (
            <div className={`fxplt-payment-gateway-item ${selected ? "selected" : ''}`} onClick={() => {
                onSelect(name);
            }}>
                <div className="fxplt-payment-gateway-logo">
                    <img src={logo} alt="" />
                </div>
                <h5>{name}</h5>
            </div>
        )
    }

    const [isSubmitting, setIsSubmitting] = useState(false);
    async function submit() {

        try {
            setIsSubmitting(true)
            const body = {
                payableValue: amount ,
                paymentMethod: 'crypto',
                paymentGatewayId: paymentGateways.find(i => i.name === selectedPaymentGateway)._id,
                campaigns:  [],
                addons:  [],
                discount: 0,
                extras: {
                    WalletRechargeAmount : fiat === 'rial' ? amount : amount * IRRUSD
                }
            }


            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/orders/no-cart`, body,{
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200 || !res.data.statusCode) {
                const invoice_url= res.data.data.Invoice.invoice_url;

                if(invoice_url){
                    window.location.href = invoice_url;
                }else{
                    ErrorToaster({message: 'اشکال در ساخت فاکتور ایجاد شده است!'})
                }
                // SuccessToaster({message: 'سفارش با موفقیت ثبت شد'}, t)
                // navigate('/account-list');
            }
            setIsSubmitting(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }

    return (<React.Fragment>
        <ModalHelper
            open={isModalOpen}
            onOpen={() => setIsModalOpen(true)}
            onClose={() => setIsModalOpen(false)}
            component={modalComponent}
        />


        <div className="grid">
            <div className="grid-header license-header">
                <div className="grid-header-image">
                    <MdOutlineAccountBalance color={"#38AF84"} size={30}/>
                </div>

                <div className="d-flex flex-column flex-grow-1 bot-selector">
                    <h3>کیف پول</h3>
                </div>
            </div>
            <div className="grid-kyc-container">


                <div className="fxplt-recharge-container mt-5">
                    <h3 className="title pb-5">شارژ کیف پول</h3>


                    <div className="fxplt-recharge-value-selector">
                        <h3 className="key pb-2">مبلغ مورد نظر را وارد کنید</h3>
                        <Field
                            id={"amount"}
                            name={"amount"}
                            placeholder={"مبلغ"}
                            type={"number"}
                            value={amount}
                            onChange={(e) => {
                                setAmount(e)
                            }}
                        />
                        <div className='floating-text bot-selector'>
                            <Field
                                id={"fiat"}
                                name={"fiat"}
                                type={'select'}
                                options={[
                                    {value: "rial", label: "تومان"},
                                    {value: "dollar", label: "دلار"}
                                ]}
                                value={fiat}
                                onChange={(e) => {
                                    setFiat(e)
                                    if(e === 'dollar'){
                                        setAmount(dollarAmounts[0].amount)
                                        setSelectedPaymentGateway(paymentGateways.find(i => i.currency === 'USDT').name)
                                    }
                                    if(e === 'rial'){
                                        setAmount(rialAmounts[0].amount)
                                        setSelectedPaymentGateway(paymentGateways.find(i => i.currency === 'IRR').name)
                                    }
                                }}
                            />
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <span>مبلغ وارد شده باید بین ۵۰,۰۰۰ تومان تا ۱,۰۰۰,۰۰۰ تومان باشد</span>
                            {
                                fiat === 'dollar' ? <h6 className="dollar-to-rial-preview">{toFarsiNumber(MoneySeparator(amount * IRRUSD))} {'تومان'}</h6> : ''
                            }
                        </div>
                    </div>

                    <div className="d-flex flex-row justify-content-between payment-value-item pt-5">
                        <div className="key">موجودی کیف پول</div>
                        <div className="value">{toFarsiNumber(MoneySeparator(userWallet.balance))} تومان</div>
                    </div>
                    <div className="divider"/>

                    <h3 className="key">یکی از مبالغ زیر را انتخاب کنید</h3>
                    <div className="fxplt-charge-value-items">
                        {
                            fiat === 'rial' ?
                                rialAmounts.map(i => {
                                    return(
                                        <ChargeValueItem
                                            selected={i.amount === amount}
                                            amount={i.amount}
                                            onSelect={(e)=>{
                                                setAmount(e)
                                            }}
                                        />
                                    )
                                }) : (
                                    fiat === 'dollar' ?
                                        dollarAmounts.map(i => {
                                            return(
                                                <ChargeValueItem
                                                    selected={i.amount === amount}
                                                    amount={i.amount}
                                                    previewAmount={toFarsiNumber(MoneySeparator(i.amount * IRRUSD))}
                                                    onSelect={(e)=>{
                                                        setAmount(e)
                                                    }}
                                                />
                                            )
                                        }) : ''
                                )
                        }
                    </div>
                    <h3 className="key">انتخاب درگاه بانگی</h3>
                    <div className="fxplt-payment-gateway-items">

                        {
                            fiat === 'rial' ?
                                paymentGateways.map(i => {
                                    if(i.currency === "IRR")
                                        return(
                                            <PaymentGatewayItem
                                                selected={selectedPaymentGateway === i.name}
                                                name={i.name}
                                                logo={i.logo}
                                                onSelect={(e)=>{
                                                    setSelectedPaymentGateway(e)
                                                }}
                                            />
                                    )
                                }) : (
                                    fiat === 'dollar' ?
                                        paymentGateways.map(i => {
                                            if(i.currency === "USDT")
                                                return(
                                                    <PaymentGatewayItem
                                                        selected={selectedPaymentGateway === i.name}
                                                        name={i.name}
                                                        logo={i.logo}
                                                        onSelect={(e)=>{
                                                            setSelectedPaymentGateway(e)
                                                         }}
                                                    />
                                                )
                                        }) : ''
                                )
                        }
                    </div>

                    <button className="btn fxplt-green-button mt-5 w-100" type="button" onClick={async () => {
                        await submit()
                    }}>
                        شارژ کیف پول
                    </button>
                </div>



            </div>
        </div>


    </React.Fragment>);
};

export default Recharge;

