import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import {MdOutlineCandlestickChart, MdOutlineContentCopy, MdVerified} from "react-icons/md";
import {LoadingState} from "../../../components/fouladyar/loading-state/loadingState";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {useTranslation} from "react-i18next";
import {Button} from "reactstrap";
import {copyToClipboard} from "../../../shared/copyToClipboard";
import {EmptyState} from "../../../components/fouladyar/empty-state/emptyState";
import {YesOrNoModal} from "../../../components/fouladyar/modal-helper/pages/yesOrNo";
import {ChangePropStateModal} from "./component/changePropStateModal";


const OrderDetail = () => {
    const auth = useSelector((state) => state.auth);

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('tab1');
    const [activeAccountTab, setActiveAccountTab] = useState('tab1');
    const profileState = useSelector((state) => state.profile);
    const [isOrderInfoLoading, setIsOrderInfoLoading] = useState(true);
    const [isAccountsStatusLoading, setIsAccountsStatusLoading] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div> empty </div>);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUserInfoLoading, setIsUserInfoLoading] = useState(false);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(1);
    const [variety, setVariety] = useState(null);
    const [kyc, setKyc] = useState({});
    const [mainUser, setMainUser] = useState({});
    const [userInfoData, setUserInfoData] = useState({
        fullName: "",
        email: "",
        phone: "",
        city: "",

    });
    const [orderInfoData, setOrderInfoData] = useState({
        orderId: "",
        createdAt: "",
        productTitle: "",
        status: "",
        amount: "",
        invoice_url: "",
    });
    const [accounts, setAccounts] = useState([
        // {
        //     inventoryId: '66ed03ee003af96fbd7bf91e',
        //     level: '1',
        //     username: "1111",
        //     password: "1111",
        //     server: "111",
        //     balance: "10000",
        //     investor: "111",
        //     disabled: true,
        //     type: "demo",
        //     broker: "66e5a98e5883a88896113497"
        // },
        // {
        //     inventoryId: '66ed03ee003af96fbd7bf91e',
        //     level: '2',
        //     username: "222222",
        //     password: "22222",
        //     server: "22222222",
        //     balance: "10000",
        //     disabled: true,
        //     investor: "222222",
        //     type: "demo",
        //     broker: "66e5a98e5883a88896113497"
        // },
        // {
        //     inventoryId: '66ed03ee003af96fbd7bf91e',
        //     level: '2',
        //     username: "222222",
        //     password: "222222",
        //     server: "222222",
        //     balance: "10000",
        //     disabled: true,
        //     investor: "222222",
        //     type: "demo",
        //     broker: "66e5a98e5883a88896113497"
        // },
        // {
        //     inventoryId: '66ed03ee003af96fbd7bf91e',
        //     level: '3',
        //     username: "rrrrrr",
        //     password: "rrrrrr",
        //     server: "rrrrr",
        //     balance: "10000",
        //     investor: "rrrrr",
        //     type: "demo",
        //     broker: "66e5a98e5883a88896113497"
        // },
    ]);


    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleAccountTabClick = (tab) => {
        setActiveAccountTab(tab);
    };


    useEffect(() => {
        getOrderDetail();
    }, []);




    async function getOrderDetail() {
        try {
            setIsOrderInfoLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/review/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                const order = res.data.data;
                console.log('userId', order, 'kyc', order?.kyc)
                setData(res.data.data)
                setKyc(order?.kyc)
                setMainUser(order?.mainUser)
                setAccounts(order.accounts);
                setCurrentLevel(order?.level);
                setUserInfoData(order.user);
                setVariety(order.variety);
                setOrderInfoData({
                    orderId: order.orderId || '',
                    createdAt: order.orderCreatedAt || '',
                    productTitle: order.productTitle || '',
                    status: order.invoice?.status,
                    amount: order.invoice.amount,
                    invoice_url: order.invoice.invoice_url,
                    brokerName: order.user.broker.name,
                    varietyName: order.variety.name
                });

                setIsOrderInfoLoading(false)
            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsOrderInfoLoading(false)
        }
    }


    async function deployAccount(accountId) {
        try {
            setIsOrderInfoLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/analysis/redeploy-trading-account/${accountId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                SuccessToaster({message: 'عملیات deploy با موفقیت انجام گرفت'} , t)
                getOrderDetail()
            }

            setIsOrderInfoLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsOrderInfoLoading(false)
        }
    }


    async function undeployAccount(accountId) {
        try {
            setIsOrderInfoLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/analysis/undeploy-trading-account/${accountId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                SuccessToaster({message: 'عملیات undeploy با موفقیت انجام گرفت'} , t)
                getOrderDetail()
            }

            setIsOrderInfoLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsOrderInfoLoading(false)
        }
    }

    function ItemInfo({slug, value, isLink}) {
        return (
            <div className="profile-item">
                <span className="profile-item-key">{slug}</span>
                {
                    !isLink ? <span className="profile-item-value">{value}</span> :
                        <a className="profile-item-value" href={value}>{value}</a>
                }

            </div>
        )
    }

    function UserInfo() {
        return (
            <div>
                <ItemInfo slug={"نام نام خانوادگی"} value={userInfoData?.fullName || '-'}/>
                <ItemInfo slug={"شماره تماس"} value={toFarsiNumber(userInfoData?.phone || '-')}/>
                <ItemInfo slug={"ایمیل"} value={userInfoData?.email || '-'}/>
                <ItemInfo slug={"شهر"} value={userInfoData?.city || '-'}/>
            </div>
        )
    }

    function OrderInfo() {
        return (
            <div>
                <ItemInfo slug={"شماره سفارش"} value={orderInfoData?.orderId || '-'}/>
                <ItemInfo slug={"تاریخ ثبت سفارش"} value={toFarsiNumber(orderInfoData?.createdAt || '-')}/>
                <ItemInfo slug={"عنوان محصول"} value={toFarsiNumber(orderInfoData?.productTitle || '-')}/>
                <ItemInfo slug={"عنوان طرح"} value={orderInfoData?.varietyName || '-'}/>
                <ItemInfo slug={"بروکر"} value={orderInfoData?.brokerName || '-'}/>
                <ItemInfo slug={"مبلغ پرداختی"} value={toFarsiNumber(orderInfoData?.amount || '-')}/>
                <ItemInfo slug={"وضعیت پرداخت"} value={orderInfoData?.status || '-'}/>
                <ItemInfo slug={"آدرس درگاه"} isLink={true} value={orderInfoData?.invoice_url || '-'}/>
            </div>
        )
    }

    function AccountManager() {
        function GenerateEmptyState() {
            return (
                <div className="empty-state-container">
                    <div className="empty-state-item">
                        <EmptyState
                            icon={<MdOutlineCandlestickChart size={80} color={"#38AF84"}/>}
                            buttonText={"بررسی مرحله فعلی"}
                            buttonOnClick={() => {

                            }}
                            title="چالش هنوز به این مرحله نرسیده است"
                            content="در صورتی که چالش مراحل فعلی را پشت سر بگذارد، اکانت ها در این بخش نمایش داده می شوند."
                        />
                    </div>
                </div>
            )
        }

        function OrderDetailAccountItem({
                                            username,
                                            password,
                                            level,
                                            disabled,
                                            metaapi_id,
                                            broker,
                                            server,
                                            balance,
                                            type,
                                            connectionStatus,
                                            investor,
                                            deployment,
                                            createdAt
                                        }) {
            return (
                <div className={` ${disabled ? 'disabled-account-style' : ''}`}>

                    <div className={`account-item order-detail-account`}>
                        <div className="account-header">
                            <div className="account-header-title">شماره حساب {toFarsiNumber(username)}</div>
                            <div style={{width: "190px", alignItems: "center"}} className="account-header-analysis"
                                 onClick={() => {
                                     // navigate('/history')
                                 }}>
                                <button className="btn small-button green-bg-color analycis-btn" type="button"
                                        onClick={async () => {
                                            console.log('KYC Form', kyc)
                                            navigate(`/analytics/${metaapi_id}`)
                                        }}>
                                    آنالیز حساب
                                </button>
                            </div>
                        </div>
                        <div className="account-detail-container">
                            <div className="account-detail-item">
                                <div className="account-detail-key">Username</div>
                                <div className="account-detail-value">
                                    {username} <MdOutlineContentCopy onClick={() => copyToClipboard(username)} size={20}
                                                                     color={"#787878"}/>
                                </div>
                            </div>
                            <div className="account-detail-item">
                                <div className="account-detail-key">Password</div>
                                <div className="account-detail-value">
                                    {password} <MdOutlineContentCopy onClick={() => copyToClipboard(password)} size={20}
                                                                     color={"#787878"}/>
                                </div>
                            </div>
                            <div className="account-detail-item">
                                <div className="account-detail-key">Server</div>
                                <div className="account-detail-value">
                                    {server} <MdOutlineContentCopy onClick={() => copyToClipboard(server)} size={20}
                                                                   color={"#787878"}/>
                                </div>
                            </div>
                            <div className="account-detail-item">
                                <div className="account-detail-key">Balance</div>
                                <div className="account-detail-value"> ${balance}</div>
                            </div>
                            <div className="account-detail-item">
                                <div className="account-detail-key">Investor</div>
                                <div className="account-detail-value">
                                    {investor} <MdOutlineContentCopy onClick={() => copyToClipboard(investor)} size={20}
                                                                     color={"#787878"}/>
                                </div>
                            </div>
{/*                            <div className="account-detail-item">
                                <div className="account-detail-key">Broker</div>
                                <div className="account-detail-value">
                                    {broker} <MdOutlineContentCopy onClick={() => copyToClipboard(investor)} size={20}
                                                                   color={"#787878"}/>
                                </div>
                            </div>*/}
                            <div className="account-detail-item">
                                <div className="account-detail-key">MetaAPI ID</div>
                                <div className="account-detail-value">
                                    {metaapi_id} <MdOutlineContentCopy onClick={() => copyToClipboard(metaapi_id)} size={20}
                                                                   color={"#787878"}/>
                                </div>
                            </div>
                            <div className="account-detail-item">
                                <div className="account-detail-key">Deployment</div>
                                <div className={`account-detail-value ${deployment === "DEPLOYED" ? 'green-color' : 'red-color'}`}>
                                    {deployment || "NONE"}

                                </div>
                            </div>
                            <div className="account-detail-item">
                                <div className="account-detail-key">Connection</div>
                                <div className={`account-detail-value ${connectionStatus === "CONNECTED" ? 'green-color' : 'red-color'}`}>
                                    {connectionStatus || "NONE"}

                                </div>
                            </div>
                            <div className="account-detail-item">
                            <div className="account-detail-key">State</div>
                            <div className={`account-detail-value ${deployment === "DEPLOYED" ? 'green-color' : 'red-color'}`}>
                                {deployment === "DEPLOYED" || deployment === "UNDEPLOYED" ?
                                    <button disabled={disabled || isOrderInfoLoading} style={{maxWidth: "90px"}} className={`btn small-button ${deployment === "DEPLOYED" ? 'red-bg-color' : (deployment === "UNDEPLOYED" ? 'green-bg-color' : '')} analycis-btn`} type="button"
                                            onClick={async () => {
                                                if(deployment === "DEPLOYED")
                                                    await undeployAccount(metaapi_id);
                                                if(deployment === "UNDEPLOYED")
                                                    await deployAccount(metaapi_id);
                                            }}>
                                        {deployment === "DEPLOYED" ? 'Undeploy' : (deployment === "UNDEPLOYED" ? 'Deploy' : '')}
                                    </button>:
                                    ''
                                }
                            </div>
                        </div>
                            {/*<div className="account-detail-item">*/}
                            {/*    <div className="account-detail-key">تاریخ خرید</div>*/}
                            {/*    <div className="account-detail-value"> {ConvertGregorianToJalali(createdAt)}</div>*/}
                            {/*</div>*/}
                            {/*<div className="account-detail-item">*/}
                            {/*    <div className="account-detail-key">نوع حساب</div>*/}
                            {/*    <div className="account-detail-value"> {type === "real" ? "Real" : "Demo"}</div>*/}
                            {/*</div>*/}
                            {/*<div className="account-detail-item">*/}
                            {/*    <div className="account-detail-key">سطح پیشرفت</div>*/}
                            {/*    <div className="account-detail-value"> مرحله ۱</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {
                        disabled ?
                            <div className="disabled-badge">
                                غیر فعال
                            </div> : ''
                    }
                </div>
            )
        }

        async function newAccount() {
            setIsModalOpen(true);
            const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/review/activate-new-account`, {
                userId: data.userId,
                orderId: orderInfoData.orderId
            }, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (newCardResponse.data.statusCode === 200) {
                await getOrderDetail();
                setIsModalOpen(false);
            }
        }

        async function levelUp() {
            setIsModalOpen(true);
            const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/review/level-up`, {
                userId: data.userId,
                orderId: orderInfoData.orderId
            }, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (newCardResponse.data.statusCode === 200) {
                await getOrderDetail();
                setIsModalOpen(false);
            }
        }

        async function reset() {
            setIsModalOpen(true);
            const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/review/reset-account`, {
                userId: data.userId,
                orderId: orderInfoData.orderId
            }, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (newCardResponse.data.statusCode === 200) {
                await getOrderDetail();
                setIsModalOpen(false);
            }
        }

        async function extend() {
            setIsModalOpen(true);
            const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/review/extend-account`, {
                userId: data.userId,
                orderId: orderInfoData.orderId
            }, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (newCardResponse.data.statusCode === 200) {
                await getOrderDetail();
                setIsModalOpen(false);
            }
        }

        async function suspend() {
            setIsModalOpen(true);
            const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/review/suspend-account`, {
                userId: data.userId,
                orderId: orderInfoData.orderId
            }, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (newCardResponse.data.statusCode === 200) {
                await getOrderDetail();
                setIsModalOpen(false);
            }
        }

        async function onActivateNewAccount(action) {
            try {
                switch (action) {
                    case 'new':
                        return await newAccount();
                    case 'levelup':
                        return await levelUp();
                    case 'reset':
                        return await reset();
                    case 'extend':
                        return await extend();
                    case 'suspend':
                        return await suspend();
                    default:
                        return null
                }
            } catch (e) {
                ErrorToaster(e, t)
                setIsModalOpen(false);
            }
        }

        function checkIfNextLevelIsReal() {
            //با بررسی سطح فعلی و نوع طرح میتونیم بفهیم مرحله چندم مرحله ی ریل خواهند بود

            if (
                currentLevel.toString() === "1" && variety.slug === 'one_stage' ||
                currentLevel.toString() === "2" && variety.slug === 'tow_stage' ||
                currentLevel.toString() === "3" && variety.slug === 'three_stage'
            )
                return true


            return false
        }

        function ActivateNewAccount({level}) {
            if (level.toString() === accounts.find(i => i.disabled === false)?.level.toString())

                return (
                    <div className={`account-item order-detail-account activate-new-account-box`}>
                        <Button className="order-detail-green-button" size="md" type="submit" onClick={() => {
                            setModalComponent(
                                <YesOrNoModal
                                    cancelText={"بستن پنجره"}
                                    submitText={"ایجاد حساب"}
                                    submitMode={'green'}
                                    onClose={() => {
                                        setIsModalOpen(false);
                                    }}
                                    onCancel={() => {
                                        setIsModalOpen(false);
                                    }}
                                    onSubmit={async () => {
                                        await onActivateNewAccount('new')
                                    }}
                                    title={'فعالسازی اکانت جدید'}
                                    content={'با تایید فعال سازی حساب جدید، اکانت قبلی کاربر غیر فعال شده و دیگر قابل استفاده نخواهد بود. همچنین کاربر باید با مشخصات جدید وارد پروسه معاملات خود شود'}
                                />
                            )
                            setIsModalOpen(true);
                        }}>
                            فعال سازی اکانت جدید
                        </Button>
                    </div>
                )
        }

        return (
            <div>
                <div className="profile-banner">
                    <div className="profile-banner-image" style={{background: "#25252B"}}>
                        <div className='banner-container d-flex flex-row'>
                            <div className="profile-info">
                                <div className="profile-name">
                                    اطلاعات اکانت ها

                                    <div className="red-color">
                                        {
                                            data.status === "suspended" ? <div>(حساب غیر فعال شده)</div> : ''
                                        }
                                    </div>
                                </div>
                                <div className="profile-description yellow-color">
                                    {
                                        currentLevel.toString() === "1" ? "مرحله اول" : (
                                            currentLevel.toString() === "2" ? "مرحله دوم" : (
                                                currentLevel.toString() === "3" ? "مرحله سوم" : (
                                                    currentLevel.toString() === "4" ? "مرحله چهارم" : ''
                                                )
                                            )
                                        )
                                    }
                                </div>
                            </div>

                            <Button disabled={data.status === 'suspended'} className="order-detail-green-button"
                                    style={{width: "200px"}} size="md" type="submit" onClick={() => {
                                if (data.status !== 'suspended') {
                                    setModalComponent(
                                        <ChangePropStateModal
                                            onClose={() => {
                                                setIsModalOpen(false);
                                            }}
                                            onCancel={() => {
                                                setIsModalOpen(false);
                                            }}
                                            onSubmit={async (action) => {
                                                console.log('action', action)
                                                await onActivateNewAccount(action)
                                            }}
                                        />
                                    )
                                    setIsModalOpen(true);
                                }
                            }}>
                                ویرایش وضعیت چالش
                            </Button>
                        </div>
                    </div>
                </div>


                <div className="tab-buttons">
                    <div className="tab-buttons-container">
                        {
                            profileState.roles.includes('admin') ?
                                <button onClick={() => handleAccountTabClick('tab1')}
                                        className={activeAccountTab === 'tab1' ? 'active' : ''}>{variety.slug !== 'real_direct' ? "اکانت مرحله اول" : 'اکانت ریل'}
                                </button> :
                                <></>
                        }
                        {
                            profileState.roles.includes('admin') && (variety.slug !== 'real_direct') ?
                                <button onClick={() => handleAccountTabClick('tab2')}
                                        className={activeAccountTab === 'tab2' ? 'active' : ''}>{variety.slug !== 'one_stage' ? "اکانت مرحله دوم" : 'اکانت ریل'}
                                </button> :
                                <></>
                        }
                        {
                            profileState.roles.includes('admin') && (variety.slug !== 'real_direct' && variety.slug !== 'one_stage') ?
                                <button onClick={() => handleAccountTabClick('tab3')}
                                        className={activeAccountTab === 'tab3' ? 'active' : ''}>{variety.slug !== 'tow_stage' ? "اکانت مرحله سوم" : 'اکانت ریل'}
                                </button> :
                                <></>
                        }
                        {
                            profileState.roles.includes('admin') && (variety.slug === 'three_stage') ?
                                <button onClick={() => handleAccountTabClick('tab4')}
                                        className={activeAccountTab === 'tab4' ? 'active' : ''}>{variety.slug !== 'three_stage' ? "اکانت مرحله چهارم" : 'اکانت ریل'}
                                </button> :
                                <></>
                        }


                    </div>
                </div>


                <div className="tab-content">
                    {activeAccountTab === 'tab1' &&
                        <div
                            className={`order-detail-account-container ${accounts.filter(i => i.level.toString() === '1').length === 0 ? 'no-grid' : ''}`}>
                            {
                                accounts.filter(i => i.level.toString() === '1').length > 0 ?
                                    <>
                                        {
                                            accounts.filter(i => i.level.toString() === '1').map(item => {
                                                return (
                                                    <OrderDetailAccountItem
                                                        broker={item.broker}
                                                        metaapi_id={item.metaapi_id}
                                                        deployment={item.deployment}
                                                        connectionStatus={item.connectionStatus}
                                                        disabled={item.disabled || data.status === "suspended"}
                                                        level={item.level.toString()}
                                                        username={item.username}
                                                        password={item.password}
                                                        server={item.server}
                                                        balance={item.balance}
                                                        type={item.type}
                                                        createdAt={item.createdAt}
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            data.status !== 'suspended' ? <ActivateNewAccount level={1}/> : ''
                                        }
                                    </> :
                                    <GenerateEmptyState/>
                            }
                        </div>
                    }

                    {activeAccountTab === 'tab2' &&
                        <div
                            className={`order-detail-account-container ${accounts.filter(i => i.level.toString() === '2').length === 0 ? 'no-grid' : ''}`}>
                            {
                                accounts.filter(i => i.level.toString() === '2').length > 0 ?
                                    <>
                                        {
                                            accounts.filter(i => i.level.toString() === '2').map(item => {
                                                return (
                                                    <OrderDetailAccountItem
                                                        broker={item.broker}
                                                        level={item.level.toString()}
                                                        disabled={item.disabled || data.status === "suspended"}
                                                        metaapi_id={item.metaapi_id}
                                                        deployment={item.deployment}
                                                        connectionStatus={item.connectionStatus}
                                                        username={item.username}
                                                        password={item.password}
                                                        server={item.server}
                                                        balance={item.balance}
                                                        type={item.type}
                                                        createdAt={item.createdAt}
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            data.status !== 'suspended' ? <ActivateNewAccount level={2}/> : ''
                                        }
                                    </> :
                                    <GenerateEmptyState/>
                            }
                        </div>
                    }
                    {activeAccountTab === 'tab3' &&
                        <div
                            className={`order-detail-account-container ${accounts.filter(i => i.level.toString() === '3').length === 0 ? 'no-grid' : ''}`}>
                            {
                                accounts.filter(i => i.level.toString() === '3').length > 0 ?
                                    <>
                                        {
                                            accounts.filter(i => i.level.toString() === '3').map(item => {
                                                return (
                                                    <OrderDetailAccountItem
                                                        broker={item.broker}
                                                        level={item.level.toString()}
                                                        disabled={item.disabled || data.status === "suspended"}
                                                        metaapi_id={item.metaapi_id}
                                                        deployment={item.deployment}
                                                        connectionStatus={item.connectionStatus}
                                                        username={item.username}
                                                        password={item.password}
                                                        server={item.server}
                                                        balance={item.balance}
                                                        type={item.type}
                                                        createdAt={item.createdAt}
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            data.status !== 'suspended' ? <ActivateNewAccount level={3}/> : ''
                                        }
                                    </>
                                    :
                                    <GenerateEmptyState/>
                            }
                        </div>
                    }
                    {activeAccountTab === 'tab4' &&
                        <div
                            className={`order-detail-account-container ${accounts.filter(i => i.level.toString() === '4').length === 0 ? 'no-grid' : ''}`}>
                            {
                                accounts.filter(i => i.level.toString() === '4').length > 0 ?
                                    <>
                                        {
                                            accounts.filter(i => i.level.toString() === '4').map(item => {
                                                return (
                                                    <OrderDetailAccountItem
                                                        broker={item.broker}
                                                        level={item.level.toString()}
                                                        disabled={item.disabled || data.status === "suspended"}
                                                        metaapi_id={item.metaapi_id}
                                                        deployment={item.deployment}
                                                        connectionStatus={item.connectionStatus}
                                                        username={item.username}
                                                        password={item.password}
                                                        server={item.server}
                                                        balance={item.balance}
                                                        type={item.type}
                                                        createdAt={item.createdAt}
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            data.status !== 'suspended' ? <ActivateNewAccount level={4}/> : ''
                                        }
                                    </>
                                    :
                                    <GenerateEmptyState/>
                            }
                        </div>
                    }

                </div>


            </div>
        )
    }


    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <div className="nk-content ">
                <div className="container-fluid">

                    <div className="profile-info-container" style={{alignItems: "center"}}>
                        <div className="history-item-image">
                            <MdOutlineCandlestickChart size={30} color={"#38AF84"}/>
                        </div>
                        <div className="profile-info">
                            {/*<div className="profile-name">اطلاعات سفارش</div>*/}
                            <div className="profile-info">
                                <div className="profile-name">{mainUser?.fullName} {kyc?.status === 'approved' ?
                                    <MdVerified size={20} color={"#4F9D81"}/> : ''} </div>
                                <div className="profile-description">

                                    <div
                                        className={`kyc-status ${kyc?.status === 'pending' || kyc?.status === 'update_required' || !kyc?.status ? "yellow-color" : (kyc?.status === 'approved' ? "green-color" : "")}`}>
                                        {kyc?.status === 'pending' || !kyc?.status ? "کاربر نیاز به احراز هویت دارد" : ""}
                                        {kyc?.status === 'update_required' ? "اطلاعات احراز هویت کاربر نیاز به تغییرات دارد" : ""}
                                        {kyc?.status === 'approved' ? "کاربر قبلا احراز هویت شده است" : ""}
                                    </div>
                                    {
                                        kyc?.status === 'pending' || kyc?.status === 'update_required' || !kyc?.status ?
                                            <button className="btn btn-outline yes-or-no-btn yellow-bg-color"
                                                    onClick={() => {
                                                        if (id)
                                                            navigate(`/kyc/${mainUser._id}`, {state: {orderId: id}})
                                                        else
                                                            navigate('/kyc')
                                                    }}
                                            >
                                                مشاهده اطلاعات کاربر

                                            </button> : ''
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-buttons">
                        <div className="tab-buttons-container">
                            {
                                profileState.roles.includes('admin') ?
                                    <button disabled={isOrderInfoLoading} onClick={() => handleTabClick('tab1')}
                                            className={activeTab === 'tab1' ? 'active' : ''}>مشخصات کاربر
                                    </button> :
                                    <></>
                            }
                            {
                                profileState.roles.includes('admin') ?
                                    <button disabled={isOrderInfoLoading}  onClick={() => handleTabClick('tab2')}
                                            className={activeTab === 'tab2' ? 'active' : ''}>اطلاعات سفارش
                                    </button> :
                                    <></>
                            }
                            {
                                profileState.roles.includes('admin') ?
                                    <button disabled={isOrderInfoLoading}  onClick={() => handleTabClick('tab3')}
                                            className={activeTab === 'tab3' ? 'active' : ''}>وضعیت اکانت ها
                                    </button> :
                                    <></>
                            }


                        </div>
                    </div>

                    <div className="tab-content">
                        {activeTab === 'tab1' &&
                            <div>
                                {
                                    isUserInfoLoading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <UserInfo/>
                                }
                            </div>
                        }

                        {activeTab === 'tab2' &&
                            <div>
                                {
                                    isOrderInfoLoading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <OrderInfo/>
                                }
                            </div>
                        }
                        {activeTab === 'tab3' &&
                            <div>
                                {
                                    isAccountsStatusLoading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <AccountManager/>
                                }
                            </div>
                        }

                    </div>

                </div>
            </div>
        </React.Fragment>

    );
};

export default OrderDetail;
