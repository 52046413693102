import React, {useEffect, useState} from "react";
import {EcommerceAppMenu, FxPilotMenu, ServicesAppMenu, SupportAppMenu, WebsiteAppMenu} from "../menu/MenuData";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux"; // Icons for mobile menu


const AppSidebar = () => {
    const [activeMenu, setActiveMenu] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menu, setMenu] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const handleMenuClick = (menuIndex) => {
        setActiveMenu(menuIndex === activeMenu ? null : menuIndex);
    };
    useEffect(() => {
        // Find the active menu item based on the current path
        const currentPath = location.pathname;
        const setMenuItems = () => {
            if(location.pathname.includes('/app/website'))
                setMenu(WebsiteAppMenu);

            if(location.pathname.includes('/app/ecommerce'))
                setMenu(EcommerceAppMenu);

            if(location.pathname.includes('/app/services'))
                setMenu(ServicesAppMenu);

            if(location.pathname.includes('/app/support'))
                setMenu(SupportAppMenu);
        }
        const findActiveMenu = () => {
            for (let i = 0; i < menu.length; i++) {
                const menuItem = menu[i];
                if (menuItem.subMenu) {
                    for (let j = 0; j < menuItem.subMenu.length; j++) {
                        if (menuItem.subMenu[j].link === currentPath) {
                            setActiveMenu(i); // Set active menu based on parent menu index
                            return;
                        }
                    }
                }
                if (menuItem.link === currentPath) {
                    setActiveMenu(i);
                    return;
                }
            }
            setActiveMenu(null);
        };
        setMenuItems();
        findActiveMenu();
    }, [location.pathname]);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const profile = useSelector((state) => state.profile);
    return (
        <>
            {/* Mobile menu toggle button */}
            <button className="menu-toggle" onClick={toggleMenu}>
                {isMenuOpen ? 'بستن منو' : 'بازکردن منو'}
            </button>

            {/* Sidebar Menu */}
            <div className={`vertical-menu ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                    {menu.map((menu, index) => (
                        <li
                            key={index}
                            // className={activeMenu === index ? 'active' : ''}
                            className={'active'}
                            onClick={() => handleMenuClick(index)}
                        >
                            {menu.text}
                            {menu.subMenu && (
                                // <ul className={`submenu ${activeMenu === index ? 'show' : ''}`}>
                                <ul className={`submenu ${'show'}`}>
                                    {menu.subMenu.map((subItem, subIndex) => {
                                        if(subItem){

                                            if(typeof subItem !== "string"){
                                                if((subItem.roles && subItem.roles.includes(profile.roles[0].toLowerCase()))){
                                                    if((subItem.applications && subItem.applications?.includes(process.env.APPLICATION)) || !subItem.applications){
                                                        return(
                                                            <li
                                                                key={subIndex}
                                                                className={subItem.link === location.pathname ? 'active' : ''}
                                                                onClick={() => {
                                                                    navigate(subItem.link)
                                                                }}
                                                            >
                                                                <a > {subItem.text}</a>

                                                            </li>
                                                        )
                                                    }
                                                }


                                            }
                                            return '';
                                        }
                                    })}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default AppSidebar;