import React from "react";
import {makeStyles} from "@material-ui/styles";
import {Button} from "reactstrap";

const useStyles = makeStyles((theme) => ({
        gray: {
            color: "#525252",
            textAlign: "center",
            display: "block",
        },
        icon: {
            fontSize: "52px",
            marginBottom: "15px"
        },
        title: {
            fontSize: "35px",
            marginBottom: "12px"
        },
        content: {
            color: "#525252",
            fontSize: "23px"
        },
        card: {
            width: "100%",
            margin: "auto",
            padding: "0px 130px 15% 130px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        emptyStateButton: {
            background: "#4F9D81",
            fontFamily: "dana",
            fontSize: "18px",
            padding: "15px 30px",
            marginTop: "15px"
        }
    })
);


export function EmptyState({icon, className, title, content, doNotUseButton, buttonText, buttonOnClick}) {
    const classes = useStyles();
    return (
        <div>
            <div className={`${classes.card} ${className}`}>
                <div className={classes.icon}>
                    {icon}
                </div>
                <div className={`${classes.title} ${classes.gray} title`}>{title}</div>
                <p className={`${classes.content} ${classes.gray} content`}>
                    {content}
                </p>
                {
                    doNotUseButton ? '' :

                        <Button className={classes.emptyStateButton} size="md" type="submit" onClick={buttonOnClick}>
                            {buttonText}
                        </Button>
                }
            </div>
        </div>
    )
}
