import {addDays, endOfMonth, format, startOfMonth, subDays} from "date-fns";
import {Bar, Line} from "react-chartjs-2";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Field} from "../../../../components/fouladyar/field/field";
import annotationPlugin from "chartjs-plugin-annotation";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Tooltip,
    BarController
} from "chart.js";

ChartJS.register(BarElement, BarController, LineElement, CategoryScale,annotationPlugin, LinearScale, PointElement, Tooltip, Legend);

export default function NumberOfTradesCompoLineAndBarChartComponent ({trades}) {


    const {t, i18n} = useTranslation();

    const [period, setPeriod] = useState('month'); //week | month


    function getLast7Days() {
        const days = [];

        for (let i = 6; i >= 0; i--) {
            const day = subDays(new Date(), i);
            const dayName =format(day, 'EEEE');
            days.push(t(dayName)); // 'EEEE' gives the full day name (e.g., 'Monday')
        }

        return days;
    }
    function getCurrentMonthDays() {
        const days = [];
        const today = new Date();
        const start = startOfMonth(today);
        const end = endOfMonth(today);

        for (let day = start; day <= end; day = addDays(day, 1)) {
            const dayNumber = format(day, 'd'); // Get the day of the month (1, 2, 3, ...)
            days.push(parseInt(dayNumber));
        }

        return days;
    }


    function getLast7DaysProfits(trades) {
        const profits = [];

        for (let i = 6; i >= 0; i--) {
            const day = subDays(new Date(), i);
            const dayString = format(day, 'yyyy-MM-dd');

            // Filter trades for this day
            const tradesForDay = trades.filter(trade =>
                format(new Date(trade.entryTime), 'yyyy-MM-dd') === dayString
            );

            // Sum the profits for this day
            const profitForDay = tradesForDay.reduce((sum, trade) => trade.pnl > 0 ? sum + 1 : sum, 0);

            // Add the sum to the profits array
            profits.push(profitForDay);
        }

        return profits;
    }
    function getLast7DaysLooses(trades) {
        const loos = [];

        for (let i = 6; i >= 0; i--) {
            const day = subDays(new Date(), i);
            const dayString = format(day, 'yyyy-MM-dd');

            // Filter trades for this day
            const tradesForDay = trades.filter(trade =>
                format(new Date(trade.entryTime), 'yyyy-MM-dd') === dayString
            );

            // Sum the profits for this day
            const profitForDay = tradesForDay.reduce((sum, trade) => trade.pnl < 0 ? sum + 1 : sum, 0);

            // Add the sum to the profits array
            loos.push(profitForDay * -1);
        }

        return loos;
    }


    function getCurrentMonthProfits(trades) {
        const profits = [];

        // Step 1: Get the start and end date of the current month
        const start = startOfMonth(new Date());
        const end = endOfMonth(new Date());

        // Step 2: Iterate through each day of the current month
        for (let i = 29; i >= 0; i--) {
            const day = subDays(new Date(), i);
            const dayString = format(day, 'yyyy-MM-dd');

            // Filter trades for this day
            const tradesForDay = trades.filter(trade =>
                format(new Date(trade.entryTime), 'yyyy-MM-dd') === dayString
            );

            // Sum the profits for this day
            const profitForDay = tradesForDay.reduce((sum, trade) => trade.pnl > 0 ? sum + 1 : sum, 0);

            // Add the sum to the profits array
            profits.push(profitForDay);
        }

        return profits;
    }
    function getCurrentMonthLooses(trades) {
        const looses = [];

        // Step 1: Get the start and end date of the current month
        const start = startOfMonth(new Date());
        const end = endOfMonth(new Date());

        // Step 2: Iterate through each day of the current month
        for (let i = 29; i >= 0; i--) {
            const day = subDays(new Date(), i);
            const dayString = format(day, 'yyyy-MM-dd');

            // Filter trades for this day
            const tradesForDay = trades.filter(trade =>
                format(new Date(trade.entryTime), 'yyyy-MM-dd') === dayString
            );

            // Sum the profits for this day
            const loosesForDay = tradesForDay.reduce((sum, trade) => trade.pnl < 0 ? sum + 1 : sum, 0);

            // Add the sum to the profits array
            looses.push(loosesForDay * -1);
        }

        return looses;
    }


    const profits_week = getLast7DaysProfits(trades)
    const profits_month = getCurrentMonthProfits(trades)

    const looses_week = getLast7DaysLooses(trades)
    const looses_month = getCurrentMonthLooses(trades)

    const week = getLast7Days();
    const month = getCurrentMonthDays();


    const data = {
        labels: period === 'month' ? month : (period === 'week' ? week : []),
        datasets: [
            {
                type: 'bar', // Bar dataset
                label: 'معاملات ضررده',
                data: period === 'month' ? looses_month : (period === 'week' ? looses_week : []), // Red segment data
                backgroundColor: '#DA6B6B', // Red color
                stack: 'Stack 0',
                barThickness: period === 'month' ? 10 : 20, // Adjust bar thickness
            },
            {
                type: 'bar', // Bar dataset
                label: 'معاملات سودآور',
                stack: 'Stack 0',
                data: period === 'month' ? profits_month : (period === 'week' ? profits_week : []), // Green segment data
                backgroundColor: '#4F9D81', // Green color
                barThickness: period === 'month' ? 10 : 20, // Adjust bar thickness
              // Set border width to simulate spacing
            },
            {
                type: 'line', // Line dataset
                label: 'تعداد کل معاملات',
                data: period === 'month' ? profits_month.map((item, index)=> item + (looses_month[index]*-1)) : (period === 'week' ? profits_week.map((item, index)=> item + (looses_week[index]*-1)) : []),
                backgroundColor: 'rgba(75, 192, 192, 1)',
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
                borderWidth: 2,
                tension: 0.4, // Adjust this value to make the line curved

                pointRadius: 0,          // Hide the dots
                pointHoverRadius: 0,     // Hide the dots on hover
                pointHitRadius: 10

            },
        ],
    };


    const annotation = {
        annotations: {
            line0: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: 'rgba(61,61,61,0.65)',
                borderWidth: 1,
            },
        }
    }


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            annotation,
            legend: {
                labels: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
            },

        },
        scales: {
            x: {
                grid: {
                    display: false, // Hide vertical grid lines
                },
                title: {
                    stacked: true,
                }
            },
            y: {
                title: {
                    stacked: true,
                }
            },
        },
    };


    return(
        <table border="1" cellPadding="10" className="w-100 mb-3">
            <thead>
            <tr>
                <th className="d-flex flex-row justify-content-between align-center">

                    <span>نمودار تعداد معاملات (سودآور/ضررده/کل)</span>
                    <div className="w-auto">
                        <Field
                            className="analytics-select-style"
                            id={"period"}
                            name={"period"}
                            type={'select'}
                            options={[
                                {label: 'هفتگی', value: 'week'},
                                {label: 'ماهانه', value: 'month'},
                            ]}
                            value={period}
                            onChange={(e) => {
                                setPeriod(e)
                            }}
                        />
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <div style={{width: '100%', height: '400px'}}>
                    <Line  data={data} options={options}/>
                </div>
            </tr>

            </tbody>
        </table>
    )

}
