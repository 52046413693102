import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";
import {Field} from "../../../components/fouladyar/field/field";
import {useTranslation} from "react-i18next";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {RxInfoCircled} from "react-icons/rx";
import {RiSendPlane2Line} from "react-icons/ri";
import {MdAttachFile} from "react-icons/md";
import {HiOutlineEmojiHappy} from "react-icons/hi";
import {LoadingState} from "../../../components/fouladyar/loading-state/loadingState";
import {ConvertGregorianToJalali} from "../../../shared/convertGregorianToJalali";
import tr from "react-datepicker";
import {Brokers} from "../../../shared/array/broker";
import {TicketStatus} from "../../../shared/array/ticket";
import toast from "react-hot-toast";
import {YesOrNoModal} from "../../../components/fouladyar/modal-helper/pages/yesOrNo";
import {replacePlaceholders} from "../../../components/fouladyar/table";

const TicketChat = () => {
    const auth = useSelector((state) => state.auth);
    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const profile = useSelector((state) => state.profile);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagesIsLoading, setMessagesIsLoading] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [messages, setMessages] = useState([]);
    const [sender, setSender] = useState({
        fullname: '',
        email: '',
        mobile: ''
    });
    const [product, setProduct] = useState({
        name: '',
        username: '',
        server: ''
    });
    const [ticketInfo, setTicketInfo] = useState({
        index: '',
        title: '',
        category: '',
        status: '',
    });
    const [mobileView, setMobileView] = useState(false);

    const [body, setBody] = useState({
        senderId: profile._id,
        receiverId: "",
        isGroupChat: true,
        chatId: id,
        message: "",
        type: "ticket"
    })






    const viewChange = () => {
        if (window.innerWidth < 750) {
            setMobileView(true);

        } else {
            setMobileView(false);
        }
    };


    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);


    async function getMessagesByChatId() {
        try {
            setMessagesIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/messages/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('messages', res.data)
            console.log('profile', profile)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setMessages(res.data.data.messages.map(item => {
                    return{
                        ...item,
                        mechat: profile.userId === item.senderId || (profile.roles.includes('admin') && item.isSentFromAdmin  === true)
                    }
                }))

            }
            setMessagesIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setMessagesIsLoading(false)
        }
    }

    async function getTicketInfo() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/tickets/info/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('ticket info', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setProduct({
                    ...res.data.data?.ticket?.extras?.product,
                    broker: {...res.data.data?.ticket?.extras?.broker},
                    account: {...res.data.data?.ticket?.extras?.account},
                    ...product
                });
                setSender({
                    ...sender,
                    ...res.data.data.sender
                });
                setTicketInfo({
                    ...ticketInfo,
                    ...res.data.data.ticket,
                    product: {...res.data.data?.ticket?.extras?.product},
                    broker: {...res.data.data?.ticket?.extras?.broker},
                    account: {...res.data.data?.ticket?.extras?.account},
                    category: res.data.data.category.name
                });

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }

    async function sendMessage() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/messages`, {
                ...body,
                isSentFromAdmin: profile.roles.includes('admin')
            },{
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('sending message response', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                getMessagesByChatId()
                setBody({
                    ...body,
                    message: ''
                })
            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }


    useEffect(() => {
        viewChange();
        getMessagesByChatId();
        getTicketInfo();


    }, []);

    async function onChangeStatus(e) {

        try {
            // setIsCardsLoading(true)
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/tickets/${ticketInfo._id}`, {
                status: e,
                isSentFromAdmin: profile.roles.includes('admin')
            },{
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('sending message response', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                getMessagesByChatId()
                setBody({
                    ...body,
                    message: ''
                })
                setIsModalOpen(false)
                SuccessToaster({message: "وضعیت تیکت با موفقیت تغییر کرد"}, t)
            }

        } catch (e) {
            ErrorToaster(e, t)
        }
    }







    function TicketInformation({className}){
        return(
            <div className={`ticket-chat-info ${className}`}>
                <div className="ticket-chat-info-item">

                    <div className="ticket-chat-info-title">اطلاعات تیکت</div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">عنوان تیکت :</div>
                        <div className="ticket-chat-info-attribute-value">{ticketInfo.title || '-'}</div>
                    </div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">دسته بندی :</div>
                        <div className="ticket-chat-info-attribute-value">{ticketInfo.category || '-'}</div>
                    </div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">وضعیت تیکت :</div>

                        {
                            profile?.roles?.includes('admin') ?
                                <Field
                                    id={"ticketStatus"}
                                    name={"ticketStatus"}
                                    type={'select'}
                                    options={TicketStatus}
                                    value={ticketInfo.status}
                                    onChange={(e) => {

                                        setTicketInfo(prevState => {
                                            return{
                                                ...prevState,
                                                status: e
                                            }
                                        })
                                        setModalComponent(
                                            <YesOrNoModal
                                                cancelText={"بستن"}
                                                submitText={"مطمئنم!"}
                                                submitMode={ 'green'}
                                                onClose={() => {
                                                    setIsModalOpen(false);
                                                }}
                                                onCancel={() => {
                                                    setIsModalOpen(false);
                                                }}
                                                onSubmit={async () => {
                                                    await onChangeStatus(e)
                                                }}
                                                title={"تغییر وضعیت تیکت"}
                                                content={"آیا از تغییر وضعیت تیکت اطمینان کامل را دارید. در صورتی که تیکت بسته شود، کاربر باید مجدد برای صحبت با پشتیبانی تیکت ثبت کند."}
                                            />
                                        );
                                        setIsModalOpen(true)
                                    }}
                                />:
                                <div className="ticket-chat-info-attribute-value">{t(ticketInfo.status) || '-'}</div>
                        }
                    </div>
                </div>
                <div className="ticket-chat-info-item">

                    <div className="ticket-chat-info-title">اطلاعات محصول</div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">عنوان محصول :</div>
                        <div className="ticket-chat-info-attribute-value"> {ticketInfo?.product?.title || "-"}</div>
                    </div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">نام کاربری :</div>
                        <div className="ticket-chat-info-attribute-value">{ticketInfo?.account?.username || "-"}</div>
                    </div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">سرور :</div>
                        <div className="ticket-chat-info-attribute-value">{ticketInfo?.account?.server || "-"}</div>
                    </div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">بروکر :</div>
                        <div className="ticket-chat-info-attribute-value">{ticketInfo?.broker?.name || "-"}</div>
                    </div>
                </div>
                <div className="ticket-chat-info-item">

                    <div className="ticket-chat-info-title">اطلاعات ارسال کننده</div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">نام نام خانوادگی :</div>
                        <div className="ticket-chat-info-attribute-value"> {sender.fullName || '-'}</div>
                    </div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">ایمیل :</div>
                        <div className="ticket-chat-info-attribute-value">{sender.email || '-'}</div>
                    </div>
                    <div className="ticket-chat-info-attribute">
                        <div className="ticket-chat-info-attribute-key">شماره تماس :</div>
                        <div className="ticket-chat-info-attribute-value">{toFarsiNumber(sender.mobile) || '-'}</div>
                    </div>
                </div>
            </div>

        )
    }

    function getJalaliTime(createdAt) {
        return toFarsiNumber(ConvertGregorianToJalali(createdAt, true))
    }

    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() =>setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <div className="nk-content ">
                <div className="container-fluid">

                    <div className="ticket-chat">

                        <div className="ticket-chat-title">
                            <div className="ticket-chat-info-title-text">شماره تیکت   {toFarsiNumber(ticketInfo.index)}  </div>
                            {
                                mobileView ?
                                    <div className="ticket-chat-info-title-text" onClick={()=>{
                                        setModalComponent(<TicketInformation/>);
                                        setIsModalOpen(true)
                                    }}><RxInfoCircled size={22} color={"#797979"} /></div> : <div></div>
                            }

                        </div>
                        <div className="ticket-chat-container">
                            <div className="ticket-chat-message-container">

                                <div className={`ticket-chat-message-items-container ${messagesIsLoading ? "messages-is-loading" : ''}`}>
                                    {
                                        messagesIsLoading ? <div className="message-loading-state-container"> <LoadingState/></div> : messages.map(item => {
                                            if(item.mechat){
                                                return(
                                                    <div className="ticket-chat-message-item-container mechat">
                                                        <div className="ticket-chat-message-item-text">{item?.message}</div>
                                                        <div className="ticket-chat-message-item-date">
                                                            {getJalaliTime(item.createdAt)} - {item?.senderFullName}
                                                        </div>
                                                    </div>
                                                )
                                            }else{
                                                return (
                                                    <div className="ticket-chat-message-item-container">
                                                        <div className="ticket-chat-message-item-text">{item?.message}</div>
                                                        <div className="ticket-chat-message-item-date">
                                                            {getJalaliTime(item.createdAt)} - {item?.senderFullName}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                </div>
                                <div className="ticket-chat-message-input-container">
                                    <Field
                                        className="ticket-input"
                                        id={"ticket-input"}
                                        name={"ticket-input"}
                                        type={'textarea'}
                                        value={body.message}
                                        onChange={(e) => {
                                            setBody({
                                                ...body,
                                                message: e
                                            })
                                        }}
                                    />
                                    <div className="ticket-input-action-container">
                                        <div className="ticket-input-action-send" onClick={async () => {await sendMessage()}}>
                                            <RiSendPlane2Line color={"#fff"} size={22}/>
                                        </div>
                                        <div className="ticket-input-action-other">
                                            <div className="ticket-input-action-emoji"><HiOutlineEmojiHappy  color={"#fff"} size={22}/></div>
                                            <div className="ticket-input-action-attach"><MdAttachFile  color={"#fff"} size={22}/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TicketInformation className={`${ mobileView ? 'mobile-hidden-view' : ''}`}/>
                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>

    );
};

export default TicketChat;
