import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";


export function ErrorToaster (e, t) {
  console.log("Error", e);
  if(t)
    toast.error(t(e.response?.data.message ? e.response?.data.message : (e.response?.data.error || (e.response?.data.message || e.message))))
  else
    toast.error(e.response?.data.message ? e.response?.data.message : (e.response?.data.error || (e.response?.data.message || e.message)))
}

export function ErrorToasterDirectString (sting) {
    toast.error(sting, {
      duration: 19000
    })
}

export function SuccessToaster (e, t) {
  console.log("Success", e);
  if(t)
    toast.success(t(e.response?.data.message ? e.response?.data.message : (e.response?.data.error || (e.response?.data.message || e.message))))
  else
    toast.success(e.response?.data.message ? e.response?.data.message : (e.response?.data.error || (e.response?.data.message || e.message)))
}
