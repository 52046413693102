import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {LuCopy, LuMoreHorizontal, LuMoreVertical} from "react-icons/lu";
import {useNavigate} from "react-router-dom";
import {IoAddOutline} from "react-icons/io5";
import bingxImage from "../../../assets/images/mrpropfund/brokers/bingx.png";
import {GoAlertFill, GoCheckCircleFill} from "react-icons/go";
import {BOT_SVG} from "../../../shared/svg";
import {Field} from "../../../components/fouladyar/field/field";
import {LoadingState} from "../../../components/fouladyar/loading-state/loadingState";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import {EmptyStateObject, filterStructure, TableActionsStructure, tableStatics, tableStructure} from "./index";
import Table, {replacePlaceholders} from "../../../components/fouladyar/table";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {YesOrNoModal} from "../../../components/fouladyar/modal-helper/pages/yesOrNo";
import toast from "react-hot-toast";
import {IoMdClose} from "react-icons/io";



const BotList = () => {

    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [yesOrNoModalIsOpen, setYesOrNoModalIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [data, setData] = useState([
        // {
        //     _id: '0292283293813203032',
        //     name: 'ربات سوئینگ رایدر (۱)',
        //     application: 'Metatrader',
        //     platform: 'MQL5',
        //     version: '1.6.17',
        // },
        // {
        //     _id: '0292283293813203032',
        //     name: 'ربات مدیریت سرمایه',
        //     application: 'Metatrader',
        //     platform: 'MQL5',
        //     version: '1.2.127',
        // },
        // {
        //     _id: '0292283293813203032',
        //     name: 'ربات ATR',
        //     application: 'Metatrader',
        //     platform: 'MQL5',
        //     version: '1.5.14',
        // },
        // {
        //     _id: '0292283293813203032',
        //     name: 'ربات سوئینگ رایدر (۱)',
        //     application: 'Metatrader',
        //     platform: 'MQL5',
        //     version: '1.4.14',
        // },

    ]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        itemPerPage: 7,
        currentPage: 1,
        totalItems: 0,
        lastUpdateBy: ''
    })
    const [modalProps, setModalProps] = useState({
        modalCancelText: "",
        modalSubmitText: "",
        modalTitle: "",
        modalContent: "",
        modalItemTarget: "",
        modalType: "",
        modalPath: ""
    });


    const [filter, setFilter] = useState({})

    async function initializeData() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setData(res.data.data.bots)
                setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function fetchData(updater) {


        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot?limit=${pagination.itemPerPage}&page=${pagination.currentPage}&${filter}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                setData(res.data.data.bots)
                if (updater === 'filter')
                    setPagination({
                        ...pagination,
                        totalItems: res.data.data.totalItems,
                        currentPage: 1,
                        lastUpdateBy: updater
                    })
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

        useEffect(() => {
            initializeData();
        }, []);


        useEffect(() => {
            if (pagination.lastUpdateBy === "pagination")
                fetchData('pagination');

        }, [pagination]);


        useEffect(() => {
            fetchData('filter');
        }, [filter]);


    function BotItem({item}) {
        return (
            <div className="bot-item">
                <div className="bot-item-image">
                    <div dangerouslySetInnerHTML={{__html: BOT_SVG}}/>
                </div>

                <div className="bot-item-title-and-platform">
                    <h5>{item.name}</h5>
                    <h6>{item.platform}</h6>
                </div>
                <div className="bot-action">
                    <div className="bot-version">
                        {toFarsiNumber(item.version)}
                    </div>
                    <UncontrolledDropdown >
                        <DropdownToggle
                            tag="a"
                            href="#more"
                            onClick={(ev) => ev.preventDefault()}
                            className="dropdown-toggle btn btn-icon btn-trigger p-1"
                        >
                            <LuMoreVertical size={17} color={"#ccc"}/>
                        </DropdownToggle>
                        <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                                {
                                    tableStructure.find(i=>i.useActionsButton).actions.map((j, index) => {
                                        if ((j.roles && j.roles.includes(profile.roles[0])) || !j.roles)
                                            return (
                                                <li key={`action-btn-${j.slug}-${index}`}>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#edit"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();

                                                            if (j.useRoute) {
                                                                navigate(`${replacePlaceholders(j.route, item) || ""}`);
                                                            }

                                                            if (j.useYesOrNoModal) {
                                                                setYesOrNoModalIsOpen(true);
                                                                setModalProps({
                                                                    modalCancelText: j.modalCancelText || "بستن",
                                                                    modalSubmitText: j.modalSubmitText || "تایید",
                                                                    modalTitle: j.modalTitle || "عنوان پنجره یافت نشد",
                                                                    modalContent: j.modalContent || "متن پنجره یافت نشد",
                                                                    modalType: j.modalType || "",
                                                                    modalPath: j.modalPath || "",
                                                                    modalItemTarget: item || "",
                                                                });
                                                            }
                                                            // onEditClick(item.id);
                                                            // toggle("edit");
                                                        }}
                                                    >
                                                        <div className="action-btn-icon">
                                                            {j.icon}
                                                        </div>
                                                        <span>{j.title}</span>
                                                    </DropdownItem>
                                                </li>
                                            );

                                        return null
                                    })
                                }
                            </ul>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <ModalHelper
                open={yesOrNoModalIsOpen}
                onOpen={() => setYesOrNoModalIsOpen(true)}
                onClose={() => setYesOrNoModalIsOpen(false)}
                component={
                    <YesOrNoModal
                        cancelText={modalProps.modalCancelText}
                        submitText={modalProps.modalSubmitText}
                        onClose={() => {
                            setYesOrNoModalIsOpen(false);
                        }}
                        onCancel={() => {
                            setYesOrNoModalIsOpen(false);
                        }}
                        onSubmit={async () => {
                            if (modalProps.modalType === 'delete') {
                                // replacePlaceholders(j.route, item)
                                try {
                                    const res = await axios.delete(`${process.env.REACT_APP_API_URL}${replacePlaceholders(modalProps.modalPath, modalProps.modalItemTarget)}`, {
                                        headers: {authorization: `Bearer ${auth.token}`}
                                    });

                                    if (res.status === 200) {
                                        await fetchData('filter');
                                        setYesOrNoModalIsOpen(false);
                                        toast.success("آیتم مورد نظر با موفقیت حذف شد")
                                    }
                                } catch (e) {
                                    ErrorToaster(e, t);
                                }
                            }
                            if (modalProps.modalType === 'post') {
                                // replacePlaceholders(j.route, item)
                                try {
                                    const res = await axios.post(`${process.env.REACT_APP_API_URL}${replacePlaceholders(modalProps.modalPath, modalProps.modalItemTarget)}`, {},{
                                        headers: {authorization: `Bearer ${auth.token}`}
                                    });

                                    if (res.status === 200) {
                                        await fetchData('filter');
                                        setYesOrNoModalIsOpen(false);
                                        toast.success("آیتم مورد نظر با موفقیت ")
                                    }
                                } catch (e) {
                                    ErrorToaster(e, t);
                                }
                            }
                        }}
                        title={modalProps.modalTitle}
                        content={modalProps.modalContent}
                    />
                }
            />

            <div className="grid">
                <div className="grid-header">
                    <h3>ربات ها</h3>
                    <div className="grid-actions">
                        <button className="btn small-button grid-action-btn" type="button" onClick={() => {
                            setModalComponent(
                                <AddBotComponent
                                    isEditing={false}
                                    onClose={() => {setIsModalOpen(false)}}
                                    onReload={async () => {await fetchData('filter')}}
                                />
                            )
                            setIsModalOpen(true);
                        }}>
                            <IoAddOutline size={17} color={"#49e7ad"}/>
                        </button>
                    </div>
                </div>

                <div className="grid-kyc-container">


                    {
                        data.map(item => {
                            return(
                                <BotItem
                                    item={item}
                                />
                            )
                        })
                    }
                </div>
            </div>


        </React.Fragment>
    );
};

export default BotList;

function AddBotComponent({isEditing, _bot, onClose, onReload}) {
    const auth = useSelector((state) => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [bot, setBot] = useState({
        name: isEditing ? _bot?.title : '',
        application: isEditing ? _bot?.cardType : 'wallet',
        platform: isEditing ? _bot?.cardNumber : '',
        version: isEditing ? _bot?.walletNetwork : '',
    })

    async function onSubmit() {
        try {
            const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/bot`, bot, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (newCardResponse.data.statusCode === 200) {
                setIsSubmitting(false);
                onClose();
                onReload()
            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }
    }

    async function onEdit({_id}) {
        try {
            const res = (await axios.put(`${process.env.REACT_APP_API_URL}/api/bot/${_bot._id}`, bot, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (res.data.statusCode === 200) {
                setIsSubmitting(false);
                onClose();
                onReload()
            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }
    }

/*    async function onDelete(_id) {
        try {
            const res = (await axios.delete(`${process.env.REACT_APP_API_URL}/api/cards/${_bot._id}`, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (res.data.statusCode === 200) {
                SuccessToaster({message: 'کارت مورد نظر با موفقیت حذف شد'}, t)
                setIsSubmitting(false);
                onClose();
                onReload()
            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }
    }*/

    return (
        <div className="modal-container">
            <div className="close-modal-btn" onClick={() => onClose()}>
                <IoMdClose size={20} color={"#FAF4F4"}/>
            </div>
            <h4 className="modal-title">{isEditing ? "ویرایش ربات" : "افزودن ربات"}</h4>


            <div className="modal-note">
                لطفا در وارد کردن شماره کارت یا آدرس ولت خود دقت فرمایید تا از هر گونه واریز اشتباه جلوگیری بعمل
                آید.
                <br/>
                <br/>
                مسئولیت واریز به آدرس کارت یا ولت اشتباه به عهده خود کاربر خواهد بود
            </div>
            <div className='modal-form'>
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"عنوان ربات"}
                    id={"name"}
                    name={"name"}
                    type={'text'}
                    value={bot.name}
                    onChange={(e) => {
                        setBot({
                            ...bot,
                            name: e
                        })
                    }}
                />
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"اپلیکیشن"}
                    id={"application"}
                    name={"application"}
                    type={'select'}
                    options={[
                        {value: "Metatrader", label: "متاتریدر"},
                        {value: "Tradingview", label: "تریدینگ ویو"}
                    ]}
                    value={bot.application}
                    onChange={(e) => {
                        setBot({
                            ...bot,
                            application: e
                        })
                    }}
                />
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"پلتفرم"}
                    id={"platform"}
                    name={"platform"}
                    type={'select'}
                    options={
                        bot.application === "Metatrader" ?
                        [
                            {value: "MQL5", label: "MQL5"},
                            {value: "MQL4", label: "MQL4"}
                        ] : (
                                bot.application === "Tradingview" ?
                                    [
                                        {value: "v1", label: "v1"},
                                        {value: "v2", label: "v2"},
                                        {value: "v3", label: "v3"},
                                        {value: "v4", label: "v4"},
                                    ] : []
                            )
                    }
                    value={bot.platform}
                    onChange={(e) => {
                        setBot({
                            ...bot,
                            platform: e
                        })
                    }}
                />
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"ورژن"}
                    id={"version"}
                    name={"version"}
                    type={'text'}
                    value={bot.version}
                    onChange={(e) => {
                        setBot({
                            ...bot,
                            version: e
                        })
                    }}
                />
            </div>

            <div className="form-submit-btn" style={{opacity: isSubmitting ? 0.5 : 1}} onClick={async () => {
                if (!isSubmitting) {
                    setIsSubmitting(true);
                    isEditing ? await onEdit(_bot._id) : await onSubmit()
                }
            }}>
                {isEditing ? "ویرایش ربات" : "ایجاد ربات"}

            </div>

        {/*    {
                isEditing ?
                    <div className="form-submit-btn remove-card-btn" style={{opacity: isSubmitting ? 0.5 : 1}}
                         onClick={async () => {
                             if (!isSubmitting) {
                                 await onDelete(_bot._id)
                             }
                         }}>
                        حذف کارت

                    </div> :
                    <></>
            }*/}

        </div>
    )
}