export const tableStatics = {
    title: "محصولات",
    description: "",
    addNewItemButtonLink: '/app/ecommerce/product-add'
}
export const formStatics = {
    title: "ایجاد محصول",
    editTitle: "ویرایش محصول",
    description: "از اینجا میتوانید اقدام به ایجاد محصول کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش محصول کنید",
    submitText: "ایجاد محصول",
    editSubmitText: "ویرایش محصول",
}
export const EmptyStateObject = {
    title: "محصول یافت نشد",
    content: "لطفا از طریق لینک زیر وارد صفحه اضافه کردن محصول شده و محصولات مورد نظر خود را اضافه کنید",
    buttonText: "افزودن محصول جدید",
    buttonOnClickUrl: "/app/ecommerce/product-add"
}

import CampaignImage from "../../../assets/images/mrpropfund/campaign-background.png";

export const TableActionsStructure = [
    {
        title: "افزودن محصول",
        description: "محصولات مورد نظر خود را با توجه به دسته بندی های ایجاد شده، اضافه و کامل کنید. پس از ایجاد محصول میتوانید اقدام به افزودن موجودی محصول کنید ",
        image: CampaignImage,
        link: '/app/ecommerce/product-add'
    }
]

export const filterStructure = [
    {
        title: "عنوان محصول",
        slug: 'title',
        type: "text",
    },
    {
        title: "نوع محصول",
        slug: 'categorId',
        type: "select",

        chooseOptionsFromApi: true,
        path: '/api/categories?section=product',
        key: 'categories',
        chooseOptionsLabelFrom: 'name',
        chooseOptionsValueFrom: '_id',
        options: [],
    },
    {
        title: "موجودی",
        slug: 'inventoryCount',
        type: "text",
    },
    {
        title: "قیمت",
        slug: 'price',
        type: "text",
    },
    {
        title: "وضعیت",
        slug: 'status',
        type: "select",
        options: [
            {label: 'در دسترس', value: 'available'},
            {label: 'غیر قابل دسترس', value: 'unavailable'},
        ],
    },
]

export const tableStructure = [
    {
        title: "عنوان محصول",
        slug: 'title',
        useFarsiNumber: true
    },
    {
        title: "نوع محصول",
        slug: 'categoryName',
        useFarsiNumber: true
    },
    {
        title: "موجودی",
        slug: 'inventoryCount',
        useFarsiNumber: true
    },
    {
        title: "قیمت",
        slug: 'price',
        useFarsiNumber: true
    },
    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "موجودی محصول",
                slug: "product-inventory",
                useRoute: true,
                route: "/app/ecommerce/inventory-list/:_id"
            },
            {
                title: "ویرایش محصول",
                slug: "edit-product",

                useRoute: true,
                route: "/app/ecommerce/product-edit/:_id"
            },
            {
                title: "حذف محصول",
                slug: 'delete-product',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف محصول',
                modalTitle: 'حذف محصول',
                modalContent: 'ایا از حذف این محصول اطمینان دارید؟ با حذف این محصول داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/products/:_id',
            },
        ]
    },
]

export const formStructure = [

]
