import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import FileExplorer from "./component/fileExplorer";
import "./component/styles.css";

const CloudStorage = () => {

    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [yesOrNoModalIsOpen, setYesOrNoModalIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const files = [
        {
            name: 'Document.pdf',
            image: 'https://picsum.photos/seed/picsum/500/300',
            uploadDate: '2024-11-01',
            size: '1.2 MB'
        },
        {
            name: 'Presentation.pptx',
            image: 'https://picsum.photos/seed/picsum/500/300',
            uploadDate: '2024-10-22',
            size: '4.8 MB'
        },
        {
            name: 'Spreadsheet.xlsx',
            image: 'https://picsum.photos/seed/picsum/500/300',
            uploadDate: '2024-09-15',
            size: '2.3 MB'
        }
    ];
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        itemPerPage: 7,
        currentPage: 1,
        totalItems: 0,
        lastUpdateBy: ''
    })
    const [modalProps, setModalProps] = useState({
        modalCancelText: "",
        modalSubmitText: "",
        modalTitle: "",
        modalContent: "",
        modalItemTarget: "",
        modalType: "",
        modalPath: ""
    });


    const [filter, setFilter] = useState({})

    async function initializeData() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setData(res.data.data.bots)
                setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function fetchData(updater) {


        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot?limit=${pagination.itemPerPage}&page=${pagination.currentPage}&${filter}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                setData(res.data.data.bots)
                if (updater === 'filter')
                    setPagination({
                        ...pagination,
                        totalItems: res.data.data.totalItems,
                        currentPage: 1,
                        lastUpdateBy: updater
                    })
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        // initializeData();
    }, []);


    useEffect(() => {
        // if (pagination.lastUpdateBy === "pagination")
        // fetchData('pagination');

    }, [pagination]);


    useEffect(() => {
        // fetchData('filter');
    }, [filter]);


    return (
        <React.Fragment>
            <div className="d-flex flex-column">
                <div className="container-fluid select-plan-container">
                    <h1>{"فضای ابری"}</h1>

                    <div className="spacer"/>
                    <h3 style={{border: 'unset'}}>
                        با جستجو نام فایل خود به راحتی آن را در فضای ذخیره ساز ابری خود پیدا کنید
                    </h3>
                    <p>
                        لطفا در انتخاب طرح مورد نظر دقت کافی داشته باشید، چرا که بعد از انجام عملیات پرداخت، امکان تغییر
                        طرح وجود نداشته و مسئولیت آن تماما بر عهده خریدار خواهد بود. لذا در بررسی طرح مورد نظر خود را
                        متناسب با نیاز خود تهیه کنید.
                    </p>
                </div>
                <FileExplorer/>
            </div>
        </React.Fragment>
    );
};

export default CloudStorage;