import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button} from "reactstrap";

const Banner = ({ title, description, image, link, useBtn, btnText }) => {

    const navigate = useNavigate();
    return (
        <div style={{padding: useBtn ? "30px 2rem" : "0px 2rem" }} className={`fxplt-home-banner ${useBtn ? 'fxplt-no-cursor' : ''}`} onClick={() => {
            if(!useBtn)
                navigate(link)
        }}>
            <div className="fxplt-home-banner-item-title">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            {
                useBtn ?

                    <Button className="fxplt-banner-btn" style={{flex: 1}} size="md" onClick={() => { navigate(link)}}>
                        {btnText}
                    </Button>:

                    <div className="fxplt-home-banner-image">
                        <div className="fxplt-home-banner-image-light"></div>
                        {image}
                    </div>
            }
        </div>
    );
};

export default Banner;
