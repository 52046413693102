import BannerImage from "../../../../assets/images/mrpropfund/image-01.jpg";
import {Button} from "reactstrap";
import React from "react";
import {useNavigate} from "react-router-dom";


export const UnderHeaderBanner = ({title, content, btnTxt, btnLink}) => {
    const navigate = useNavigate();
    return(
        <div className="profile-banner">
            <div className="profile-banner-image">
                <img src={BannerImage} alt=""/>
                <div className='banner-container d-flex flex-row'>
                    <div className="profile-info">
                        <div className="profile-name">{title}</div>
                        <div className="profile-description">{content}</div>
                    </div>

                    <Button className="green-button" size="md" type="submit" onClick={() => {
                        // navigate(btnLink)
                        window.location.href = btnLink
                    }}>
                        {btnTxt}
                    </Button>
                </div>
            </div>
        </div>
    )
}