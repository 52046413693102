export const tableStatics = {
    title: "تخفیف ها",
    description: "",
    addNewItemButtonLink: '/campaign-add'
}
export const formStatics = {
    title: "ایجاد تخفیف",
    editTitle: "ویرایش تخفیف",
    description: "از اینجا میتوانید اقدام به ایجاد تخفیف کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش تخفیف کنید",
    submitText: "ایجاد تخفیف",
    editSubmitText: "ویرایش تخفیف",
}


export const EmptyStateObject = {
    title: "تخفیف فعال یافت نشد",
    content: "از این بخش میتوانید به اقدام به ایجاد یا ویرایش تخفیف ها کنید.",
    buttonText: "ایجاد تخفیف جدید",
    buttonOnClickUrl: "/app/ecommerce/discount-add"
}




import CampaignImage from "../../../assets/images/mrpropfund/campaign-background.png";

export const TableActionsStructure = [
    {
        title: "ایجاد تخفیف",
        description: "با ایجاد یک تخفیف جدید، هدایا و یا تخفیف های مختلفی را به مشتریان خود اعطا کنید",
        image: CampaignImage,
        link: '/app/ecommerce/discount-add'
    },
]

export const filterStructure = [
    {
        title: "عنوان",
        slug: 'title',
        type: "text",
    },
    {
        title: "کد تخفیف",
        slug: 'code',
        type: "text",
    },
    {
        title: "میزان تخفیف",
        slug: 'value',
        type: "text",
    },
    {
        title: "دفعات استفاده",
        slug: 'numberOfUse',
        type: "text",
    },
    {
        title: "تاریخ انقضا",
        slug: 'expirationDate',
        disabled: true,
        type: "date",
    },
]

export const tableStructure = [
    {
        title: "عنوان",
        slug: 'title',
    },
    {
        title: "کد تخفیف",
        slug: 'code',
        useFarsiNumber: true
    },
    {
        title: "میزان تخفیف",
        slug: 'value',
        useFarsiNumber: true
    },
    {
        title: "دفعات استفاده",
        slug: 'numberOfUse',
        useFarsiNumber: true
    },
    {
        title: "تاریخ انقضا",
        slug: 'expirationDate',
        useJalaliFormat: true,
        useFarsiNumber: true
    },


    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "ویرایش تخفیف",
                slug: "edit-discount",
                useRoute: true,
                route: "/app/ecommerce/discount-edit/:_id"
            },
            {
                title: "حذف تخفیف",
                slug: 'delete-campaign',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف تخفیف',
                modalTitle: 'حذف تخفیف',
                modalContent: 'ایا از حذف این تخفیف اطمینان دارید؟ با حذف این تخفیف داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/discount/:_id',
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "عنوان تخفیف",
            description: "عنوان تخفیف باید شامل نوع تخفیف (تخیف  یا ضریب)  و دلیل تخفیف (اعیاد یا مناسبت) باشد",
            slug: 'title',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "توضیحات",
            description: "در صورتی که شرایط و قواعدی جهت شرکت در این تخفیف وجود دارد لطفا در این بخش عنوان کنید\n",
            slug: 'description',
            type: "textarea",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "کد تخفیف",
            description: "این کد باید شامل فقط حروف انگلیسی و اعداد باشد.",
            slug: 'code',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "میزان تخفیف",
            description: "لطفا میزان تخفیف خود را به درصد وارد کنید",
            slug: 'value',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "تعداد دفعات استفاده",
            description: "معین کنید هر کاربر به چه تعداد دفعات قادر به استفاده از این کد خواهد بود؟ عدد صفر به معنای بینهایت بار خواهید بود",
            slug: 'numberOfUse',
            type: "number",
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "تاریخ انقضا",
            description: "تا فرا رسیدن به این تاریخ، کد تخفیف شما قابل استفاده میشباشد.",
            slug: 'expirationDate',
            type: "date",
            placeholder: "",
            isRequired: true,
        },
    ],

]
