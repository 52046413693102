import React, {useEffect, useState} from "react";
import Form, {FormIsLoading} from "../../../components/fouladyar/form";
import {useDispatch, useSelector} from "react-redux";
import {formStatics, formStructure} from "./index";
import axios from "axios";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import {Field} from "../../../components/fouladyar/field/field";
import {IoMdClose} from "react-icons/io";
import {MdOutlineAdd} from "react-icons/md";
import at from "react-datepicker";
import VarietyAttributes from "../product/components/VarietyAttributes";

function VarietyAdd({section, callback}) {
    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const location = useLocation();
    const isEditing = location.pathname.includes("variety-edit");
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [isLoading, setIsloading] = useState(isEditing ? true : false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [attributes, setAttributes] = useState([
        {
            key: "",
            value: ""
        },
    ]);

    const [data, setData] = useState({
        "name": "",
        "slug": "",
        "categoryId": ''
    });

    async function loadData() {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/variety/${id}`, {
            headers: {authorization: `Bearer ${auth.token}`}
        })
        console.log('loaddata', res.data)
        if (res.data.statusCode === 200) {

            setData((prevData) => ({
                ...prevData,
                ...res.data.data,
                "name": res.data.data.name,
                "slug": res.data.data.slug,
                "categoryId": res.data.data.categoryId
            }));
            if(res.data.data.attributes)
                setAttributes(res.data.data.attributes);
            setIsloading(false);
            return data;
        }

        return {};

    }


    useEffect(() => {
        if (isEditing)
            loadData();
    }, []);


    async function onCreate() {
        try {
            setIsSubmitting(true)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/variety`,
                {
                    ...data,
                    attributes: attributes
                },
                {
                    headers: {authorization: `Bearer ${auth.token}`}
                })

            if (res.data.statusCode === 200) {
                navigate(callback);
            }

            setIsSubmitting(false)

        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    async function onUpdate() {
        try {

            setIsSubmitting(true)
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/variety/${id}`,
                {
                    ...data,
                    attributes: attributes
                }, {
                    headers: {
                        "authorization": `Bearer ${auth.token}`
                    }
                }
            );

            if (res.data.statusCode === 200) {
                navigate(callback);
            }

            setIsSubmitting(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }

    function handleOnFieldChange(change) {
        setData((prevData) => ({
            ...prevData,
            ...change
        }));
    }

    async function handleOnSubmit() {
        !isEditing ? await onCreate() : await onUpdate();

        console.log('attributes', attributes)
    }




    return (

        <>
            {
                isLoading ?
                    <FormIsLoading
                        statics={formStatics}
                        isEditing={isEditing}
                    />
                    :
                    <Form
                        form={data}
                        isEditing={isEditing}
                        fields={formStructure}
                        statics={formStatics}
                        isloading={isSubmitting}
                        additionalComponent={
                            <VarietyAttributes
                                attributes={attributes}
                                onAttributeChange={(updatedAttributes) =>
                                    setAttributes(updatedAttributes)
                                }
                            />
                        }
                        submitButtonText={"ایجاد تنوع کالا"}
                        onFieldChange={handleOnFieldChange}
                        onFormSubmit={handleOnSubmit}
                    />
            }

        </>

    );
};

export default VarietyAdd;


