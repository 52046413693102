import React, {useEffect, useState} from "react";
import Form, {FormIsLoading} from "../../../components/fouladyar/form";
import {useDispatch, useSelector} from "react-redux";
import {formStatics, formStructure} from "./index";
import axios from "axios";
import {convertDate} from "../../../shared/shared";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";


function UserAdd({...props}) {

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const location = useLocation();
    const isEditing = location.pathname.includes("customer-edit");
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [isLoading, setIsloading] = useState(isEditing ? true : false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [data, setData] = useState({
        "fullName": "",
        "nationalCode": "",
        "email": "",
        "mobile": "",
        "password": "",
        "repeatPassword": ""
    });

    async function loadData() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('user', res.data)
            if (res.data.statusCode === 200) {
                setData((prevData) => ({
                    ...prevData,
                    ...res.data.data.user,
                    "fullName": res.data.data.user.fullName,
                    "nationalCode": res.data.data.user.nationalCode,
                    "email": res.data.data.user.email,
                    "mobile": res.data.data.user.mobile,
                    "password": res.data.data.user.password,
                    "repeatPassword": res.data.data.user.repeatPassword
                }));
                setIsloading(false);
                return data;
            }


        }catch (e) {

            ErrorToaster(e, t)
            setIsloading(false)
        }

    }


    useEffect(() => {
        if (isEditing)
            loadData();
    }, []);


    async function onCreate() {
        try {
            setIsSubmitting(true)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/new-admin`,
                data,
                {
                    headers: {authorization: `Bearer ${auth.token}`}
                })

            if (res.data.statusCode === 200) {
                navigate(`/app/ecommerce/customer-list`);
            }

            setIsSubmitting(false)

        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    async function onUpdate() {
        try {

            setIsSubmitting(true)

            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/${id}`,
                {...data},
                {headers: {"authorization": `Bearer ${auth.token}`}
                }
            );

            if (res.data.statusCode === 200) {
                navigate(`/app/ecommerce/customer-list`);
            }


            setIsSubmitting(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    function handleOnFieldChange(change) {
        setData((prevData) => ({
            ...prevData,
            ...change
        }));
    }

    async function handleOnSubmit() {
        !isEditing ? await onCreate() : await onUpdate();

    }

    return (

        <>
            {
                isLoading ?
                    <FormIsLoading
                        statics={formStatics}
                        isEditing={isEditing}
                    />
                    :
                    <Form
                        form={data}
                        isEditing={isEditing}
                        fields={formStructure}
                        statics={formStatics}
                        isloading={isSubmitting}
                        submitButtonText={"ایجاد کاربر جدید"}
                        onFieldChange={handleOnFieldChange}
                        onFormSubmit={handleOnSubmit}
                    />
            }
        </>


    );
};

export default UserAdd;
