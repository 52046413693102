import {addDays, endOfMonth, format, startOfMonth, subDays} from "date-fns";
import {Bar, Scatter} from "react-chartjs-2";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip} from "chart.js";
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);


export default function ProfitMAEScatterRedChartComponent ({trades}) {
    const {t, i18n} = useTranslation();

    const [period, setPeriod] = useState('week'); //week | month
    const tradesData = trades?.filter(item => item.entryType === "DEAL_ENTRY_OUT");


    function generateTradeSummary(trades) {
        let scatterChartData = [];

        trades.forEach(trade => {
            const {type, pnl, openPrice, lowestPnl, highestPnl } = trade;

            // MFE: حداکثر سود محقق نشده را در طول معامله اندازه گیری می کند.
            // MAE: حداکثر زیان تحقق نیافته در طول معامله را اندازه گیری می کند.
            const MFE = Math.max(highestPnl);
            const MAE = Math.max(lowestPnl);
            scatterChartData.push({
                x: MAE,  // Use positionId or any unique index if positionId is not suitable
                y: pnl
            })

        });

        return scatterChartData
    }


    const dataset = generateTradeSummary(trades)


    const data = {
        datasets: [
            {
                label: 'Profit/MAE',
                data: dataset,
                backgroundColor: '#DA6B6B',
            },
        ],
    };
    const annotation = {
        annotations: {
            line0: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: 'rgba(61,61,61,0.65)',
                borderWidth: 1,
            },
            line2: {
                type: 'line',
                xMin: 0,
                xMax: 0,
                borderColor: 'rgba(61,61,61,0.65)',
                borderWidth: 1,
            },
        }
    }
    const options = {
        maintainAspectRatio: false,
        plugins: {
            annotation,
            legend: {
                labels: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
            },
        },
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
            },
        },
    };

    return(
        <table border="1" cellPadding="10" className="w-100 mb-3">
            <thead>
            <tr>
                <th className="d-flex flex-row justify-content-between align-center">

                    <span>نمودار تجمع سود نسبت به بیشترین ضرر در هر معامله</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <div style={{width: '100%', height: '400px'}}>
                    <Scatter data={data} options={options}/>
                </div>
            </tr>

            </tbody>
        </table>
    )

}
