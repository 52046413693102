import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Step1} from "./newCourseSteps/step1";
// import {Step2} from "./newCourseSteps/step2";
// import {Step3} from "./newCourseSteps/step3";
import Wizard from "../../../../../../components/fouladyar/wizard";
import {Step2} from "./newCourseSteps/step2";
import {Step3} from "./newCourseSteps/step3";
import {LuSave} from "react-icons/lu";
import {handleUpload} from "../../../../../../shared/file/file";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../../../../shared/toaster";
import {Spinner} from "reactstrap";
import {toFarsiNumber} from "../../../../../../shared/toFarsiNumber";
import {Step4} from "./newCourseSteps/step4";


function EditCourse({...props}) {

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const isEditing = location.pathname.includes("course-edit");
    const auth = useSelector((state) => state.auth);
    const [isLoading, setIsloading] = useState(isEditing);
    const [reloading, setReloading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categories, setCategories] = useState([])



    /*
        This is a copy of the course variable that will only be updated
        when the save button is pressed and will update the components.
    */
    const [courseOnServer, setCourseOnServer] = useState({
        title: '',
        slug: '',
        description: '',
        shortDescription: '',
        image: {},
        imageCaption: '',
        imageTags: '',
        price: '',
        discountPrice: '',
        chapters: []
    })
    const [course, setCourse] = useState({
        title: '',
        slug: '',
        description: '',
        shortDescription: '',
        image: {},
        imageCaption: '',
        imageTags: '',
        price: '',
        discountPrice: '',
        chapters: [

        ]
    })

    const [currentStep, setCurrentStep] = useState(0);
    const [userData, setUserData] = useState({
        title: '',
        description: '',
        shortDescription: '',
        imageCaption: '',
        imageTags: '',
    })
    /*const [steps, setSteps] = useState([
        {
            name: 'اطلاعات اولیه',
            slug: 'Step 1',
            component: <Step1
                data={course}
                onChange={(e)=>{
                    setCourse(prevState => {
                        return{
                            ...prevState,
                            title: e.title,
                            shortDescription: e.shortDescription,
                            description: e.description,
                        }
                    })
                }}
            />,
            value: '',
            status: 'incomplete'
        },
        {
            name: 'آپلود عکس',
            slug: 'Step 2',
            component: <Step2
                data={userData}
                onChange={(e)=>{
                    setUserData(prevState => {
                        return{
                            ...prevState,
                            ...e
                        }
                    })
                }}
            />,
            value: '',
            status: 'incomplete'
        },
        {
            name: 'فصل ها',
            slug: 'Step 3',
            component: <Step3
                data={course.chapters}
                onChange={(e)=>{
                    setCourse(prevState => {
                        return{
                            ...prevState,
                            chapters: e
                        }
                    })
                }}
            />,
            value: '',
            status: 'incomplete'
        },
    ])*/
    const [steps, setSteps] = useState([
        {
            name: 'اطلاعات اولیه',
            slug: 'Step 1',
            component: Step1, // Reference to the component
            getData: () => ({
                data: courseOnServer,
                onChange: (e) => {
                    setCourse((prevState) => ({
                        ...prevState,
                        title: e.title,
                        slug: e.slug,
                        shortDescription: e.shortDescription,
                        description: e.description,
                    }));
                },
            }),
            value: '',
            status: 'incomplete',
        },
        {
            name: 'آپلود عکس',
            slug: 'Step 2',
            component: Step2, // Reference to the component
            getData: () => ({
                data: courseOnServer,
                onChange: (e) => {
                    setUserData((prevState) => ({
                        ...prevState,
                        ...e,
                    }));
                },
            }),
            value: '',
            status: 'incomplete',
        },
        {
            name: 'فصل ها',
            slug: 'Step 3',
            component: Step3, // Reference to the component
            getData: () => ({
                data: courseOnServer.chapters,
                onChange: (e) => {
                    setCourse((prevState) => ({
                        ...prevState,
                        chapters: e,
                    }));
                },
            }),
            value: '',
            status: 'incomplete',
        },
        {
            name: 'قیمت گذاری',
            slug: 'Step 4',
            component: Step4, // Reference to the component
            getData: () => ({
                data: courseOnServer,
                onChange: (e) => {
                    setCourse((prevState) => ({
                        ...prevState,
                        price: e.price,
                        discountPrice: e.discountPrice
                    }));
                },
            }),
            value: '',
            status: 'incomplete',
        },
    ]);


    const reloadPage = () => {
        window.location.reload();
    };

    const [fileCount, setFileCount] = useState(0)
    const [startToUpload, setStartToUpload] = useState(false)


    async function onSaveDraft() {
        try {
            setFileCount(0)
            setIsSaving(true)

            /* Calculate Number of file to upload on server*/
            course?.chapters?.map(chp => {
                const numOfUploadRequired = chp?.lessons?.filter(lesson => lesson?.content?.file).length
                setFileCount(numOfUploadRequired)
            })

            const uploadPromises = course?.chapters?.map(async (chp, chpIndex) => {
                setStartToUpload(true)
                return Promise.all(
                    chp?.lessons?.map(async (lesson, lsnIndex) => {
                        if (lesson?.content?.file) {
                            try {
                                const image = await handleUpload(lesson?.content?.file[0]);
                                return {
                                    ...lesson,
                                    content: {},
                                    file: image
                                }
                            } catch (uploadError) {
                                console.log(
                                    `Error uploading file for chapter: ${chp.id}, lesson: ${lesson.id}`,
                                    uploadError?.response?.data || uploadError
                                );
                                ErrorToaster({
                                    message: `Error uploading file for chapter: ${chp.id}, lesson: ${lesson.id} ${
                                        uploadError?.response?.data?.message || uploadError
                                    }`,
                                });
                                setStartToUpload(false)
                            }
                        }

                        return lesson
                    })

                );
            });



            // Wait for all chapter uploads to complete
            const result = await Promise.all(uploadPromises);
            setStartToUpload(false)
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/lms/course/draft`,
                {
                    ...course,
                    chapters: course.chapters.map((chpt, index) => {
                        return{
                            ...chpt,
                            lessons: result[index]
                        }
                    })
                }, {
                    headers: {
                        "authorization": `Bearer ${auth.token}`
                    }
                }
            );

            if (res.data.statusCode === 200) {
                reloadPage()
            }

            setIsSubmitting(false)

            SuccessToaster({ message: 'ذخیره سازی با موفیت انجام شد' });
            setIsSaving(false)
        }catch (e) {
            setIsSaving(false)
        }

    }

    async function onCreateCourse() {
        try {
            setFileCount(0)
            setIsSaving(true)

            /* Calculate Number of file to upload on server*/
            course?.chapters?.map(chp => {
                const numOfUploadRequired = chp?.lessons?.filter(lesson => lesson?.content?.file).length
                setFileCount(numOfUploadRequired)
            })

            const uploadPromises = course?.chapters?.map(async (chp, chpIndex) => {
                setStartToUpload(true)
                return Promise.all(
                    chp?.lessons?.map(async (lesson, lsnIndex) => {
                        if (lesson?.content?.file) {
                            try {
                                const image = await handleUpload(lesson?.content?.file[0]);
                                return {
                                    ...lesson,
                                    content: {},
                                    file: image
                                }
                            } catch (uploadError) {
                                console.log(
                                    `Error uploading file for chapter: ${chp.id}, lesson: ${lesson.id}`,
                                    uploadError?.response?.data || uploadError
                                );
                                ErrorToaster({
                                    message: `Error uploading file for chapter: ${chp.id}, lesson: ${lesson.id} ${
                                        uploadError?.response?.data?.message || uploadError
                                    }`,
                                });
                                setStartToUpload(false)
                            }
                        }

                        return lesson
                    })

                );
            });

            // Wait for all chapter uploads to complete
            const result = await Promise.all(uploadPromises);
            setStartToUpload(false)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/lms/course`,
                {
                    ...course,
                    chapters: course.chapters.map((chpt, index) => {
                        return{
                            ...chpt,
                            lessons: result[index]
                        }
                    })
                }, {
                    headers: {
                        "authorization": `Bearer ${auth.token}`
                    }
                }
            );

            if (res.data.statusCode === 200) {
                SuccessToaster({ message: 'ذخیره سازی با موفیت انجام شد' });
                navigate('/app/ecommerce/lms/course/manage-courses')
            }

            setIsSubmitting(false)
            setIsSaving(false)
        }catch (e) {
            setIsSaving(false)
        }

    }

    async function loadData() {
        try {
            setIsloading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/lms/course/draft`,
                {
                    headers: {
                        "authorization": `Bearer ${auth.token}`
                    }
                }
            );

            if(res.data.statusCode === 200){
                setCourse(res.data.data)
                setCourseOnServer(res.data.data)
            }

            setIsloading(false)

        }catch (e) {
            ErrorToaster(e);
            setReloading(false)
            setIsloading(false)
        }


    }

    useEffect(()=>{
            loadData()
    }, [])


    useEffect(()=>{
        if(courseOnServer){
            setSteps(steps.map(step => {

                if(step.slug === 'Step 3'){
                    return{
                        ...step,
                        getData: () => ({
                            data: courseOnServer.chapters,
                            onChange: (e) => {
                                setCourse((prevState) => ({
                                    ...prevState,
                                    chapters: e,
                                }));
                            },
                        }),
                    }
                }

                if(step.slug === 'Step 1'){
                    return{
                        ...step,
                        getData: () => ({
                            data: courseOnServer,
                            onChange: (e) => {
                                setCourse((prevState) => ({
                                    ...prevState,
                                    title: e.title,
                                    slug: e.slug,
                                    shortDescription: e.shortDescription,
                                    description: e.description,
                                }));
                            },
                        }),
                    }
                }

                if(step.slug === 'Step 2'){
                    return{
                        ...step,
                        getData: () => ({
                            data: courseOnServer,
                            onChange: (e) => {
                                setCourse((prevState) => ({
                                    ...prevState,
                                    imageTags: e.imageCaption,
                                    imageCaption: e.imageCaption
                                }));
                            },
                        }),
                    }
                }

                if(step.slug === 'Step 4'){
                    return{
                        ...step,
                        getData: () => ({
                            data: courseOnServer,
                            onChange: (e) => {
                                setCourse((prevState) => ({
                                    ...prevState,
                                    price: e.price,
                                    discountPrice: e.discountPrice
                                }));
                            },
                        }),
                    }
                }


                return step

            }))
        }
    }, [courseOnServer])


    useEffect(()=>{
        if (reloading === true)
            loadData()

    }, [reloading])

    return (

        <div className="App">
            <Wizard
                steps={steps}
                currentStep={currentStep}
                setCurrentStep={(e)=>setCurrentStep(e)}
            />


            <div className="saving-draft-btn" onClick={() => {
            }}>
                ? بررسی داده ها
            </div>
            <div className='save-button' onClick={async () => {
               await onSaveDraft()
            }}>
                {isSaving ? <Spinner size="sm" color="light" /> : <LuSave color={"#fff"} size={25}/>}
                {
                    startToUpload ?
                        <div className='saving-status'>
                            {fileCount} فایل جدید در حال آپلود
                        </div> : ''
                }
            </div>

            {
                currentStep < 3 ?
                    <button className="btn green-button w-100" type="button" onClick={() => {
                        if(currentStep < 3)
                            setCurrentStep(currentStep+1)
                    }}>
                        مرحله بعدی
                    </button> : (
                        currentStep === 3 ?
                            <button className="btn green-button w-100" type="button" onClick={() => {
                                onCreateCourse()
                            }}>
                                ایجاد دوره
                            </button> : ''
                    )
            }


        </div>

    );
};


export default EditCourse;
