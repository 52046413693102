import React, {useEffect, useState} from 'react';
import {Field} from "../../../../../../../components/fouladyar/field/field";
import {useTranslation} from "react-i18next";
import {Col} from "../../../../../../../components/grid/Grid";
import {Row} from "reactstrap";
import ImageFileUploadV2, {FileUploadV2, VideoFileUploadV2} from "../../../../../../components/fxpilot/imageUploaderV2";

import {FiInfo} from "react-icons/fi";
import DRMVideoPlayer from "../../../../../../../components/fouladyar/DRMVideoPlayer";
import {convertToEncodedUrl} from "../../../../../../../shared/convertToEncodedUrl";

const Lesson = ({lesson, onChange, onRemove}) => {
    const {t, i18n} = useTranslation();
    const [initialize, setInitialize] = useState(false);
    const [lessonData, setLessonData] = useState(
        {
            id: "",
            order: 0,
            lastModify: "",
            title: "",
            description: "",
            type: '',
            content: {
                file: '', // Selected file from PC or Mobile
                original: '', // uploaded file Url
                thumbnail: '', //uploaded file thumbnail file Url if is "Image or Video"
            },
            attachments: []
        }
    );







    useEffect(() => {
        console.log('lesson', lesson)
        if (lesson ) {
            setLessonData(lesson);
            // setInitialize(true)
        }
    }, [lesson]);

    useEffect(() => {
        if (lessonData) {
            onChange(lessonData);
        }
    }, [lessonData]);




    return (
        <div className="lesson-content">
            <div className='lesson-content-segment'>


                    <LessonFile
                        data={lessonData}
                        onChange={(e) => {
                            setLessonData(prevState => ({
                                ...prevState,
                                type: e.type,
                                file: e.initialized ? e.file : prevState.file,
                                content: {
                                    ...prevState.content,
                                    file: e?.content?.file,
                                }
                            }));
                        }}
                    />



                {/* Title */}
                <LessonItem
                    lessonData={{
                        name: 'title',
                        title: 'عنوان درس',
                        description: 'در این بخش اطلاعات درس مانند عنوان درس و توضیحات کلی در مورد درس را بیان کنید',
                        type: 'text',
                        value: lessonData?.title
                    }}
                    onChange={(e) => {
                        setLessonData(prevState => ({
                            ...prevState,
                            title: e
                        }));
                    }}
                    // disabled={}
                />

                {/* Visibility */}
                <LessonItem
                    lessonData={{
                        name: 'visibility',
                        title: 'سطح دسترسی',
                        description: 'بیان کنید که آیا این درس در چه سطحی به کاربر نمایش داده خواهد شد. آیا نیاز به خرید دوره است یا خیر؟',
                        type: 'select',
                        options: [
                            {label: 'رایگان', value: 'free'},
                            {label: 'نیاز به پرداخت', value: 'need-to-pay'},
                        ],
                        value: lessonData?.visibility
                    }
                    }
                    onChange={(e) => {
                        setLessonData(prevState => ({
                            ...prevState,
                            visibility: e
                        }));
                    }}
                    // disabled={}
                />

                {/* Description */}
                <LessonItem
                    lessonData={{
                        name: 'description',
                        title: 'توضیحات',
                        description: 'در این فیلد توضیحی مختصر در مورد این درس بدهید. به مواردی اشاره کنید که در این ویدیو درس داده خواهد شد',
                        type: 'textarea',
                        value: lessonData?.description
                    }}
                    onChange={(e) => {
                        setLessonData(prevState => ({
                            ...prevState,
                            description: e
                        }));
                    }}
                    // disabled={}
                />
            </div>
        </div>
    );
};

export function LessonItem({lessonData, disabled, onChange}) {



    return (
        <Row key={`row-${name}`} className={`field-item-container`}>
            <Col className={`field-item ${lessonData.type}`} key={`col-${lessonData.rowIndex}`}>
                <div className="field-item-title-and-desc">
                    <div className="field-item-title">
                        {lessonData.title}
                    </div>
                </div>
                <div className="field-item-input">
                    <Field
                        id={lessonData.name}
                        name={lessonData.name}
                        disabled={disabled}
                        options={lessonData.options}
                        onChange={(value) => onChange(value)}
                        value={lessonData.value}
                        type={lessonData.type}
                        className={'post-image-uploader'}
                    />
                </div>
                <div className="field-item-description">
                    <FiInfo color={"#666666"} size={16}/>
                    {lessonData.description}
                </div>

            </Col>
        </Row>
    )
}

export function LessonFile({data, onChange}) {
    const [type, setType] = useState('')
    const [chosenFile, setChosenFile] = useState('')
    const [file, setFile] = useState('')
    const [initialized, setInitialized] = useState(false)




    const [videoThumbnail, setVideoThumbnail] = useState()
    const [videoUrl, setVideoUrl] = useState()
    const [drmLicenseUrl, setDrmLicenseUrl] = useState()

    useEffect(() => {
        if(data){
            if(data?.file){
                if(data?.file?.thumbnail){
                    setVideoThumbnail(convertToEncodedUrl(`${process.env.REACT_APP_FXPLT_LIARA_STORAGE}/${data?.file?.thumbnail?.path}`))
                }
                if(data?.file?.original){
                    setVideoUrl(convertToEncodedUrl(`${process.env.REACT_APP_FXPLT_LIARA_STORAGE}/${data?.file?.original?.path}`))
                }
                setFile(data.file)
            }
            if(data?.type)
                setType(data.type)
            setInitialized(true)
        }
    }, [data])

    useEffect(() => {
        onChange({
            content: {
                file: chosenFile
            },
            file: file,
            type: type,
            initialized: initialized
        })
    }, [type, chosenFile, file])
    return (
        <Row className={`field-item-container`}>
            <Col className={`field-item`} key={`col-${data.rowIndex}`}>
                <div className="field-item-title-and-desc">
                    <div className="field-item-title">
                        نوع درس
                    </div>
                </div>
                <div className="field-item-input">
                    <Field
                        id={'lesson-type'}
                        name={'lesson-type'}
                        options={[
                            {value: "video", label: "ویدیویی"},
                            {value: "readable", label: "متنی"},
                            {value: "practice", label: "تکلیف"},
                            {value: "quiz", label: "آزمون"}
                        ]}
                        onChange={(e) => setType(e)}
                        value={type}
                        type={'select'}
                        className={'post-image-uploader'}
                    />
                </div>
                <div className="field-item-description">
                    <FiInfo color={"#666666"} size={16}/>
                    لطفا نوع فایل این درس را تعیین کنید
                </div>

            </Col>
            {
                file ?
                    <div className="uploaded-file-preview">
                        <DRMVideoPlayer
                            thumbnail={videoThumbnail}
                            videoUrl={videoUrl}
                            drmLicenseUrl={drmLicenseUrl}
                        />
                        <div className="delete-lesson" onClick={() => {
                            setFile(null)
                        }}>
                            حذف فایل
                        </div>
                    </div>:
                    <Col className={`field-item ${data.type}`} key={`col-${data.rowIndex}`}>
                        <div className="field-item-input">
                            {
                                type === 'video' ?
                                    <VideoFileUploadV2
                                        onChange={(e) => {
                                            if(e.length > 0){
                                                setChosenFile(e);
                                                console.log(e)
                                            }


                                        }}
                                    /> : (
                                        type === 'readable' ?
                                            <FileUploadV2
                                                onChange={(e) => {
                                                    if(e.length > 0){
                                                        setChosenFile(e);
                                                        console.log(e)
                                                    }


                                                }}
                                            /> : (
                                                <>این نوع درس به زودی اضافه خواهد شد!</>
                                            )
                                    )
                            }

                        </div>
                    </Col>

            }

        </Row>
    )
}





export default Lesson;
