import React, { useState } from "react";
import FileGridView from "./fileGridView";
import FileControls from "./fileControls";
import FileTableView from "./fileTableView";
import SearchBar from "./searchBar";

const FileExplorer = () => {
    const [files, setFiles] = useState([

        {
            id: 1,
            name: "Image1.jpg",
            size: "1.2 MB",
            owner: "John",
            createdAt: "2024-11-01",
            updatedAt: "2024-11-15",
            isFavorite: false,
            preview: "https://via.placeholder.com/150",
        },
        {
            id: 2,
            name: "Document.pdf",
            size: "300 KB",
            owner: "Jane",
            createdAt: "2024-10-20",
            updatedAt: "2024-11-10",
            isFavorite: true,
            preview: "",
        },
        {
            id: 3,
            name: "vcvbxclvbkxvcvbxclvbkxvcvbxclvbkx.png",
            size: "667 KB",
            owner: "Jane",
            createdAt: "2024-10-20",
            updatedAt: "2024-11-10",
            isFavorite: true,
            preview: "",
        },
        {
            id: 2,
            name: "Document.pdf",
            size: "300 KB",
            owner: "Jane",
            createdAt: "2024-10-20",
            updatedAt: "2024-11-10",
            isFavorite: true,
            preview: "",
        },
        {
            id: 3,
            name: "vcvbxclvbkxvcvbxclvbkxvcvbxclvbkx.png",
            size: "667 KB",
            owner: "Jane",
            createdAt: "2024-10-20",
            updatedAt: "2024-11-10",
            isFavorite: true,
            preview: "",
        },
        {
            id: 2,
            name: "Document.pdf",
            size: "300 KB",
            owner: "Jane",
            createdAt: "2024-10-20",
            updatedAt: "2024-11-10",
            isFavorite: true,
            preview: "",
        },
        {
            id: 3,
            name: "vcvbxclvbkxvcvbxclvbkxvcvbxclvbkx.png",
            size: "667 KB",
            owner: "Jane",
            createdAt: "2024-10-20",
            updatedAt: "2024-11-10",
            isFavorite: true,
            preview: "",
        },
    ]);
    const [view, setView] = useState("grid");
    const [searchQuery, setSearchQuery] = useState("");

    const toggleFavorite = (id) => {
        setFiles((prevFiles) =>
            prevFiles.map((file) =>
                file.id === id ? { ...file, isFavorite: !file.isFavorite } : file
            )
        );
    };

    const filteredFiles = files.filter((file) =>
        file.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="file-explorer">
            <SearchBar onSearch={setSearchQuery} />
            <FileControls view={view} onChangeView={setView} />
            {view === "grid" ? (
                <FileGridView files={filteredFiles} onToggleFavorite={toggleFavorite} />
            ) : (
                <FileTableView files={filteredFiles} onToggleFavorite={toggleFavorite} />
            )}
        </div>
    );
};

export default FileExplorer;
