import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from "react-icons/md";
import {Divider} from "../signals";
import {Field} from "../../../../../components/fouladyar/field/field";
import React from "react";

export function TelegramChannelItem({index2, item, isOpen, setIsOpen, onRemoveTelegramChannel, onChange}){

    function removeChannelItem(index) {
        console.log('removeChannel', item,  index)
        onRemoveTelegramChannel(index)
        // Create a copy of the array using slice or spread operator
        const newIsOpen = [...isOpen];

        // Remove the element at the specified index
        newIsOpen.splice(index, 1);
        setIsOpen(newIsOpen)
    }

    return(
        <div className="channel-setting-expandable-item-container" key={`channel-${index2}`}>
            <div className="d-flex flex-row justify-content-between" onClick={() => {
                setIsOpen(isOpen.map((itm, indx) => {
                    if (indx === index2)
                        return !itm;

                    return itm
                }))
            }}>
                <span>{item.username || '@empty'}</span>
                {
                    isOpen[index2] ?
                        <MdOutlineKeyboardArrowUp size={30} color={"#fff"}/> :
                        <MdOutlineKeyboardArrowDown size={30} color={"#fff"}/>
                }
            </div>
            <Divider/>
            <div className={`channel-item pb-5 d-flex flex-column ${!isOpen[index2] ? 'hidden' : ''}`}>
                <div className={` d-flex flex-row`} style={{gap: "30px"}}>
                    <Field
                        label={"اسم کانال"}
                        id={"channelName"}
                        name={"channelName"}
                        type={'text'}
                        defaultValue={item.title}
                        onChange={(e)=>{}}
                        onBlur={(e) => {
                            onChange({
                                ...item,
                                title: e.target.value
                            })
                        }}
                    />



                    <Field
                        label={"آیدی کانال"}
                        id={"channelId"}
                        name={"channelId"}
                        type={'text'}
                        defaultValue={item?.username}
                        onChange={(e)=>{}}
                        onBlur={(e) => {
                            onChange({
                                ...item,
                                username: e.target.value
                            })
                        }}
                    />
                </div>
                <Field
                    label={"توضیحات"}
                    id={"description"}
                    name={"description"}
                    type={'textarea'}
                    defaultValue={item?.description}
                    onChange={(e)=>{}}
                    onBlur={(e) => {
                        onChange({
                            ...item,
                            description: e.target.value
                        })
                    }}
                />
                <div className="delete-lesson" onClick={() => {removeChannelItem(index2);}}>
                    حذف کانال
                </div>
            </div>
        </div>
    )
}