import {IoMdClose} from "react-icons/io";
import {Field} from "../../../components/fouladyar/field/field";
import React, {useState} from "react";
import {UpperHeader} from "../../../layout/header/upperHeader";
import AppWrap from "../../../layout/global/AppWrap";
import AppMain from "../../../layout/global/AppMain";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setToken} from "../../../redux/store/services/auth/store";
import {setProfile} from "../../../redux/store/services/profile/store";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";
import {countries} from "./countries";
import {VscEye, VscEyeClosed} from "react-icons/vsc";


export const Register = () => {



    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [form, setForm] = useState({
        fullName: "",
        email: "",
        mobile: "",
        nationalCode: "",
        country: "iran",
        city: "",
        password: "",
        repeatPassword: "",
    });
    const auth = useSelector((state) => state.auth);
    const register = async () => {
        try {

            console.log("register", form);
            setIsSubmitting(true);
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/register`, form);

            if (res.data.statusCode === 200 || res.data.statusCode === 201) {
                SuccessToaster({message: 'ثبت نام با موفقیت انجام شد'}, t)
                navigate(`/signin`);
            }
            setIsSubmitting(false);

        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }

    };



    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatPassword, setShowRepeatPassword] = useState(false)

    return(
        <AppMain>
            <AppWrap>
                <UpperHeader/>
                <div className="login-box">
                    <div className="nk-auth-body">
                        <h4 className="login-title">{"ثبت نام"}</h4>

                        <div className="login-under-title">
                            لطفا اطلاعات زیر را با دقت وارد نمایید
                        </div>
                        <div className="spacer-8"/>
                        <Field
                            className=" modal-fields "
                            label={"نام نام خانوادگی"}
                            id={"fullName"}
                            name={"fullName"}
                            type={'text'}
                            value={form.fullName}
                            onChange={(e) => {
                                setForm({...form, fullName: e});
                            }}
                        />
                        <Field
                            className=" modal-fields "
                            label={"ایمیل"}
                            id={"email"}
                            name={"email"}
                            type={'text'}
                            value={form.email}
                            onChange={(e) => {
                                setForm({...form, email: e});
                            }}
                        />
                        <Field
                            className=" modal-fields "
                            label={"شماره تماس"}
                            id={"mobile"}
                            name={"mobile"}
                            type={'text'}
                            value={form.mobile}
                            onChange={(e) => {
                                setForm({...form, mobile: e});
                            }}
                        />
                        <Field
                            className=" modal-fields "
                            label={"کد ملی"}
                            id={"nationalCode"}
                            name={"nationalCode"}
                            type={'text'}
                            value={form.nationalCode}
                            onChange={(e) => {
                                setForm({...form, nationalCode: e});
                            }}
                        />
                        <Field
                            className=" modal-fields "
                            label={"کشور"}
                            id={"country"}
                            name={"country"}
                            type={'select'}
                            options={countries}
                            value={form.country}
                            onChange={(e) => {
                                setForm({...form, country: e});
                            }}
                        />
                        <Field
                            className=" modal-fields "
                            label={"شهر"}
                            id={"city"}
                            name={"city"}
                            type={'text'}
                            value={form.city}
                            onChange={(e) => {
                                setForm({...form, city: e});
                            }}
                        />

                        <div style={{position: 'relative'}}>
                            <Field
                                className="modal-fields"
                                label={"رمز عبور"}
                                id={"password"}
                                name={"password"}
                                type={showPassword ? 'text' : 'password'}
                                value={form.password}
                                onChange={(e) => {
                                    setForm({...form, password: e});
                                }}
                            />
                            <div className="password-eye">
                                {
                                    showPassword ?
                                        <VscEyeClosed size={18} color={"#fff"} onClick={()=>{setShowPassword(false)}}/> :
                                        <VscEye  size={18} color={"#fff"} onClick={()=>{setShowPassword(true)}}/>
                                }
                            </div>
                        </div>

                        <div style={{position: 'relative'}}>
                            <Field
                                className="modal-fields"
                                label={"تکرار رمز عبور"}
                                id={"repeatPassword"}
                                name={"repeatPassword"}
                                type={showRepeatPassword ? 'text' : 'password'}
                                value={form.repeatPassword}
                                onChange={(e) => {
                                    setForm({...form, repeatPassword: e});
                                }}
                            />
                            <div className="password-eye">
                                {
                                    showRepeatPassword ?
                                        <VscEyeClosed size={18} color={"#fff"} onClick={()=>{setShowRepeatPassword(false)}}/> :
                                        <VscEye  size={18} color={"#fff"} onClick={()=>{setShowRepeatPassword(true)}}/>
                                }
                            </div>
                        </div>

                        <div className="mt-5 form-submit-btn" style={{opacity: isSubmitting ? 0.5 : 1}} onClick={async () => {
                            await register()
                        }}>
                            ثبت نام
                        </div>
                        <div className="spacer"/>
                        <div className="register-text">
                            قبلا حساب ساخته اید؟
                            <div className="create-account" onClick={() => {
                                navigate('/signin')
                            }}>ورود به حساب </div>
                        </div>
                    </div>
                </div>
            </AppWrap>
        </AppMain>
    )
}