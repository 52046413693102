export const tableStatics = {
    title: "تنوع کالا",
    description: "",
    addNewItemButtonLink: '/variety-add'
}
export const formStatics = {
    title: "ایجاد تنوع کالا",
    editTitle: "ویرایش تنوع کالا",
    description: "از اینجا میتوانید اقدام به ایجاد تنوع کالا کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش تنوع کالا کنید",
    submitText: "ایجاد تنوع کالا",
    editSubmitText: "ویرایش تنوع کالا",
}
export const EmptyStateObject = {
    title: "تنوع کالا یافت نشد",
    content: "لطفا از طریق لینک زیر وارد صفحه اضافه کردن تنوع کالا شده و تنوع کالا ها مورد نظر خود را اضافه کنید",
    buttonText: "افزودن تنوع کالا جدید",
    buttonOnClickUrl: "/app/ecommerce/variety-add"
}

import CampaignImage from "../../../assets/images/mrpropfund/campaign-background.png";

export const TableActionsStructure = [
    {
        title: "افزودن تنوع کالا",
        description: "تنوع کالا ها مورد نظر خود را با توجه به تنوع کالا های ایجاد شده، اضافه و کامل کنید.",
        image: CampaignImage,
        link: '/app/ecommerce/variety-add'
    }
]

export const filterStructure = [
    {
        title: "نام",
        slug: 'name',
        type: "text",
    },
    {
        title: "شناسه",
        slug: 'slug',
        type: "text",
    },
    {
        title: "دسته بندی",
        slug: 'categoryId',
        type: "text",
    },
    {
        title: "تاریخ ایجاد",
        slug: 'createdAt',
        type: "date",
    },
    {
        title: "وضعیت",
        slug: 'status',
        type: "select",
        options: [
            // {label: 'منتشر شده', value: 'published'},
            // {label: 'در دست انتشار', value: 'waiting_for_publish'},
        ],
    },
]

export const tableStructure = [
    {
        title: "اسم",
        slug: 'name',
        useFarsiNumber: true
    },
    {
        title: "شناسه",
        slug: 'slug',
        useFarsiNumber: true
    },
    {
        title: "دسته بندی",
        slug: 'category',
        useFarsiNumber: true
    },
    {
        title: "تاریخ ایجاد",
        slug: 'createdAt',
        useFarsiNumber: true
    },
    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "ویرایش تنوع کالا",
                slug: "edit-variety",
                useRoute: true,
                route: "/app/ecommerce/variety-edit/:_id"
            },
            {
                title: "حذف تنوع کالا",
                slug: 'delete-category',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف تنوع کالا',
                modalTitle: 'حذف تنوع کالا',
                modalContent: 'ایا از حذف این تنوع کالا اطمینان دارید؟ با حذف این تنوع کالا داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/variety/:_id',
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "عنوان تنوع کالا",
            slug: 'name',
            type: "text",
            description: "عنوان تنوع کالا را وارد کنید",
            isRequired: true,
        }
    ],
    [
        {
            title: "شناسه تنوع کالا",
            slug: 'slug',
            type: "text",
            description: "شناسه تنوع کالا باید حتما با حروف انگلیسی وارد شده باشد.",
            isRequired: true,
        }
    ],
    [
        {
            title: "دسته بندی",
            slug: 'categoryId',
            description: "تنوع کالا مورد نظر شما در کدام دسته بندی خواهد بود. لطفا این بخش با حروف انگلیسی وارد شود",
            isRequired: true,
            type: "select",

            chooseOptionsFromApi: true,
            path: '/api/categories?section=product',
            key: 'categories',
            chooseOptionsLabelFrom: 'name',
            chooseOptionsValueFrom: '_id',
            options: [],
            placeholder: "دسته بندی را انتخاب کنید",
        }
    ],

]
