export const tableStatics = {
    title: "تسویه حساب ها",
    description: "",
    addNewItemButtonLink: '/withdraw-add'
}
export const formStatics = {
    title: "درخواست تسویه حساب",
    editTitle: "ویرایش تسویه حساب",
    description: "از اینجا میتوانید اقدام به ایجاد تسویه حساب کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش تسویه حساب کنید",
    submitText: "ایجاد تسویه حساب",
    editSubmitText: "ویرایش تسویه حساب",
}

export const EmptyStateObject = {
    title: "تسویه حساب فعال یافت نشد",
    content: "از این بخش میتوانید به اقدام به درخواست  یا ویرایش تسویه حساب ها کنید.",
    buttonText: "ایجاد تسویه حساب جدید",
    buttonOnClickUrl: "/withdraw-add"
}



import WithdrawRequestBackground from "../../../assets/images/mrpropfund/withdraw-request-background-image.png";

export const TableActionsStructure = [
    {
        title: "درخواست تسویه حساب",
        description: "با ایجاد درخواست تسویه حساب به راحتی سود حاصل از معاملات ریل خود را در کمترین زمان ممکن برداشت کنید.",
        image: WithdrawRequestBackground,
        link: '/withdraw-add'
    },
]

export const filterStructure = [
    {
        title: "شماره",
        slug: 'index',
        disabled: true,
        type: "text",
    },
    {
        title: "حساب",
        slug: 'accountUsername',
        type: "text",
    },
    {
        title: "درصد",
        slug: 'percentageOfProfit',
        type: "text",
    },
    {
        title: "مبلغ",
        slug: 'amount',
        type: "text",
    },
    {
        title: "بالانس",
        slug: 'balanceAtTimeOfWithdrawal',
        type: "text",
    },
    {
        title: "مانده",
        slug: 'remainingProfitAfterWithdrawal',
        type: "text",
    },
    {
        title: "وضعیت",
        slug: 'status',
        type: "select",
        options: [
            {label: 'در حال بررسی', value: 'pending'},
            {label: 'انجام شده', value: 'done'},
        ],
    },
]

export const tableStructure = [
    {
        title: "حساب",
        slug: 'account.username',
        useTranslate: true
    },
    {
        title: "مبلغ",
        slug: 'amount',
        useFarsiNumber: true
    },
    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true
    },

    {
        title: "تاریخ درخواست",
        slug: 'createdAt',
        useJalaliFormat: true,
        useFarsiNumber: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "مشاهده درخواست",
                slug: "transaction-overview",
                useRoute: true,
                route: "/withdraw-detail/:_id"
            },
            {
                title: "لغو درخواست",
                slug: 'delete-withdraw',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'لغو درخواست',
                modalTitle: 'لغو درخواست برداشت',
                modalContent: 'ایا از لغو درخواست برداشت از حساب خود اطمینان دارد؟ در صورتی که تایید نمایید، برای برداشت باید مجددا درخواست ثبت کنید.',
                modalType: "delete",
                modalPath: '/api/withdraw/:_id',
            },
        ]
    },
]
export const tableStructureAdmin = [
    {
        title: "ایمیل",
        slug: 'user.email',
        useTranslate: true
    },
    {
        title: "حساب",
        slug: 'account.username',
        useTranslate: true
    },
    {
        title: "مبلغ",
        slug: 'amount',
        useFarsiNumber: true
    },
    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true
    },

    {
        title: "تاریخ درخواست",
        slug: 'createdAt',
        useJalaliFormat: true,
        useFarsiNumber: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "تکمیل فرآیند",
                slug: "complete-withdraw",
                useRoute: true,
                route: "/withdraw-confirmation/:_id",
                roles: ['admin'],
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "انتخاب حساب",
            description: "دقت کنید که در این بخش تنها به حساب های ریل خود دسترسی خواهید داشت. لذا در صورتی که حساب ریل شما در سود است، میتوانید اقدام به برداشت سود کنید.",
            slug: 'orderId',
            type: "select",
            chooseOptionsFromApi: true,
            path: '/api/accounts/real',
            key: 'accounts',
            chooseOptionsLabelFrom: 'name',
            chooseOptionsValueFrom: 'orderId',
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "انتخاب کیف پول",
            description: "لطفا کیف پول خود را انتخاب کنید. در صورت نبود کیف پول ثب شده میتوانید از منو داشبورد و تب (ولت) اقدام به ثبت کیف پول خود کنید",
            slug: 'walletId',
            type: "select",
            chooseOptionsFromApi: true,
            path: '/api/cards',
            key: 'cards',
            chooseOptionsLabelFrom: 'title',
            chooseOptionsValueFrom: '_id',
            placeholder: "",
            isRequired: true,
        },
    ],
    // [
    //
    //     {
    //         title: "وضعیت",
    //         description: "قبل از ارسال این تسویه حساب میتوانید وضعیت آن را مشخص کنید",
    //         slug: 'status',
    //         type: "select",
    //         placeholder: "",
    //         options: [
    //             {label: 'فعال', value: 'beginner'},
    //             {label: 'غیر فعال', value: 'professional'},
    //         ],
    //         isRequired: true,
    //     },
    // ],
]
