import {isAfter, isSameDay, subDays} from "date-fns";
import {Line} from "react-chartjs-2";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Field} from "../../../../components/fouladyar/field/field";
import annotationPlugin from "chartjs-plugin-annotation";

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip
} from "chart.js";
ChartJS.register(LineElement, CategoryScale, annotationPlugin, LinearScale, PointElement, Tooltip, Legend);

export default function HighestAndLowestPnlLineChartComponent({trades}) {


    const {t, i18n} = useTranslation();

    const [period, setPeriod] = useState('week'); //week | month


    function getLastWeekTrades(trades) {
        const oneWeekAgo = subDays(new Date(), 7);

        // Filter trades for the last 7 days
        const lastWeekTrades = trades.filter(trade => {
            const entryTime = new Date(trade.entryTime);
            return isAfter(entryTime, oneWeekAgo);
        });

        return lastWeekTrades;
    }

    function getLastMonthTrades(trades) {
        const oneMonthAgo = subDays(new Date(), 30);

        // Filter trades for the last 30 days
        const lastMonthTrades = trades.filter(trade => {
            const entryTime = new Date(trade.entryTime);
            return isAfter(entryTime, oneMonthAgo);
        });

        return lastMonthTrades;
    }

    function getTodaysTrades(trades) {
        const today = new Date();

        // Filter trades for today
        const todaysTrades = trades.filter(trade => {
            const entryTime = new Date(trade.entryTime);
            return isSameDay(entryTime, today);
        });

        return todaysTrades;
    }

    const todayTrades = getTodaysTrades(trades)
    const lastWeekTrades = getLastWeekTrades(trades)
    const lastMonthTrades = getLastMonthTrades(trades)

    const result = period === 'month' ? lastMonthTrades : (period === 'week' ? lastWeekTrades : (period === 'today' ? todayTrades : []))


    const data = {
        labels: result.map((item, index) => {
            return index + 1
        }),
        datasets: [
            {
                type: 'line', // Line dataset
                label: 'بیشترین سود دیده شده',
                data: result.map(item => {
                    return item.highestPnl
                }),
                backgroundColor: 'rgb(79,157,129)',
                borderColor: 'rgb(79,157,129)',
                fill: false,
                borderWidth: 2,
                tension: 0.2, // Adjust this value to make the line curved

                pointRadius: 0,          // Hide the dots
                pointHoverRadius: 0,     // Hide the dots on hover
                pointHitRadius: 10

            },
            {
                type: 'line', // Line dataset
                label: 'بیشترین ضرر دیده شده',
                data: result.map(item => {
                    return item.lowestPnl
                }),
                backgroundColor: 'rgb(196,86,106)',
                borderColor: 'rgb(196,86,106)',
                fill: false,
                borderWidth: 2,
                tension: 0.2, // Adjust this value to make the line curved

                pointRadius: 0,          // Hide the dots
                pointHoverRadius: 0,     // Hide the dots on hover
                pointHitRadius: 10

            },
            {
                type: 'line', // Line dataset
                label: 'نتیجه معامله',
                data: result.map(item => {
                    return item.pnl
                }),
                backgroundColor: 'rgba(75, 192, 192, 1)',
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
                borderWidth: 2,
                tension: 0.2, // Adjust this value to make the line curved

                pointRadius: 0,          // Hide the dots
                pointHoverRadius: 0,     // Hide the dots on hover
                pointHitRadius: 10

            },
        ],
    };


    const annotation = {
        annotations: {
            line0: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: 'rgba(61,61,61,0.65)',
                borderWidth: 1,
            },
        }
    }


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            annotation,
            legend: {
                labels: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Hide vertical grid lines
                },
                title: {
                    stacked: true,
                }
            },
            y: {
                title: {
                    stacked: true,
                }
            },
        },
    };


    return (
        <table border="1" cellPadding="10" className="w-100 mb-3">
            <thead>
            <tr>
                <th className="d-flex flex-row justify-content-between align-center">

                    <span>نمودار بیشترین (سود/ضرر) در هر معامله</span>
                    <div className="w-auto">
                        <Field
                            className="analytics-select-style"
                            id={"period"}
                            name={"period"}
                            type={'select'}
                            options={[
                                {label: 'روزانه', value: 'today'},
                                {label: 'هفتگی', value: 'week'},
                                {label: 'ماهانه', value: 'month'},
                            ]}
                            value={period}
                            onChange={(e) => {
                                setPeriod(e)
                            }}
                        />
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <div style={{width: '100%', height: '400px'}}>
                    <Line data={data} options={options}/>
                </div>
            </tr>

            </tbody>
        </table>
    )

}
