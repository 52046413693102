import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FxpilotUpperHeader} from "./fxpilot/upperHeader";
import {MrpropfundUpperHeader} from "./mrpropfund/upperHeader";

export const UpperHeader = ({isTokenValid}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const profile = useSelector((state) => state.profile);

    return (
        <>
            {
                process.env.APPLICATION === 'fxpilot' ?
                    <FxpilotUpperHeader/> : (
                        process.env.APPLICATION === 'mrpropfund' ?
                            <MrpropfundUpperHeader/> : <></>
                    )
            }
        </>
    )
}