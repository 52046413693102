export const countries = [
    // { label: 'ایران', value: 'Iran' },
    // { label: 'افغانستان', value: 'Afghanistan' },
    // { label: 'پاکستان', value: 'Pakistan' },
    { label: 'ترکیه', value: 'turkey' },
    { label: 'مالزی', value: 'malaysia' },
    // { label: 'عراق', value: 'Iraq' },
    // { label: 'سوریه', value: 'Syria' },
    // { label: 'لبنان', value: 'Lebanon' },
    { label: 'امارات متحده عربی', value: 'emirates' },
    // { label: 'عربستان سعودی', value: 'Saudi Arabia' },
    // { label: 'قطر', value: 'Qatar' },
    // { label: 'بحرین', value: 'Bahrain' },
    // { label: 'کویت', value: 'Kuwait' },
    // { label: 'عمان', value: 'Oman' },
    // { label: 'یمن', value: 'Yemen' },
    // { label: 'مصر', value: 'Egypt' },
    // { label: 'اردن', value: 'Jordan' },
    // { label: 'هند', value: 'India' },
    // { label: 'چین', value: 'China' },
    // { label: 'روسیه', value: 'Russia' },
    // { label: 'فرانسه', value: 'France' },
    // { label: 'آلمان', value: 'Germany' },
    // { label: 'انگلستان', value: 'United Kingdom' },
    // { label: 'ایتالیا', value: 'Italy' },
    // { label: 'کانادا', value: 'Canada' },
    // { label: 'آمریکا', value: 'United States' },
    // { label: 'ژاپن', value: 'Japan' },
    // { label: 'کره جنوبی', value: 'South Korea' },
    // { label: 'برزیل', value: 'Brazil' },
    // { label: 'استرالیا', value: 'Australia' },
    // { label: 'مکزیک', value: 'Mexico' }
];