import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import {LuLayoutGrid, LuLayoutList} from "react-icons/lu";

const FileControls = ({view, onChangeView}) => {
    return (
        <div className="file-controls">
            <div className="icons">
                <LuLayoutGrid
                    color={`${view === "grid" ? "#38AF84" : "#fff"}`}
                    size={18}
                    className={`icon ${view === "grid" ? "active" : ""}`}
                    onClick={() => onChangeView("grid")}
                />
                <LuLayoutList
                    color={`${view === "table" ? "#38AF84" : "#fff"}`}
                    size={18}
                    className={`icon ${view === "table" ? "active" : ""}`}
                    onClick={() => onChangeView("table")}
                />
                <RefreshIcon className="icon" size={18}/>
            </div>
            <div className="path">/path/to/files</div>
        </div>
    );
};

export default FileControls;
