export const tableStatics = {
    title: "موجودی ها",
    description: "",
    addNewItemButtonLink: '/inventory-add'
}
export const formStatics = {
    title: "ایجاد موجودی",
    editTitle: "ویرایش موجودی",
    description: "از اینجا میتوانید اقدام به ایجاد موجودی کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش موجودی کنید",
    submitText: "ایجاد موجودی",
    editSubmitText: "ویرایش موجودی",
}


export const EmptyStateObject = {
    title: "موجودی فعال یافت نشد",
    content: "از این بخش میتوانید به اقدام به ایجاد یا ویرایش موجودی ها کنید.",
    buttonText: "ایجاد موجودی جدید",
    buttonOnClickUrl: "/app/ecommerce/inventory-add"
}


export const filterStructure = [
    {
        title: "نام کاربری",
        slug: 'username',
        type: "text",
    },
    {
        title: "سرور",
        slug: 'server',
        type: "text",
    },
    {
        title: "رمز عبور",
        slug: 'password',
        type: "text",
    },
    {
        title: "انوستور",
        slug: 'investor',
        type: "text",
    },
    {
        title: "بالانس",
        slug: 'balance',
        type: "text",
    },
    {
        title: "نوع",
        slug: 'type',
        type: "select",
        options: [
            {label: 'دمو', value: 'demo'},
            {label: 'ریل', value: 'real'},
        ],
    },
    {
        title: "وضعیت",
        slug: 'status',
        type: "select",
        options: [
            {label: 'موجود', value: 'in_stock'},
            {label: 'فروخته شده', value: 'sold'},
        ],
    },
]

export const tableStructure = [
    {
        title: "نام کاربری",
        slug: 'specification.username',
        useFarsiNumber: true
    },
    {
        title: "سرور",
        slug: 'specification.server',
        useTranslate: true
    },
    {
        title: "رمز عبور",
        slug: 'specification.password',
    },
    {
        title: "بروکر",
        slug: 'specification.broker',
        useTranslate: true
    },
    {
        title: "بالانس",
        slug: 'specification.balance',
        useFarsiNumber: true
    },
    {
        title: "نوع",
        slug: 'specification.type',
        useTranslate: true
    },
    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "ویرایش موجودی",
                slug: "edit-inventory",

                useRoute: true,
                route: "/app/ecommerce/inventory-edit/:_id"
            },
            {
                title: "حذف موجودی",
                slug: 'delete-inventory',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف موجودی',
                modalTitle: 'حذف موجودی',
                modalContent: 'ایا از حذف این موجودی اطمینان دارید؟ با حذف این موجودی داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/inventory/:_id',
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "نام کاربری",
            description: "نام کاربری حساب مورد نظر را وارد کنید",
            slug: 'username',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "سرور",
            description: "سرور مورد نظر بر اساس لوکیشن و نام صرافی را وارد کنید",
            slug: 'server',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "پسورد",
            description: "رمز عبور حساب دریافتی از بروکر مورد نظر را وارد کنید",
            slug: 'password',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "پسورد انوستور",
            description: "رمز عبور نوع سرمایه گذار حساب دریافتی از بروکر مورد نظر را وارد کنید",
            slug: 'investor',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "بالانس",
            description: "موجودی اولیه حساب دریافتی از بروکر مورد نظر را وارد کنید",
            slug: 'balance',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "بوکر",
            description: "لطفا بروکر مورد نظر خود را وارد کنید",
            slug: 'broker',
            type: "select",

            chooseOptionsFromApi: true,
            path: '/api/categories?section=broker',
            key: 'categories',
            chooseOptionsLabelFrom: 'name',
            chooseOptionsValueFrom: '_id',
            options: [],
            placeholder: "بروکر را انتخاب کنید",

        },
    ],
    [

        {
            title: "نوع حساب",
            description: "نوع حساب دریافتی از بروکر را وارد کنید",
            slug: 'type',
            type: "select",
            options: [
                {label: 'ریل', value: 'real'},
                {label: 'دمو', value: 'demo'},
            ],
            placeholder: "",
            isRequired: true,
        },
    ],
]
