import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";
import {Field} from "../../../components/fouladyar/field/field";
import {useTranslation} from "react-i18next";

const Checkout = () => {
    const auth = useSelector((state) => state.auth);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [profile, setProfile] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [form, setForm] = useState({
        fullName: '',
        city: '',
        email: '',
        phone: '',
        privacyAndPolicy: false
    })

    const [cartItems, setCartItems] = useState([]);
    const [payableValue, setPayableValue] = useState([]);
    const [product, setProduct] = useState({
        title: '0',
        price: 0
    });
    const [campaignItems, setCampaignItems] = useState([]);
    const [addonItems, setAddonItems] = useState([]);
    const [brokers, setBrokers] = useState([]);
    const [brokerId, setBrokerId] = useState();
    const [paymentGateways, setPaymentGateways] = useState([]);
    const [paymentGatewayId, setPaymentGatewayId] = useState();
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [discount, setDiscount] = useState(null);
    const [discountCode, setDiscountCode] = useState('');


    async function getProductById() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${cartItems[0].productId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('product', res.data.data.varieties.find(i => i._id === cartItems[0].varietyId) )
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setProduct({
                    ...res.data.data,
                    price: res.data.data.varieties?.find(i => i._id === cartItems[0].varietyId).price
                })

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }


    async function getCart() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/cart`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('cart', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setCartItems(res.data.data.items)

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }

    async function getCampaigns() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/campaigns/active`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('campaigns', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setCampaignItems(res.data.data.campaigns.map(item => {
                    return ({
                        ...item,
                        apply: true
                    })
                }))

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }

    async function getBrokers() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/brokers/in-stock/${cartItems[0].productId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('brokers', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setBrokers(res.data.data.brokers)
                setBrokerId(res.data.data.brokers[0]._id)
            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }

    async function getPaymentGateways() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/payment-gateways`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('paymentGateways', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setPaymentGateways(res.data.data.paymentGateways)
                setPaymentGatewayId(res.data.data.paymentGateways[0]._id)

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }


    async function getAddons() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/addon/active/all`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('addons', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setAddonItems(res.data.data.addons)

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }


    useEffect(() => {
        getCart();
        getCampaigns();
        getPaymentGateways()
        getAddons()
    }, []);

    useEffect(() => {
        if(cartItems.length > 0) {
            getProductById()
            getBrokers();
        }
    }, [cartItems]);


    useEffect(() => {
        if(cartItems.length > 0)
            setPayableValue(product.price - (product.price))

        async function calculatePayableValue(){
            let campaignDiscounts = 0;
            let discountCodes = 0;
            let addonCosts = 0;

            if(campaignItems.length > 0){
                campaignItems.map(item => {
                    if(item.type === "discount"){
                        campaignDiscounts += product.price * (item.amount / 100);
                    }
                })
            }
            if(selectedAddons.length > 0){

                addonItems.filter(item => selectedAddons.includes(item._id)).map(item => {
                    if(item.type === "percentage"){
                        addonCosts += product.price * (item.amount / 100);
                    }
                    if(item.type === "constant"){
                        addonCosts += item.amount;
                    }
                })
            }
            if(discount){
                discountCodes += product.price * (discount.value / 100);
            }
            setPayableValue(prevState => {
                return product.price === 0 ? 0 : (product.price - campaignDiscounts - discountCodes + addonCosts);
            })
            return campaignDiscounts
        }

        calculatePayableValue()
    }, [product, campaignItems, selectedAddons, discount]);


    function SegmentItem({heading, cancelable, items, onCancel}) {
        return (
            <div className="segment">
                <div className="title">{heading}</div>
                {
                    items?.map((item, index) => {

                        return (
                            <div>
                                <div className="subtitle">
                                    <div className="subtitle-text">{item?.title}  </div>
                                    {
                                        cancelable ? <div className="cancel" onClick={() => {
                                           onCancel(item._id)
                                        }}> x </div> : ''
                                    }

                                </div>
                                <div className="description">{item?.description}</div>
                                <div className="checkout-detail-item-attribute-container">
                                    {
                                        item.attributes ? item.attributes?.map((attr) => {
                                            return (
                                                <div className="checkout-detail-item-attribute">
                                                    <div className="plans-attributes-key">{attr.key}</div>
                                                    <div className="plans-attributes-value">{attr.value}</div>
                                                </div>
                                            )
                                        }) : ''
                                    }
                                </div>
                                {
                                    (items.length > 1 && items.length - 1 !== index) ?
                                        <div className="_dash-divider"/> : (<div className="_divider"/>)
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }


    async function submit() {

        try {
            setIsSubmitting(true)
            const body = {
                payableValue: payableValue,
                paymentMethod: 'crypto',
                paymentGatewayId: paymentGatewayId,
                campaigns: campaignItems.length > 0 ? campaignItems.map(i => {return i._id}) : [],
                addons: selectedAddons.length > 0 ? selectedAddons : [],
                discount: discount,
                extras: {
                    ...form,
                    broker: brokers.find(i=>i._id===brokerId)
                }
            }

            console.log('body', body)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/orders`, body,{
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('order', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                const invoice_url= res.data.data.Invoice.invoice_url;

                if(invoice_url){
                    window.location.href = invoice_url;
                }else{
                    ErrorToaster({message: 'اشکال در ساخت فاکتور ایجاد شده است!'})
                }
                // SuccessToaster({message: 'سفارش با موفقیت ثبت شد'}, t)
                // navigate('/account-list');
            }
            setIsSubmitting(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }

    function checkRequiredFields() {
        let res = ''
        let status = true;
        if (form.city === '') {
            res += 'لطفا فیلد شهر را پر کنید\n';
            return false
        }
        if (form.phone === '') {
            res += 'لطفا فیلد شماره تماس را پر کنید\n';
            return false
        }
        if (form.fullName === '') {
            res += 'لطفا فیلد نام نام خانوادگی را پر کنید\n';
            return false
        }
        if (form.privacyAndPolicy === '') {
            res += 'لطفا تیک قوانین را بزنیدی\n';
            return false
        }
        if (form.email === '') {
            res += 'لطفا فیلد ایمیل را پر کنید\n';
            return false
        }
        if (!brokerId) {
            res += 'لطفا بروکر خود را انتخاب کنید\n';
            return false
        }
        if (!paymentGatewayId) {
            res += 'لطفا درگاه پرداخت خود را انتخاب کنید\n';
            return false
        }


        return true
    }

    async function OnDiscountCodeCheck() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/discount/code/${discountCode}`,{
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('Discount', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                    setDiscount({
                        ...res.data.data,
                        title: `${res.data.data.title} (${res.data.data.code})`,
                        attributes: [
                            {
                                key: 'تخفیف',
                                value: `%${res.data.data.value}`
                            }
                        ]
                    })
            }
            setIsSubmitting(false)
        }catch (e) {
            ErrorToaster(e, t)
            setDiscount(null)
            setIsSubmitting(false)
        }
    }

    return (
        <React.Fragment>
            {/*<ModalHelper*/}
            {/*    open={isModalOpen}*/}
            {/*    onOpen={() =>setIsModalOpen(true)}*/}
            {/*    onClose={() => setIsModalOpen(false)}*/}
            {/*    component={*/}
            {/*        <AddWalletComponent/>*/}
            {/*    }*/}
            {/*/>*/}

            <div className="nk-content ">
                <div className="container-fluid">

                    <div className="checkout">
                        <div className="checkout-form">
                            <div className="spacer"/>


                            <Field
                                className="pt-0 pb-0 checkout-fields "
                                label={"نام نام خانوادگی"}
                                id={"title"}
                                name={"title"}
                                type={'text'}
                                value={form.fullName}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        fullName: e
                                    })
                                }}
                            />
                            <Field
                                className="pt-0 pb-0 checkout-fields "
                                label={"استان"}
                                id={"city"}
                                name={"city"}
                                type={'text'}
                                value={form.city}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        city: e
                                    })
                                }}
                            />
                            <Field
                                className="pt-0 pb-0 checkout-fields "
                                label={"ایمیل"}
                                id={"email"}
                                name={"email"}
                                type={'text'}
                                value={form.email}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        email: e
                                    })
                                }}
                            />
                            <Field
                                className="pt-0 pb-0 checkout-fields "
                                label={"شماره تماس"}
                                id={"phone"}
                                name={"phone"}
                                type={'text'}
                                value={form.phone}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        phone: e
                                    })
                                }}
                            />
                            <div className="spacer"/>
                            <div className="form-label">انتخاب بروکر</div>
                            <div className="broker-container">
                                {
                                    brokers?.map(item => {
                                        return (
                                            <div
                                                className={`selectable-button ${item._id === brokerId ? "is-active" : ''}`}
                                                onClick={async () => {
                                                    setBrokerId(item._id)
                                                }}>
                                                {item.name} {item.platform}
                                            </div>
                                        )
                                    })
                                }
                            </div>


                            <div className="spacer"/>
                            <div className="form-label">انتخاب درگاه پرداخت</div>
                            <div className="broker-container">
                                {
                                    paymentGateways?.map(item => {
                                        return (
                                            <div
                                                className={`selectable-button ${item._id === paymentGatewayId ? "is-active" : ''}`}
                                                onClick={async () => {
                                                    setPaymentGatewayId(item._id)
                                                }}>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>


                            <div className="spacer"/>
                            <div className="form-label">انتخاب افزونه ها</div>
                            <div className="broker-container">
                                {
                                    addonItems?.map(item => {
                                        return (
                                            <div
                                                className={`selectable-button addon-style ${selectedAddons.includes(item._id) ? "is-active" : ''}`}
                                                onClick={async () => {
                                                    if(selectedAddons.includes(item._id)){
                                                        setSelectedAddons(prevState => {
                                                            return(
                                                                [
                                                                    ...selectedAddons.filter(i => i !== item._id)
                                                                ]
                                                            )
                                                        })
                                                    }else{
                                                        setSelectedAddons([
                                                            ...selectedAddons,
                                                            item._id
                                                        ])
                                                    }
                                                }}>
                                                <div className="addon-title">
                                                    {item.title}
                                                </div>
                                                <div className="addon-cost">
                                                    {item.type === 'percentage' ? '%' : "USDT"} {item.amount}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="spacer"/>
                            <div className="modal-note">
                                بروکر انتخابی را به صورت کامل بررسی نموده چرا که مسترپراب‌فاند هیچگونه مسئولیتی در قبال
                                بروکر ندارد.

                                <br/>
                                <br/>
                                بعد از پرداخت امکان بازگشت وجه وجود ندارد.
                            </div>


                            <Field
                                className=" privacy-and-policy"
                                label={"تمامی قوانین را مطالعه نموده و مورد قبول میباشد."}
                                id={"privacy-and-policy"}
                                name={"privacy-and-policy"}
                                type={'checkbox'}
                                checked={form.privacyAndPolicy}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        privacyAndPolicy: e
                                    })
                                }}
                            />

                        </div>
                        <div className="checkout-detail">
                            <div className="checkout-detail-item">

                                <SegmentItem
                                    heading={"سفارش انتخاب شده"}
                                    cancelable={false}
                                    items={[
                                        {
                                            title: '',
                                            description: '',
                                            attributes: [
                                                {
                                                    key: 'محصول',
                                                    value: `${product.title} دلاری`
                                                },
                                                {
                                                    key: 'قیمت',
                                                    value: `${product.price} تتر`
                                                },
                                            ]
                                        },
                                    ]}
                                />

                                {
                                    campaignItems.length > 0 ?
                                        <SegmentItem
                                            heading={"کمپین ها"}
                                            cancelable={true}
                                            onCancel={(_id) => {
                                                setCampaignItems([
                                                    ...campaignItems.filter(campaign => campaign._id !== _id)
                                                ])
                                            }}
                                            items={[
                                                ...campaignItems?.map(item => {
                                                    return {
                                                        _id: item._id,
                                                        title: item.title,
                                                        description: item.description,
                                                        attributes: [

                                                            item.type === 'discount' ?
                                                                {
                                                                    key: 'تخفیف',
                                                                    value: `${toFarsiNumber(item.amount)}%`
                                                                } : (
                                                                    item.type === 'multiplier' ?
                                                                        {
                                                                            key: 'ضریب',
                                                                            value: `x${toFarsiNumber(item.amount)}`
                                                                        } : ''
                                                                )

                                                        ]
                                                    }
                                                }),

                                            ]}
                                        /> : ''
                                }
                                {
                                    selectedAddons.length > 0 ?
                                        <SegmentItem
                                            heading={"افزونه ها"}
                                            cancelable={true}
                                            onCancel={(_id) => {
                                                console.log('افزونه کلیک شده' , _id)
                                                setSelectedAddons(prevState => {
                                                    return(
                                                        [
                                                            ...selectedAddons.filter(i => i !== _id)
                                                        ]
                                                    )
                                                })
                                            }}
                                            items={
                                                addonItems?.filter(item => selectedAddons.includes(item._id)).map(item => {
                                                    {
                                                        if(selectedAddons.includes(item._id)){
                                                            return {
                                                                _id: item._id,
                                                                title: item.title,
                                                                description: item.description,
                                                                attributes: [
                                                                    {
                                                                        key: 'هزینه',
                                                                        value: ((item.type === 'percentage' ? '%' : "USDT") +  " " +  (item.amount))
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                        /> : ''
                                }
                                {
                                    discount ?
                                        <SegmentItem
                                            heading={"کد تخفیف"}
                                            cancelable={true}
                                            onCancel={(_id) => {
                                                setDiscount(null)
                                            }}
                                            items={[discount]}
                                        /> : ''
                                }
                                {/*<SegmentItem
                                    heading={"کد دعوت"}
                                    cancelable={true}
                                    items={[
                                        {
                                            title: 'تخفیف دعوت کننده',
                                            description: 'این نوع تخفیف یک بار مصرف میباشد. در صورتی که میخواهید در خرید بعدی از آن استفاده کنید، کد دعوت را کنسل کنید',
                                            attributes: [
                                                {
                                                    key: 'آرین عبدی',
                                                    value: 'تخفیف'
                                                },
                                                {
                                                    key: 'تخفیف',
                                                    value: '۱۰%'
                                                }
                                            ]
                                        }
                                    ]}
                                />*/}

                            </div>
                            <div className="checkout-detail-item">
                                <div className="segment">
                                    <div className="title">کد تخفیف</div>
                                    <Field
                                        className="pt-0 pb-0 modal-fields "
                                        id={"title"}
                                        name={"title"}
                                        type={'text'}
                                        value={discountCode}
                                        onChange={(e) => {
                                            setDiscountCode(e)
                                        }}
                                    />
                                    <div className="form-submit-btn" onClick={async () => {
                                        await OnDiscountCodeCheck()
                                    }}>
                                        اعمال کد تخفیف
                                    </div>

                                </div>
                            </div>

                            <div className="checkout-detail-item">
                                <SegmentItem
                                    heading={""}
                                    cancelable={false}
                                    items={[
                                        {
                                            title: '',
                                            description: '',
                                            attributes: [
                                                {
                                                    key: 'مبلغ پرداختی دلاری',
                                                    value: `${toFarsiNumber(payableValue)} تتر`
                                                },
                                          /*      {
                                                    key: 'مبلغ پرداختی ریال',
                                                    value: `${toFarsiNumber(payableValue * 630000)} ریال `
                                                }*/
                                            ]
                                        }
                                    ]}
                                />

                                <div className="payment-note-title">
                                    درگاه پرداخت کریپتو
                                </div>
                                <div className="payment-note">
                                    اطلاعات شخصی شما برای پردازش سفارش شما، پشتیبانی از تجربه شما در سراسر این وب سایت و
                                    برای اهدافی که در سیاست حفظ حریم خصوصی ذکر شده است استفاده می شود.
                                </div>

                                <div className={`form-submit-btn  ${checkRequiredFields() ? "" : 'disabled'}`}
                                     onClick={async () => {
                                         if (checkRequiredFields())
                                             await submit()
                                     }}>
                                    پرداخت
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>

    );
};

export default Checkout;
