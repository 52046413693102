import {Field} from "../../../components/fouladyar/field/field";
import React, {useState} from "react";
import {UpperHeader} from "../../../layout/header/upperHeader";
import AppWrap from "../../../layout/global/AppWrap";
import AppMain from "../../../layout/global/AppMain";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";


export const ForgotPassword2 = () => {


    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [form, setForm] = useState({
        fullName: "",
        email: "",
        mobile: "",
        nationalCode: "",
        country: "iran",
        city: "",
        password: "",
        repeatPassword: "",
    });
    const auth = useSelector((state) => state.auth);
    const forgotPassword = async () => {
        try {
            console.log("register", form);
            setIsSubmitting(true);
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/forgot-password`, form);

            if (res.data.statusCode === 200 || res.data.statusCode === 201) {
                SuccessToaster({message: 'لینک تغییر پسورد به ایمیل شما ارسال شد'}, t)
                navigate(`/signin`);
            }
            setIsSubmitting(false);

        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }

    };
    return (


        <AppMain>
            <AppWrap>
                <UpperHeader/>
                <div className="login-box">
                    <div className="nk-auth-body">
                        <h4 className="login-title">{"فراموشی رمز عبور"}</h4>

                        <div className="login-under-title">
                            ایمیلی که در زمان ثبت نام وارد کرده اید را در فیلد زیر وارد کرده تا لینک تغییر پسورد برای
                            شما ارسال شود. در صورتی که ایمیل را دریافت نکرده اید، فولد spam ایمیل خود را چک کنید
                        </div>
                        <div className="spacer-8"/>
                        <Field
                            className=" modal-fields "
                            label={"ایمیل"}
                            id={"email"}
                            name={"email"}
                            type={'text'}
                            value={form.email}
                            onChange={(e) => {
                                setForm({...form, email: e.toLowerCase()});
                            }}
                        />
                        <div className="mt-5 form-submit-btn" style={{opacity: isSubmitting ? 0.5 : 1}}
                             onClick={async () => {
                                 await forgotPassword()
                             }}>
                            ارسال لینک
                        </div>
                        <div className="spacer"/>
                    </div>
                </div>
            </AppWrap>
        </AppMain>
    )
}