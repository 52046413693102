import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import IDentifyCard from "./component/IDentityCard";
import {EmptyState} from "../../../components/fouladyar/empty-state/emptyState";
import {LoadingState} from "../../../components/fouladyar/loading-state/loadingState";

const UserIDentifyCards = () => {

    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [isApplyingFilter, setIsApplyingFilter] = useState(false);
    const [pagination, setPagination] = useState({
        itemPerPage: 7,
        currentPage: 1,
        totalItems: 0,
        lastUpdateBy: ''
    })

    const [filter, setFilter] = useState({})

    async function initializeData() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/my-kyc-orders`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setOrders(res.data.data.orders)
                setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function fetchData(updater) {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/my-kyc-orders`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                setOrders(res.data.data.orders)
                if (updater === 'filter')
                    setPagination({
                        ...pagination,
                        totalItems: res.data.data.totalItems,
                        currentPage: 1,
                        lastUpdateBy: updater
                    })
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        initializeData();
    }, []);


    useEffect(() => {
        if(reload){
            initializeData();
            setReload(false)
        }
    }, [reload]);


    useEffect(() => {
        if (pagination.lastUpdateBy === "pagination")
            fetchData('pagination');

    }, [pagination]);


    useEffect(() => {
        fetchData('filter');
    }, [filter]);


    async function deleteOrder(id) {
        try {
            setIsLoading(true)
            const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/orders/my-kyc-orders/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                setReload(true)
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="grid">
                {/*<div className="grid-header license-header">
                    <div className="grid-header-image">
                        <div dangerouslySetInnerHTML={{__html: FACE_ID_SVG}}/>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 bot-selector">
                        <h3>احراز هویت صرافی</h3>
                    </div>
                    <div className="grid-actions">
                        <button className="btn small-button grid-action-btn" type="button" onClick={() => {
                            navigate(`${process.env.FXPILOT_BASE_URL}/identify/add`)
                        }}>
                            <IoAddOutline size={17} color={"#49e7ad"}/>
                        </button>
                    </div>
                </div>
*/}
                <div className="container-fluid select-plan-container">
                    <h1>{"احراز هویت"}</h1>
                    <div className="spacer"/>
                    <h3 style={{border: 'unset'}}>
                        در این بخش متناسب اطلاعات مورد نیاز جهت صدور مدارک فیزیکی را وارد کنید
                    </h3>
                    <p>
                        در وارد کردن اطلاعات زیر دقت کافی را داشته باشید چرا که پس از ثبت نهایی فرم و صدور مدارک،
                        اطلاعات وارد شده قابل ویرایش نیستند
                    </p>
                    <div className="spacer"/>
                    <div className="saving-draft-btn" onClick={() => {
                        navigate(`${process.env.FXPILOT_BASE_URL}/identify/add`)
                    }}>
                        + درخواست جدید
                    </div>
                </div>
                <div className="identify-card-container">
                    {
                       isLoading ? <LoadingState/> :
                           orders?.length === 0 ? <EmptyState/> :
                               orders?.map(item => {
                                   return (
                                       <IDentifyCard
                                           _id={item._id}
                                           fullName={item.fullName}
                                           userImage={item.userImage}
                                           birthdate={item.birthdate}
                                           status={item.status}
                                           uniqueNumber={item.uniqueNumber || ''}
                                           exchange={item.exchange}
                                           fatherName={item.fatherName}
                                           targetCountry={item.targetCountry}
                                           onDelete={async (id) => {
                                               await deleteOrder(id)
                                           }}
                                       />
                                   )
                               })
                    }
                </div>
            </div>


        </React.Fragment>
    );
};

export default UserIDentifyCards;



