import React, {useEffect, useState} from "react";
import TelegramLogo from "../../../../../assets/images/fxpilot/telegram-logo.png";
import MetaTraderLogo from "../../../../../assets/images/fxpilot/metatrader-logo.png";
import {LuCopy} from "react-icons/lu";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {atomDark} from "react-syntax-highlighter/dist/cjs/styles/prism";
import {toFarsiNumber} from "../../../../../shared/toFarsiNumber";
import {CodeView} from "../templates";
import {copyToClipboard} from "../../../../../shared/copyToClipboard";
import {Field} from "../../../../../components/fouladyar/field/field";
import {SignalBotIdAndAccountNumber, SignalCodeTemplate} from "../../codes/signalCodeTemplate";
import {ConvertGregorianToJalali} from "../../../../../shared/convertGregorianToJalali";


export function SignalItem({index, object, isOpen, setIsOpen}) {


    const [item, setItem] = useState();
    useEffect(()=>{
        if(object){
            setItem(object.extras)
        }
    },[object])


    return (
        <div className="fxplt-signal-item" onClick={() => {
            setIsOpen(isOpen.map((itm, indx) => {
                if (indx === index)
                    return !itm;

                return itm
            }))
        }}>
            <div className="d-flex flex-row justify-content-between w-100 align-center" style={{gap: "10px"}}>
                <div className="fxplt-signal-item-app">
                    <div className="fxplt-signal-item-app-img">
                        <img src={ImageSelector(item?.SentMessagePlatform)} alt={item?.SentMessagePlatform}/>
                    </div>
                </div>
                <Dashed/>
                <span>{toFarsiNumber(ConvertGregorianToJalali(object.createdAt, true))}</span>
                <Dashed/>
                <div className="fxplt-signal-item-app">
                    <div className="fxplt-signal-item-app-img">
                        <img src={ImageSelector(item?.ReceivedMessagePlatform)} alt={item?.ReceivedMessagePlatform}/>
                    </div>
                </div>
            </div>
            <div className={`fxplt-signal-item-content ${!isOpen[index] ? 'hidden' : ''}`}>
                <Divider/>
                <div className="d-flex flex-column justify-content-between w-100" style={{direction: "ltr"}}>
                    <div className="fxplt-signal-item-code-view">
                        <div className="fxplt-signal-item-app">
                            <div className="fxplt-signal-item-app-img">
                                <img src={ImageSelector(item?.ReceivedMessagePlatform)}
                                     alt={item?.ReceivedMessagePlatform}/>
                            </div>
                        </div>

                        <pre>
                           {JSON.stringify(item?.ReceivedMessage, null, 2)}
                        </pre>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-between w-100" style={{direction: "ltr"}}>
                    <div className="fxplt-signal-item-code-view">
                        <div className="fxplt-signal-item-app">
                            <div className="fxplt-signal-item-app-img">
                                <img src={ImageSelector(item?.SentMessagePlatform)} alt={item?.SentMessagePlatform}/>
                            </div>
                        </div>
                        <pre>
                                   {item?.SentMessage}
                                </pre>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Signals({signals, isOpen, setIsOpen}) {
    return (
        <div className="fxplt-signal-container">
            {
                signals.map((item, index) => {
                    return (
                        <SignalItem index={index} object={item} isOpen={isOpen} setIsOpen={setIsOpen}/>
                    )
                })
            }
        </div>
    );
}

export function ImageSelector(name) {
    switch (name) {
        case 'telegram':
            return TelegramLogo;
        case 'mql5':
            return MetaTraderLogo;
        default:
            console.warn(`No logo found for ${name}`);
            return 'DefaultLogo'; // Optional: a placeholder or null

    }
}

export function TutorSelector(name, botId) {

    const templateId = '43128332905'

    switch (name) {
        case 'telegram':
            return <>
                <h2 style={{fontWeight: "100"}}>دسترسی ارسال پیام به تلگرام</h2>
                <p>در این بخش تنها کافیست کانال ها ای که Public بوده و دارای Username هستند را اینجا وارد کنید و توضیحات
                    مورد نیاز خود را در صورت نیاز وارد کنید.</p>
                <p> از ارسال پیام اتوماتیک به تلگرام، باید ربات @fxpilot_bot را به کانال خود اضافه کنید و اجازه و دسترسی
                    ارسال پیام را به آن بدهید. دقت کنید آیدی کانال وارد شده در این صفحه حتما شامل @ باشد در غیر اینصورت
                    در دریافت پیام های خود به مشکل برخواهید خورد.</p>
                <CodeView text={"@fxpilot_channel"}/>
                <p>برای اینکه مطمئن شوید که تنظیمات به درستی انجام شده است میوانید از دکمه ارسال پیام تستی استفاده کنید
                    و و در صورتی که پیام “آزمایشی” را دریافت کردید یعنی تنظیمات به درستی ذخیره شده است.</p>

                <Divider/>
            </>;
        case 'mql5':
            const webhookUrl = `${process.env.REACT_APP_SIGNAL_WEBOHOOK_URL}`;
            const CheckLicenseWithAccountCode = `
// Define base URL
#define BASE_URL "${webhookUrl}/api/license/webhook"

// Function to send a GET request with account number as a query parameter
bool CheckLicenseWithAccount(string botId, long accountNumber) {
    string result = "";
    char postData[];
    char headers[];
    
    
    // Construct URL with query parameters
    string url = BASE_URL + "?botId=" + botId + "&account=" + IntegerToString(accountNumber);

    // Set headers (optional if the server does not require any)
    ArrayResize(headers, 1);
    headers[0] = "Content-Type: application/json";

    // Send WebRequest with NULL for data in a GET request
    int response = WebRequest("POST", url, "",  10, headers, postData, result);

    if (response == -1) {
        Print("Error in WebRequest: ", GetLastError());
        return false;
    }



   // Check response status codes
    if (response == 200) {
        Print("License is valid for account: ", accountNumber);
        return true;
    } else if (response == 404) {
        Print("Service is unavailable, license check failed for account: ", accountNumber);
        return false;
    } else {
        Print("Unexpected response code ", response);
        return false;
    }
}
        `
            return <>
                <h2 style={{fontWeight: "100"}}>تنظیمات متاتریدر</h2>
                <span className="referral-code-heading1">ایجاد لایسنس کد با استفاده از آدرس وب هوک</span>
                <span className="referral-code-description">با استفاده از آدرس زیر قادر خواهید بود با خیال راحت کد اکسپرت خود را در اختیار دیگران قرار داده و اجازه دسترسی به حساب های مورد نظر را خود کنترل کنید.</span>
                <span className="referral-code-heading4">آدرس وب هوک</span>

                <div className="referral-code w-100 align-left">
                    <LuCopy className="copy-icon mr-3" size={18} color={"#fff"} onClick={() => {
                        copyToClipboard(webhookUrl)
                    }}/>
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        id={"title"}
                        name={"title"}
                        type={'text'}
                        value={webhookUrl}
                    />
                </div>

                <div className="referral-code-description2 d-block">
                    <p>باید در نرم افزار متاتریدر ۵ مقدار بالا را در Allowed URL اضافه کنید. بدین منظور وارد منوی زیر
                        میشوید
                        <br/><code style={{display: 'block', textAlign: "left"}}>Tools -&gt; Options -&gt; Expert
                            Advisors</code>
                        <br/><br/>
                        و سپس در بخش آخر با دبل کلیک مقدار URL را وارد کنید. بعد از اینکه آدرس وب هوک در نرم افزار
                        متاتریدر وارد شد، نوبت به اضافه کردن کد مربوط به لایسنس در کد ربات شما میشود. ابتدا تابع زیر را
                        وارد کد خود کنید</p>
                </div>

                <div className="referral-code-description2 d-block">
                    <p>
                        نکته: حتما از قطعه کد زیر جهت دریافت شماره حساب کد خود استفاده کنید تا دیگران قادر به ارسال سیگنال از اکانت های دیگر نباشند.
                    </p>
                    <div className="code-view" style={{direction: 'ltr', width: "100%"}} onClick={() => {
                        copyToClipboard(SignalCodeTemplate(process.env.REACT_APP_SIGNAL_WEBOHOOK_URL, botId, templateId))
                    }}>
                        <div style={{backgroundColor: "#323238", borderRadius: "9px", padding: "10px"}}>
                            <SyntaxHighlighter
                                language=""
                                style={atomDark}
                                customStyle={{
                                    fontSize: "14px",
                                    backgroundColor: "transparent",
                                    padding: "0",
                                }}
                            >
                                {
                                    SignalBotIdAndAccountNumber(botId)
                                }
                            </SyntaxHighlighter>
                        </div>
                    </div>
                </div>

                <br/>


                <div className="code-view" style={{direction: 'ltr', width: "100%"}} onClick={() => {
                    copyToClipboard(SignalCodeTemplate(process.env.REACT_APP_SIGNAL_WEBOHOOK_URL, botId, templateId))
                }}>
                    <div style={{backgroundColor: "#323238", borderRadius: "9px", padding: "10px"}}>

                        <SyntaxHighlighter
                            language=""
                            style={atomDark}
                            customStyle={{
                                fontSize: "14px",
                                backgroundColor: "transparent",
                                padding: "0",
                            }}
                        >
                            {SignalCodeTemplate(process.env.REACT_APP_SIGNAL_WEBOHOOK_URL, botId, templateId)}
                        </SyntaxHighlighter>
                    </div>
                </div>


                <Divider/>
            </>;
        default:
            console.warn(`No logo found for ${name}`);
            return 'DefaultLogo'; // Optional: a placeholder or null

    }
}

export function Dashed() {
    return (
        <div style={{borderBottom: "3px dotted rgb(204 204 204 / 28%)", flex: 1}}></div>
    )
}

export function Divider() {
    return (
        <div style={{
            borderBottom: "2px solid rgb(204 204 204 / 28%)",
            width: "100%",
            margin: "35px 0px",
            flex: 1
        }}></div>
    )
}