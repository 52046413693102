import React, {useEffect, useState} from "react";

import LicenseDrive from "../../../../assets/images/mrpropfund/kyc/license-drive.png";
import BankStatement from "../../../../assets/images/mrpropfund/kyc/bank-statement.png";
import IdentityCard from "../../../../assets/images/mrpropfund/kyc/identity-card.png";
import Passport from "../../../../assets/images/mrpropfund/kyc/passport.png";
import {GoCheckCircleFill} from "react-icons/go";

export const Step3 = ({data, onChange}) => {

    const items = [
        {name: 'گواهینامه رانندگی', image: LicenseDrive, price: 5000000},
        {name: 'کارت شناسایی', image: IdentityCard, price: 5000000},
        {name: 'پاسپورت', image: Passport, price: 5000000},
        {name: 'گردش حساب', image: BankStatement, price: 5000000},
    ];


    useEffect(()=>{

    }, [])

    return (
        <div>
            <div className="container-fluid select-plan-container">
                <h1>{"مدارک فیزیکی"}</h1>
                <h3 style={{border: 'unset'}}>
                    در این بخش مدارک مورد نیاز فیزیکی خود را انتخاب کنید.
                </h3>
                <p>
                    به صورت پیشفرض با توجه اطلاعات وارد شده در بخش اول، مدارک مورد نیاز به حالت انتخاب در خواهند آمد. در صورتی که به مدارک دیگری نیازمندید آن ها را انتخاب کنید.
                </p>
                <div className="spacer"/>
            </div>
            <MultiSelect items={items}/>
        </div>
    );
};


const Item = ({item, isSelected, onSelect}) => {
    return (
        <div
            className={`kyc-physical-item ${isSelected ? 'selected' : ''}`}
            onClick={() => onSelect(item)}
        >
            <div className="d-flex flex-row align-items-center">
                <GoCheckCircleFill size={20} color={isSelected ? "#38AF84" : "#636363"}/>
                <div className="item-name">{item.name}</div>
            </div>
            <img src={item.image} alt={item.name} className="item-image"/>
        </div>
    );
};


// MultiSelect Component - allows users to select multiple items
const MultiSelect = ({items}) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelect = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter(i => i !== item)); // Deselect item
        } else {
            setSelectedItems([...selectedItems, item]); // Select item
        }
    };

    return (
        <div className="multi-select">
            {items.map((item, index) => (
                <Item
                    key={index}
                    item={item}
                    isSelected={selectedItems.includes(item)}
                    onSelect={handleSelect}
                />
            ))}

        </div>
    );
};