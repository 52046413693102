import {Line} from "react-chartjs-2";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import annotationPlugin from "chartjs-plugin-annotation";
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip} from "chart.js";
ChartJS.register(LineElement, CategoryScale, annotationPlugin, LinearScale, PointElement, Tooltip, Legend);

export default function GrowthLineChartComponent ({trades}) {


    const {t, i18n} = useTranslation();

    const [period, setPeriod] = useState('week'); //week | month


    // Calculate the running total of pnl
    const dataset = trades.reduce((acc, trade) => {
        const lastTotal = acc.length > 0 ? acc[acc.length - 1] : 0;
        const newTotal = lastTotal + trade.pnl;
        acc.push(newTotal);
        return acc;
    }, []);

    const data = {
        labels: [0, ...trades.map((item, index) => {return index+1})],
        datasets: [
            {
                type: 'line', // Line dataset
                label: 'رشد سرمایه',
                data: [0, ...dataset],
                backgroundColor: 'rgba(75, 192, 192, 1)',
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
                borderWidth: 2,
                tension: 0.1, // Adjust this value to make the line curved

                pointRadius: 0,          // Hide the dots
                pointHoverRadius: 0,     // Hide the dots on hover
                pointHitRadius: 10

            },
        ],
    };

    const annotation = {
        annotations: {
            line0: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: 'rgba(61,61,61,0.65)',
                borderWidth: 1,
            },
        }
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            annotation,
            legend: {
                labels: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Hide vertical grid lines
                },
                title: {
                    stacked: true,
                }
            },
            y: {
                title: {
                    stacked: true,
                }
            },
        },
    };


    return(
        <table border="1" cellPadding="10" className="w-100 mb-3">
            <thead>
            <tr>
                <th className="d-flex flex-row justify-content-between align-center">

                    <span>نمودار رشد سرمایه</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <div style={{width: '100%', height: '400px'}}>
                    <Line  data={data} options={options}/>
                </div>
            </tr>

            </tbody>
        </table>
    )

}
