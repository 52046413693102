import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {LuCopy} from "react-icons/lu";
import {useNavigate} from "react-router-dom";
import {IoAddOutline} from "react-icons/io5";
import {BOT_SVG} from "../../../shared/svg";
import {Field} from "../../../components/fouladyar/field/field";
import {LoadingState} from "../../../components/fouladyar/loading-state/loadingState";
import {EmptyStateObject, filterStructure, tableStatics, tableStructure} from "./index";
import Table, {replacePlaceholders} from "../../../components/fouladyar/table";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {YesOrNoModal} from "../../../components/fouladyar/modal-helper/pages/yesOrNo";
import toast from "react-hot-toast";
import {IoMdClose} from "react-icons/io";
import {copyToClipboard} from "../../../shared/copyToClipboard";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {SignalCodeTemplate} from "../signal/codes/checkLicenseWithAccountCode";


const LicenseList = () => {

    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [data, setData] = useState([
        // {
        //     _id: '0292283293813203032',
        //     firstName: 'محسن',
        //     lastName: 'رضایی',
        //     broker: 'alpari',
        //     expirationDate: '۱۴۰۳/۰۱/۰۵',
        //     accountNumber: '99665211656'
        // },
        // {
        //     _id: '0292283293813203032',
        //     firstName: 'محسن',
        //     lastName: 'رضایی',
        //     broker: 'alpari',
        //     expirationDate: '۱۴۰۳/۰۱/۰۵',
        //     accountNumber: '99665211656'
        // },
        // {
        //     _id: '0292283293813203032',
        //     firstName: 'محسن',
        //     lastName: 'رضایی',
        //     broker: 'alpari',
        //     expirationDate: '۱۴۰۳/۰۱/۰۵',
        //     accountNumber: '99665211656'
        // },
        // {
        //     _id: '0292283293813203032',
        //     firstName: 'محسن',
        //     lastName: 'رضایی',
        //     broker: 'alpari',
        //     expirationDate: '۱۴۰۳/۰۱/۰۵',
        //     accountNumber: '99665211656'
        // },
        // {
        //     _id: '0292283293813203032',
        //     firstName: 'محسن',
        //     lastName: 'رضایی',
        //     broker: 'alpari',
        //     expirationDate: '۱۴۰۳/۰۱/۰۵',
        //     accountNumber: '99665211656'
        // },
    ]);
    const navigate = useNavigate();
    const [yesOrNoModalIsOpen, setYesOrNoModalIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBotId, setSelectedBotId] = useState('');
    const [bots, setBots] = useState([]);
    const [pagination, setPagination] = useState({
        itemPerPage: 7,
        currentPage: 1,
        totalItems: 0,
        lastUpdateBy: ''
    })

    const [modalProps, setModalProps] = useState({
        modalCancelText: "",
        modalSubmitText: "",
        modalTitle: "",
        modalContent: "",
        modalItemTarget: "",
        modalType: "",
        modalPath: ""
    });

    const [filter, setFilter] = useState({})


    async function loadBots() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setBots(res.data.data.bots)

            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function initializeData() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/license/bot/${selectedBotId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setData(res.data.data.licenses.map(item => {
                    return {
                        ...item,
                        expirationDate: (new Date(item.expirationDate)).toLocaleDateString()
                    }
                }))
                setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function fetchData(updater) {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/license/bot/${selectedBotId}?limit=${pagination.itemPerPage}&page=${pagination.currentPage}&${filter}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                setData(res.data.data.licenses.map(item => {
                    return {
                        ...item,
                        expirationDate: (new Date(item.expirationDate)).toLocaleDateString()
                    }
                }))
                if (updater === 'filter')
                    setPagination({
                        ...pagination,
                        totalItems: res.data.data.totalItems,
                        currentPage: 1,
                        lastUpdateBy: updater
                    })
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        loadBots();
    }, []);


    useEffect(() => {
        if (bots.length > 0 && selectedBotId) {
            initializeData();
        }

        if (bots.length > 0 && !selectedBotId) {
            console.log('--------bots[0]._id--------', bots[0]._id)
            setSelectedBotId(bots[0]._id);
        }

    }, [bots, selectedBotId]);

    useEffect(() => {
        if (pagination.lastUpdateBy === "pagination")
            fetchData('pagination');

    }, [pagination]);


    useEffect(() => {
        // fetchData('filter');
    }, [filter]);


    const [activeTab, setActiveTab] = useState('tab1');
    const [loading, setLoading] = useState(false);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    function WebhookUrl() {
        const onInitCode = `
// Run license check when expert is initialized
int OnInit() {
    
    ShowActiveLabel(); // Display the "Active" label
    
    string botId = "${selectedBotId}"; // Example bot ID
    long accountNumber = AccountInfoInteger(ACCOUNT_LOGIN); // Gets the current account number

    if (!CheckLicenseWithAccount(botId, accountNumber)) {
        return INIT_FAILED; // Stop the EA if the license is invalid
    }
    

    Print("License check passed.");
    return INIT_SUCCEEDED;
}
        `
        const webhookUrl = `${process.env.REACT_APP_LICENSE_WEBOHOOK_URL}`;
        const CheckLicenseWithAccountCode =SignalCodeTemplate(webhookUrl)
        return (
            <div className="referral-container webhook-page">
                <div className="referral-code">
                    <span className="referral-code-heading1">ایجاد لایسنس کد با استفاده از آدرس وب هوک</span>
                    <span className="referral-code-description">با استفاده از آدرس زیر قادر خواهید بود با خیال راحت کد اکسپرت خود را در اختیار دیگران قرار داده و اجازه دسترسی به حساب های مورد نظر را خود کنترل کنید.</span>
                    <span className="referral-code-heading4">آدرس وب هوک</span>

                    <div className="referral-code">
                        <LuCopy className="copy-icon mr-3" size={18} color={"#fff"} onClick={() => {
                            copyToClipboard(webhookUrl)
                        }}/>
                        <Field
                            className="pt-0 pb-0 modal-fields "
                            id={"title"}
                            name={"title"}
                            type={'text'}
                            value={webhookUrl}
                        />
                    </div>

                    <div className="referral-code-description2 d-block" >
                        <p>باید در نرم افزار متاتریدر ۵ مقدار بالا را در Allowed URL اضافه کنید. بدین منظور وارد منوی زیر میشوید
                            <br/><code style={{display: 'block', textAlign: "left"}}>Tools -&gt; Options -&gt; Expert Advisors</code>
                            و سپس در بخش آخر با دبل کلیک مقدار URL را وارد کنید. بعد از اینکه آدرس وب هوک در نرم افزار متاتریدر وارد شد، نوبت به اضافه کردن کد مربوط به لایسنس در کد ربات شما میشود. ابتدا تابع زیر را وارد کد خود کنید</p>
                    </div>

                    <div style={{direction: 'ltr', width: "100%"}} onClick={() => {
                        copyToClipboard(CheckLicenseWithAccountCode)
                    }}>
                        <SyntaxHighlighter
                            language="c"
                            style={atomDark}
                            customStyle={{
                                fontSize: '16px',
                                backgroundColor: "rgb(37 37 43)" ,
                                borderRadius: "9px",
                                cursor: "pointer"
                        }}
                        >
                            {CheckLicenseWithAccountCode}
                        </SyntaxHighlighter>
                    </div>


                    <div className="referral-code-description2">
                            سپس در تابع onInit از کد متا خود مقادیر زیر را وارد کنید
                    </div>



                    <div style={{direction: 'ltr', width: "100%"}} onClick={() => {
                        copyToClipboard(onInitCode)
                    }}>
                        <SyntaxHighlighter
                            language="c"
                            style={atomDark}
                            customStyle={{
                                fontSize: '16px',
                                backgroundColor: "rgb(37 37 43)" ,
                                borderRadius: "9px",
                                cursor: "pointer"
                            }}
                        >
                            {onInitCode}
                        </SyntaxHighlighter>
                    </div>


                    <span className="referral-code-description2 d-block">
                      سپس کد را build کرده و به نرم افزار متاتریدر برگشته و expert مورد نظر را روی چارت بی اندازید. نتیجه باید با پیام زیر مواجه شوید.
                            <br/><code style={{display: 'block', textAlign: "left"}}>Service is unavailable, license check failed for account: XXXXXXXXX</code>
                        حال باید شماره اکانت را با دکمه افزودن حساب به لایسنس ها اضافه کرده و تاریخ لایسنس را تعیین کنید. بعد از افزودن به جدول خود مشاهده خواهید کرد کد شما برای فقط آن حساب فعال خواهد شد.

                   </span>
                </div>
            </div>
        )
    }


    function ActiveKeys() {
        return (
            <Table
                loading={isLoading}
                filter={filter}
                tableData={data}
                pagination={pagination}
                tableHeading={tableStatics}
                tableStructure={tableStructure}
                filterStructure={filterStructure}
                // tableActionsStructure={profile.roles.includes('admin') ? [] : TableActionsStructure}
                emptystate={EmptyStateObject}
                onItemPerPageChange={(itemPerPage, currentPage) => {
                    setPagination({
                        ...pagination,
                        itemPerPage: itemPerPage,
                        currentPage: currentPage,
                        lastUpdateBy: 'pagination'
                    })
                }}
                onCurrentPageChange={(currentPage) => {
                    setPagination({...pagination, currentPage: currentPage, lastUpdateBy: 'pagination'})
                }}
                onFilterSubmit={(e) => {
                    setFilter(e);
                }}
                onDeleteComplete={async (e) => {
                    await fetchData('filter');
                }}
            />
        );
    }

    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <ModalHelper
                open={yesOrNoModalIsOpen}
                onOpen={() => setYesOrNoModalIsOpen(true)}
                onClose={() => setYesOrNoModalIsOpen(false)}
                component={
                    <YesOrNoModal
                        cancelText={modalProps.modalCancelText}
                        submitText={modalProps.modalSubmitText}
                        onClose={() => {
                            setYesOrNoModalIsOpen(false);
                        }}
                        onCancel={() => {
                            setYesOrNoModalIsOpen(false);
                        }}
                        onSubmit={async () => {
                            if (modalProps.modalType === 'delete') {
                                // replacePlaceholders(j.route, item)
                                try {
                                    const res = await axios.delete(`${process.env.REACT_APP_API_URL}${replacePlaceholders(modalProps.modalPath, modalProps.modalItemTarget)}`, {
                                        headers: {authorization: `Bearer ${auth.token}`}
                                    });

                                    if (res.status === 200) {
                                        await fetchData('filter');
                                        setYesOrNoModalIsOpen(false);
                                        toast.success("آیتم مورد نظر با موفقیت حذف شد")
                                    }
                                } catch (e) {
                                    ErrorToaster(e, t);
                                }
                            }
                            if (modalProps.modalType === 'post') {
                                // replacePlaceholders(j.route, item)
                                try {
                                    const res = await axios.post(`${process.env.REACT_APP_API_URL}${replacePlaceholders(modalProps.modalPath, modalProps.modalItemTarget)}`, {}, {
                                        headers: {authorization: `Bearer ${auth.token}`}
                                    });

                                    if (res.status === 200) {
                                        await fetchData('filter');
                                        setYesOrNoModalIsOpen(false);
                                        toast.success("آیتم مورد نظر با موفقیت ")
                                    }
                                } catch (e) {
                                    ErrorToaster(e, t);
                                }
                            }
                        }}
                        title={modalProps.modalTitle}
                        content={modalProps.modalContent}
                    />
                }
            />


            <div className="grid">
                <div className="grid-header license-header">
                    <div className="grid-header-image">
                        <div dangerouslySetInnerHTML={{__html: BOT_SVG}}/>
                    </div>

                    <div className="d-flex flex-column flex-grow-1 bot-selector">
                        {/*<h3>لایسنس ها</h3>*/}
                        <Field
                            id={"bot"}
                            name={"bot"}
                            type={'select'}
                            defaultValue={selectedBotId}
                            options={
                                bots.map(item => {
                                    return {label: item.name, value: item._id}
                                })
                            }
                            value={selectedBotId}
                            onChange={(e) => {
                                setSelectedBotId(e)
                            }}
                        />
                        <h5>MQL5</h5>
                    </div>
                    <div className="grid-actions">
                        <button className="btn small-button grid-action-btn" type="button" onClick={() => {
                            setModalComponent(
                                <AddBotComponent
                                    botId={selectedBotId}
                                    isEditing={false}
                                    onClose={() => {
                                        setIsModalOpen(false)
                                    }}
                                    onReload={async () => {
                                        await fetchData('filter')
                                    }}
                                />
                            )
                            setIsModalOpen(true);
                        }}>
                            <IoAddOutline size={17} color={"#49e7ad"}/>
                        </button>
                    </div>
                </div>
                <div className="grid-kyc-container">


                    <div className="tab-buttons">
                        <div className="tab-buttons-container">
                            <button onClick={() => handleTabClick('tab1')}
                                    className={activeTab === 'tab1' ? 'active' : ''}>لایسنس ها
                            </button>
                       {/*     {
                                <button onClick={() => handleTabClick('tab2')}
                                        className={activeTab === 'tab2' ? 'active' : ''}>بایگانی
                                </button>
                            }*/}
                            {
                                <button onClick={() => handleTabClick('tab3')}
                                        className={activeTab === 'tab3' ? 'active' : ''}>آدرس وب هوک
                                </button>
                            }


                        </div>
                    </div>


                    <div className="tab-content">
                        {activeTab === 'tab1' &&
                            <div>
                                {
                                    loading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <ActiveKeys/>
                                }
                            </div>
                        }


                        {activeTab === 'tab2' &&
                            <div>
                                {/*{
                                    loading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <UserCards myCards={cards}/>
                                }*/}
                                بایگانی
                            </div>
                        }
                        {activeTab === 'tab3' &&
                            <div>
                                {
                                    loading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <WebhookUrl/>
                                }
                            </div>
                        }

                    </div>


                </div>
            </div>


        </React.Fragment>
    );
};

export default LicenseList;

function AddBotComponent({isEditing, botId, _bot, onClose, onReload}) {
    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [license, setLicense] = useState({
        botId: botId,
        firstname: isEditing ? _bot?.firstname : '',
        lastname: isEditing ? _bot?.lastname : '',
        accountNumber: isEditing ? _bot?.accountNumber : '',
        broker: isEditing ? _bot?.broker : '',
        expirationDate: isEditing ? _bot?.expirationDate : '',
    })

    async function onSubmit() {
        try {
            const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/license`, license, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (newCardResponse.data.statusCode === 200) {
                setIsSubmitting(false);
                onClose();
                onReload()
            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }
    }

    async function onEdit({_id}) {
        try {
            const res = (await axios.put(`${process.env.REACT_APP_API_URL}/api/license/${_bot._id}`, license, {
                headers: {Authorization: `Bearer ${auth.token}`}
            }));

            if (res.data.statusCode === 200) {
                setIsSubmitting(false);
                onClose();
                onReload()
            }
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }
    }


    return (
        <div className="modal-container">
            <div className="close-modal-btn" onClick={() => onClose()}>
                <IoMdClose size={20} color={"#FAF4F4"}/>
            </div>
            <h4 className="modal-title">{isEditing ? "ویرایش لایسنس" : "افزودن لایسنس"}</h4>


            <div className="modal-note">
                لطفا در این فرم اطلاعات کاربر خود را به طور دقیق وارد کنید. در صورت اشتباه وارد کردن شماره حساب، لایسنس
                روی حساب کاربر شما فعال نخواهد شد
            </div>
            <div className='modal-form'>
                <Field
                    disabled={true}
                    className="pt-0 pb-0 modal-fields "
                    label={"شناسه ربات"}
                    id={"botId"}
                    name={"botId"}
                    type={'text'}
                    value={license.botId}
                    onChange={(e) => {
                        setLicense({
                            ...license,
                            botId: e
                        })
                    }}
                />
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"نام"}
                    id={"firstname"}
                    name={"firstname"}
                    type={'text'}
                    value={license.firstname}
                    onChange={(e) => {
                        setLicense({
                            ...license,
                            firstname: e
                        })
                    }}
                />
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"نام خانوادگی"}
                    id={"lastname"}
                    name={"lastname"}
                    type={'text'}
                    value={license.lastname}
                    onChange={(e) => {
                        setLicense({
                            ...license,
                            lastname: e
                        })
                    }}
                />
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"شماره حساب"}
                    id={"accountNumber"}
                    name={"accountNumber"}
                    type={'text'}
                    value={license.accountNumber}
                    onChange={(e) => {
                        setLicense({
                            ...license,
                            accountNumber: e
                        })
                    }}
                />
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"بروکر"}
                    id={"broker"}
                    name={"broker"}
                    type={'text'}
                    value={license.broker}
                    onChange={(e) => {
                        setLicense({
                            ...license,
                            broker: e
                        })
                    }}
                />
                <Field
                    className="pt-0 pb-0 modal-fields "
                    label={"تاریخ انقضا"}
                    id={"expirationDate"}
                    name={"expirationDate"}
                    type={'date'}
                    value={license.expirationDate}
                    onChange={(e) => {
                        setLicense({
                            ...license,
                            expirationDate: e
                        })
                    }}
                />
            </div>

            <div className="form-submit-btn" style={{opacity: isSubmitting ? 0.5 : 1}} onClick={async () => {
                if (!isSubmitting) {
                    setIsSubmitting(true);
                    isEditing ? await onEdit(_bot._id) : await onSubmit()
                }
            }}>
                {isEditing ? "ویرایش لایسنس" : "ایجاد لایسنس"}

            </div>

            {/*    {
                isEditing ?
                    <div className="form-submit-btn remove-card-btn" style={{opacity: isSubmitting ? 0.5 : 1}}
                         onClick={async () => {
                             if (!isSubmitting) {
                                 await onDelete(_bot._id)
                             }
                         }}>
                        حذف کارت

                    </div> :
                    <></>
            }*/}

        </div>
    )
}