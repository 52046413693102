import {AiOutlineQuestionCircle} from "react-icons/ai";

const ExchangeIcons = (exchangeName) => {
    switch (exchangeName?.toLowerCase()) {
        case "coinex":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/exchange/coinex.png';
        case "bingx":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/exchange/bingx.png';
        case "binance":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/exchange/binance.png';
        case "kucoin":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/exchange/kucoin.png';
        case "kraken":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/exchange/kraken.png';
        case "bybit":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/exchange/bybit.png';
        case "toobit":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/exchange/toobit.png';
        case "okx":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/exchange/okx.png';
        case "fundednext":
            return 'https://mashinbazaar.storage.iran.liara.space/images/fxpilot/exchange/fundednext.png';
        default:
            return <AiOutlineQuestionCircle />; // Default icon for unknown exchanges
    }
};

export default ExchangeIcons;