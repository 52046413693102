export const tableStatics = {
    title: "سفارش ها",
    description: "",
    addNewItemButtonLink: '/order-add'
}
export const formStatics = {
    title: "خرید اکانت",
    editTitle: "ویرایش سفارش",
    description: "از اینجا میتوانید اقدام به ایجاد سفارش کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش سفارش کنید",
    submitText: "ایجاد سفارش",
    editSubmitText: "ویرایش سفارش",
}


export const EmptyStateObject = {
    title: "سفارش یافت نشد",
    content: "از این بخش میتوانید به اقدام به ایجاد یا ویرایش سفارش ها کنید.",
    buttonText: "خرید اکانت",
    buttonOnClickUrl: "/order-add"
}

import accountBackground from "../../../assets/images/mrpropfund/new-account-background.png";

export const TableActionsStructure = [
    {
        title: "خرید اکانت جدید",
        description: "با خرید حساب جدید وارد چالش جذب سرمایه شده و تا ۵۰ هزار دلار برای معامله گری جذب سرمایه کنید",
        image: accountBackground,
        link: '/plans'
    }
]


export const filterStructure = [
    {
        title: "سفارش دهنده",
        slug: 'mainUserEmail',
        type: "text",
    },
    {
        title: "نام",
        slug: 'fullName',
        type: "text",
    },
    {
        title: "ایمیل",
        slug: 'email',
        type: "text",
    },
    {
        title: "عنوان محصول",
        slug: 'productTitle',
        type: "text",
    },
    {
        title: "نوع طرح",
        slug: 'productVariety',
        type: "text",
    },
]

export const tableStructure = [
    {
        title: "سفارش دهنده",
        slug: 'mainUserEmail',
    },
    {
        title: "نام",
        slug: 'fullName',
    },
    {
        title: "ایمیل",
        slug: 'email',
        useFarsiNumber: true
    },
    {
        title: "عنوان محصول",
        slug: 'productTitle',
        useFarsiNumber: true
    },
    {
        title: "نوع طرح",
        slug: 'productVariety',
        useFarsiNumber: true
    },
/*    {
        title: "لول",
        slug: 'level',
        useTranslate: true,
    },
    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true,
    },
    {
        title: "تاریخ سفارش",
        slug: 'createdAt',
        useJalaliFormat: true,
        useFarsiNumber: true
    },*/
    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "جزئیات سفارش",
                slug: "edit-order",
                useRoute: true,
                route: "/order-detail/:_id"
            },
            {
                title: "تایید سفارش",
                roles: ['admin'],
                slug: 'confirm-order',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'تایید سفارش',
                modalTitle: 'تایید سفارش',
                modalContent: 'آیا از تایید این سفارش اطمینان دارید؟ پس از تایید سفارش حساب دمو برای کاربر مورد نظر ارسال خواهد شد',
                modalType: "post",
                modalPath: '/api/orders/complete/:_id',
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "عنوان سفارش",
            description: "عنوان سفارش باید شامل نوع سفارش (تخیف  یا ضریب)  و دلیل سفارش (اعیاد یا مناسبت) باشد",
            slug: 'title',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "توضیحات",
            description: "در صورتی که شرایط و قواعدی جهت شرکت در این سفارش وجود دارد لطفا در این بخش عنوان کنید\n",
            slug: 'description',
            type: "textarea",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "دسته بندی",
            description: "دسته بندی سفارش",
            slug: 'categoryId',
            type: "select",
            options: [
                {label: 'اعمال ضریب', value: '667d374e6a8ff41756eefc55'},
                {label: 'اعمال تخفیف', value: '667d37586a8ff41756eefc58'},
            ],
            // chooseOptionsFromApi: true,
            // path: '/api/categories?section=order',
            // key: 'categories',
            // chooseOptionsLabelFrom: 'name',
            // chooseOptionsValueFrom: '_id',
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "مقدار",
            description: "مقداری که این سفارش با توجه به نوع آن قرار است به کاربران اعطا کند. به عنوان مثال ۱۰ درصد تخیف یا ضریب اکانت ۲",
            slug: 'amount',
            type: "number",
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "تاریخ انقضا",
            description: "با استپفاده از این تاریخ، کمیپن شما غیر فعال خواهد شد. شما قادر خواهید بود آن را مجدد فعال و تاریخ جدید ثب کنید",
            slug: 'expirationDate',
            type: "date",
            placeholder: "",
            isRequired: true,
        },
    ],
    // [
    //
    //     {
    //         title: "وضعیت",
    //         description: "قبل از ارسال این سفارش میتوانید وضعیت آن را مشخص کنید",
    //         slug: 'status',
    //         type: "select",
    //         placeholder: "",
    //         options: [
    //             {label: 'فعال', value: 'beginner'},
    //             {label: 'غیر فعال', value: 'professional'},
    //         ],
    //         isRequired: true,
    //     },
    // ],
]
