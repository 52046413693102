import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import {useSelector} from "react-redux";
import {validateToken} from "../pages/asoo/auth";
import {UpperHeader} from "./header/fxpilot/upperHeader";
import Header from "./header/Header";
import menu from "./menu/MenuData";
import FxPilotAppSidebar from "./sidebar/fxpilot/AppSidebar";
import {UnderHeaderBanner} from "../pages/components/mrpropfund/underHeaderBanner";
import {Footer} from "../pages/components/fxpilot/footer";



const FxpilotAppLayoutWithSidebar = ({title, app, ...props}) => {
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [IS_TOKEN_VALID, SET_IS_TOKEN_VALID] = useState(false);
    const appTitle = menu.find(item => location.pathname.includes(item.link) && item.link.includes('/profile'))?.text;

    useEffect(() => {

        async function validateAccessToken() {
            const tokenValidation = await validateToken(auth.token);


            if (!tokenValidation) {
                navigate(`/signin`)
            } else {
                SET_IS_TOKEN_VALID(true);
            }

        }

        validateAccessToken()


    }, [auth.token]);

    function AppHeader(){
        return(
            <div className="app-header">
                {appTitle || ''}
            </div>
        )
    }

    return (
        <>
            {
                !IS_TOKEN_VALID ? <></> :
                    <>

                        <Head title={!title && 'Loading'}/>
                        <AppRoot>
                            {/*<Appbar />*/}


                            <AppMain className={"fxplt-body"}>
                                <Sidebar fixed/>
                                <AppWrap>
                                    <div className="application-wrapper">
                                        <FxPilotAppSidebar/>
                                        <div className="application-view">
                                            <Outlet/>
                                        </div>
                                    </div>
                                    <Footer/>
                                </AppWrap>
                            </AppMain>
                        </AppRoot>
                    </>
            }

        </>

    );
};
export default FxpilotAppLayoutWithSidebar;
