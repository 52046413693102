
import React, {useEffect, useState} from "react";
import ImageFileUploadV2 from "../../../components/fxpilot/imageUploaderV2";


export const Step2 = ({data, onChange}) => {

    const [userImage, setUserImage] = useState({
        image: '',
        selectedFile: null
    });

    async function initializeData() {
        setUserImage({
            image: data.image,
            selectedFile: null
        })
    }
    useEffect(() => {
        initializeData()
    }, [])

    useEffect(() => {
        onChange(userImage)
    }, [userImage.selectedFile])

    useEffect(() => {
        console.log('----------', data)
        initializeData()
    }, [data])


    return (
        <div>
            <div className="container-fluid select-plan-container">
                <h1>{"عکس پرسنلی"}</h1>
                <h3 style={{border: 'unset'}}>
                    در این بخش عکس پرسنلی مناسب جهت احراز هویت را آپلود کنید
                </h3>
                <p>
                    به این نکته توجه داشته باشید که در زمان عکس برداری باید عکس شما حداقل ۱.۵ از شما فاصله داشته باشد و مشابه عکس های گرفته شده برای لاتاری باشد، یعنی بکگراند سفید و از تمام رخ گرفته شود.
                </p>

                <div className="spacer"/>
            </div>
            <div className="course-image-upload">
                {
                    userImage?.image ?
                        <div className="w-100 d-flex justify-content-center align-items-center">
                            <img src={`${process.env.REACT_APP_FXPLT_LIARA_STORAGE}/${userImage?.image}`} alt="User" className="identify-image-upload-preview" />

                            <div className="delete-lesson" onClick={() => {
                                setUserImage(prevState => {
                                    return{
                                        ...prevState,
                                        image: ''
                                    }
                                })
                            }}>
                                حذف فایل
                            </div>
                        </div> :
                        <ImageFileUploadV2
                            onChange={(e) => {
                                setUserImage(prevState => {
                                    return{
                                        ...prevState,
                                        selectedFile: e
                                    }
                                });
                                console.log(e)
                            }}
                        />
                }

            </div>

        </div>
    );
};