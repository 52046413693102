import React, { useEffect, useMemo, useState } from "react";
import Table from "../../../components/fouladyar/table";
import { useDispatch, useSelector } from "react-redux";
import { filterStructure, tableStatics, tableStructure } from "./index";
import { ConvertFilterObjectToUrlParam } from "../../../redux/store/shared/shared";
import { getItems } from "../../../redux/store/services/asoo/transactions/store";
import { ErrorToaster } from "../../../shared/toaster";
import { useTranslation } from "react-i18next";


const TransactionList = () => {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  const [pagination, setPagination] = useState({
    itemPerPage: 7,
    currentPage: 1,
    totalItems: 0,
    lastUpdateBy: ''
  })

  const [filter, setFilter] = useState({ sort: 'createdAt'})
  async function initializeData() {

   try {
     setIsLoading(true)
     const res = await dispatch(getItems(
       pagination
     ));

     if(res.statusCode === 200 ){
       setData(res.data.transactions)
       setPagination({...pagination, totalItems: res.count || 9, lastUpdateBy: 'initializer'})
     }

     setIsLoading(false)
   }catch (e) {
     ErrorToaster(e, t)
     setIsLoading(false)
   }

  }


  async function fetchData(updater) {
    try {
      setIsLoading(true)
      const res = await dispatch(getItems(
        pagination,
        ConvertFilterObjectToUrlParam(filter)
      ));
      if(res.statusCode === 200){
        setData(res.data.transactions)
        if(updater === 'filter')
          setPagination({
            ...pagination,
            totalItems: res.data.count,
            currentPage: 1,
            lastUpdateBy: updater
          })
      }

      setIsLoading(false)
    }catch (e) {
      ErrorToaster(e, t)
      setIsLoading(true)
    }

  }

  useEffect(() => {
    initializeData();
  }, []);


  useEffect(() => {
    if(pagination.lastUpdateBy === "pagination")
        fetchData('pagination');

  }, [pagination]);



  useEffect(() => {
    fetchData('filter');
  }, [filter]);



  return (
    <React.Fragment>
      <Table
        loading={isLoading}
        filter={filter}
        tableData={data}
        pagination={pagination}
        tableHeading={tableStatics}
        tableStructure={tableStructure}
        filterStructure={filterStructure}
        onItemPerPageChange={(itemPerPage, currentPage)=> {
          setPagination({...pagination, itemPerPage: itemPerPage, currentPage: currentPage, lastUpdateBy: 'pagination'})
        }}
        onCurrentPageChange={(currentPage)=> {
          setPagination({...pagination, currentPage: currentPage, lastUpdateBy: 'pagination'})
        }}
        onFilterSubmit={ (e)=>{
          setFilter(e);
        }}
        onDeleteComplete={ (e)=>{
          fetchData('pagination');
        }}
      />
    </React.Fragment>
  );
};

export default TransactionList;
