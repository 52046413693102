export const provinces = [
    { label: 'آذربایجان شرقی', value: 'East Azerbaijan' },
    { label: 'آذربایجان غربی', value: 'West Azerbaijan' },
    { label: 'اردبیل', value: 'Ardabil' },
    { label: 'اصفهان', value: 'Isfahan' },
    { label: 'البرز', value: 'Alborz' },
    { label: 'ایلام', value: 'Ilam' },
    { label: 'بوشهر', value: 'Bushehr' },
    { label: 'تهران', value: 'Tehran' },
    { label: 'چهارمحال و بختیاری', value: 'Chaharmahal and Bakhtiari' },
    { label: 'خراسان جنوبی', value: 'South Khorasan' },
    { label: 'خراسان رضوی', value: 'Razavi Khorasan' },
    { label: 'خراسان شمالی', value: 'North Khorasan' },
    { label: 'خوزستان', value: 'Khuzestan' },
    { label: 'زنجان', value: 'Zanjan' },
    { label: 'سمنان', value: 'Semnan' },
    { label: 'سیستان و بلوچستان', value: 'Sistan and Baluchestan' },
    { label: 'فارس', value: 'Fars' },
    { label: 'قزوین', value: 'Qazvin' },
    { label: 'قم', value: 'Qom' },
    { label: 'کردستان', value: 'Kurdistan' },
    { label: 'کرمان', value: 'Kerman' },
    { label: 'کرمانشاه', value: 'Kermanshah' },
    { label: 'کهگیلویه و بویراحمد', value: 'Kohgiluyeh and Boyer-Ahmad' },
    { label: 'گلستان', value: 'Golestan' },
    { label: 'گیلان', value: 'Gilan' },
    { label: 'لرستان', value: 'Lorestan' },
    { label: 'مازندران', value: 'Mazandaran' },
    { label: 'مرکزی', value: 'Markazi' },
    { label: 'هرمزگان', value: 'Hormozgan' },
    { label: 'همدان', value: 'Hamadan' },
    { label: 'یزد', value: 'Yazd' }
];