
import {Progress} from "reactstrap";
import React, {useState} from "react";
import CourseTemporary from "../../../../../assets/images/fxpilot/course-temporary.png";
import {MoneySeparator} from "../../../../../shared/moneySeparator";
import {useNavigate} from "react-router-dom";
import { FaPlayCircle, FaDownload, FaFileAlt } from 'react-icons/fa';
import {RiPlayFill} from "react-icons/ri";
import {toFarsiNumber} from "../../../../../shared/toFarsiNumber";
import ExpandableReadMoreBox from "../../../../components/fxpilot/ExpandableReadMoreBox";
import {Divider} from "../../../signal/tabs/signals";

export function CourseItemDetail({item}) {
    const navigate = useNavigate();
    const [tutorExpand, setTutorExpand] = useState(false);

    return (
        <div className="fxplt-course-item fxplt-course-item-detail">
            <div className="fxplt-course-item-detail-container">

                <div className="fxplt-course-item-image">
                    <img src={CourseTemporary} alt=""/>
                </div>

                <div className="fxplt-course-item-content">
                    <div className="fxplt-course-item-title-and-content">
                        <h2>{item.title}</h2>
                        <h5>{item.content}</h5>
                    </div>
                    <Progress className={"fxplt-course-item-progress-bar"} value="18"/>
                    <div className="fxplt-course-item-creator-and-price">
                        <h5>{item.creator}</h5>
                        <h3>{toFarsiNumber(MoneySeparator(item.price))} تومان </h3>
                    </div>
                </div>

            </div>


            <Divider/>

            <ExpandableReadMoreBox
                className="inner-card course-expand-tutor"
                open={tutorExpand}
                setOpen={()=>{setTutorExpand(!tutorExpand)}}
                content={
                    <CourseInfo
                         title={"ساخت استراتژی اتوماتیک  +‌ مدیریت سرمایه با MQL5 و Tradingview"}
                         content={`
                         با استفاده از آدرس زیر قادر خواهید بود با خیال راحت کد اکسپرت خود را در اختیار دیگران قرار داده و اجازه دسترسی به حساب های مورد نظر را خود کنترل کنید. با استفاده از آدرس زیر قادر خواهید بود با خیال راحت کد اکسپرت خود را در اختیار دیگران ق

شسیب نبی شسینبشسیبکن  با استفاده از آدرس زیر قادر خواهید بود با خیال راحت کد اکسپرت خود را در اختیار دیگران قرار داده و اجازه دسترسی به حساب های مورد نظر را خود کنترل کنید.
                         `}
                    />
                }
            />
            <Divider/>
            <LMSComponent chapters={item.chapters} />

        </div>
    );
}


const LMSComponent = ({ chapters }) => {
    return (
        <div className="lms-component">
            {chapters.map((chapter, chapterIndex) => (
                <Chapter key={chapterIndex} chapter={chapter} />
            ))}
        </div>
    );
};

const Chapter = ({ chapter }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="chapter">
            <div className="chapter-header" onClick={toggleExpand}>
                <h3>{chapter.title} <span>({toFarsiNumber(chapter.lessons.length)} درس)</span></h3>
            </div>
            {isExpanded && (
                <div className="lessons">
                    {chapter.lessons.map((lesson, lessonIndex) => (
                        <Lesson key={lessonIndex} lesson={lesson} />
                    ))}
                </div>
            )}
        </div>
    );
};

const Lesson = ({ lesson }) => {
    return (
        <div className="lesson">
            {/* First Row */}
            <div className="lesson-header">
                <div className="play-icon">
                    <RiPlayFill size={20} color={"#fff"}/>
                </div>
                <span className="lesson-title">{toFarsiNumber(lesson.title)}</span>
                <span className="duration">{toFarsiNumber(lesson.duration)}</span>
            </div>

            {/* Second Row */}
            <div className="lesson-actions">
                {lesson.type === 'file' && (
                    <button className="action-button">
                        <FaDownload color={"#fff"}/> دانلود فایل
                    </button>
                )}
                {lesson.type === 'video' && (
                    <>
                        <button className="action-button">
                            نمایش مجدد
                        </button>
                        <button className="action-button">
                            <FaFileAlt color={"#fff"}/> یادداشت
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};






export function CourseInfo({title, content}) {
    return <>
        <h2 style={{fontWeight: "100"}}>{title}</h2>
        <p>
            {content}
        </p>
        <Divider/>
    </>
}
