import { TbEdit } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";

export const tableStatics = {
  title: "تیکت ها",
  description: "",
  addNewItemButtonLink: '/ticket-add'
}
import TicketImage from "../../../assets/images/mrpropfund/ticket-background.png";

export const TableActionsStructure = [
  {
    title: "ایجاد تیکت",
    useModal: true,
    description: "در صورت رخداد مشکل و یا پیگیری فرایند های چالش پراب با ایجاد تیکت جدید با پشتیبانی مکاتبه کنید",
    image: TicketImage,
    link: '/'
  }
]


export const formStatics = {
  title: "ایجاد تیکت",
  editTitle: "ویرایش تیکت",
  description: "از اینجا میتوانید اقدام به ایجاد تیکت کنید",
  editDescription: "از اینجا میتوانید اقدام به ویرایش تیکت کنید",
  submitText: "ایجاد تیکت",
  editSubmitText: "ویرایش تیکت",
}


export const EmptyStateObject = {
  useModal: true,
  title: "تیکت یافت نشد",
  content: "به منظور بررسی چالش ها و یا رفع مشکلات با توجه به دسته بندی مورد نظر خود اقدام به ایجاد تیکت جدید کنید.",
  buttonText: "ایجاد تیکت"
}


export const filterStructure = [
  {
    title: "عنوان تیکت",
    slug: 'title',
    type: "text",
  },
  {
    title: "ایجاد کننده",
    slug: 'category',
    disabled: true,
    type: "select",

    // chooseOptionsFromApi: true,
    // path: '/Courses/Course?page=1&limit=100&sort=createdAt&sortType=ASC',
    // key: 'courses',
    // chooseOptionsLabelFrom: 'title',
    // chooseOptionsValueFrom: 'id',
    options: [],
  },
  {
    title: "دسته بندی",
    slug: 'category',
    type: "select",
    chooseOptionsFromApi: true,
    path: '/api/categories?section=ticket',
    key: 'categories',
    chooseOptionsLabelFrom: 'name',
    chooseOptionsValueFrom: '_id',
    options: [],
  },
  {
    title: "تاریخ ایجاد",
    slug: 'createdAt',
    type: "date",
  },
  {
    title: "وضعیت",
    slug: 'status',
    type: "select",
    options: [
      {label: 'همه', value: ''},
      {label: 'در حال بررسی', value: 'pending'},
      {label: 'پاسخ داده شده', value: 'answered'},
      {label: 'بسته شده', value: 'closed'},
    ],
  },
]

export const tableStructure = [
  {
    title: "عنوان تیکت",
    slug: 'title',
  },
  {
    title: "ایجاد کننده",
    slug: 'creatorName',
  },
  {
    title: "دسته بندی",
    slug: 'categoryName',
  },
  {
    title: "تاریخ ایجاد",
    slug: 'createdAt',
    useJalaliFormat: true,
    useFarsiNumber: true
  },
  {
    title: "وضعیت",
    useTranslate: true,
    slug: 'status',
  },

  {
    title: "عملیات",
    useActionsButton: true, //use delete-item class to automatic implement the removing item.
    slug: '',
    actions: [
      {
        title: "مشاهده تیکت",
        slug: "ticket-chat",
        useRoute: true,
        roles: ['admin'],
        route: "/app/support/ticket-chat/:_id"
      },
      {
        title: "مشاهده تیکت",
        slug: "ticket-chat",
        useRoute: true,
        roles: ['customer'],
        route: "/ticket-chat/:_id"
      },
      // {
      //   title: "حذف تیکت",
      //   slug: 'delete-product',
      //   useYesOrNoModal: true,
      //   modalCancelText: 'بستن پنجره',
      //   modalSubmitText: 'حذف تیکت',
      //   modalTitle: 'حذف تیکت',
      //   modalContent: 'ایا از حذف این تیکت اطمینان دارید؟ با حذف این تیکت داده ها دیگر در دسترس نخواهند بود',
      //   modalType: "delete",
      //   modalPath: '/api/products/:_id',
      // },
    ]
  },
]

