import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Field} from "../../../components/fouladyar/field/field";
import {LoadingState} from "../../../components/fouladyar/loading-state/loadingState";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {MdOutlinePodcasts} from "react-icons/md";
import * as PropTypes from "prop-types";
import {Settings} from "./tabs/settings/settings";
import {Signals} from "./tabs/signals";
import {Templates} from "./tabs/templates";


Templates.propTypes = {botId: PropTypes.string};
const SignalTabs = () => {
    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [activeTab, setActiveTab] = useState('tab1');
    const [loading, setLoading] = useState(false);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [signalIsOpen, setSignalIsOpen] = useState([])
    const [signals, setSignals] = useState([
        /*{
            _id: '0292283293813203032',
            date: '13:45 1403/11/15',
            ReceivedMessagePlatform: 'mql5',
            SentMessagePlatform: 'telegram',
            ReceivedMessage: `{
    symbol: 'GBPUSD',
    position_size: 0.5,
    entry_price: 1.3,
    current_price: 1.3,
    profit: 0,
    position_type: 'Sell'
}`,
            SentMessage: `🟢اگه این چند تا رمز ارز وحشی بشن میتونن سود خوبی تو بخش اسپات بهتون بدن ، میتونید یه پله از صرافی توبیت بخرید و هولدر باشید 🫡
$WIF ( 2.69$ )🚀
$UMA ( 2.82$ )🚀
$JASMY ( 0.0197$ )🚀

✈️ @WHALE_CHANEL 🔥
Instagram (http://www.instagram.com/trade_shahin) 🩷 YouTube (https://youtube.com/@trade_shahin?si=Hs4e9TJoPwDOd2B1) ❤️`
        },*/
        /*        {
                    _id: '0292283293813203032',
                    date: '13:45 1403/11/15',
                    ReceivedMessagePlatform: 'mql5',
                    SentMessagePlatform: 'telegram',
                    ReceivedMessage: `{
            symbol: 'GBPUSD',
            position_size: 0.5,
            entry_price: 1.3,
            current_price: 1.3,
            profit: 0,
            position_type: 'Sell'
        }`,
                    SentMessage: `🟢اگه این چند تا رمز ارز وحشی بشن میتونن سود خوبی تو بخش اسپات بهتون بدن ، میتونید یه پله از صرافی توبیت بخرید و هولدر باشید 🫡
        $WIF ( 2.69$ )🚀
        $UMA ( 2.82$ )🚀
        $JASMY ( 0.0197$ )🚀

        ✈️ @WHALE_CHANEL 🔥
        Instagram (http://www.instagram.com/trade_shahin) 🩷 YouTube (https://youtube.com/@trade_shahin?si=Hs4e9TJoPwDOd2B1) ❤️`
                },
                {
                    _id: '0292283293813203032',
                    date: '13:45 1403/11/15',
                    ReceivedMessagePlatform: 'mql5',
                    SentMessagePlatform: 'telegram',
                    ReceivedMessage: `{
            symbol: 'GBPUSD',
            position_size: 0.5,
            entry_price: 1.3,
            current_price: 1.3,
            profit: 0,
            position_type: 'Sell'
        }`,
                    SentMessage: `🟢اگه این چند تا رمز ارز وحشی بشن میتونن سود خوبی تو بخش اسپات بهتون بدن ، میتونید یه پله از صرافی توبیت بخرید و هولدر باشید 🫡
        $WIF ( 2.69$ )🚀
        $UMA ( 2.82$ )🚀
        $JASMY ( 0.0197$ )🚀

        ✈️ @WHALE_CHANEL 🔥
        Instagram (http://www.instagram.com/trade_shahin) 🩷 YouTube (https://youtube.com/@trade_shahin?si=Hs4e9TJoPwDOd2B1) ❤️`
                },
                {
                    _id: '0292283293813203032',
                    date: '13:45 1403/11/15',
                    ReceivedMessagePlatform: 'mql5',
                    SentMessagePlatform: 'telegram',
                    ReceivedMessage: `{
            symbol: 'GBPUSD',
            position_size: 0.5,
            entry_price: 1.3,
            current_price: 1.3,
            profit: 0,
            position_type: 'Sell'
        }`,
                    SentMessage: `🟢اگه این چند تا رمز ارز وحشی بشن میتونن سود خوبی تو بخش اسپات بهتون بدن ، میتونید یه پله از صرافی توبیت بخرید و هولدر باشید 🫡
        $WIF ( 2.69$ )🚀
        $UMA ( 2.82$ )🚀
        $JASMY ( 0.0197$ )🚀

        ✈️ @WHALE_CHANEL 🔥
        Instagram (http://www.instagram.com/trade_shahin) 🩷 YouTube (https://youtube.com/@trade_shahin?si=Hs4e9TJoPwDOd2B1) ❤️`
                },
                {
                    _id: '0292283293813203032',
                    date: '13:45 1403/11/15',
                    ReceivedMessagePlatform: 'mql5',
                    SentMessagePlatform: 'telegram',
                    ReceivedMessage: `{
            symbol: 'GBPUSD',
            position_size: 0.5,
            entry_price: 1.3,
            current_price: 1.3,
            profit: 0,
            position_type: 'Sell'
        }`,
                    SentMessage: `🟢اگه این چند تا رمز ارز وحشی بشن میتونن سود خوبی تو بخش اسپات بهتون بدن ، میتونید یه پله از صرافی توبیت بخرید و هولدر باشید 🫡
        $WIF ( 2.69$ )🚀
        $UMA ( 2.82$ )🚀
        $JASMY ( 0.0197$ )🚀

        ✈️ @WHALE_CHANEL 🔥
        Instagram (http://www.instagram.com/trade_shahin) 🩷 YouTube (https://youtube.com/@trade_shahin?si=Hs4e9TJoPwDOd2B1) ❤️`
                },*/
    ]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [isLoading, setIsLoading] = useState(false);
    const [signalId, setSignalId] = useState('');
    const [selectedBotId, setSelectedBotId] = useState('');
    const [bots, setBots] = useState([]);


    const [pagination, setPagination] = useState({
        itemPerPage: 7,
        currentPage: 1,
        totalItems: 0,
        lastUpdateBy: ''
    })


    const [filter, setFilter] = useState({})


    async function loadBots() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setBots(res.data.data.bots)

            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function initializeData() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/signal/${selectedBotId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {


                // setTemplates(res.data.data.templates)
                // if(res.data.data?.templates.length > 0){
                //     setTemplateIsOpen(res.data.data?.templates.map(i => {
                //         return false
                //     }))
                // }

                setSignalId(res.data.data._id)
                // setSignals(res.data.data.licenses.map(item => {
                //     return {
                //         ...item,
                //         expirationDate: (new Date(item.expirationDate)).toLocaleDateString()
                //     }
                // }))
                // setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            }

            setIsLoading(false)
        } catch (e) {
            // ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function getSignals() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/usage/signal?botId=${selectedBotId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('signal', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setSignals(res.data.data.usage)
                // setTemplates(res.data.data.templates)
                // setSignals(res.data.data.licenses.map(item => {
                //     return {
                //         ...item,
                //         expirationDate: (new Date(item.expirationDate)).toLocaleDateString()
                //     }
                // }))
                // setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function fetchData(updater) {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/license/bot/${signalId}?limit=${pagination.itemPerPage}&page=${pagination.currentPage}&${filter}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                setSignals(res.data.data.licenses.map(item => {
                    return {
                        ...item,
                        expirationDate: (new Date(item.expirationDate)).toLocaleDateString()
                    }
                }))
                if (updater === 'filter')
                    setPagination({
                        ...pagination,
                        totalItems: res.data.data.totalItems,
                        currentPage: 1,
                        lastUpdateBy: updater
                    })
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        loadBots();
    }, []);

    useEffect(() => {
        if (signals.length > 0) {
            setSignalIsOpen(signals.map(i => {
                return false
            }))
        }
    }, [signals]);


    useEffect(() => {
        if (bots.length > 0 && selectedBotId) {
            initializeData();
            getSignals();
        }

        if (bots.length > 0 && !selectedBotId) {
            console.log('--------bots[0]._id--------', bots[0]._id)
            setSelectedBotId(bots[0]._id);
        }

    }, [bots, selectedBotId]);

    useEffect(() => {
        // if (pagination.lastUpdateBy === "pagination")
        // fetchData('pagination');

    }, [pagination]);

    useEffect(() => {
        // fetchData('filter');
    }, [filter]);

    async function updateSettings(newSettings) {
        try {
            setIsLoading(true)
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/signal/update/settings`, {
                botId: selectedBotId,
                settings: newSettings
            },{
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                SuccessToaster({message: 'بروزرسانی تنظیمات با موفقیت انجام شد'}, t)
            }

            setIsLoading(false)
        } catch (e) {
            // ErrorToaster(e, t)
            setIsLoading(false)
        }
    }
    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <div className="grid">
                <div className="grid-header license-header">
                    <div className="grid-header-image">
                        <MdOutlinePodcasts size={20} color={"49e7ad"}/>
                    </div>

                    <div className="d-flex flex-column flex-grow-1 bot-selector">
                        {/*<h3>لایسنس ها</h3>*/}
                        <Field
                            id={"bot"}
                            name={"bot"}
                            type={'select'}
                            defaultValue={selectedBotId}
                            options={
                                bots.map(item => {
                                    return {label: item.name, value: item._id}
                                })
                            }
                            value={selectedBotId}
                            onChange={(e) => {
                                console.log('new bot id', e)
                                setSelectedBotId(e)
                            }}
                        />
                        <h5>MQL5</h5>
                    </div>
                    {/*<div className="grid-actions">
                        <button className="btn small-button grid-action-btn" type="button" onClick={() => {setIsModalOpen(true);}}>
                            <IoAddOutline size={17} color={"#49e7ad"}/>
                        </button>
                    </div>*/}
                </div>
                <div className="grid-kyc-container">


                    <div className="tab-buttons">
                        <div className="tab-buttons-container">
                            <button onClick={() => handleTabClick('tab1')}
                                    className={activeTab === 'tab1' ? 'active' : ''}>سیگنال ها
                            </button>
                            {
                                <button onClick={() => handleTabClick('tab2')}
                                        className={activeTab === 'tab2' ? 'active' : ''}>قالب پیام
                                </button>
                            }
                            {
                                <button onClick={() => handleTabClick('tab3')}
                                        className={activeTab === 'tab3' ? 'active' : ''}>تنظیمات
                                </button>
                            }


                        </div>
                    </div>


                    <div className="tab-content">
                        {activeTab === 'tab1' &&
                            <div>
                                {
                                    isLoading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <Signals signals={signals} isOpen={signalIsOpen} setIsOpen={setSignalIsOpen}/>
                                }
                            </div>
                        }


                        {activeTab === 'tab2' &&
                            <div>
                                {
                                    isLoading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <Templates botId={selectedBotId}/>
                                }
                            </div>
                        }
                        {activeTab === 'tab3' &&
                            <div>
                                {
                                    isLoading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <Settings
                                            botId={selectedBotId}
                                            onUpdateSettings={ (e) => {
                                                updateSettings(e)
                                            }}
                                        />
                                }
                            </div>
                        }

                    </div>


                </div>
            </div>


        </React.Fragment>
    );
};

export default SignalTabs;

