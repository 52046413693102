import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {IoAddOutline} from "react-icons/io5";
import {BOOK} from "../../../../../../shared/svg"
import {CourseItem} from "../../../Components/Courses/courseItem";
import ModalHelper from "../../../../../../components/fouladyar/modal-helper/modalHelper";
import CourseTemporary from "../../../../../../assets/images/fxpilot/course-temporary.png";
import {ManagerCourseItem} from "../../../Components/Courses/ManagerCourseItem";
import {PaginationComponent} from "../../../../../../components/Component";
import {Pagination} from "../../../../../../components/fouladyar/table";
import {LuSave} from "react-icons/lu";


const ManageCourses = () => {

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [yesOrNoModalIsOpen, setYesOrNoModalIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [data, setData] = useState([
        {
            _id: '0292283293813203032',
            title: 'ساخت استراتژی اتوماتیک  +‌ مدیریت سرمایه با MQL5 و Tradingview',
            content: 'ما مجموعه‌ای کامل از آموزش‌های تخصصی را برای شما فراهم کرده‌ایم که تمامی نیازهای آموزشی شما را پوشش می‌دهد. در بخش مقالات، می‌توانید به منابع متنوع و به‌روز در موضوعات مختلف دسترسی پیدا کنید.',
            teacher: 'آرین عبدی',
            price: '3990000',
            videoCount: '117',
            image: CourseTemporary,
            updatedAt: '2024-03-15',
        },
        {
            _id: '0292283293813203032',
            title: 'ساخت استراتژی اتوماتیک  +‌ مدیریت سرمایه با MQL5 و Tradingview',
            content: 'ما مجموعه‌ای کامل از آموزش‌های تخصصی را برای شما فراهم کرده‌ایم که تمامی نیازهای آموزشی شما را پوشش می‌دهد. در بخش مقالات، می‌توانید به منابع متنوع و به‌روز در موضوعات مختلف دسترسی پیدا کنید.',
            teacher: 'آرین عبدی',
            price: '3990000',
            videoCount: '117',
            image: CourseTemporary,
            updatedAt: '2024-03-15',
        },
        {
            _id: '0292283293813203032',
            title: 'ساخت استراتژی اتوماتیک  +‌ مدیریت سرمایه با MQL5 و Tradingview',
            content: 'ما مجموعه‌ای کامل از آموزش‌های تخصصی را برای شما فراهم کرده‌ایم که تمامی نیازهای آموزشی شما را پوشش می‌دهد. در بخش مقالات، می‌توانید به منابع متنوع و به‌روز در موضوعات مختلف دسترسی پیدا کنید.',
            teacher: 'آرین عبدی',
            price: '3990000',
            videoCount: '117',
            image: CourseTemporary,
            updatedAt: '2024-03-15',
        },
    ]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [modalProps, setModalProps] = useState({
        modalCancelText: "",
        modalSubmitText: "",
        modalTitle: "",
        modalContent: "",
        modalItemTarget: "",
        modalType: "",
        modalPath: ""
    });
    // Change Page
    const [totalItems, setTotalItems] = useState(12);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [itemPerPage] = useState(5);

    const [filter, setFilter] = useState({})

    async function initializeData() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setData(res.data.data.bots)
                setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function fetchData(updater) {


        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot?limit=${pagination.itemPerPage}&page=${pagination.currentPage}&${filter}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                setData(res.data.data.bots)
                if (updater === 'filter')
                    setPagination({
                        ...pagination,
                        totalItems: res.data.data.totalItems,
                        currentPage: 1,
                        lastUpdateBy: updater
                    })
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        // initializeData();
    }, []);


    useEffect(() => {
        // fetchData('filter');
    }, [filter]);

    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />
            <div className="grid">
                <div className="grid-header">
                    <div className="grid-header-image fxplt-grid-header">
                        <div dangerouslySetInnerHTML={{__html: BOOK}}/>
                    </div>
                    <h3 style={{flex: 1}}>دوره ها</h3>
                    <div className="grid-actions">
                        <button className="btn small-button grid-action-btn" type="button" onClick={() => {
                            navigate(`${process.env.LMS_BASE_URL}/course/new-courses`)
                        }}>
                            <IoAddOutline size={17} color={"#49e7ad"}/>
                        </button>
                    </div>
                </div>

                <div className="pt-5 pb-5" style={{display: 'flex', flexDirection: 'column', gap: "10px"}}>
                    {
                        data.map(item => {
                            return (
                                <ManagerCourseItem
                                    item={item}
                                    size={100}
                                    value={100}
                                    onEdit={() => {
                                        navigate(`${process.env.LMS_BASE_URL}/course/edit-courses/:${id}`)
                                    }}
                                />
                            )
                        })
                    }


                    <Pagination
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        setRowsPerPage={(e) => {
                            setRowsPerPage(parseInt(e), 1);
                        }}
                        paginate={(page)=>{setCurrentPage(page)}}
                        currentPage={currentPage}
                    />
                </div>
            </div>


        </React.Fragment>
    );
};

export default ManageCourses;