import React, { useRef, useState, useEffect } from 'react';
import shaka from 'shaka-player';

const DRMVideoPlayer = ({ thumbnail, videoUrl, drmLicenseUrl }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (isPlaying) {
            initializeShakaPlayer();
        }
    }, [isPlaying]);

    const initializeShakaPlayer = async () => {
        if (videoRef.current) {
            const player = new shaka.Player(videoRef.current);

            // Attach error event listener
            player.addEventListener('error', (error) => {
                console.error('Error Code:', error.detail.code);
                console.error('Error Data:', error.detail.data);
            });

            try {
                // Configure DRM
                player.configure({
                    drm: {
                        servers: {
                            'com.widevine.alpha': drmLicenseUrl, // Widevine DRM
                        },
                    },
                });

                // Load the video
                await player.load(videoUrl);
                console.log('Video loaded successfully!');
            } catch (error) {
                console.error('Error loading video:', error);
            }
        }
    };

    return (
        <div style={{ position: 'relative', width: '100%', maxWidth: '800px' }}>
            {!isPlaying ? (
                <div
                    style={{
                        backgroundImage: `url(${thumbnail})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '450px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => setIsPlaying(true)}
                >
                    <button
                        style={{
                            padding: '20px',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            borderRadius: '50%',
                            color: 'white',
                            border: 'none',
                            fontSize: '24px',
                        }}
                    >
                        ▶
                    </button>
                </div>
            ) : (
                <video
                    ref={videoRef}
                    width="100%"
                    height="450px"
                    controls
                    autoPlay
                />
            )}
        </div>
    );
};

export default DRMVideoPlayer;
