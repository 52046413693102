import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { MdOutlineAdd } from 'react-icons/md';
import './varietyAttribute.css'

// VarietyAttributes component
export default function VarietyAttributes({ attributes, onAttributeChange }) {

    // Remove an attribute by index
    const removeAttribute = (index) => {
        const updatedAttributes = [
            ...attributes.slice(0, index),
            ...attributes.slice(index + 1)
        ];
        onAttributeChange(updatedAttributes); // Pass changes to parent
    };

    // Add a new blank attribute
    const addAttribute = () => {
        const newAttribute = { key: '', value: '' };
        onAttributeChange([...attributes, newAttribute]);
    };

    // Update a specific attribute (either key or value)
    const updateAttribute = (index, key, value) => {
        const updatedAttributes = [...attributes];
        updatedAttributes[index] = { ...updatedAttributes[index], [key]: value };
        onAttributeChange(updatedAttributes); // Pass changes to parent
    };

    return (
        <div className="attribute-container">
            {attributes.map((item, index) => (
                <div className="attribute-item" key={index}>
                    <input
                        className="input-field"
                        type="text"
                        value={item.key}
                        placeholder="Attribute Key"
                        onChange={(e) => updateAttribute(index, 'key', e.target.value)}
                    />
                    <input
                        className="input-field"
                        type="text"
                        value={item.value}
                        placeholder="Attribute Value"
                        onChange={(e) => updateAttribute(index, 'value', e.target.value)}
                    />
                    <button className="remove-btn" onClick={() => removeAttribute(index)}>
                        <IoMdClose size={20} color={"#fff"} />
                    </button>
                </div>
            ))}
            <div className="add-attribute-btn" onClick={addAttribute}>
                <span>افزودن ویژگی</span>
            </div>
        </div>
    );
}
