import React, {useEffect, useState} from 'react';
import Chapter from './Chapter';
import './styles.css';
import {fetchCourseData, saveDraft} from './api';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {RiArrowDropDownFill, RiArrowDropUpFill, RiMenuFill} from "react-icons/ri";
import {toFarsiNumber} from "../../../../../../../shared/toFarsiNumber";

const CourseModule = ({newData, onChange}) => {
    const [initialize, setInitialize] = useState(true)
    const [courseData, setCourseData] = useState(
        [
           /* {
                "id": "ch1",
                "title": "فصل 1: معرفی",
                "description": "این فصل معرفی است",
                "order": 1,
                "lastModify": "2024-11-15T10:00:00Z",
                "lessons": [
                    {
                        "id": "ls1",
                        "order": 1,
                        "lastModify": "2024-11-15T10:00:00Z",
                        "prerequisites": [],
                        "title": "درس اول: معرفی دوره",
                        "description": "در این درس، دوره را به شما معرفی می‌کنیم.",
                        "contentType": "video",
                        "videoUrl": "https://example.com/intro-video",
                        "coverUrl": "https://example.com/intro-video",
                        "attachments": [],
                    },
                ]
            }*/
        ]
    );

    useEffect(()=> {
        if(newData ) {
            setCourseData(newData)
        }
    },[newData])



    useEffect(()=> {
        if(courseData)
            onChange(courseData)
    },[courseData])

    const addNewChapter = () => {
        const NewChapter = {
            "id": `ch${Date.now()}`,
            "title": `عنوان فصل ${toFarsiNumber(courseData.length + 1)}`,
            "description": "این فصل شامل مفاهیم پیشرفته‌تر برای کاربران باتجربه است.",
            "order": 0,
            "lastModify": "2024-11-16T10:00:00Z",
            "lessons": [
                {
                    "id": `ls${Date.now()}`,
                    "order": 1,
                    "lastModify": "2024-11-16T10:00:00Z",
                    "title": `عنوان درس ۱`,
                    "description": "",
                    "type": "",
                    "url": "",
                    "attachments": [],
                }
            ]
        }
        // Update the state
        setCourseData((Chapters) => {
            return[
                ...Chapters,
                NewChapter
            ]
        });
    };
    const [isDraftSaving, setDraftSaving] = useState(false);

    // Function to update a specific chapter's fields
    const handleUpdateChapterField = (chapterId, fieldName, newValue) => {
        const updatedCourseData = courseData.map((chapter) =>
            chapter.id === chapterId
                ? { ...chapter, [fieldName]: newValue }
                : chapter
        );
        setCourseData(updatedCourseData);
    };

    // State to track which chapter is expanded
    const [expandedChapters, setExpandedChapters] = useState({});


    const handleSaveDraft = async () => {
        setDraftSaving(true);
        await saveDraft(courseData);
        setDraftSaving(false);
    };

    const handleDragEnd = (result) => {
        const {source, destination} = result;

        // If dropped outside the list, return
        if (!destination) return;

        // Reorder chapters
        const updatedCourseData = Array.from(courseData);
        const [removedChapter] = updatedCourseData.splice(source.index, 1);
        updatedCourseData.splice(destination.index, 0, removedChapter);
        setCourseData(updatedCourseData);
    };

    const handleToggleChapter = (chapterId) => {
        setExpandedChapters((prev) => ({
            ...prev,
            [chapterId]: !prev[chapterId],
        }));
    };

    return (
        <div className="course-module">
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="course" direction="vertical">
                    {(provided) => (
                        <div
                            className="edit-chapters"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <div className="d-flex flex-row justify-content-between course-title">
                                <h3 style={{border: 'unset'}}>
                                    فصل ها

                                    <div className="saving-draft-btn" onClick={() => {
                                        addNewChapter()
                                    }}>
                                        + ایجاد فصل
                                    </div>
                                </h3>
                                {/* Save Draft Button */}
                                <div className="saving-draft-btn" onClick={handleSaveDraft} disabled={isDraftSaving}>
                                    {isDraftSaving ? 'در حال ذخیره سازی...' : 'ذخیره تغییرات'}
                                </div>
                            </div>
                            {courseData.map((chapter, index) => (
                                <Draggable
                                    key={chapter.id}
                                    draggableId={chapter.id}
                                    index={index}
                                >
                                    {(provided) => (
                                        <div className="chapter-item-container"
                                             ref={provided.innerRef}
                                             {...provided.draggableProps}
                                        >
                                            <div className="edit-chapter-header">
                                                {/* Drag Handle (Only the button or icon to drag) */}
                                                <div
                                                    className="drag-handle"
                                                    {...provided.dragHandleProps}
                                                >
                                                    <RiMenuFill color={"#A0A0A0"} size={24}/>
                                                </div>


                                                <h2>{chapter.title}</h2>
                                                {/* Expand/Collapse Button */}

                                                <div className="d-flex flex-row justify-content-between align-items-center ">

                                                    <div className="delete-lesson" onClick={() => {
                                                        setCourseData(chapters => {
                                                            return chapters.filter(c => {
                                                                if(c.id !== chapter.id){
                                                                    return c
                                                                }
                                                            })

                                                        })
                                                    }}>
                                                        حذف فصل
                                                    </div>
                                                    <div
                                                        className="expand-collapse-btn"
                                                        onClick={() => handleToggleChapter(chapter.id)}
                                                    >
                                                        {expandedChapters[chapter.id] ?
                                                            <RiArrowDropUpFill color={"#fff"} size={24}/> :
                                                            <RiArrowDropDownFill color={"#fff"} size={24}/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                            {/* Chapter Content (Only shown if expanded) */}
                                            {expandedChapters[chapter.id] && (
                                                <Chapter
                                                    chapter={chapter}
                                                    onUpdateField={handleUpdateChapterField}
                                                    onLessonsChange={(lessons) => {
                                                        setCourseData(chapters => {
                                                            return chapters.map(c => {
                                                                    if(c.id === chapter.id){
                                                                        return {
                                                                            ...c,
                                                                            lessons: lessons
                                                                        }
                                                                    }

                                                                    return c
                                                                })

                                                        })
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            <div className="d-flex flex-row justify-content-between course-title">
                                <h3 style={{border: 'unset'}}>
                                    <div className="saving-draft-btn" onClick={() => {
                                        addNewChapter()
                                    }}>
                                        + ایجاد فصل
                                    </div>
                                </h3>
                            </div>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>


            <div className="saving-draft-btn" onClick={() => {
            }}>
                ? بررسی داده دوره ها
            </div>
        </div>
    );
};

export default CourseModule;
