import React from "react";
import {AiOutlineMore} from "react-icons/ai";
import getFileIcon from "../utils/getFileIcon";

const FileTableView = ({ files, onToggleFavorite }) => {
    return (
        <table className="table-view">
            <thead>
            <tr>
                <th></th>
                <th>Last Update</th>
                <th>Owner</th>
                <th>Size</th>
                <th>Name</th>
            </tr>
            </thead>
            <tbody>
            {files.map((file) => (
                <tr key={file.id}>
                    <td>
                        <AiOutlineMore  color={"#fff"}/>
                    </td>
                    <td>{file.updatedAt}</td>
                    <td>{file.owner}</td>
                    <td>{file.size}</td>
                    <td className="table-view-name">{file.name} <div style={{width: "16px"}}>{getFileIcon(file.name.split(".").pop())} </div> </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default FileTableView;
