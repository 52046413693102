import React, {useEffect, useState} from "react";
import {FormIsLoading} from "../../../components/fouladyar/form";
import {useSelector} from "react-redux";
import {formStatics} from "./index";
import axios from "axios";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import {Field} from "../../../components/fouladyar/field/field";
import {IoMdClose} from "react-icons/io";
import {Button, Col, Row} from "reactstrap";
import {MdOutlineAdd} from "react-icons/md";
import VarietyAttributes from "./components/VarietyAttributes";


function ProductAdd({...props}) {

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const location = useLocation();
    const isEditing = location.pathname.includes("product-edit");
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const [isLoading, setIsloading] = useState(isEditing ? true : false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [categories, setCategories] = useState([])
    const [varieties, setVarieties] = useState([])
    const [form, setForm] = useState({
        title: "",
        slug: "",
        description: "",
        type: "virtual",
        variety: "license",
        categoryId: "",
        price: "",
        unit: "USDT",
        status: "available",
        inventory: 0,
        attributes: [
            {
                key: "دوره چالش اول",
                value: ""
            },
            {
                key: "دوره چالش دوم",
                value: ""
            },
            {
                key: "حداقل تعداد روز معاملاتی\n",
                value: ""
            },
            {
                key: "حداکثر افت سرمایه در یک روز",
                value: ""
            },
            {
                key: "حداکثر افت سرمایه در کل",
                value: ""
            },
            {
                key: "سود چالش اول",
                value: ""
            },
            {
                key: "سود چالش دوم",
                value: ""
            },
            {
                key: "حداکثر ریسک پوزیشن",
                value: ""
            }
        ]
    })

    useEffect(() => {
        getCategories()
    }, []);


    async function loadData() {

        try {
            setIsloading(true);
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data data', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                const data = res.data.data;
                setForm(res.data.data)
                setVarieties(res.data.data.varieties)

            }
            setIsloading(false);
        } catch (e) {
            ErrorToaster(e, t)
            setIsloading(false);
        }


    }


    useEffect(() => {
        if (isEditing)
            loadData();
    }, []);


    async function getCategories() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories?section=product`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('categories', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setCategories(res.data.data.categories)

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }

    async function getVarieties() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/variety/category/${form.categoryId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('varieties', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                if(!isEditing){
                    setVarieties(res.data.data.varieties.map(i => {
                        return{
                            ...i,
                            isActive: false,
                            price: 0
                        }
                    }))
                }else{
                    setVarieties(res.data.data.varieties.map(i => {
                        const v = varieties.find(j=>j._id===i._id)
                        if(varieties.find(j=>j._id===i._id)){
                            return v
                        }

                        return i
                    }))
                }

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }


    async function submit() {
        try {
            setIsSubmitting(true)

            let res;


            if (isEditing)
                res = await axios.put(`${process.env.REACT_APP_API_URL}/api/products/${id}`, {...form, varieties: varieties}, {
                    headers: {authorization: `Bearer ${auth.token}`}
                })
            else
                res = await axios.post(`${process.env.REACT_APP_API_URL}/api/products`, {...form, varieties: varieties}, {
                    headers: {authorization: `Bearer ${auth.token}`}
                })

            console.log('product', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                navigate('/app/ecommerce/product-list')

                setIsSubmitting(false)
            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    useEffect(() => {
        if (form.categoryId)
            getVarieties();
    }, [form.categoryId]);

    const handleIsActiveChange = (varietyId, status) => {
        setVarieties((prevVarieties) =>
            prevVarieties.map((variety) =>
                variety._id === varietyId
                    ? { ...variety, isActive: status }
                    : variety
            )
        );
    };


    const handlePriceChange = (varietyId, price) => {
        setVarieties((prevVarieties) =>
            prevVarieties.map((variety) =>
                variety._id === varietyId
                    ? { ...variety, price: price }
                    : variety
            )
        );
    };

    const handleAttributeChange = (varietyId, updatedAttributes) => {
        setVarieties((prevVarieties) =>
            prevVarieties.map((variety) =>
                variety._id === varietyId
                    ? { ...variety, attributes: updatedAttributes }
                    : variety
            )
        );
    };


    return (

        <>
            {
                isLoading ?
                    <FormIsLoading
                        statics={formStatics}
                        isEditing={isEditing}
                    />
                    :
                    <div className="outlet-container">
                        <div className="form-container">
                            <div className="form-container">
                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                عنوان محصول
                                            </div>
                                            <div className="field-item-description">
                                                اسم فارسی محصول
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"title"}
                                                name={"title"}
                                                type={'text'}
                                                value={form.title}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        title: e
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>

                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                شناسه محصول
                                            </div>
                                            <div className="field-item-description">
                                                این شناسه باید با استفاده از حروف انگلیسی نوشته شود
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"slug"}
                                                name={"slug"}
                                                type={'text'}
                                                value={form.slug}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        slug: e
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>

                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                قیمت
                                            </div>
                                            <div className="field-item-description">
                                                قیمت محصولات به دلار نمایش داده میشوند. لذا قیمت دلاری این محصول را وارد
                                                کنید.
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"price"}
                                                name={"price"}
                                                type={'number'}
                                                value={form.price}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        price: e
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>

                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                نوع محصول
                                            </div>
                                            <div className="field-item-description">
                                                محصول فیزیکی است یاغیر فیزیکی. برای مثال آموزش یا فایل (فایل غیر فیزیکی)
                                                است
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"type"}
                                                name={"type"}
                                                type={'select'}
                                                options={[
                                                    {value: "virtual", label: "غیر فیزیکی"},
                                                    // {value: "physical", label: "فیزیکی"},
                                                ]}
                                                value={"virtual"}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        type: e
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>

                                {/*<Row className={`field-item-container`}>
                                    <Col  className={`field-item `} >

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                تنوع کالا
                                            </div>
                                            <div className="field-item-description">
                                                از این بخش دسته بندی تنوع کالا را انتخاب کنید. این بخش از طریق طراح قابل ویرایش است
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"variety"}
                                                name={"variety"}
                                                type={'select'}
                                                options={[
                                                    {value: "license", label: "لایسنس"},
                                                ]}
                                                value={"license"}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        variety: e
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>*/}

                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                دسته بندی
                                            </div>
                                            <div className="field-item-description">
                                                دسته بندی کالا را انتخاب کنید
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"category"}
                                                name={"category"}
                                                type={'select'}
                                                options={[
                                                    ...categories?.map(item => {
                                                        return ({value: item._id, label: item.name})
                                                    })
                                                ]}
                                                value={form.categoryId}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        categoryId: e
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>

                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                وضعیت
                                            </div>
                                            <div className="field-item-description">
                                                از این بخش تعیین کنید محصول شما قابل نمایش به کابران باشد یا خیر
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"status"}
                                                name={"status"}
                                                type={'select'}
                                                options={[
                                                    {value: "available", label: "در دسترس"},
                                                    {value: "unavailable", label: "غیر قابل دسترس"},
                                                ]}
                                                value={"available"}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        status: e
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                            </div>

                        </div>

                        {
                            varieties.length > 0 ?
                                <div className="attribute-container">
                                    <div className='form-heading'>تنوع محصول</div>
                                    <div className="attribute-column">
                                        {
                                            varieties?.map((item, index) => {
                                                return (
                                                    <div className={"product-variety-expandable"}>

                                                        <div className="attribute-item variety-item">
                                                            <div className='attribute-key'>
                                                                <Field
                                                                    className="w-100"
                                                                    label={item.name}
                                                                    id={`check-price-${item.slug}`}
                                                                    name={`check-price-${item.slug}`}
                                                                    type={'checkbox'}
                                                                    value={varieties.find(j => j._id === item._id)?.isActive}
                                                                    onChange={(e) => {
                                                                        handleIsActiveChange(item._id, e)
                                                                    }}
                                                                />
                                                                {/*<div className='variety-name'>{item.name}</div>*/}
                                                            </div>
                                                            <div className='attribute-value'>
                                                                <Field
                                                                    className="w-100"
                                                                    label={"قیمت"}
                                                                    id={`price-${item.slug}`}
                                                                    name={`price-${item.slug}`}
                                                                    type={'number'}
                                                                    value={varieties.find(j => j._id === item._id)?.price}
                                                                    onChange={(e) => {
                                                                        handlePriceChange(item._id, e)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            item.attributes ?
                                                                <VarietyAttributes
                                                                    attributes={item.attributes}
                                                                    onAttributeChange={(updatedAttributes) =>
                                                                        handleAttributeChange(item._id, updatedAttributes)
                                                                    }
                                                                />
                                                                : ''
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : ''
                        }

                        <div className="attribute-container">
                            <div className='form-heading'>ویژگی محصول</div>
                            <div className="attribute-column">
                                {
                                    form.attributes?.map((item, index) => {
                                        return (
                                            <div className="attribute-item">
                                                <div className='attribute-index'>{index}</div>
                                                <div className='attribute-key'>
                                                    <Field
                                                        className="w-100"
                                                        id={`key-${index}`}
                                                        name={`key-${index}`}
                                                        type={'text'}
                                                        value={item.key}
                                                        onChange={(e) => {
                                                            const attr = form.attributes;
                                                            attr[index].key = e;
                                                            setForm({
                                                                ...form,
                                                                attributes: attr
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className='attribute-value'>
                                                    <Field
                                                        className="w-100"
                                                        id={`value-${index}`}
                                                        name={`value-${index}`}
                                                        type={'text'}
                                                        value={item.value}
                                                        onChange={(e) => {
                                                            const attr = form.attributes;
                                                            attr[index].value = e;
                                                            setForm({
                                                                ...form,
                                                                attributes: attr
                                                            })
                                                        }}
                                                    />
                                                </div>

                                                <div className='attribute-actions'>
                                                    <div className="close-modal-btn" onClick={() => {

                                                        let attr = form.attributes;
                                                        let pos = index;
                                                        attr = attr.filter((i, inx) => i.index !== pos);

                                                        setForm({
                                                            ...form,
                                                            attributes: attr
                                                        })
                                                    }}>
                                                        <IoMdClose size={20} color={"#FAF4F4"}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="attribute-item">
                                    <div className="add-attribute-button" onClick={() => {
                                        setForm({
                                            ...form,
                                            attributes: [
                                                ...form.attributes,
                                                {
                                                    key: '',
                                                    value: ''
                                                }
                                            ]
                                        })
                                    }}>
                                        <MdOutlineAdd size={20} color={"#FAF4F4"}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="spacer"/>

                        <Button
                            className={`green-button w-100 ${isSubmitting ? 'disabled' : ''}`}
                            size="md"
                            type="submit"
                            onClick={async () => {
                                console.log(varieties);
                                if (!isSubmitting)
                                    await submit()
                            }}>
                            {
                                isEditing ? "ویرایش محصول" : "ایجاد محصول"
                            }

                        </Button>

                    </div>
            }
        </>


    );
};

export default ProductAdd;
