import React, {useEffect, useState} from "react";
import Table from "../../../components/fouladyar/table";
import {useDispatch, useSelector} from "react-redux";
import {EmptyStateObject, filterStructure, TableActionsStructure, tableStatics, tableStructure} from "./index";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {IoMdClose} from "react-icons/io";
import {Field} from "../../../components/fouladyar/field/field";
import {ConvertFilterObjectToUrlParam} from "../../../redux/store/shared/shared";
import {IoAddOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";



const TicketList = () => {

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const profile = useSelector((state) => state.profile);
    const [isApplyingFilter, setIsApplyingFilter] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [ticketCategories, setTicketCategories] = useState([
        {value: "شسیبشسیبشسیبشسشسب", label: "ریست حساب"}
    ]);


    const [pagination, setPagination] = useState({
        itemPerPage: 7,
        currentPage: 1,
        totalItems: 0,
        lastUpdateBy: ''
    })

    const [filter, setFilter] = useState({})


    async function initializeData() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/tickets/${profile.roles.includes('admin') ? 'admin-access': 'customer-access'}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setData(res.data.data.tickets)
                setPagination({
                    ...pagination,
                    totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'
                })
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function fetchData(updater) {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/tickets/${profile.roles.includes('admin') ? 'admin-access': 'customer-access'}?limit=${pagination.itemPerPage}&page=${pagination.currentPage}&${ConvertFilterObjectToUrlParam(filter)}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (!res) {

            } else if (res.data.statusCode === 200) {
                setData(res.data.data.tickets)
                if (updater === 'filter')
                    setPagination({
                        ...pagination,
                        totalItems: res.data.data.totalItems,
                        currentPage: 1,
                        lastUpdateBy: updater
                    })
            } else {

            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function getTicketCategories() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories?section=ticket`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('ticket', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setTicketCategories(res.data.data.categories.map(item=>{
                    return( {value: item._id, label: item.name, slug: item.slug})
                }))

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }

    useEffect(() => {
        initializeData();
        getTicketCategories()
    }, []);


    useEffect(() => {
        if (pagination.lastUpdateBy === "pagination")
            fetchData('pagination');

    }, [pagination]);


    useEffect(() => {
        fetchData('filter');
    }, [filter]);





    function CreateNewTicketFormModal() {
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [isUserAccountLoading, setIsUserAccountLoading] = useState(false);


        const [ticket, setTicket] = useState({
            title: "",
            categoryId: "",
            accountId: '',
            orderId: '',
            productId: '',
            message: ""
        })

        const [userAccounts, setUserAccounts] = useState([
            // {value: "شسیبشسیبشسیبشسشسب", label: "اکانت شماره ۲۳۲۵۵"}
        ]);
        async function loadUserAccounts(type){
            try {


                setIsUserAccountLoading(true)
                let res;
                if(type === 'demo'){


                    res = await axios.get(`${process.env.REACT_APP_API_URL}/api/accounts/demo`, {
                        headers: {authorization: `Bearer ${auth.token}`}
                    })
                    console.log('demo', res.data)
                }


                if(type === 'all'){
                    res = await axios.get(`${process.env.REACT_APP_API_URL}/api/accounts/all`, {
                        headers: {authorization: `Bearer ${auth.token}`}
                    })
                    console.log('all', res.data)
                }
                if (res.data.statusCode === 200 || !res.data.statusCode) {
                    setUserAccounts(res.data.data.map(item=>{
                        //value is OrderId
                        return( {
                            value: item?.orderId,
                            label: item?.account?.username,
                            productId: item?.account?.productId,
                            orderId: item?.orderId
                        })
                    }))

                    setIsUserAccountLoading(false)
                }
            } catch (e) {
                ErrorToaster(e, t)
                setIsUserAccountLoading(false)
            }
        }

        useEffect(() => {
            /*
                By changing the type of ticket, we load the appropriate accounts for that ticket
            */
            const req = ticketCategories.find(i => i.value === ticket.categoryId);
            console.log('ticket.categoryId', ticketCategories.find(i => i.value === ticket.categoryId))

            /*
               If the category type is one of [first_stage_approval, second_stage_approval, third_stage_approval],
               load demo user accounts, otherwise, load all available user accounts.
            */
            if(req?.slug === "first_stage_approval" || req?.slug === "second_stage_approval" || req?.slug === "third_stage_approval" ){
                loadUserAccounts('demo');
            }else{
                loadUserAccounts('all');
            }

        }, [ticket.categoryId]);

        useEffect(() => {
            console.log('userAccounts', userAccounts)
        }, [userAccounts]);


        async function submit() {
            try {
                setIsSubmitting(true)
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/tickets`,
                    ticket,
                    {headers: {authorization: `Bearer ${auth.token}`}
                })

                console.log('new Ticket', res.data)
                if (res.data.statusCode === 200 || !res.data.statusCode) {
                    // setUserAccounts(res.data.data.deliveries)
                    SuccessToaster({message: 'تیکت با موفقیت ثبت شد'}, t)
                    setIsModalOpen(false)
                    setIsSubmitting(false)
                    await fetchData('pagination');
                }
                setIsSubmitting(false)
            } catch (e) {
                ErrorToaster(e, t)
                setIsSubmitting(false)
            }
        }

        return (
            <div className="modal-container">
                <div className="close-modal-btn" onClick={() => setIsModalOpen(false)}>
                    <IoMdClose size={20} color={"#FAF4F4"}/>
                </div>
                <h4 className="modal-title">{"ایجاد تیکت جدید"}</h4>


                <div className="modal-note">
                    لطفا در انتخاب دسته بندی تیکت دقت کافی را بعمل آوردید تا اپراتور مورد نظر در کمترین زمان ممکن پاسخ
                    شما را بدهد.
                </div>
                <div className='modal-form'>
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        label={"عنوان تیکت"}
                        id={"title"}
                        name={"title"}
                        type={'text'}
                        value={ticket.title}
                        onChange={(e) => {
                            setTicket({
                                ...ticket,
                                title: e
                            })
                        }}
                    />
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        label={"دسته بندی"}
                        id={"category"}
                        name={"category"}
                        type={'select'}
                        options={ticketCategories ?? []}
                        value={ticket.categoryId}
                        onChange={(e) => {
                            setTicket({
                                ...ticket,
                                categoryId: e
                            })
                        }}
                    />
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        label={"حساب"}
                        id={"account"}
                        name={"account"}
                        type={'select'}
                        disabled={userAccounts.length === 0 || isUserAccountLoading}
                        options={userAccounts || []}
                        value={ticket.accountId}
                        onChange={(e) => {
                            const account = userAccounts.find(i => i.orderId === e)
                            setTicket({
                                ...ticket,
                                productId: account.productId,
                                orderId: e,
                            })
                        }}
                    />
                    {
                        userAccounts.length === 0 ?
                            <h6 className="red-color">در حال حاضر اکانت فعال ندارید</h6> :
                            ''
                    }


                    <Field
                        className="pt-0 pb-0 modal-fields "
                        label={"متن تیکت"}
                        id={"message"}
                        name={"message"}
                        type={'textarea'}
                        value={ticket.message}
                        onChange={(e) => {
                            setTicket({
                                ...ticket,
                                message: e
                            })
                        }}
                    />
                </div>

                <div className="form-submit-btn" style={{opacity: isSubmitting ? 0.5 : 1}} onClick={async () => {
                    submit()
                }}>
                    ایجاد تیکت
                </div>

            </div>
        )
    }


    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />


            <div className="grid-header">
                <div className="title-with-tab">
                    <h3>تیکت ها</h3>
                </div>
                <div className="grid-actions">
                    <button className="btn small-button grid-action-btn" type="button" onClick={() => {
                        setModalComponent(<CreateNewTicketFormModal/>)
                        setIsModalOpen(true)
                    }}>
                        <IoAddOutline size={17} color={"#49e7ad"}/>
                    </button>
                </div>
            </div>



            <Table
                loading={isLoading}
                filter={filter}
                tableData={data}
                tableActionsStructure={TableActionsStructure}
                pagination={pagination}
                tableHeading={tableStatics}
                tableStructure={tableStructure}
                filterStructure={filterStructure}
                emptystate={EmptyStateObject}
                onOpenModal={() => {
                    setModalComponent(<CreateNewTicketFormModal/>)
                    setIsModalOpen(true)
                }}
                onItemPerPageChange={(itemPerPage, currentPage) => {
                    setPagination({
                        ...pagination,
                        itemPerPage: itemPerPage,
                        currentPage: currentPage,
                        lastUpdateBy: 'pagination'
                    })
                }}
                onCurrentPageChange={(currentPage) => {
                    setPagination({...pagination, currentPage: currentPage, lastUpdateBy: 'pagination'})
                }}
                onFilterSubmit={(e) => {
                    setFilter(e);
                }}
                onDeleteComplete={async (e) => {
                    await fetchData('filter');
                }}
            />
        </React.Fragment>
    );
};

export default TicketList;
