import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {IoAddOutline} from "react-icons/io5";
import {BOOK} from "../../../../../../shared/svg"
import {CourseItem} from "../../../Components/Courses/courseItem";
import ModalHelper from "../../../../../../components/fouladyar/modal-helper/modalHelper";



const MyCourses = () => {

    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [yesOrNoModalIsOpen, setYesOrNoModalIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [data, setData] = useState([
        {
            _id: '0292283293813203032',
            title: 'ساخت استراتژی اتوماتیک  +‌ مدیریت سرمایه با MQL5 و Tradingview',
            content: 'شسیم ک نشکسب شکسنشکس بشس شکس شسبشکسبشسبشسکبشسکم شکسیبش سیبنکشسنیبکش',
            creator: 'آرین عبدی',
            price: 1400000,
        },
        {
            _id: '0292283293813203032',
            title: 'ساخت استراتژی اتوماتیک  +‌ مدیریت سرمایه با MQL5 و Tradingview',
            content: 'شسیم ک نشکسب شکسنشکس بشس شکس شسبشکسبشسبشسکبشسکم شکسیبش سیبنکشسنیبکش',
            creator: 'آرین عبدی',
            price: 1400000,
        },
        {
            _id: '0292283293813203032',
            title: 'ساخت استراتژی اتوماتیک  +‌ مدیریت سرمایه با MQL5 و Tradingview',
            content: 'شسیم ک نشکسب شکسنشکس بشس شکس شسبشکسبشسبشسکبشسکم شکسیبش سیبنکشسنیبکش',
            creator: 'آرین عبدی',
            price: 1400000,
        },
        {
            _id: '0292283293813203032',
            title: 'ساخت استراتژی اتوماتیک  +‌ مدیریت سرمایه با MQL5 و Tradingview',
            content: 'شسیم ک نشکسب شکسنشکس بشس شکس شسبشکسبشسبشسکبشسکم شکسیبش سیبنکشسنیبکش',
            creator: 'آرین عبدی',
            price: 1400000,
        },

    ]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        itemPerPage: 7,
        currentPage: 1,
        totalItems: 0,
        lastUpdateBy: ''
    })
    const [modalProps, setModalProps] = useState({
        modalCancelText: "",
        modalSubmitText: "",
        modalTitle: "",
        modalContent: "",
        modalItemTarget: "",
        modalType: "",
        modalPath: ""
    });


    const [filter, setFilter] = useState({})

    async function initializeData() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setData(res.data.data.bots)
                setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function fetchData(updater) {


        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bot?limit=${pagination.itemPerPage}&page=${pagination.currentPage}&${filter}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                setData(res.data.data.bots)
                if (updater === 'filter')
                    setPagination({
                        ...pagination,
                        totalItems: res.data.data.totalItems,
                        currentPage: 1,
                        lastUpdateBy: updater
                    })
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        // initializeData();
    }, []);

    useEffect(() => {
        // if (pagination.lastUpdateBy === "pagination")
        // fetchData('pagination');

    }, [pagination]);

    useEffect(() => {
        // fetchData('filter');
    }, [filter]);

    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <div className="grid">
                <div className="grid-header">
                    <div className="grid-header-image fxplt-grid-header">
                        <div dangerouslySetInnerHTML={{__html: BOOK}}/>
                    </div>
                    <h3 style={{flex: 1}}>دوره ها</h3>
                    <div className="grid-actions">
                        <button className="btn small-button grid-action-btn" type="button" onClick={() => {
                            // setIsModalOpen(true);
                        }}>
                            <IoAddOutline size={17} color={"#49e7ad"}/>
                        </button>
                    </div>
                </div>

                <div className="grid-kyc-container grid-course-container">
                    {
                        data.map(item => {
                            return (
                                <CourseItem
                                    item={item}
                                    size={100}
                                    value={100}
                                />
                            )
                        })
                    }
                </div>
            </div>


        </React.Fragment>
    );
};

export default MyCourses;