export const tableStatics = {
    title: "افزونه ها",
    description: "",
    addNewItemButtonLink: '/campaign-add'
}
export const formStatics = {
    title: "ایجاد افزونه",
    editTitle: "ویرایش افزونه",
    description: "از اینجا میتوانید اقدام به ایجاد افزونه کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش افزونه کنید",
    submitText: "ایجاد افزونه",
    editSubmitText: "ویرایش افزونه",
}


export const EmptyStateObject = {
    title: "افزونه فعال یافت نشد",
    content: "از این بخش میتوانید به اقدام به ایجاد یا ویرایش افزونه ها کنید.",
    buttonText: "ایجاد افزونه جدید",
    buttonOnClickUrl: "/add-on-add"
}
import AddOnImage from "../../../assets/images/mrpropfund/add-on-background.png";

export const TableActionsStructure = [
    {
        title: "ایجاد افزونه",
        description: "با ایجاد یک افزونه جدید برای محصولات خود، به کاربران خود حق انتخاب های بیشتری را اعطا کنید.",
        image: AddOnImage,
        link: '/add-on-add'
    }
]
export const filterStructure = [
    {
        title: "عنوان",
        slug: 'title',
        type: "text",
    },
    {
        title: "هزینه",
        slug: 'amount',
        type: "text",
    },
    {
        title: "نوع هزینه",
        slug: 'type',
        type: "select",
        options: [
            {label: 'ثابت', value: 'constant'},
            {label: 'درصدی', value: 'percentage'},
        ],
    },
    {
        title: "وضعیت",
        slug: 'status',
        type: "select",
        options: [
            {label: 'فعال', value: 'active'},
            {label: 'غیر فعال', value: 'not_active'},
        ],
    },
]

export const tableStructure = [
    {
        title: "عنوان",
        slug: 'title',
    },
    {
        title: "هزینه",
        slug: 'amount',
        useTranslate: true
    },
    {
        title: "نوع هزینه",
        slug: 'type',
        useTranslate: true
    },
    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "ویرایش افزونه",
                slug: "edit-add-on",
                useRoute: true,
                route: "/app/ecommerce/add-on-edit/:_id"
            },
            {
                title: "حذف افزونه",
                slug: 'delete-add-on',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف افزونه',
                modalTitle: 'حذف افزونه',
                modalContent: 'ایا از حذف این افزونه اطمینان دارید؟ با حذف این افزونه داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/addon/:_id',
            },
            {
                title: "غیر فعال کردن",
                slug: 'deactivate-add-on',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'غیر فعال کردن افزونه',
                modalTitle: 'غیر فعال سازی',
                modalContent: 'ایا از غیر فعال کردن این افزونه اطمینان دارید؟ با  غیر فعال کردن این افزونه در لیست افزونه ها در فرم خرید حساب نمایش داده نمیشود',
                modalType: "delete",
                modalPath: '/api/addon/deactivate/:_id',
            },
            {
                title: "فعال کردن",
                slug: 'activate-add-on',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'فعال کردن افزونه',
                modalTitle: ' فعال سازی',
                modalContent: 'ایا از فعال کردن این افزونه اطمینان دارید؟ با غیر فعال کردن، این افزونه در لیست افزونه ها در فرم خرید اکانت نمایش داده میشود',
                modalType: "put",
                modalPath: '/api/addon/activate/:_id',
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "عنوان",
            description: "عنوان افزونه باید حداکثر تا ۶ کلمه را شامل شده و منظور کلی آن را برساند",
            slug: 'title',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "توضیحات",
            description: "در این قسمت شرایط کامل افزونه را توضیح داده تا کاربر در انتخاب آن دچار اشتباه نشود.",
            slug: 'description',
            type: "textarea",
            placeholder: "",
            isRequired: true,
        },
    ],
    [

        {
            title: "نوع هزینه",
            description: "لطفا تعیین کنید هزینه ای که قرار است این افزونه داشته باشد از نوع درصدی از مبلغ اصلی است یا عدد ثابت دلاری",
            slug: 'type',
            type: "select",
            options: [
                {label: 'ثابت', value: 'constant'},
                {label: 'درصدی', value: 'percentage'},
            ],
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "هزینه",
            description: "هزینه این افزونه چقدر خواهد بود. در صورتی که هزینه ثابت است، آن را به دلار بیان کنید.",
            slug: 'amount',
            type: "number",
            placeholder: "",
            isRequired: true,
        },
    ]
]
