import {Field} from "../../../components/fouladyar/field/field";
import React, {useEffect, useState} from "react";
import {UpperHeader} from "../../../layout/header/upperHeader";
import AppWrap from "../../../layout/global/AppWrap";
import AppMain from "../../../layout/global/AppMain";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";


export const ResetPassword = () => {

    const location = useLocation();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [token, setToken] = useState('');
    const [form, setForm] = useState({
        password: "",
        repeatPassword: "",
    });
    const auth = useSelector((state) => state.auth);
    const resetPassword = async () => {
        try {
            if(!token)
                throw new Error('توکن مورد نظر نامعتبر است. لطفا مجدد از طریق صفحه فراموشی رمز عبور اقدام کنید.')

            if(form.password !== form.repeatPassword)
                throw new Error('پسورد های وارد شده در هر دو فیلد با هم برابر نیستند، لطفا مجدد تلاش کنید.')

            console.log("Reset Password", form);

            setIsSubmitting(true);
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/reset-password?token=${token}`, form);

            if (res.data.statusCode === 200 || res.data.statusCode === 201) {
                SuccessToaster({message: 'تغییر پسورد با موفقیت انجام شد'}, t)
                navigate(`/signin`);
            }
            setIsSubmitting(false);

        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false);
        }

    };
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tokenParam = queryParams.get('token');
        if (tokenParam) {
            setToken(tokenParam);
        }
    }, [location]);



    return (
        <AppMain>
            <AppWrap>
                <UpperHeader/>
                <div className="login-box">
                    <div className="nk-auth-body">
                        <h4 className="login-title">{"ایجاد پسورد جدید"}</h4>

                        <div className="login-under-title">
                            لطفا پسورد مورد نظر خود را در فیلد های زیر وارد کرده وسپس روی دکمه تغییر پسورد بزنید. لینک تغییر پسورد تا ۱۲ ساعت از زمان ایجاد معتبر خواهد بود
                        </div>
                        <div className="spacer-8"/>
                        <Field
                            className=" modal-fields "
                            label={"پسورد را وارد کنید"}
                            id={"password"}
                            name={"password"}
                            type={'password'}
                            value={form.password}
                            onChange={(e) => {
                                setForm({...form, password: e});
                            }}
                        />
                        <Field
                            className=" modal-fields "
                            label={"مجدد پسور را وارد کنید"}
                            id={"repeatPassword"}
                            name={"repeatPassword"}
                            type={'password'}
                            value={form.repeatPassword}
                            onChange={(e) => {
                                setForm({...form, repeatPassword: e});
                            }}
                        />
                        <div className="mt-5 form-submit-btn" style={{opacity: isSubmitting ? 0.5 : 1}}
                             onClick={async () => {
                                 await resetPassword()
                             }}>
                            تغییر پسورد
                        </div>
                        <div className="spacer"/>
                    </div>
                </div>
            </AppWrap>
        </AppMain>
    )
}