import React, {useEffect, useState} from "react";
import {MdOutlineDataObject,} from "react-icons/md";
import {LuCopy} from "react-icons/lu";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {ErrorToaster, SuccessToaster} from "../../../../../shared/toaster";
import {generateUniqueNumber} from "../../../../../shared/generateUniqueNumber";
import ExpandableReadMoreBox from "../../../../components/fxpilot/ExpandableReadMoreBox";
import {Field} from "../../../../../components/fouladyar/field/field";
import {copyToClipboard} from "../../../../../shared/copyToClipboard";
import {Divider} from "../signals";

export function CodeView({text}) {
    return (
        <div className="d-flex flex-column justify-content-between w-100" style={{direction: "ltr"}}>
            <div className="fxplt-signal-item-code-view">
                        <pre>
                           {text}
                        </pre>
            </div>
        </div>
    )
}

export function TemplateItem({index, item, isOpen, setIsOpen, onChange, onDelete}) {


    return (
        <div className="fxplt-template-item">
            <div className="d-flex flex-row justify-content-between w-100 align-center" style={{gap: "10px"}}>
                <div className="fxplt-template-app">
                    <div className="fxplt-template-img">
                        <MdOutlineDataObject size={34} color={"#38AF84"}/>
                    </div>
                </div>
                <h5 onClick={() => {
                    setIsOpen(isOpen?.map((itm, indx) => {
                        if (indx === index)
                            return !itm;

                        return itm
                    }))
                }}>{item.title}</h5>
                <div className="delete-lesson" onClick={() => {
                    onDelete()
                }}>
                    حذف قالب
                </div>
                <div className="referral-code" style={{width: "unset", maxWidth: "160px"}}>
                    <LuCopy className="copy-icon mr-3" size={18} color={"#fff"} onClick={() => {
                        copyToClipboard(item.code)
                    }}/>
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        id={"title"}
                        name={"title"}
                        type={'text'}
                        value={item.code}
                    />
                </div>
                {/*<button*/}
                {/*    style={{fontSize: "14px", justifyContent: 'center', padding: "12px 0px"}}*/}
                {/*    className="btn red-bg-color dana-font mb-4" type="button"*/}
                {/*    onClick={() => {*/}
                {/*        onDelete()*/}
                {/*    }}*/}
                {/*>*/}
                {/*    حذف قالب*/}
                {/*</button>*/}
            </div>

            <div className={`fxplt-template-content pb-4 ${!isOpen[index] ? 'hidden' : ''}`}>
                <Divider/>
                <Field
                    label={"عنوان پیام"}
                    id={"title"}
                    name={"title"}
                    type={'text'}
                    defaultValue={item.title}
                    onChange={() => {
                    }}
                    onBlur={(e) => {
                        onChange({
                            ...item,
                            title: e.target.value
                        })
                    }}
                />
                <Field
                    label={"متن پیام"}
                    id={"message"}
                    name={"message"}
                    type={'textarea'}
                    defaultValue={item.message}
                    onChange={() => {
                    }}
                    onBlur={(e) => {
                        onChange({
                            ...item,
                            message: e.target.value
                        })
                    }}
                />
            </div>
        </div>
    )
}

export function Templates({botId}) {


    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [tutorExpand, setTutorExpand] = useState(false);
    const [isOpen, setIsOpen] = useState([])
    const [signalId, setSignalId] = useState('');
    const [emptyTemplates, setEmptyTemplates] = useState([
        {
            _id: '',
            title: '',
            code: '',
            message: ``
        }
    ])
    const [templates, setTemplates] = useState([]);

    async function initializeData() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/signal/${botId}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setTemplates(res.data?.data?.templates)
                if (res.data?.data?.templates.length > 0) {
                    setIsOpen(res.data?.data?.templates.map(i => {
                        return false
                    }))
                }
                setSignalId(res.data?.data?._id)
            }

            setIsLoading(false)
        } catch (e) {
            // ErrorToaster(e, t)
            setIsLoading(false)
            if(e.response?.data?.statusCode === 404){
                setTemplates(emptyTemplates)
            }
        }
    }

    useEffect(() => {
        if (botId) {
            initializeData();
        }

    }, [botId]);

    useEffect(() => {
        console.log('templates', templates)
    }, [templates]);

    useEffect(() => {
        console.log('isOpen', isOpen)
    }, [isOpen]);


    async function updateTemplates(newTemplate) {
        try {
            setIsLoading(true)
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/signal/${signalId}/template`, {
                templates: newTemplate
            }, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (res.data.statusCode === 200) {
                SuccessToaster({message: 'بروزرسانی قالب ها با موفقیت انجام شد'}, t)
                // setTemplates(res.data.data.licenses.map(item => {
                //     return {
                //         ...item,
                //         expirationDate: (new Date(item.expirationDate)).toLocaleDateString()
                //     }
                // }))
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function addEmptyTemplate() {
        setTemplates([
            ...templates,
            {
                title: '',
                code: generateUniqueNumber(),
                message: ``
            }
        ])
        setIsOpen([
            ...isOpen,
            false
        ])


    }

    return (
        <div className="fxplt-template-container">

            <ExpandableReadMoreBox
                open={tutorExpand}
                setOpen={() => {
                    setTutorExpand(!tutorExpand)
                }}
                content={
                    <>
                        <h2 style={{fontWeight: "100"}}>آموزش ساخت قالب پیام</h2>
                        <p>ابتدا متن پیام خود را وارد فیلد متن پیام کنید و سپس در بخش هایی که میخواهید متغیر استفاده
                            کنید به این
                            شکل عمل کنید. به عنوان مثال برای اینکه متن زیر را دریافت کنید:</p>
                        <CodeView text={`$WIF(1.3$ )🚀`}/>
                        <p>و تمایل دارید تا به جای عدد ۱.۳ از متغیر استفاده کنید باید به شکل زیر عمل کنید. یعنی متغیر
                            خود را به
                            شکل {`{entry_price}`} قرار دهید.</p>
                        <CodeView text={`$WIF({entry_price}$ )🚀`}/>
                        <p>دقت کنید که دیتای دریافتی شما در سیگنال باید مقدار entry_price را در خود داشته باشد در غیر
                            این صورت
                            داده ای نمایش داده نخواهد شد. برای نمونه داده زیر شامل این متغیر میباشد.</p>
                        <CodeView text={`{
  id: '56732338‍',
  symbol: 'GBPUSD',
  entry_price: 1.3,
}
`}/>
                        <p>فقط دقت کنید که حتما در کد خود از <span className="highlight"> شناسه قالب </span>استفاده کنید
                            و غیر
                            این صورت پیامی دریافت نخواهید کرد.</p>
                        <Divider/>
                    </>
                }
            />

            {
                templates?.map((item, index) => {
                    return (
                        <TemplateItem
                            index={index}
                            item={item}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            onChange={(e) => {
                                setTemplates(templates.map((i, inx) => {
                                    if (index === inx)
                                        return e

                                    return i
                                }))
                            }}
                            onDelete={() => {
                                console.log('--------index---------', index)
                                // Create a copy of the array using slice or spread operator
                                const newTemplates = [...templates];
                                const newIsOpen = [...isOpen];

                                // Remove the element at the specified index
                                newIsOpen.splice(index, 1);
                                newTemplates.splice(index, 1);

                                setIsOpen(newIsOpen)
                                setTemplates(newTemplates)
                            }}
                        />
                    )
                })
            }

            <div className="saving-draft-btn" style={{padding: "5px 0px 40px 0px"}} onClick={async () => {
                await addEmptyTemplate()
            }}>
                + ایجاد قالب جدید
            </div>

            <button
                style={{fontSize: "14px", justifyContent: 'center', padding: "12px 0px"}}
                className="btn green-bg-color dana-font mb-4" type="button"
                onClick={async () => {
                    await updateTemplates(templates)
                }}
            >
                بروز رسانی
            </button>

        </div>
    );
}