import React, {useEffect, useState} from "react";
import Table from "../../../components/fouladyar/table";
import {useDispatch, useSelector} from "react-redux";
import {EmptyStateObject, filterStructure, TableActionsStructure, tableStatics, tableStructure} from "./index";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {IoAddOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import Tree from "../../../components/fouladyar/tree";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {AddNode} from "./component/addNode";
import {LoadingState} from "../../../components/fouladyar/loading-state/loadingState";
import {ConvertFilterObjectToUrlParam} from "../../../shared/convertFilterObjectToUrlParam";


const CategoryList = ({section, createRoutePath}) => {
    const auth = useSelector((state) => state.auth);
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [tree, setTree] = useState([]);
    const [activeTab, setActiveTab] = useState('tab1');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedNode, setSelectedNode] = useState('');
    const [modalComponent, setModalComponent] = useState(<div> empty </div>);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pagination, setPagination] = useState({
        itemPerPage: 7,
        currentPage: 1,
        totalItems: 0,
        lastUpdateBy: ''
    })

    const [filter, setFilter] = useState({})

    async function initializeTree() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories?section=${section || ''}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('data', res)

            if (!res) {
                ErrorToaster({message: 'شما مجوز دسترسی به این بخش را ندارید!'}, t)
            } else if (res.data.statusCode === 200 || !res.data.statusCode) {
                setTree(res.data.data.categories?.map(item => {
                    const date = new Date(item.createdAt);
                    const formattedDate = date.toLocaleDateString();
                    return {
                        ...item,
                        createdAt: formattedDate
                    }
                }))
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function initializeData() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories?section=${section || ''}&limit=${pagination.itemPerPage}&page=${pagination.currentPage}&${filter}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('data', res)

            if (!res) {
                ErrorToaster({message: 'شما مجوز دسترسی به این بخش را ندارید!'}, t)
            } else if (res.data.statusCode === 200 || !res.data.statusCode) {
                setData(res.data.data.categories?.map(item => {
                    const date = new Date(item.createdAt);
                    const formattedDate = date.toLocaleDateString();
                    return {
                        ...item,
                        createdAt: formattedDate
                    }
                }))
                setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
            } else {

            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function fetchData(updater) {
        try {
            setIsLoading(true)

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories?section=${section || ''}&limit=${pagination.itemPerPage}&page=${pagination.currentPage}&${Object.keys(filter).length === 0 ? '' : await ConvertFilterObjectToUrlParam(filter)}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            if (!res) {

            } else if (res.data.statusCode === 200) {
                console.log('newwwwwwwwwwwww', res.data.data.categories)
                setData(res.data.data.categories?.map(item => {
                    const date = new Date(item.createdAt);
                    const formattedDate = date.toLocaleDateString();
                    return {
                        ...item,
                        createdAt: formattedDate
                    }
                }))
                if (updater === 'filter')
                    setPagination({
                        ...pagination,
                        totalItems: res.data.data.totalItems,
                        currentPage: 1,
                        lastUpdateBy: updater
                    })
            } else {

            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        initializeData();
        initializeTree();
    }, []);


    useEffect(() => {
        if (pagination.lastUpdateBy === "pagination")
            fetchData('pagination');

    }, [pagination]);


    useEffect(() => {
        fetchData('filter');
    }, [filter]);


    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <div className="grid-header grid-header-margin-2">
                <div className="title-with-tab">
                    <h3>دسته بندی</h3>
                    <div className="tab-buttons">
                        <div className="tab-buttons-container">
                            <button onClick={() => handleTabClick('tab1')}
                                    className={activeTab === 'tab1' ? 'active' : ''}>جدول
                            </button>
                            <button onClick={() => handleTabClick('tab2')}
                                    className={activeTab === 'tab2' ? 'active' : ''}>درختی
                            </button>
                        </div>
                    </div>
                </div>
                <div className="grid-actions">
                    <button className="btn small-button grid-action-btn" type="button" onClick={() => {
                        navigate(createRoutePath)
                    }}>
                        <IoAddOutline size={17} color={"#49e7ad"}/>
                    </button>
                </div>
            </div>


            <div className="tab-content">
                {activeTab === 'tab1' &&
                    <div>

                        <Table
                            loading={isLoading}
                            filter={filter}
                            tableData={data}
                            tableActionsStructure={TableActionsStructure}
                            pagination={pagination}
                            tableHeading={tableStatics}
                            tableStructure={tableStructure}
                            filterStructure={filterStructure}
                            emptystate={EmptyStateObject}
                            onItemPerPageChange={(itemPerPage, currentPage) => {
                                setPagination({
                                    ...pagination,
                                    itemPerPage: itemPerPage,
                                    currentPage: currentPage,
                                    lastUpdateBy: 'pagination'
                                })
                            }}
                            onCurrentPageChange={(currentPage) => {
                                setPagination({...pagination, currentPage: currentPage, lastUpdateBy: 'pagination'})
                            }}
                            onFilterSubmit={(e) => {
                                setFilter(e);
                            }}
                            onDeleteComplete={async (e) => {
                                await fetchData('filter');
                            }}
                        />

                    </div>
                }


                {activeTab === 'tab2' &&
                    <div>
                        {
                            isLoading ?
                                <div className="pt-5 pb-5 d-flex justify-center align-center">
                                    <LoadingState/>
                                </div> :
                                <Tree data={tree} onAddNode={({node, isEdit}) => {
                                    console.log('parentId', node._id)
                                    setModalComponent(
                                        <AddNode
                                            node={node}
                                            isEditing={isEdit}
                                            setIsModalClose={() => {
                                                setIsModalOpen(false)
                                            }}
                                            onComplete={async () => {
                                                setIsModalOpen(false)
                                                await initializeTree();
                                            }}
                                        />
                                    )
                                    setIsModalOpen(true);
                                }}/>
                        }
                    </div>
                }

            </div>


            {/*            <div >
                <Field
                    label={"دسته بندی"}
                    id={"type"}
                    name={"type"}
                    type={'tree'}
                    treeMode={'category'}
                    treeSection={'post'}
                    value={selectedNode.name}
                    onChange={(e) => {
                        setSelectedNode(e)
                    }}
                />

                <div >{selectedNode._id || '-----'} | {selectedNode.name}</div>
            </div>*/}

        </React.Fragment>
    );
};

export default CategoryList;
