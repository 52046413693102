import React, { useState } from 'react';
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import {GoCheckCircleFill} from "react-icons/go";

// Step Component
const Step = ({ name, index, isActive, isCompleted, onClick }) => {
    return (
        <div
            className={`step ${isActive ? 'active' : ''}`}
            onClick={() => onClick(index)}
        >
            {
                isCompleted ?
                    <GoCheckCircleFill size={35} color={"#38AF84"}/> :
                    <div className="step-index">{toFarsiNumber(index + 1)}</div>
            }

            <div className="step-name">{name}</div>
        </div>
    );
};

// Wizard Component
export default function Wizard ({ steps, currentStep, setCurrentStep }) {
    const [stepData, setStepData] = useState(steps);

    const StepComponent = steps[currentStep].component; // Get the component reference
    const stepProps = steps[currentStep].getData();


    const handleStepClick = (index) => {
        setCurrentStep(index);
    };

    const handleValueChange = (index, value) => {
        const updatedSteps = [...stepData];
        updatedSteps[index].value = value;
        setStepData(updatedSteps);
    };

    const markCompleted = (index) => {
        const updatedSteps = [...stepData];
        updatedSteps[index].status = 'completed';
        setStepData(updatedSteps);
    };

    return (
        <div className="wizard">
            {/* Wizard Steps Navigation */}
            <div className="wizard-header">
                {stepData.map((step, index) => {
                    return(
                        <>
                            <Step
                                key={index}
                                name={step.name}
                                index={index}
                                isActive={index === currentStep}
                                isCompleted={index < currentStep}
                                onClick={handleStepClick}
                            />
                            {
                                index < stepData.length -1 ?
                                    <div className="step-line"/> : ''
                            }
                        </>
                    )
                })}
            </div>

            {/* Wizard Step Content */}
            <div className="wizard-content">
                <div className="step-content">
                    {/*{stepData.map((step, index) => {
                        return(

                        )
                    })}*/}
                    <div>
                       {/* {
                            step.component
                        }*/}
                        <StepComponent {...stepProps} />
                    </div>
                </div>
            </div>
        </div>
    );
};
