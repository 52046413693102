import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {LuCopy} from "react-icons/lu";
import {useNavigate} from "react-router-dom";
import {IoAddOutline} from "react-icons/io5";
import {Field} from "../../../components/fouladyar/field/field";
import Table, {replacePlaceholders} from "../../../components/fouladyar/table";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {YesOrNoModal} from "../../../components/fouladyar/modal-helper/pages/yesOrNo";
import toast from "react-hot-toast";
import {MdOutlineAccountBalance} from "react-icons/md";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import {MoneySeparator} from "../../../shared/moneySeparator";
import {ConvertGregorianToJalali} from "../../../shared/convertGregorianToJalali";


const Wallet = () => {

    const {t, i18n} = useTranslation();
    const auth = useSelector((state) => state.auth);
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [data, setData] = useState([
    ]);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBotId, setSelectedBotId] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [balance, setBalance] = useState(0);
    const [pagination, setPagination] = useState({
        itemPerPage: 7, currentPage: 1, totalItems: 0, lastUpdateBy: ''
    })

    const [modalProps, setModalProps] = useState({
        modalCancelText: "",
        modalSubmitText: "",
        modalTitle: "",
        modalContent: "",
        modalItemTarget: "",
        modalType: "",
        modalPath: ""
    });

    const [filter, setFilter] = useState({})


    async function loadTransactions() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/transactions`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('loadTransactions', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setTransactions(res.data.data.transactions)

            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }

    async function getWalletBalance() {
        try {

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/wallet`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('getWalletBalance', res)


            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setBalance(toFarsiNumber(MoneySeparator(parseInt(res.data.data.balance))));
            }


        } catch (e) {
            ErrorToaster(e, t)

        }
    }


    useEffect(() => {
        loadTransactions();
        getWalletBalance();
    }, []);



    useEffect(() => {
        if (pagination.lastUpdateBy === "pagination") fetchData('pagination');

    }, [pagination]);

    useEffect(() => {
        // fetchData('filter');
    }, [filter]);


    function TableItem({cost, title, createdAt, type}){
        return(
            <tr>
                <td className={`${type === 'debit' ? 'red-color' : (type === 'credit' ? 'green-color' : '')}`}>{cost} تومان</td>
                <td>{title || "-"}</td>
                <td>{createdAt}</td>
                <td>{t(type)}</td>
            </tr>
        )
    }


    return (<React.Fragment>
        <ModalHelper
            open={isModalOpen}
            onOpen={() => setIsModalOpen(true)}
            onClose={() => setIsModalOpen(false)}
            component={modalComponent}
        />


        <div className="grid">
            <div className="grid-header license-header">
                <div className="grid-header-image">
                    <MdOutlineAccountBalance color={"#38AF84"} size={30}/>
                </div>

                <div className="d-flex flex-column flex-grow-1 bot-selector">
                    <h3>کیف پول</h3>
                </div>
            </div>
            <div className="grid-kyc-container">



                <div className="fxplt-balance-container">
                    <h3 className="balance">{toFarsiNumber(MoneySeparator(balance))} تومان</h3>
                    <h3 className="desc">موجودی کیف پول</h3>
                    <button className="btn fxplt-green-button w-100" type="button" onClick={() => {
                        navigate(`${process.env.FXPILOT_BASE_URL}/recharge`)
                    }}>
                        شارژ کیف پول
                    </button>
                </div>






                <div className="fxplt-transactions">
                    <h2>تراکنش های اخیر</h2>
                    <div className="fxplt-simple-table">
                        <table>
                            <thead>
                            <tr>
                                <th>مبلغ تراکنش</th>
                                <th>وضعیت</th>
                                <th>تاریخ ایجاد</th>
                                <th>نوع تراکنش</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                transactions?.map(transaction => {
                                    return(<TableItem
                                        title={t(transaction?.usage?.title)}
                                        cost={toFarsiNumber(MoneySeparator(transaction?.amount || 0))}
                                        createdAt={toFarsiNumber(ConvertGregorianToJalali(transaction?.createdAt, true))}
                                        type={transaction?.type}
                                    />)
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>





            </div>
        </div>


    </React.Fragment>);
};

export default Wallet;

