import {Line} from "react-chartjs-2";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import annotationPlugin from "chartjs-plugin-annotation";
import {
    CategoryScale,
    BarElement,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip,
    BarController
} from "chart.js";


ChartJS.register(BarElement, LineElement,BarController, CategoryScale, annotationPlugin, LinearScale, PointElement, Tooltip, Legend);

export default function TradesByHoursLineChartComponent({trades}) {

    function countTradesByHour() {
        // Step 1: Initialize the object with all hours from 0 to 23, each set to 0
        const tradesByHour = {};
        for (let hour = 0; hour < 24; hour++) {
            tradesByHour[hour] = 0;
        }

        // Step 2: Iterate through the trades and count them by hour
        trades.forEach(trade => {
            const hour = new Date(trade.exitTime).getUTCHours();
            tradesByHour[hour]++;
        });

        return {tradesByHour};
    }


    const {tradesByHour} = countTradesByHour();


    const data = {
        labels: Object.keys(tradesByHour),
        datasets: [
            {
                type: 'bar', // Bar dataset
                label: 'تعداد معاملات',
                data: Object.values(tradesByHour),
                backgroundColor: '#FFA268', // Red color
                stack: 'Stack 0',
                barThickness: 4, // Adjust bar thickness
            },
        ],
    };


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Hide vertical grid lines
                },
                title: {
                    stacked: true,
                }
            },
            y: {
                title: {
                    stacked: true,
                }
            },
        },
    };


    return (
        <table border="1" cellPadding="10" className="w-100 mb-3">
            <thead>
            <tr>
                <th className="d-flex flex-row justify-content-between align-center">

                    <span>تعداد معاملات (براساس ساعت)</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <div style={{width: '100%', height: '290px'}}>
                    <Line data={data} options={options}/>
                </div>
            </tr>

            </tbody>
        </table>
    )

}
