import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FailedPaymentImage from "../../../assets/images/mrpropfund/failed-payment.png";
import PaymentStatusBox from "./paymentStatusBox";
import axios from "axios";
import {ErrorToaster} from "../../../shared/toaster";
import {ConvertGregorianToJalali} from "../../../shared/convertGregorianToJalali";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";

const PaymentFailed = () => {
    const auth = useSelector((state) => state.auth);
    const {t, i18n} = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);


    async function initializeData() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/single/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('data', res.data)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setData(res.data.data)
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }



    useEffect(() => {
        initializeData();
    }, []);

    return (
        <PaymentStatusBox
            image={FailedPaymentImage}
            type={'failed'}
            amount={toFarsiNumber(data?.invoice.amount) || 0}
            currency={'USDT'}
            date={toFarsiNumber(ConvertGregorianToJalali(data?.invoice.createdAt, true)) || 0}
            fee={'-'}
            total={0}
        />
    );
};

export default PaymentFailed;
