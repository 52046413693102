export const tableStatics = {
    title: "کاربران",
    description: "",
    addNewItemButtonLink: '/user-add'
}
export const formStatics = {
    title: "ایجاد کاربر",
    editTitle: "ویرایش کاربر",
    description: "از اینجا میتوانید اقدام به ایجاد کاربر کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش کاربر کنید",
    submitText: "ایجاد کاربر",
    editSubmitText: "ویرایش کاربر",
}


export const EmptyStateObject = {
    title: "کاربر فعال یافت نشد",
    content: "از این بخش میتوانید به اقدام به ایجاد یا ویرایش کاربر ها کنید.",
    buttonText: "ایجاد کاربر جدید",
    buttonOnClickUrl: "/user-add"
}

import userImage from "../../../assets/images/mrpropfund/user-background.png";

export const TableActionsStructure = [
    {
        title: "ایجاد ادمین",
        description: "با استفاده از این بخش کاربران ادمین خود را اضافه کرده و سطوح دسترسی مورد نیاز خود را برای هر یک از کارکنان تعیین کنید. ",
        image: userImage,
        link: '/user-add'
    }
]


export const filterStructure = [
    {
        title: "نام مشتری",
        slug: 'fullName',
        type: "text",
    },
    {
        title: "تلفن",
        slug: 'mobile',
        type: "text",
    },
    {
        title: "ایمیل",
        slug: 'email',
        type: "text",
    },
    {
        title: "تاریخ عضویت",
        slug: 'createdAt',
        disabled: true,
        type: "date",
    },
]

export const tableStructure = [
    {
        title: "نام مشتری",
        slug: 'fullName',
    },
    {
        title: "تلفن",
        slug: 'mobile',
        useFarsiNumber: true
    },
    {
        title: "ایمیل",
        slug: 'email',
    },
    {
        title: "تاریخ عضویت",
        slug: 'createdAt',
        useFarsiNumber: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "ویرایش کاربر",
                slug: "edit-user",
                useRoute: true,
                route: "/app/ecommerce/customer-edit/:_id"
            },
            {
                title: "حذف کاربر",
                slug: 'delete-user',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف کاربر',
                modalTitle: 'حذف کاربر',
                modalContent: 'ایا از حذف این کاربر اطمینان دارید؟ با حذف این کاربر داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/user/:_id',
            },
        ]
    },
]

export const formStructure = [

    [
        {
            title: "نام نام خانوادگی",
            description: "نام نام خانوادگی کاربر ادمین را در این بخش وارد کنید",
            slug: 'fullName',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "کد ملی",
            description: "کد ملی کاربر ادمین مورد نظر را وارد ",
            slug: 'nationalCode',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "ایمیل",
            description: "ایمیل کاربر ادمین مورد نظر را وارد. دقت کنید پس از ایجاد، کاربر با استفاده از ایمیل خود قادر به ورود به سمیستم خواهد بود",
            slug: 'email',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "شماره تماس",
            description: "شماره تماس کاربر ادمین را وارد کنید.",
            slug: 'mobile',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "پسورد",
            description: "لطفا رمز عبور این حساب را وارد کنید. دقت کنید که بازیابی رمز عبور امکان پذیر نیست. لذا پسورد انتخابی خود را در جایی یادداشت کنید. ",
            slug: 'password',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],
    [
        {
            title: "تکرار پسورد",
            description: "مجددا پسورد انتخابی خود را وارد کنید",
            slug: 'repeatPassword',
            type: "text",
            placeholder: "",
            isRequired: true,
        },
    ],

]
