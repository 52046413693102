import {IoMdClose} from "react-icons/io";
import {Field} from "../../../components/fouladyar/field/field";
import React, {useState} from "react";
import {UpperHeader} from "../../../layout/header/upperHeader";
import AppWrap from "../../../layout/global/AppWrap";
import AppMain from "../../../layout/global/AppMain";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setToken} from "../../../redux/store/services/auth/store";
import {setProfile} from "../../../redux/store/services/profile/store";
import {ErrorToaster} from "../../../shared/toaster";
import Cookies from 'js-cookie';
import {VscEye, VscEyeClosed} from "react-icons/vsc";
import {Footer} from "../../components/fxpilot/footer";

export const CustomLogin = () => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [form, setForm] = useState({
        username: "",
        password: ""
    });

    const handleSetCookie = (token) => {
        Cookies.set('token', token, { expires: 30 }); // Expires in 30 days

    };
    const auth = useSelector((state) => state.auth);
    const onFormSubmit = async () => {
        try {

            console.log("submit", form);
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/login`, {
                "email": form.username,
                "password": form.password
            });

            if (res.data.statusCode === 200 || res.data.statusCode === 201) {
                dispatch(setToken(res.data.data.accessToken));
                handleSetCookie(res.data.data.accessToken);
                dispatch(setProfile({
                    _id: res.data.data.user._id,
                    email: res.data.data.user.email, //res.data.user.email,
                    userNumber: res.data.data.user.fullName,
                    username: res.data.data.user.username,
                    avatar: res.data.data.user.avatar,
                    roles: res.data.data.user.roles
                }))

                navigate(`/${process.env.PUBLIC_URL === 'fxpilot' ? 'home' : (process.env.PUBLIC_URL === 'mrpropfund' ? 'dashboard' :'')}`);

            }

        } catch (e) {
            ErrorToaster(e, t)
        }

    };


    const [showPassword, setShowPassword] = useState(false)

    return(
        <AppMain>
            <AppWrap>
                <UpperHeader/>
                <div className="login-box">
                    <div className="nk-auth-body">
                        <h4 className="login-title" >{"ورود  |  ثبت نام"}</h4>
                        <div className="login-under-title">
                            برای دسترسی به اطلاعات، حساب ها و معاملات خود ابتدا باید وارد حساب کاربری خود شوید. لطفاً نام کاربری و رمز عبور خود را وارد کنید تا به امکانات پنل دسترسی داشته باشید.
                        </div>
                        <div className="spacer-8"/>
                        <Field
                            className=" modal-fields "
                            label={"ایمیل"}
                            id={"username"}
                            name={"username"}
                            type={'text'}
                            value={form.username}
                            onChange={(e) => {
                                setForm({...form, username: e});
                            }}
                        />
                        <div style={{position: 'relative'}}>
                            <Field
                                className="modal-fields"
                                label={"رمز عبور"}
                                id={"password"}
                                name={"password"}
                                type={showPassword ? 'text' : 'password'}
                                value={form.password}
                                onChange={(e) => {
                                    setForm({...form, password: e});
                                }}
                            />
                            <div className="password-eye">
                                {
                                    showPassword ?
                                        <VscEyeClosed size={18} color={"#fff"} onClick={()=>{setShowPassword(false)}}/> :
                                        <VscEye  size={18} color={"#fff"} onClick={()=>{setShowPassword(true)}}/>
                                }
                            </div>
                        </div>
                        <div className="mt-5 form-submit-btn" style={{opacity: isSubmitting ? 0.5 : 1}} onClick={async () => {
                            await onFormSubmit()
                        }}>
                            ورود
                        </div>
                        <div className="register-text">
                            عضو جدید هستید؟
                            <div className="create-account" onClick={() => {
                                navigate('/register')
                            }}>ایجاد حساب </div>
                            |
                            <div className="create-account" onClick={() => {
                                navigate('/forgot-password')
                            }}>فراموشی رمز عبور </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </AppWrap>
        </AppMain>
    )
}