import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {DropdownFileUploader} from "../DropdownFileUploader/DropdownFileUploader";
import MultiDatePicker from "react-multi-date-picker";
import fa from "react-date-object/locales/persian_fa";
import en from "react-date-object/locales/gregorian_en";
import jalali from "react-date-object/calendars/jalali";
import gregorian from "react-date-object/calendars/gregorian";
// import TimePicker from "react-multi-date-picker/plugins/time_picker";
import TimePicker from 'react-awesome-time-picker';
import 'react-awesome-time-picker/assets/index.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {SelectableTreeModal} from "../tree/modal/selectableTreeModal";
import ModalHelper from "../modal-helper/modalHelper"; // Import the Quill stylesheet

export const Field = ({
      id,
      ref,
      key,
      name,
      type,
      label,
      value,
      // file-upload
      fileUploadType,
      fileUploadAcceptedFiles,
      formControlClassName,
      multiple,
      accept,
      format,
      sources,
      disabled,
      isRequired,
      isJalali,
      defaultValue,
      options,
      treeMode,
      treeSection,
      className,
      errors,
      validation,
      showSuccessValidation,
      placeholder,
      labelClassName,
      onChange,
      onBlur,
      onFocus,
      ...props
  }) => {
    const {t, i18n} = useTranslation();
    const [modalComponent, setModalComponent] = useState(<div> empty </div>);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState('');

    let invalid = {
        color: "#e85347",
        fontSize: "11px",
        fontStyle: "italic"
    }


    function FieldLabel({className}) {
        return (
            <>
                {
                    !label ? '' :
                        <div className={`form-label-group ${labelClassName} ${className}`}>
                            <label style={{opacity: `${disabled ? '0.5' : "1"}`}}>
                                {label} <span className="text-danger">{isRequired ? "*" : ''}</span>
                            </label>
                        </div>
                }
            </>
        )
    }


    if (type === "number") {
        return (
            <div className="form-group w-100 mb-0">
                <FieldLabel/>
                <div className="form-control-group">
                    <input
                        key={key}
                        type={"text"}
                        pattern="[0-9]*"
                        placeholder={placeholder}
                        className={`form-control form-control-lg ${className}`}
                        ref={ref}
                        disabled={disabled}
                        name={name}
                        // defaultValue={value}
                        value={value}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            onChange(e.target.value ? parseFloat(e.target.value) : '')
                        }}
                    />

                    {
                        validation ? (!validation.isValid ? (<span
                            className="invalid">{t(validation.message)}</span>) : (showSuccessValidation === true ?
                            <span className="valid">{t(validation.message)}</span> : '')) : ''
                    }
                </div>
            </div>
        )
    }

    if (type === "text" || type === "password") {
        return (
            <div className="form-group w-100 mb-0">
                <FieldLabel/>
                <div
                    className={`form-control-group form-group ${formControlClassName}`}>
                    <input
                        key={key}
                        type={type}
                        className={`form-control form-control-lg ${className}`}
                        ref={ref}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value)
                        }}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        // required={isRequired}
                    />
                    {
                        validation ? (!validation.isValid ? (<span
                            className="invalid">{t(validation.message)}</span>) : (showSuccessValidation === true ?
                            <span className="text-success">{t(validation.message)}</span> : '')) : ''
                    }
                </div>
            </div>
        )
    }

    if (type === 'checkbox') {
        const handleChange = () => {
            // setChecked(!checked);
        };
        return (
            <>
                <div className={`form-group ${className}`}>

                    <label className="custom-checkbox">
                        <input
                            key={name}
                            type="checkbox"
                            checked={value}
                            value={value}
                            disabled={disabled}
                            onChange={(e) => {
                                onChange(e.target.checked)
                            }}
                            id={id}
                            name={name}
                        />
                        <span className="checkbox-mark"></span>
                        <FieldLabel/>
                    </label>
                </div>
            </>

        )
    }


    if (type === 'tree') {

        return (
            <>

                <ModalHelper
                    open={isModalOpen}
                    onOpen={() => setIsModalOpen(true)}
                    onClose={() => setIsModalOpen(false)}
                    component={modalComponent}
                />
                {
                    treeMode === "category" ?

                        <div className="form-group w-100 mb-0">
                            <FieldLabel/>
                            <div className={`form-control-group form-group ${formControlClassName}`}>
                                <input
                                    style={{width: "100%", borderRadius: "7px"}}
                                    key={name}
                                    type="text"
                                    checked={value}
                                    value={value?.name}
                                    onClick={()=>{
                                        console.log('tree clicked!')
                                        setModalComponent(
                                            <SelectableTreeModal
                                                setIsModalClose={() => {
                                                    setIsModalOpen(false)
                                                }}
                                                section={treeSection}
                                                onSelectNode={async (e)=>{
                                                    onChange(e)
                                                    setIsModalOpen(false)
                                                }}
                                            />
                                        )
                                        setIsModalOpen(true);
                                    }}
                                    id={id}
                                    name={name}
                                />
                            </div>
                        </div>: ''
                }
            </>

        )
    }

    if (type === 'time') {
        let time = value === '' || value === null ? null : new Date(value);
        return (
            <div className="form-group w-100 mb-0">
                <FieldLabel/>
                <div className="form-control-group">

                    <TimePicker
                        className="form-group custom-time-picker"
                        onChange={(e) => {

                            console.log('TimePickerTimePicker', e)
                            onChange(e)
                        }}
                        value={value}
                        placeholder={placeholder}
                        format={format || "HH:mm:ss"}
                        disabled={disabled}
                        showHour={false}
                    />


                </div>

            </div>
        )
    }
    if (type === 'editor') {
        // Full toolbar configuration
        const modules = {
            toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }], // Headers and fonts
                [{ list: 'ordered' }, { list: 'bullet' }], // Ordered and bullet lists
                ['bold', 'italic', 'underline', 'strike'], // Text formatting
                [{ color: [] }, { background: [] }], // Font color and background color
                [{ script: 'sub' }, { script: 'super' }], // Subscript / Superscript
                [{ align: [] }], // Text alignments
                ['blockquote', 'code-block'], // Blockquote and code block
                ['link', 'image', 'video'], // Links, images, and videos
                [{ indent: '-1' }, { indent: '+1' }], // Indentations
                [{ direction: 'rtl' }], // Text direction
                ['clean'], // Remove formatting button
            ],
        };
        const quillRef = useRef(null);
        const handleBlur = (range, source) => {
            // Access the editor's current value
            const editorContent = quillRef.current?.getEditor().getText() || value;
            onBlur({target: {
                value: editorContent
            }})
        };
        useEffect(() => {
            // Access the Quill editor instance and set default formats
            const quill = quillRef.current.getEditor();
            // quill.format("direction", "rtl"); // Set default direction to RTL
            quill.format("align", "right"); // Set default alignment to right
        }, []);

        return (
            <div className="form-group w-100 mb-0 ">
                <FieldLabel/>
                <div className="form-control-group">

                    <ReactQuill
                        ref={quillRef} // Attach the ref to ReactQuill
                        value={value}
                        modules={modules}
                        onChange={onChange}
                        onBlur={handleBlur}
                        placeholder={placeholder}
                    />

                </div>

            </div>
        )
    }

    if (type === 'date') {

        let date = value === '' || value === null ? null : new Date(value);


        return (
            <div className={`form-group w-100 mb-0`}>
                <FieldLabel/>
                <div className="form-control-group">

                    <MultiDatePicker
                        placeholder={placeholder}
                        className={`form-control form-control-lg ${className}`}
                        value={date}
                        locale={isJalali ? fa : en} ie
                        calendar={isJalali ? jalali : gregorian}
                        disabled={disabled}
                        name={name}
                        id={id}
                        format={format}
                        onClose={onBlur}
                        onChange={event => {
                            if (!event)
                                onChange('')
                            else
                                onChange(new Date(event?.toDate?.().toString()));
                        }}
                    />
                    {
                        validation ? (!validation.isValid ? (<span
                            className="invalid">{t(validation.message)}</span>) : (showSuccessValidation === true ?
                            <span className="text-success">{t(validation.message)}</span> : '')) : ''
                    }
                </div>

            </div>
        )
    }

    if (type === "image-preview") {
        if (!value)
            return null

        return (
            <>
                <div className="card">
                    <FieldLabel className={"label"}/>
                    <img src={value} alt={""}/>
                </div>
            </>
        )
    }

    if (type === "file-upload") {

        return (
            <div className="form-group">
                <FieldLabel/>
                <DropdownFileUploader
                    value={value}
                    className={className}
                    multiple={multiple}
                    fileUploadAcceptedFiles={fileUploadAcceptedFiles}
                    fileUploadType={fileUploadType}
                    onChange={onChange}
                />
            </div>
        )
    }


    if (type === "radiobox") {
        return (
            <div className="form-group">
                <FieldLabel/>
                <ul className="custom-control-group g-3 align-center flex-wrap"

                >
                    {
                        options.map(item => {
                            return (
                                <li>
                                    <div className="custom-control custom-radio"
                                         onClick={(event) => {
                                             onChange(item.value)
                                         }}>
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            checked={value === item.value}
                                            name={name}
                                            value={item.value}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={name}
                                        >
                                            {item.label}
                                        </label>
                                    </div>
                                </li>
                            )
                        })
                    }
                    {/*<li>*/}
                    {/*    <div className="custom-control custom-radio">*/}
                    {/*        <input*/}
                    {/*          type="radio"*/}
                    {/*          className="custom-control-input"*/}
                    {/*          defaultChecked*/}
                    {/*          name="reg-public"*/}
                    {/*          id="reg-enable"*/}
                    {/*        />*/}
                    {/*        <label className="custom-control-label" htmlFor="reg-enable">*/}
                    {/*            Enable*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <div className="custom-control custom-radio">*/}
                    {/*        <input*/}
                    {/*          type="radio"*/}
                    {/*          className="custom-control-input"*/}
                    {/*          name="reg-public"*/}
                    {/*          id="reg-disable"*/}
                    {/*        />*/}
                    {/*        <label className="custom-control-label" htmlFor="reg-disable">*/}
                    {/*            Disable*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <div className="custom-control custom-radio">*/}
                    {/*        <input*/}
                    {/*          type="radio"*/}
                    {/*          className="custom-control-input"*/}
                    {/*          name="reg-public"*/}
                    {/*          id="reg-request"*/}
                    {/*        />*/}
                    {/*        <label className="custom-control-label" htmlFor="reg-request">*/}
                    {/*            On Request*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                </ul>
            </div>
        )
    }

    if (type === "textarea") {
        return (
            <div className="form-group w-100 mb-0">
                <FieldLabel/>
                <div className="form-control-group">
                    <textarea
                        key={key}
                        className="form-control form-control-lg no-resize ex-large"
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        value={value}
                        ref={ref}
                        disabled={disabled}
                        id={id}
                        name={name}
                        onChange={(e) => {
                            onChange(e.target.value)
                        }}
                        onBlur={onBlur}
                    ></textarea>
                    {
                        validation ? (!validation.isValid ? (<span
                            className="invalid">{t(validation.message)}</span>) : (showSuccessValidation === true ?
                            <span className="text-success">{t(validation.message)}</span> : '')) : ''
                    }
                </div>
            </div>

        )
    }

    if (type === 'select') {

        const selectOptions = options.map(item => {
            return {value: item.value, label: t(item.label)}
        });
        return (
            <div className="form-group w-100 mb-0">
                <FieldLabel/>
                <div
                    className={`form-control-group form-group ${formControlClassName}`}
                >
                    <div className={`form-control-select ${className}`}>
                        <Select
                            key={name}
                            id={id}
                            name={name}
                            isDisabled={disabled}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            // defaultValue={(selectOptions.filter(item => item.value === value))[0]}
                            value={(selectOptions.find(item => item.value === value))}
                            options={selectOptions}
                            onChange={(e) => {
                                onChange(e.value)
                            }}
                            placeholder={placeholder}
                        />
                    </div>
                    {
                        validation ? (!validation.isValid ? (<span style={invalid}
                                                                   className="invalid">{t(validation.message)}</span>) : (showSuccessValidation === true ?
                            <span className="text-success">{t(validation.message)}</span> : '')) : ''
                    }
                </div>
            </div>
        )
    }

};
