import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {useTranslation} from "react-i18next";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Button, Col, Row} from "reactstrap";
import {Field} from "../../../components/fouladyar/field/field";
import {IoAddOutline, IoReorderTwoOutline} from "react-icons/io5";
import axios from "axios";
import {ErrorToaster} from "../../../shared/toaster";
import {EmptyState} from "../../../components/fouladyar/empty-state/emptyState";
import {HiMiniArrowsUpDown} from "react-icons/hi2";
import {AddPostToSeriesModal} from "./components/AddPostToSeriesModal";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";


/*// Sample post data
const initialPosts = [
    {order: '1', title: 'Post 1', _id: '4873475099996734'},
    {order: '2', title: 'Post 2', _id: '4873475099996734'},
    {order: '3', title: 'Post 3', _id: '4873475099996734'},
    {order: '4', title: 'Post 4', _id: '4873475099996734'},
];*/


const PostSeriesAdd = () => {
    const auth = useSelector((state) => state.auth);

    const {id} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const isEditing = location.pathname.includes("post-series-edit");
    const [modalComponent, setModalComponent] = useState(<div> empty </div>);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [data, setData] = useState({
        title: '',
        slug: '',
        description: ''
    });
    const [ids, setIds] = useState([]);

    // Function to handle the drag end event
    const onDragEnd = (result) => {
        console.log('result', result)
        const {source, destination} = result;
        // If the destination is null (dragged outside), or the post wasn't moved, do nothing
        if (!destination || source.index === destination.index) {
            return;
        }

        // Reorder the posts array and update the state
        const reorderedPosts = reorder(ids, source.index, destination.index);
        console.log(reorderedPosts)
        setIds(reorderedPosts);
    };

    const reorder = (list, startIndex, endIndex) => {
        // Create a shallow copy of the list
        const result = Array.from(list);

        // Remove the item from the startIndex
        const [removed] = result.splice(startIndex, 1);

        // Insert the removed item at the endIndex
        result.splice(endIndex, 0, removed);

        // Update the order field for each item based on its new index
        return result.map((item, index) => ({
            ...item,
            order: (index + 1).toString()  // Update 'order' as a string
        }));
    };

    async function loadData() {
        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/series/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('data', res.data)

            if (res.data.statusCode === 200) {
                setData(res.data.data)
                setIds(res.data.data?.ids)
            }
            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }


    useEffect(() => {
        if (isEditing)
            loadData();
    }, []);

    useEffect(() => {
        console.log('ids changes', ids)
    }, [ids]);


    async function submit() {
        try {
            setIsSubmitting(true)

            let res;


            if (isEditing)
                res = await axios.put(`${process.env.REACT_APP_API_URL}/api/series/${id}`, {
                    ...data,
                    ids: ids
                }, {
                    headers: {authorization: `Bearer ${auth.token}`}
                })
            else
                res = await axios.post(`${process.env.REACT_APP_API_URL}/api/series`, {
                    ...data,
                    ids: ids
                }, {
                    headers: {authorization: `Bearer ${auth.token}`}
                })

            console.log('series', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                navigate('/app/website/post-series-list')

                setIsSubmitting(false)
            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }


    function onRemoveItem(order) {
        setIds(prevState => {
            return prevState.filter(i => i.order !== order)
        })

        setIds(prevState => {
            return prevState.map((i, indx) => {
                return{...i, order: (indx + 1).toString()}
            })
        })
    }

    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <div className="nk-content ">
                <Row className={`field-item-container`}>
                    <Col className={`field-item `}>

                        <div className="field-item-title-and-desc">
                            <div className="field-item-title">
                                عنوان
                            </div>
                            <div className="field-item-description">
                                لطفا عنوان سری مقالات خود را در این فیلد وارد کنید
                            </div>
                        </div>
                        <div className='form-field-input'>
                            <Field
                                className="w-100"
                                id={"title"}
                                name={"title"}
                                type={'text'}
                                value={data?.title}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        title: e,
                                    })
                                }}
                            />
                        </div>

                    </Col>
                </Row>
                <Row className={`field-item-container`}>
                    <Col className={`field-item `}>

                        <div className="field-item-title-and-desc">
                            <div className="field-item-title">
                                شناسه
                            </div>
                            <div className="field-item-description">
                                شناسه سری مقالات خود را وارد کنید. لطفا تنها از حروف انگلیسی برای این مورد استفاده کنید
                            </div>
                        </div>
                        <div className='form-field-input'>
                            <Field
                                className="w-100"
                                id={"slug"}
                                name={"slug"}
                                type={'text'}
                                value={data?.slug}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        slug: e,
                                    })
                                }}
                            />
                        </div>

                    </Col>
                </Row>
                <Row className={`field-item-container`}>
                    <Col className={`field-item `}>

                        <div className="field-item-title-and-desc">
                            <div className="field-item-title">
                                توضیحات
                            </div>
                            <div className="field-item-description">
                                در صورتی که نیاز به بیان توضیحات برای این سری هستید در این بخش وارد کنید
                            </div>
                        </div>
                        <div className='form-field-input'>
                            <Field
                                className="w-100"
                                id={"description"}
                                name={"description"}
                                type={'textarea'}
                                value={data?.description}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        description: e,
                                    })
                                }}
                            />
                        </div>

                    </Col>
                </Row>
                <Row className={`field-item-container`}>
                    <Col className={`field-item flex-column`}>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="field-item-title-and-desc">
                                <div className="field-item-title">
                                    لیست مقالات
                                </div>
                                <div className="field-item-description">
                                    در صورتی که لیست اولویت بندی مقالات شما متفاوت است لطف با کشیدن و رها کردن آیتم مورد نظر در جای مناسب لیست سری خود را مرتب کنید
                                </div>
                            </div>
                            <div>

                                <button className="btn small-button grid-action-btn" type="button" onClick={() => {
                                    setModalComponent(
                                        <AddPostToSeriesModal
                                            isEditing={false}
                                            items={ids}
                                            onClose={() => {setIsModalOpen(false)}}
                                            onAdd={(e) => {
                                                console.log('e', e, 'ids', ids)
                                                setIds(prevState => {
                                                    return[
                                                        ...prevState,
                                                        {
                                                            ...e,
                                                            order: (ids?.length + 1).toString()
                                                        }
                                                    ]
                                                })
                                                setIsModalOpen(false)
                                            }}
                                            // onReload={async () => {await fetchData('filter')}}
                                        />
                                    )
                                    setIsModalOpen(true);
                                }}>
                                    <IoAddOutline size={17} color={"#49e7ad"}/>
                                </button>
                            </div>
                        </div>
                        <div className='w-100'>
                            {
                                ids?.length > 0 ?
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className={"post-series-container"}
                                                >
                                                    {ids.map((item, index) => (
                                                        <Draggable key={item.order} draggableId={item.order}
                                                                   index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className="post-series-item justify-content-between"
                                                                    style={{
                                                                        ...provided.draggableProps.style,
                                                                    }}
                                                                >
                                                                    <div className="d-flex flex-row">
                                                                        <IoReorderTwoOutline color={"#8f8f8f"} size={20}/>
                                                                        {toFarsiNumber(item.order)} {item.title}
                                                                    </div>
                                                                    <div className="d-flex flex-row">
                                                                        <button className="btn red-bg-color post-series-remove-btn" onClick={() => {
                                                                            onRemoveItem(item.order)
                                                                        }}>
                                                                            حذف
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext> :
                                    <div>
                                        {/*<EmptyState
                                            doNotUseButton={true}
                                            icon={<HiMiniArrowsUpDown size={75} color={"#525252"}/>}
                                            title={"مقاله یافت نشد"}
                                            content={"لطفا از طریق دکمه افزودن، مقاله های مورد نظر خود را انتخاب کنید."}
                                        />*/}
                                    </div>
                            }
                        </div>

                    </Col>
                </Row>

                <Row className={`field-item-container`}>
                    <Col className={`field-item `}>
                        <Button
                            className={`green-button w-100 ${isSubmitting ? 'disabled' : ''}`}
                            size="md"
                            type="submit"
                            onClick={async () => {
                                if (!isSubmitting)
                                    await submit()
                            }}>
                            {
                                isEditing ? "ویرایش سری" : "ایجاد سری"
                            }

                        </Button>

                    </Col>
                </Row>


            </div>
        </React.Fragment>

    );
};

export default PostSeriesAdd;
