import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import {useSelector} from "react-redux";
import {validateToken} from "../pages/asoo/auth";
import {UpperHeader} from "./header/upperHeader";
import Header from "./header/Header";
import AppSidebar from "./sidebar/AppSidebar";
import menu from "./menu/MenuData";
import {UnderHeaderBanner} from "../pages/components/mrpropfund/underHeaderBanner";
import {Footer} from "../pages/components/fxpilot/footer";
const AppLayout = ({title, app, ...props}) => {
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [IS_TOKEN_VALID, SET_IS_TOKEN_VALID] = useState(false);
    const appTitle = menu.find(item => location.pathname.includes(item.link) && item.link.includes('/app')).text;

    useEffect(() => {

        async function validateAccessToken() {
            const tokenValidation = await validateToken(auth.token);


            if (!tokenValidation) {
                navigate(`/signin`)
            } else {
                SET_IS_TOKEN_VALID(true);
            }

        }

        validateAccessToken()


    }, [auth.token]);

    function AppHeader(){
        return(
            <div className="app-header">
                {appTitle || ''}
            </div>
        )
    }

    return (
        <>
            {
                !IS_TOKEN_VALID ? <></> :
                    <>

                        <Head title={!title && 'Loading'}/>
                        <AppRoot>
                            {/*<Appbar />*/}


                            <AppMain>
                                <Sidebar fixed/>
                                <AppWrap>
                                    <UpperHeader isTokenValid={IS_TOKEN_VALID}/>
                                    <Header/>

                                    <UnderHeaderBanner
                                        title={"معامله گر بازارهای مالی فارکس"}
                                        btnTxt={"مشاهده مقالات"}
                                        content={"ما مجموعه‌ای کامل از آموزش‌های تخصصی را برای شما فراهم کرده‌ایم که تمامی نیازهای آموزشی شما را پوشش می‌دهد. در بخش مقالات، می‌توانید به منابع متنوع و به‌روز در موضوعات مختلف دسترسی پیدا کنید. این آموزش‌ها به گونه‌ای طراحی شده‌اند که به شما در یادگیری مهارت‌های جدید و ارتقاء دانش کمک کنند. برای استفاده از این منابع، کافی است به بخش مقالات سایت ما مراجعه کنید."}
                                        btnLink={`${process.env.REACT_APP_WEBSITE_URL}/blog`}
                                    />
                                    {/*<AppHeader/>*/}
                                    <div className="application-wrapper">
                                        <div className="application-view">
                                            <Outlet/>
                                        </div>
                                    </div>
                                    <Footer/>
                                </AppWrap>
                            </AppMain>
                        </AppRoot>
                    </>
            }

        </>

    );
};
export default AppLayout;
