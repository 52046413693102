
import React, {useEffect, useState} from "react";
import {Field} from "../../../../../../../components/fouladyar/field/field";

export const Step4 = ({data, onChange, markCompleted}) => {
    const [courseInfo, setCourseInfo] = useState({
        price: '',
        discountPrice: '',

    })
    useEffect(() => {
        setCourseInfo({
            price: data.price || '',
            discountPrice: data.discountPrice || ''
        })
    }, [])

    useEffect(() => {
        onChange(courseInfo)
    }, [courseInfo])

    return (
        <div>
            <div className="container-fluid select-plan-container">

                <h1>{"قیمت گذاری"}</h1>


                <div className="spacer"/>
                <h3 style={{border: 'unset'}}>
                    در این بخش هزینه دوره +‌ تخفیف مورد نظر ثبت خواهد شد
                </h3>
                <p>
                    در صورتی که میخواهید دوره شما با تخفیف نمایش داده شود کافیست عدد نهایی با تخیف را وارد کنید تا در سایت محصول با درصد حساب شده نمایش داده شود. در غیر این صورت فیلد قیمت با تخفیف دوره را خالی رها کنید.
                </p>
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"قیمت نهایی دوره"}
                    id={"price"}
                    name={"price"}
                    type={'text'}
                    defaultValue={courseInfo.price}
                    value={courseInfo.price}
                    onChange={(e) => {
                        setCourseInfo(prevState => {
                            return {
                                ...prevState,
                                price: e
                            }
                        })
                    }}
                    onBlur={(e) => {
                    }}
                />
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"قیمت با تخفیف"}
                    id={"discountPrice"}
                    name={"discountPrice"}
                    type={'text'}
                    defaultValue={courseInfo.discountPrice}
                    value={courseInfo.discountPrice}
                    onChange={(e) => {
                        setCourseInfo(prevState => {
                            return {
                                ...prevState,
                                discountPrice: e
                            }
                        })
                    }}
                    onBlur={(e) => {
                    }}
                />
            </div>
        </div>
    );
};