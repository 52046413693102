import React, {useEffect, useRef, useState} from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Col,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    PaginationComponent,
    Row
} from "../../../components/Component";
import {Card, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown} from "reactstrap";
import {useForm} from "react-hook-form";
import ModalHelper from "../modal-helper/modalHelper";
import CopyToClipboard from "react-copy-to-clipboard";
import exportFromJSON from "export-from-json";
import {ConvertGregorianToJalali} from "../../../shared/convertGregorianToJalali";
import {useTranslation} from "react-i18next";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import Filter from "../filter/filter";
import {useNavigate} from "react-router-dom";
import {YesOrNoModal} from "../modal-helper/pages/yesOrNo";
import {Field} from "../field/field";
import {LoadingState} from "../loading-state/loadingState";
import {useSelector} from "react-redux";
import {LuCopy, LuMoreHorizontal, LuPrinter} from "react-icons/lu";
import {RiFileExcel2Line} from "react-icons/ri";
import {TbFileTypeCsv, TbWorldSearch} from "react-icons/tb";
import {MdAdd, MdManageSearch, MdOutlineAccountBalance} from "react-icons/md";
import axios from "axios";
import {ErrorToaster} from "../../../shared/toaster";
import toast from "react-hot-toast";
import {CgSearchLoading} from "react-icons/cg";
import {FormUtils} from "../../../shared/forms";
import {EmptyState} from "../empty-state/emptyState";
import { Tooltip } from 'react-tooltip'
import moment from "moment/moment";

const Export = ({data}) => {
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (modal === true) {
            setTimeout(() => setModal(false), 2000);
        }
    }, [modal]);

    const fileName = "user-data";

    const exportCSV = () => {
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({data, fileName, exportType});
    };

    const exportExcel = () => {
        const exportType = exportFromJSON.types.xls;
        exportFromJSON({data, fileName, exportType});
    };

    const copyToClipboard = () => {
        setModal(true);
    };


    return (
        <React.Fragment>
            <div className="dt-export-buttons d-flex align-center">
                <div className="dt-buttons btn-group btn-group-grid flex-wrap">
                    <button className="btn btn-secondary buttons-html5" type="button"
                            onClick={() => copyToClipboard()}>
                        <CopyToClipboard text={JSON.stringify(data)}>
                            <LuCopy size={17} color={"#657180"}/>
                        </CopyToClipboard>
                    </button>
                    <button className="btn btn-secondary buttons-html5" type="button" onClick={() => exportCSV()}>
                        <TbFileTypeCsv size={17} color={"#657180"}/>
                    </button>
                    <button className="btn btn-secondary buttons-html5" type="button" onClick={() => exportExcel()}>
                        <RiFileExcel2Line size={17} color={"#657180"}/>
                    </button>
                    <button className="btn btn-secondary buttons-html5" type="button" onClick={() => print()}>
                        <LuPrinter size={17} color={"#657180"}/>
                    </button>
                </div>
            </div>
            <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
                <ModalBody className="text-center m-2">
                    <h5>کپی</h5>
                </ModalBody>
                <div className="p-3 bg-light">
                    <div className="text-center">کپی جدول با موفقیت انجام شد</div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export const Pagination = ({itemPerPage, currentPage, totalItems, setRowsPerPage, paginate}) => {

    return (
        <Row className={`justify-between mt-4 mb-4 g-2 with-export`}>
            <Col className="col-7 text-start" sm="4">
                <div className="card-inner">
                    <PaginationComponent
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </div>
            </Col>
            <Col className="col-5 text-end item-per-page-component" sm="8">
                <div className="card-inner d-flex align-center justify-end">
                    <div className="dataTables_info" id="DataTables_Table_2_info" role="status" aria-live="polite">
                        {itemPerPage * (currentPage - 1) + 1} - {(totalItems > itemPerPage * currentPage) ? itemPerPage * currentPage : totalItems} از {totalItems}
                    </div>
                    {/*<span className="d-none d-sm-inline-block">Show</span>*/}
                    <div className="form-control-select">
                        {" "}
                        <select
                            name="DataTables_Table_0_length"
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => {
                                setRowsPerPage(e.target.value);
                            }}
                            value={itemPerPage}
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="5">5</option>
                            <option value="7">7</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>{" "}
                    </div>
                </div>
            </Col>
        </Row>
    );
};

const Table = ({
                   tableHeading,
                   tableStructure,
                   filterStructure,
                   hideActions,
                   hidePagination,
                   tableData,
                   pagination,
                   loading,
                   filter,
                   emptystate,
                   onOpenModal,
                   tableActionsStructure,
                   onEveryGridButtonClick,
                   onItemPerPageChange,
                   onCurrentPageChange,
                   onFilterSubmit,
                   onDeleteComplete
               }) => {
    const auth = useSelector((state) => state.auth);
    const {t, i18n} = useTranslation();
    // const [data, setData] = useState([]);
    const [smOption, setSmOption] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(<div>empty</div>);
    const [_rows, set_rows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();

    const profile = useSelector((state) => state.profile);
    const [formData, setFormData] = useState({
        name: "",
        img: null,
        sku: "",
        price: 0,
        salePrice: 0,
        stock: 0,
        category: [],
        fav: false,
        check: false
    });
    const [editId, setEditedId] = useState();
    const [view, setView] = useState({
        edit: false,
        add: false,
        details: false
    });
    const [onSearchText, setSearchText] = useState("");
    const [totalItems, setTotalItems] = useState(12);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage] = useState(5);
    const [files, setFiles] = useState([]);

    //modal
    const [yesOrNoModalIsOpen, setYesOrNoModalIsOpen] = useState(false);
    const [modalProps, setModalProps] = useState({
        modalCancelText: "",
        modalSubmitText: "",
        modalTitle: "",
        modalContent: "",
        modalItemTarget: "",
        modalType: "",
        modalPath: ""
    });

    //scroll off when sidebar shows
    useEffect(() => {
        view.add ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [view.add]);
    //scroll off when sidebar shows

    // Changing state value when searching name
    useEffect(() => {
        if (onSearchText !== "") {
            const filteredObject = tableData.filter((item) => {
                return item.sku.toLowerCase().includes(onSearchText.toLowerCase());
            });
            // setData([...filteredObject]);
        } else {
            // setData([...tableData]);
        }
    }, [onSearchText]);


    const resetForm = () => {
        setFormData({
            name: "",
            img: null,
            sku: "",
            price: 0,
            salePrice: 0,
            stock: 0,
            category: [],
            favorite: false,
            check: false
        });
        reset({});
    };

    useEffect(() => {
        set_rows([])

        async function fetchDataAndRender() {
            for (const [rowIndex, row] of filterStructure.entries()) {
                const selectOptions = row.chooseOptionsFromApi ?
                    (await FormUtils.loadOptionsFromApi(auth, row.path, row.key, row.chooseOptionsLabelFrom, row.chooseOptionsValueFrom) || null) :
                    (row.options || null)

                set_rows(prevState => ([
                    ...prevState,
                    {
                        ...row,
                        options: selectOptions,
                        rowIndex: rowIndex
                    }
                ]))
            }

            _rows.map(item => {
                if (filter.hasOwnProperty(item.slug)) {
                    // If the key exists, set isSelected to true
                    return {
                        ...item,
                        selected: true
                    }
                }

                return item;
            })

            setIsLoading(false)
        }

        fetchDataAndRender();


    }, [])

    useEffect(() => {
        reset(formData);
    }, [formData]);

    useEffect(() => {
        console.log('rows', _rows)
    }, [_rows]);

    // selects all the products
    const selectorCheck = (e) => {
        let newData;
        newData = tableData.map((item) => {
            item.check = e.currentTarget.checked;
            return item;
        });
        // setData([...newData]);
    };

    // selects one product
    const onSelectChange = (e, id) => {
        let newData = tableData;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].check = e.currentTarget.checked;
        // setData([...newData]);
    };

    const toggle = (type) => {
        setView({
            edit: type === "edit" ? true : false,
            add: type === "add" ? true : false,
            details: type === "details" ? true : false
        });
    };


    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    // const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);


    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    function onFilterPress() {
        setModalComponent(
            <Filter
                filterStructure={filterStructure}
                filter={filter}
                onClose={() => setIsModalOpen(false)}
                onSubmit={(e) => {
                    onFilterSubmit(e);
                    setIsModalOpen(false);
                }}

            />
        );
        setIsModalOpen(true);
    }

    function TableHeader() {
        return (
            <BlockHead size="sm">
                <BlockBetween>
                    {
                        !tableHeading ? "" :
                            <BlockHeadContent>
                                <BlockTitle>{tableHeading.title}</BlockTitle>
                            </BlockHeadContent>
                    }
                    <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                                href="#more"
                                className="btn btn-icon btn-trigger toggle-expand me-n1"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setSmOption(!smOption);
                                }}
                            >
                                <LuMoreHorizontal size={17} color={"#c2c2c2"}/>
                            </a>
                            <div className="toggle-expand-content" style={{display: smOption ? "block" : "none"}}>

                                {
                                    hideActions ? "" :
                                        <ul className="nk-block-tools btn-group-grid g-3 dt-buttons">
                                            {
                                                !tableHeading ? "" :
                                                    !tableHeading.addNewItemButtonLink ? "" :
                                                        <li>
                                                            <button className="btn btn-secondary  buttons-html5 "
                                                                    type="button" onClick={() => {
                                                                navigate(`${tableHeading.addNewItemButtonLink}`);
                                                            }}>
                                                                <MdAdd size={18} color={"#657180"}/>
                                                            </button>
                                                        </li>
                                            }


                                            <li>
                                                <button className="btn btn-secondary  buttons-html5 " type="button"
                                                        onClick={() => onFilterPress()}>
                                                    <CgSearchLoading size={16} color={"#657180"}/>
                                                </button>
                                            </li>
                                            <Export data={tableData || []}/>

                                        </ul>
                                }
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
        );
    }

    async function getFilterSearchValues() {
        const obj = _rows.filter(item => item.value !== undefined).reduce((acc, curr) => {
            acc[curr.slug] = curr.value;
            return acc;
        }, {});

        return obj;
    }

    function InlineFilterItem({key, type, disabled, value, title, selected, onSelect, onChange, options}) {

    }

    function TableActions() {
        return (
            <BlockHead size="sm" className="table-filter-container">
                <BlockBetween>
                    <BlockHeadContent className="w-100">
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                                href="#more"
                                className="btn btn-icon btn-trigger toggle-expand me-n1"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setSmOption(!smOption);
                                }}
                            >
                                <LuMoreHorizontal size={17} color={"#cecece"}/>
                            </a>
                            <div className="toggle-expand-content" style={{display: smOption ? "block" : "none"}}>


                                {
                                    hideActions ? "" :
                                        <div className="table-action-items-container">
                                            {
                                                tableActionsStructure.map((item) => {
                                                    return(
                                                        <div className="table-actions-container">
                                                            <div className="new-account-background"> <img src={item.image} alt={""} /></div>
                                                            <div className="table-action-title">{item.title}</div>
                                                            <div className="table-action-description">{item.description}</div>
                                                            <button className="btn btn-secondary  buttons-html5 " type="button" onClick={() => {
                                                                if (item.useModal) {
                                                                    onOpenModal(item)
                                                                } else {
                                                                    navigate(item.link)
                                                                }
                                                            }}>
                                                                {item.title}
                                                            </button>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>

                                        /*<ul className="nk-block-tools btn-group-grid table-action-buttons g-3 dt-buttons">
                                            {
                                                tableActionsStructure.map((item) => {

                                                    return (
                                                        <li className="vertical-buttons">
                                                            <button
                                                                className="table-action-button-item table-filter-button btn btn-secondary buttons-html5"
                                                                type="button"
                                                                onClick={async () => {
                                                                    if (item.useModal) {
                                                                        onOpenModal()
                                                                    } else {
                                                                        navigate(item.link)
                                                                    }
                                                                }}
                                                            >
                                                                {item.title}
                                                            </button>
                                                        </li>
                                                    )
                                                })
                                            }


                                        </ul>*/
                                }
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
        );
    }

    function TableFilter() {
        return (
            <BlockHead size="sm" className="table-filter-container">
                <BlockBetween>
                    <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                                href="#more"
                                className="btn btn-icon btn-trigger toggle-expand me-n1"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setSmOption(!smOption);
                                }}
                            >
                                <LuMoreHorizontal size={17} color={"#cecece"}/>
                            </a>
                            <div className="toggle-expand-content" style={{display: smOption ? "block" : "none"}}>

                                {
                                    hideActions ? "" :
                                        <ul className="nk-block-tools btn-group-grid g-3 dt-buttons">

                                            {
                                                _rows.map((item) => {
                                                    return (
                                                        <InlineFilterItem
                                                            key={item.slug}
                                                            title={item.title}
                                                            value={item.value}
                                                            type={item.type}
                                                            disabled={item.disabled}
                                                            selected={item.selected}
                                                            onSelect={() => {
                                                                set_rows([
                                                                    ..._rows.map((i) => {
                                                                        if (i.title === item.title)
                                                                            return {
                                                                                ...i,
                                                                                selected: !item.selected
                                                                            }
                                                                        return i
                                                                    }),
                                                                ])
                                                            }}
                                                            options={item.options}
                                                            onChange={(e) => {
                                                                set_rows([
                                                                    ..._rows.map((i) => {
                                                                        if (i.title === item.title)
                                                                            return {
                                                                                ...i,
                                                                                value: e
                                                                            }
                                                                        return i
                                                                    }),
                                                                ])
                                                            }}
                                                        />
                                                    )
                                                })
                                            }


                                            <li className="vertical-fields">
                                                <h6 className="table-filter-lable"></h6>

                                                <button
                                                    className="table-filter-button btn btn-secondary buttons-html5"
                                                    type="button"
                                                    onClick={async () => onFilterSubmit(await getFilterSearchValues())}

                                                >
                                                    فیلتر جدول
                                                </button>
                                            </li>

                                        </ul>
                                }
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
        );
    }

    function ActionsDropdownButton() {
        return (
            <ul className="nk-tb-actions gx-1 my-n1">
                <li className="me-n1">
                    <UncontrolledDropdown>
                        <DropdownToggle
                            tag="a"
                            href="#toggle"
                            onClick={(ev) => ev.preventDefault()}
                            className="dropdown-toggle btn btn-icon btn-trigger"
                        >
                            <LuMoreHorizontal size={17} color={"#c2c2c2"}/>
                        </DropdownToggle>

                    </UncontrolledDropdown>
                </li>
            </ul>
        );
    }

    function TableHead() {
        return (
            <>
                <DataTableHead>
                    {/*<DataTableRow className="nk-tb-col-check">*/}
                    {/*  <div className="custom-control custom-control-sm custom-checkbox notext">*/}
                    {/*    <input*/}
                    {/*      type="checkbox"*/}
                    {/*      className="custom-control-input"*/}
                    {/*      id="uid_1"*/}
                    {/*      onChange={(e) => selectorCheck(e)}*/}
                    {/*    />*/}
                    {/*    <label className="custom-control-label" htmlFor="uid_1"></label>*/}
                    {/*  </div>*/}
                    {/*</DataTableRow>*/}
                    {
                        tableStructure.map(item => {
                            if (item.useActionsButton) {
                                return (

                                    <DataTableRow key={`${item.slug}-head`} className={`nk-tb-col-tools tb-header-item`}>
                                        <span>عملیات</span>
                                    </DataTableRow>
                                );
                            }
                            return (
                                <DataTableRow key={`${item.slug}-head`} className={`nk-tb-col-tools tb-header-item`} size="sm">
                                    <span>{item.title}</span>
                                </DataTableRow>
                            );
                        })
                    }
                </DataTableHead>
            </>
        );
    }

    function submitFilter() {

        const result = _rows.reduce((acc, item) => {
            // Only include the "slug" if it has a "value"
            if (item.value !== undefined && !item.disabled) {
                acc[item.slug] = item.value;
            }
            return acc;
        }, {});

        console.log(result);
        if(Object.keys(result).length > 0 )
            onFilterSubmit(result);
    }
    const inputRefs = useRef([]);
    function TableFilterV2() {
        return (
            <>
                <DataTableHead>
                    {
                        tableStructure.map((item, index) => {
                            if (item.useActionsButton) {
                                return (

                                    <DataTableRow key={`${item.slug}-head`} className="nk-tb-col-tools">

                                        <button className="btn green-button small-button" type="button" onClick={() => {
                                            submitFilter();
                                        }}>
                                            اعمال فیلتر
                                        </button>
                                    </DataTableRow>
                                );
                            }
                            return (
                                <DataTableRow key={`${item.slug}-head`} size="sm">
                                    <li key={`${_rows[index]?.slug}-li`} className={`vertical-fields ${filterStructure.disabled ? "filter-field-disabled" : ''}`}>
                                        {/*<h6 className="table-filter-lable">{title}</h6>*/}
                                        <Field
                                            className="pt-0 pb-0 filter-field "
                                            id={_rows[index]?.title}
                                            key={_rows[index]?.slug}
                                            name={_rows[index]?.title}
                                            type={_rows[index]?.type || 'text'}
                                            defaultValue={_rows[index]?.value || ''}
                                            // value={_rows[index]?.value || ''}
                                            options={_rows[index]?.options  || []}
                                            onBlur={(e) => {
                                                set_rows([
                                                    ..._rows.map((i) => {
                                                        if (i.title === item.title)
                                                            return {
                                                                ...i,
                                                                value: e.target.value
                                                            }
                                                        return i
                                                    }),
                                                ])
                                            }}
                                            onChange={(e) => {
                                                if(_rows[index]?.type === 'select' || _rows[index]?.type === 'date')
                                                    set_rows([
                                                        ..._rows.map((i) => {
                                                            if (i.title === item.title)
                                                                return {
                                                                    ...i,
                                                                    value: e
                                                                }
                                                            return i
                                                        }),
                                                    ])
                                            }}
                                           /* onChange={(e) => {
                                                set_rows([
                                                    ..._rows.map((i) => {
                                                        if (i.title === item.title)
                                                            return {
                                                                ...i,
                                                                value: e
                                                            }
                                                        return i
                                                    }),
                                                ])

                                            }}*/
                                            disabled={_rows[index]?.disabled}
                                        />
                                    </li>




                                </DataTableRow>
                            );
                        })
                    }
                </DataTableHead>
            </>
        );
    }



    function DataGenerator({index, item, itemConfig, itemValue}) {

        //Action Buttons
        if (itemConfig.useActionsButton) {
            return (
                <UncontrolledDropdown key={`${itemConfig.slug}-${index}-${itemValue}`}>
                    <DropdownToggle
                        tag="a"
                        href="#more"
                        onClick={(ev) => ev.preventDefault()}
                        className="dropdown-toggle btn btn-icon btn-trigger p-1"
                    >
                        <LuMoreHorizontal size={17} color={"#c7c7c7"}/>
                    </DropdownToggle>
                    <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                            {
                                itemConfig.actions.map(j => {
                                    if ((j.roles && j.roles.includes(profile.roles[0])) || !j.roles)
                                        return (
                                            <li key={`action-btn-${j.slug}-${index}`}>
                                                <DropdownItem
                                                    tag="a"
                                                    href="#edit"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();

                                                        if (j.useRoute) {
                                                            navigate(`${replacePlaceholders(j.route, item) || ""}`);
                                                        }

                                                        if (j.useModal) {
                                                            onOpenModal(item)
                                                        }

                                                        if (j.useYesOrNoModal) {
                                                            setYesOrNoModalIsOpen(true);
                                                            setModalProps({
                                                                modalCancelText: j.modalCancelText || "بستن",
                                                                modalSubmitText: j.modalSubmitText || "تایید",
                                                                modalTitle: j.modalTitle || "عنوان پنجره یافت نشد",
                                                                modalContent: j.modalContent || "متن پنجره یافت نشد",
                                                                modalType: j.modalType || "",
                                                                modalPath: j.modalPath || "",
                                                                modalItemTarget: item || "",
                                                            });
                                                        }
                                                        // onEditClick(item.id);
                                                        // toggle("edit");
                                                    }}
                                                >
                                                    <div className="action-btn-icon">
                                                        {j.icon}
                                                    </div>
                                                    <span >{j.title}</span>
                                                </DropdownItem>
                                            </li>
                                        );

                                    return null
                                })
                            }
                        </ul>
                    </DropdownMenu>
                </UncontrolledDropdown>
            );
        }

        if (itemConfig.useSelect) {
            return (
                <Field
                    formControlClassName={` ${itemConfig.className || ''}`}
                    id={"username"}
                    name={"username"}
                    type={"select"}
                    disabled={item.disabled ?? false}
                    placeholder={itemConfig.placeholder || ""}
                    options={itemConfig.options || []}
                    value={itemValue}
                    onChange={(e) => {
                        onEveryGridButtonClick({
                            slug: itemConfig.slug,
                            id: (itemConfig.pickIdFrom.split(".")).length === 1 ? item[itemConfig.pickIdFrom] : (itemConfig.pickIdFrom.split(".").reduce((obj, key) => obj && obj[key], item) || ""),
                            value: e
                        });
                    }}
                />
            );
        }

        if (itemConfig.useButton) {
            return (
                <div
                    className={`btn btn-sm ${item.disabled ? 'disabled' : ''} btn-${itemConfig.buttonColor || "primary"} custom-btn-font`}
                    style={{}}
                    onClick={() => {

                        if (!item.disabled)
                            onEveryGridButtonClick({slug: itemConfig.slug, id: item[itemConfig.pickIdFrom]});
                    }}
                >
                    {itemConfig.buttonTitle ? itemConfig.buttonTitle : " "}
                </div>
            );
        }

        if (itemValue) {
            if (itemConfig.useField) {
                return (
                    <Field
                        formControlClassName={`add-student-to-class-text ${itemConfig.className || ''}`}
                        id={itemConfig.slug}
                        name={itemConfig.slug}
                        type={itemConfig.type}
                        disabled={item.disabled ?? false}
                        placeholder={itemConfig.placeholder || ""}
                        options={itemConfig.options || []}
                        value={itemValue}
                        onChange={(e) => {
                            onEveryGridButtonClick({
                                slug: itemConfig.slug,
                                id: (itemConfig.pickIdFrom.split(".")).length === 1 ? item[itemConfig.pickIdFrom] : (itemConfig.pickIdFrom.split(".").reduce((obj, key) => obj && obj[key], item) || ""),
                                value: e
                            });
                        }}
                    />
                );
            }


            //این داده قطعا تاریخ است. با فرمت جلالی نشون بده
            if (itemConfig.useJalaliFormat) {
                if (itemConfig.useFarsiNumber) {
                    const formattedDatetime = moment(itemValue ? itemValue : new Date()).format('YYYY-MM-DD');

                    return (
                        <span
                            className="title"
                            key={`${itemConfig.slug}-${index}-${formattedDatetime}`}
                            data-tooltip={toFarsiNumber(ConvertGregorianToJalali(formattedDatetime, itemConfig.showDateTime ? itemConfig.showDateTime : false))}
                        >
                            {toFarsiNumber(ConvertGregorianToJalali(formattedDatetime, itemConfig.showDateTime ? itemConfig.showDateTime : false))}
                        </span>
                    );


                }
                return (
                    <span
                        key={`${itemConfig.slug}-${index}-${itemValue}`}
                        className="title"
                        data-tooltip-id={`${itemConfig.slug}-${index}`}
                        data-tooltip-content={ConvertGregorianToJalali(itemValue, itemConfig.showDateTime ? itemConfig.showDateTime : false)}
                        data-tooltip-place="top"
                    >
                        {ConvertGregorianToJalali(itemValue, itemConfig.showDateTime ? itemConfig.showDateTime : false)}
                    </span>
                );
            }

            //ترجمه این داده رو نشون بده
            if (itemConfig.useTranslate) {
                if (itemConfig.useFarsiNumber) {
                    return (
                        <span
                            key={`${itemConfig.slug}-${index}-${itemValue}`}
                            className="title"
                            data-tooltip-id={`${itemConfig.slug}-${index}`}
                            data-tooltip-content={toFarsiNumber(itemValue)}
                            data-tooltip-place="top"
                        >
                            {toFarsiNumber(itemValue)}
                        </span>
                    );
                }

                return (
                    <span
                        key={`${itemConfig.slug}-${index}-${itemValue}`}
                        className="title my-anchor-element"
                        data-tooltip-id={`${itemConfig.slug}-${index}`}
                        data-tooltip-content={`${t(itemValue)}`}
                        data-tooltip-place="top"
                    >
                        {t(itemValue)}
                    </span>
                );
            }


            if (itemConfig.useFarsiNumber) {
                return (
                    <span
                        key={`${itemConfig.slug}-${index}-${itemValue}`}
                        className="title my-anchor-element"
                        data-tooltip-id={`${itemConfig.slug}-${index}`}
                        data-tooltip-content={`${toFarsiNumber(itemValue)}`}
                        data-tooltip-place="top"
                    >
                        {toFarsiNumber(itemValue)}
                    </span>
                );
            }

            if (typeof itemValue === 'boolean') {
                return (
                    <span
                        key={`${itemConfig.slug}-${index}-${itemValue}`}
                        className="title my-anchor-element"
                        data-tooltip-id={`${itemConfig.slug}-${index}`}
                        data-tooltip-content={itemValue === true ? itemConfig.ifItsTrue : itemConfig.ifItsFalse}
                        data-tooltip-place="top"
                    >
                        {itemValue === true ? itemConfig.ifItsTrue : itemConfig.ifItsFalse}
                    </span>
                );
            }

            return (
                <span
                    key={`${itemConfig.slug}-${index}-${itemValue}`}
                    className="title my-anchor-element"
                    data-tooltip-id={`${itemConfig.slug}-${index}`}
                    data-tooltip-content={itemValue}
                    data-tooltip-place="top"
                >
                    {itemValue}
                </span>
            );
        }


        return <span>-</span>


    }

    function TableRow({item}) {
        return (
            <DataTableItem className={`${item.disabled ? 'low-opacity' : ''}`} key={item?._id ? item?._id : item?.id}>
                {/*<DataTableRow className="nk-tb-col-check">*/}
                {/*  <div className="custom-control custom-control-sm custom-checkbox notext">*/}
                {/*    <input*/}
                {/*      disabled={true}*/}
                {/*      type="checkbox"*/}
                {/*      className="custom-control-input"*/}
                {/*      defaultChecked={item.check}*/}
                {/*      id={item.id + "uid1"}*/}
                {/*      key={Math.random()}*/}
                {/*      onChange={(e) => onSelectChange(e, item?._id)}*/}
                {/*    />*/}
                {/*    <label className="custom-control-label" htmlFor={item?._id + "uid1"}></label>*/}
                {/*  </div>*/}
                {/*</DataTableRow>*/}
                {
                    tableStructure.map((i, index) => {

                        return (
                            <DataTableRow key={`  ${item.slug}-${index}`} className={`${!i.useActionsButton ? 'tb-item-wrapper' : ''}`} size="sm">
                  <span
                      className={` tb-product  ${i.useActionsButton ? "more-action-column" : ""} ${i.useActionsButton ? "d-flex justify-end align-end" : ""}`}>
                    {/*<img src={item.img ? item.img : ProductH} alt="product" className="thumb" />*/}
                      <DataGenerator
                          index={index}
                          item={item}
                          itemConfig={i}
                          itemValue={(i.slug.split(".")).length === 1 ? item[i.slug] : (i.slug.split(".").reduce((obj, key) => obj && obj[key], item) || "")}
                      />

                  </span>
                            </DataTableRow>
                        );
                    })
                }
            </DataTableItem>
        );
    }


    function MobileTableRow({item}) {
        return (
            <DataTableItem className={`mobile-table-item-container`} key={item?._id ? item?._id : item?.id}>

                {
                    tableStructure.map((i, index) => {

                        return (
                            <div key={`${i.slug}-${index}`} className="mobile-table-item-container-item" size="sm">
                                <div className='mobile-table-item-key'>{i.title}</div>
                                 <div className='mobile-table-item-value'>
                                      <DataGenerator
                                          index={index}
                                          item={item}
                                          itemConfig={i}
                                          itemValue={(i.slug.split(".")).length === 1 ? item[i.slug] : (i.slug.split(".").reduce((obj, key) => obj && obj[key], item) || "")}
                                      />
                                </div>
                            </div>
                        );
                    })
                }
            </DataTableItem>
        );
    }

    function TableItems() {

        return (
            <>
                {

                    tableData.length > 0 ?
                        tableData.map((item, index) => {
                            return (
                                <TableRow key={`data-${index}`} item={item}/>
                            );
                        }) : null
                }
            </>
        );
    }

    function MobileTableItems() {

        return (
            <>
                {

                    tableData.length > 0 ?
                        tableData.map((item, index) => {
                            return (
                                <MobileTableRow key={`data-${index}`} item={item}/>
                            );
                        }) : null
                }
            </>
        );
    }

    function TableBody() {
        return (
            <Block>
                <Card className="card">
                    <div className="card-inner-group">
                        <div className="card-inner p-0">
                            {/*<TableActions/>*/}
                            {/*<TableFilter/>*/}

                            <DataTableBody className={'table-mobile-view'}>
                                <MobileTableItems key={"tableItems"}/>
                            </DataTableBody>

                            <DataTableBody className={'table-web-view'}>
                                <TableHead key={"tableHead"}/>
                                <TableFilterV2/>
                                {
                                    loading ? <div className="pt-5 pb-5 d-flex justify-center align-center"><LoadingState/></div> :
                                        <>
                                            {
                                                tableData.length === 0 ?
                                                    <>
                                                        <EmptyState
                                                            className={'table-empty-state'}
                                                            icon={<MdManageSearch size={90} color={"#4f9d81"}/>}
                                                            title={emptystate.title}
                                                            content={emptystate.content}
                                                            doNotUseButton={true}
                                                            /*buttonText={emptystate.buttonText}
                                                            buttonOnClick={() => {
                                                                if (emptystate.useModal) {
                                                                    onOpenModal()
                                                                } else {
                                                                    navigate(`${emptystate.buttonOnClickUrl}`)
                                                                }
                                                            }}*/
                                                        />
                                                    </> :
                                                    <TableItems key={"tableItems"}/>

                                            }
                                        </>
                                }
                            </DataTableBody>

                         {/*   {

                                tableData.length > 0 ?
                                    null : (
                                        <Col className="col-12 text-start no-data-found">
                                            <div className="text-center">
                                                            <span
                                                                className="text-silent">داده مورد نظر یافت نشد</span>
                                            </div>
                                        </Col>

                                    )
                            }*/}
                            {
                                hidePagination ? "" :
                                    <Pagination
                                        itemPerPage={pagination.itemPerPage}
                                        totalItems={pagination.totalItems}
                                        currentPage={pagination.currentPage}
                                        paginate={(pageNumber) => {
                                            onCurrentPageChange(pageNumber);
                                        }}
                                        setRowsPerPage={(e) => {
                                            onItemPerPageChange(parseInt(e), 1);
                                        }}
                                    />
                            }


                        </div>
                    </div>
                </Card>
            </Block>
        );
    }

    return (
        <>
            {
                !tableHeading ? "" : <Head title={tableHeading.title}></Head>
            }

            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <ModalHelper
                open={yesOrNoModalIsOpen}
                onOpen={() => setYesOrNoModalIsOpen(true)}
                onClose={() => setYesOrNoModalIsOpen(false)}
                component={
                    <YesOrNoModal
                        cancelText={modalProps.modalCancelText}
                        submitText={modalProps.modalSubmitText}
                        submitMode={modalProps.modalType === 'delete' ? 'red' : 'green'}
                        onClose={() => {
                            setYesOrNoModalIsOpen(false);
                        }}
                        onCancel={() => {
                            setYesOrNoModalIsOpen(false);
                        }}
                        onSubmit={async () => {
                            if (modalProps.modalType === 'delete') {
                                // replacePlaceholders(j.route, item)
                                try {
                                    const res = await axios.delete(`${process.env.REACT_APP_API_URL}${replacePlaceholders(modalProps.modalPath, modalProps.modalItemTarget)}`, {
                                        headers: {authorization: `Bearer ${auth.token}`}
                                    });

                                    if (res.status === 200) {
                                        onDeleteComplete()
                                        setYesOrNoModalIsOpen(false);
                                        toast.success("آیتم مورد نظر با موفقیت حذف شد")
                                    }
                                } catch (e) {
                                    ErrorToaster(e, t);
                                }
                            }
                            if (modalProps.modalType === 'post') {
                                // replacePlaceholders(j.route, item)
                                try {
                                    const res = await axios.post(`${process.env.REACT_APP_API_URL}${replacePlaceholders(modalProps.modalPath, modalProps.modalItemTarget)}`, {},{
                                        headers: {authorization: `Bearer ${auth.token}`}
                                    });

                                    if (res.status === 200) {
                                        onDeleteComplete()
                                        setYesOrNoModalIsOpen(false);
                                        toast.success("آیتم مورد نظر با موفقیت ")
                                    }
                                } catch (e) {
                                    ErrorToaster(e, t);
                                }
                            }
                            if (modalProps.modalType === 'put') {
                                // replacePlaceholders(j.route, item)
                                try {
                                    const res = await axios.put(`${process.env.REACT_APP_API_URL}${replacePlaceholders(modalProps.modalPath, modalProps.modalItemTarget)}`, {},{
                                        headers: {authorization: `Bearer ${auth.token}`}
                                    });

                                    if (res.status === 200) {
                                        onDeleteComplete()
                                        setYesOrNoModalIsOpen(false);
                                        toast.success("آیتم مورد نظر با موفقیت آپدیت شد ")
                                    }
                                } catch (e) {
                                    ErrorToaster(e, t);
                                }
                            }
                        }}
                        title={modalProps.modalTitle}
                        content={modalProps.modalContent}
                    />
                }
            />

            <Tooltip style={{zIndex: 1000}} anchorSelect=".my-anchor-element" place="top">
                Hello world!
            </Tooltip>
            <Content>
                {/*{*/}
                {/*  !tableHeading ? "" : <TableHeader />*/}
                {/*}*/}
                <TableBody/>

                {view.add && <div className="toggle-overlay" onClick={toggle}></div>}
            </Content>
        </>
    );
};

export default Table;


export function replacePlaceholders(str, data) {

    return str.replace(/:(\w+(\.\w+)?)/g, (match, key) => {
        const keys = key.split(".");
        let value = data;

        for (const k of keys) {
            value = value[k];
            if (value === undefined) {
                return match; // Return the original placeholder if the nested key doesn't exist
            }
        }
        return value;
    });
}