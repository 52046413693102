import React, { useEffect, useMemo, useState } from "react";
import Table from "../../../components/fouladyar/table";
import { useDispatch, useSelector } from "react-redux";
import {EmptyStateObject, filterStructure, tableStatics, tableStructure, tableStructureAdmin} from "./index";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {TableActionsStructure} from "./index";
import {IoAddOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {BiReceipt} from "react-icons/bi";




const WithdrawListAdmin = () => {

  const { t, i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  const [pagination, setPagination] = useState({
    itemPerPage: 7,
    currentPage: 1,
    totalItems: 0,
    lastUpdateBy: ''
  })
  const navigate = useNavigate();
  const [filter, setFilter] = useState({})
  async function initializeData() {


    try {
      setIsLoading(true)
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/withdraw/admin/all`, {
        headers: {authorization: `Bearer ${auth.token}`}
      })


      console.log('data', res.data)


     if(res.data.statusCode === 200 || !res.data.statusCode){
        setData(res.data.data.withdraw)
        setPagination({...pagination, totalItems: res.data.data.totalItems || 9, lastUpdateBy: 'initializer'})
      }

      setIsLoading(false)
    }catch (e) {
      ErrorToaster(e, t)
      setIsLoading(false)
    }
  }

  async function fetchData(updater) {


    try {
      setIsLoading(true)
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/withdraw/admin/all`, {
        headers: {authorization: `Bearer ${auth.token}`}
      })



      if(res.data.statusCode === 200){
        setData(res.data.data.withdraw)
        if(updater === 'filter')
          setPagination({
            ...pagination,
            totalItems: res.data.data.totalItems,
            currentPage: 1,
            lastUpdateBy: updater
          })
      }

      setIsLoading(false)
    }catch (e) {
      ErrorToaster(e, t)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initializeData();
  }, []);


  useEffect(() => {
    if(pagination.lastUpdateBy === "pagination")
        fetchData('pagination');

  }, [pagination]);



  useEffect(() => {
    fetchData('filter');
  }, [filter]);



  return (
    <React.Fragment>


      <div className="grid-header">
        <div className="title-with-tab">
          <h3>تسویه حساب ها</h3>
        </div>
      </div>


      <Table
        loading={isLoading}
        filter={filter}
        tableData={data}
        pagination={pagination}
        tableHeading={tableStatics}
        tableStructure={tableStructureAdmin}
        filterStructure={filterStructure}
        tableActionsStructure={TableActionsStructure}
        emptystate={EmptyStateObject}
        onItemPerPageChange={(itemPerPage, currentPage)=> {
          setPagination({...pagination, itemPerPage: itemPerPage, currentPage: currentPage, lastUpdateBy: 'pagination'})
        }}
        onCurrentPageChange={(currentPage)=> {
          setPagination({...pagination, currentPage: currentPage, lastUpdateBy: 'pagination'})
        }}
        onFilterSubmit={ (e)=>{
          setFilter(e);
        }}
        onOpenModal={(e) => {
          console.log('e', e)
        }}
        onDeleteComplete={async (e)=>{
          await fetchData('filter');
        }}
      />
    </React.Fragment>
  );
};

export default WithdrawListAdmin;
