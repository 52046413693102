import React, {useEffect, useState} from "react";
import {Field} from "../../../../../../../components/fouladyar/field/field";


export const Step1 = ({data, onChange}) => {


    const [courseInfo, setCourseInfo] = useState({
        title: '',
        slug: '',
        shortDescription: '',
        description: ''
    })

    useEffect(() => {
        setCourseInfo({
            title: data.title || '',
            slug: data.slug || '',
            shortDescription: data.shortDescription || '',
            description: data.description || '',
        })
    }, [])

    useEffect(() => {
        onChange(courseInfo)
    }, [courseInfo])

    useEffect(() => {
        if (data)
            setCourseInfo({
                title: data.title || '',
                slug: data.slug || '',
                shortDescription: data.shortDescription || '',
                description: data.description || '',
            })
    }, [data])

    return (
        <div>
            <div className="container-fluid select-plan-container">

                <h1>{"اطلاعات پایه"}</h1>


                <div className="spacer"/>
                <h3 style={{border: 'unset'}}>
                    در این بخش متناسب با سرمایه و ریسک خود پلن مورد نظر را انتخاب کنید
                </h3>
                <p>
                    لطفا در انتخاب طرح مورد نظر دقت کافی داشته باشید، چرا که بعد از انجام عملیات پرداخت، امکان تغییر
                    طرح وجود نداشته و مسئولیت آن تماما بر عهده خریدار خواهد بود. لذا در بررسی طرح مورد نظر خود را
                    متناسب با نیاز خود تهیه کنید.
                </p>
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"عنوان دوره"}
                    id={"title"}
                    name={"title"}
                    type={'text'}
                    defaultValue={courseInfo.title}
                    value={courseInfo.title}
                    onChange={(e) => {
                        setCourseInfo(prevState => {
                            return {
                                ...prevState,
                                title: e
                            }
                        })
                    }}
                    onBlur={(e) => {
                    }}
                />
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"شناسه دوره"}
                    id={"slug"}
                    name={"slug"}
                    type={'text'}
                    defaultValue={courseInfo.slug}
                    value={courseInfo.slug}
                    onChange={(e) => {
                        setCourseInfo(prevState => {
                            return {
                                ...prevState,
                                slug: e
                            }
                        })
                    }}
                    onBlur={(e) => {
                    }}
                />
            </div>
            <div className="kyc-form-row">
                <Field
                    label={"توضیحات جزئی"}
                    id={"shortDescription"}
                    name={"shortDescription"}
                    type={'text'}
                    defaultValue={courseInfo.shortDescription}
                    value={courseInfo.shortDescription}
                    onChange={(e) => {
                        setCourseInfo(prevState => {
                            return {
                                ...prevState,
                                shortDescription: e
                            }
                        })
                    }}
                    onBlur={(e) => {
                    }}
                />
            </div>
            <div>
                <Field
                    className="w-100"
                    label={"توضیحات کامل"}
                    id={"description"}
                    name={"description"}
                    type={'editor'}
                    value={courseInfo.description}
                    onChange={(e) => {
                        setCourseInfo(prevState => {
                            return {
                                ...prevState,
                                description: e
                            }
                        })
                    }}
                    onBlur={(e) => {

                    }}
                />
            </div>
        </div>
    );
};