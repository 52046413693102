import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {toFarsiNumber} from "../../../shared/toFarsiNumber";
import {MdOutlineEdit, MdVerified} from "react-icons/md";
import {getCardItems} from "../../../redux/store/services/mrpropfund/card/store/card-actions";
import {LoadingState} from "../../../components/fouladyar/loading-state/loadingState";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";
import ModalHelper from "../../../components/fouladyar/modal-helper/modalHelper";
import {Field} from "../../../components/fouladyar/field/field";
import {IoMdAdd, IoMdClose} from "react-icons/io";
import {useTranslation} from "react-i18next";

import ProfileImage from "../../../assets/images/mrpropfund/profile.png";
import BannerImage from "../../../assets/images/mrpropfund/image-01.jpg";
import {Button} from "reactstrap";
import {LuCopy} from "react-icons/lu";
import {UnderHeaderBanner} from "../../components/mrpropfund/underHeaderBanner";

const Profile = () => {
    const auth = useSelector((state) => state.auth);

    const {id} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('tab1');
    const profileState = useSelector((state) => state.profile);
    const [isCardsLoading, setIsCardsLoading] = useState(true);
    const [modalComponent, setModalComponent] = useState(<div> empty </div>);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const dispatch = useDispatch();
    const [cards, setCards] = useState([]);
    const [profile, setProfile] = useState({
        _id: "",
        fullName: "",
        email: "",
        mobile: "",
        nationalCode: "",
        country: "",
        city: "",
        password: "",
        repeatPassword: "",

    });


    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    useEffect(() => {
        if(id){
            console.log('The userId is: ', id)
            getProfileByUserId();

            if(profileState.roles.includes('customer')){
                getCards();
            }
        }else{
            console.log('The logged In User')
            getProfile();

            if(profileState.roles.includes('customer')){
                getCards();
            }
        }



    }, []);


    async function getCards() {
        try {
            setIsCardsLoading(true)
            const res = await dispatch(getCardItems());

            console.log('data', res.data)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setCards(res.data.cards)
            }

            setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsCardsLoading(false)
        }
    }

    async function getProfileByUserId() {
        try {
            setIsProfileLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/profile/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('cart', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setProfile(res.data.data.user)
            }
            setIsProfileLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsProfileLoading(false)
        }
    }

    async function getProfile() {
        try {
            setIsProfileLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('cart', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setProfile(res.data.data.user)

            }
            setIsProfileLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsProfileLoading(false)
        }
    }

    function ReferralCode() {
        const referralDiscount = 10;
        // console.log('addddddddddddd', profileInformation)
        return (
            <div className="referral-container">
                <div className="referral-code">
                    <span className="referral-code-heading1">کسب ۱۰% از فروش با کد دعوت</span>
                    <span className="referral-code-description">با استفاده از کد دعوت مسترپراپ‌فاند و دعوت دوستان خود در فروش حساب ها سهیم شوید و تا ۱۰% از مبلغ پرداختی را دریافت کنید</span>
                    <span className="referral-code-heading4">لینک کد دعوت شما</span>

                    <div className="referral-code">
                        <LuCopy className="copy-icon" size={18} color={"#fff"} onClick={() => {
                            alert('clipboard')
                        }}/>
                        <Field
                            className="pt-0 pb-0 modal-fields "
                            id={"title"}
                            name={"title"}
                            type={'text'}
                            value={`mrpropfunc.com/ref?code=${profile._id}`}
                        />
                    </div>

                    <span className="referral-code-description2">
                       این کد دعوت دارای <div className="green">{toFarsiNumber(referralDiscount)}%</div> تخفیف  در خرید اول از سمت ادمین می‌باشد
                   </span>
                </div>
            </div>
        )
    }

    function UserProfile() {
        function ProfileItem({slug, value}) {
            return (
                <div className="profile-item">
                    <span className="profile-item-key">{slug}</span>
                    <span className="profile-item-value">{value}</span>
                </div>
            )
        }

        return (
            <div>
                <ProfileItem slug={"نام نام خانوادگی"} value={profile.fullName || '-'}/>
                <ProfileItem slug={"کد ملی"} value={toFarsiNumber(profile.nationalCode || '-')}/>
                <ProfileItem slug={"شماره تماس"} value={toFarsiNumber(profile.mobile || '-')}/>
                <ProfileItem slug={"کشور"} value={profile.country || '-'}/>
                <ProfileItem slug={"شهر"} value={profile.city || '-'}/>
            </div>
        )
    }

    function UserCards({myCards}) {
        async function onEditCard(_id) {
            try {
                const res = (await axios.get(`${process.env.REACT_APP_API_URL}/api/cards/${_id}`, {
                    headers: {Authorization: `Bearer ${auth.token}`}
                }));

                console.log('wallet', res)
                if (res.data.statusCode === 200) {
                    setModalComponent(<AddWalletComponent
                        isEditing={true}
                        _wallet={res.data.data}
                    />)
                    setIsModalOpen(true);
                }
            } catch (e) {
                ErrorToaster(e, t)
            }
        }

        function CardItem({_id, title, cardNumber, walletNetwork, walletExchangeName}) {

            return (
                <div className="card-item">
                    <div className="card-item-row row-01">
                        <div className="card-name">{title}</div>
                        <div className="edit-card-button">
                            <MdOutlineEdit size={20} color={"#38AF84"} onClick={async () => {
                                await onEditCard(_id)
                            }}/>
                        </div>
                    </div>
                    <div className="card-number card-item-row row-02">
                        {cardNumber}
                    </div>
                    <div className="card-item-row row-03">
                        <div className="card-exchange-name">{walletExchangeName}</div>
                        <div className="card-network-name">{walletNetwork}</div>
                    </div>
                </div>
            )
        }

        function AddNewCardItem() {
            return (
                <div className="card-item" onClick={() => onCreateNewCard()}>
                    <div className="add-new-card"><IoMdAdd size={20} color={"#767676"}/> افزون کارت جدید</div>
                </div>
            )
        }

        return (
            <div className="user-card-container">
                <AddNewCardItem/>
                {
                    myCards.map(item => {
                        return (
                            <CardItem
                                _id={item._id}
                                cardNumber={item.cardNumber || ''}
                                walletExchangeName={item.walletExchangeName || ''}
                                title={item.title || ''}
                                ownerName={item.ownerName || ''}
                                walletNetwork={item.walletNetwork || ''}
                            />
                        )
                    })
                }

            </div>
        )
    }

    function AddWalletComponent({isEditing, _wallet}) {
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [wallet, setWalletData] = useState({
            title: isEditing ? _wallet?.title : '',
            cardType: isEditing ? _wallet?.cardType : 'wallet',
            cardNumber: isEditing ? _wallet?.cardNumber : '',
            walletNetwork: isEditing ? _wallet?.walletNetwork : '',
            walletExchangeName: isEditing ? _wallet?.walletExchangeName : '',
            ownerName: isEditing ? _wallet?.ownerName : '',
        })

        async function onSubmit() {
            try {
                const newCardResponse = (await axios.post(`${process.env.REACT_APP_API_URL}/api/cards`, wallet, {
                    headers: {Authorization: `Bearer ${auth.token}`}
                }));

                if (newCardResponse.data.statusCode === 200) {
                    setIsSubmitting(false);
                    setIsModalOpen(false);
                    await getCards()
                }
            } catch (e) {
                ErrorToaster(e, t)
                setIsSubmitting(false);
            }
        }

        async function onEdit({_id}) {
            try {
                const res = (await axios.put(`${process.env.REACT_APP_API_URL}/api/cards/${_wallet._id}`, wallet, {
                    headers: {Authorization: `Bearer ${auth.token}`}
                }));

                if (res.data.statusCode === 200) {
                    setIsSubmitting(false);
                    setIsModalOpen(false);
                    await getCards()
                }
            } catch (e) {
                ErrorToaster(e, t)
                setIsSubmitting(false);
            }
        }

        async function onDelete(_id) {
            try {
                const res = (await axios.delete(`${process.env.REACT_APP_API_URL}/api/cards/${_wallet._id}`, {
                    headers: {Authorization: `Bearer ${auth.token}`}
                }));

                if (res.data.statusCode === 200) {
                    SuccessToaster({message: 'کارت مورد نظر با موفقیت حذف شد'}, t)
                    setIsSubmitting(false);
                    setIsModalOpen(false);
                    await getCards();
                }
            } catch (e) {
                ErrorToaster(e, t)
                setIsSubmitting(false);
            }
        }

        return (
            <div className="modal-container">
                <div className="close-modal-btn" onClick={() => setIsModalOpen(false)}>
                    <IoMdClose size={20} color={"#FAF4F4"}/>
                </div>
                <h4 className="modal-title">{isEditing ? "ویرایش کارت" : "افزودن کارت جدید"}</h4>


                <div className="modal-note">
                    لطفا در وارد کردن شماره کارت یا آدرس ولت خود دقت فرمایید تا از هر گونه واریز اشتباه جلوگیری بعمل
                    آید.
                    <br/>
                    <br/>
                    مسئولیت واریز به آدرس کارت یا ولت اشتباه به عهده خود کاربر خواهد بود
                </div>
                <div className='modal-form'>
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        label={"عنوان کارت"}
                        id={"title"}
                        name={"title"}
                        type={'text'}
                        value={wallet.title}
                        onChange={(e) => {
                            setWalletData({
                                ...wallet,
                                title: e
                            })
                        }}
                    />
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        label={"نوع کارت"}
                        id={"card-type"}
                        name={"card-type"}
                        type={'select'}
                        options={[
                            {value: "wallet", label: "کیف پول دیجیتال"}
                        ]}
                        value={wallet.cardType}
                        onChange={(e) => {
                            setWalletData({
                                ...wallet,
                                cardType: e
                            })
                        }}
                    />
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        label={"شبکه"}
                        id={"wallet-network"}
                        name={"wallet-network"}
                        type={'text'}
                        value={wallet.walletNetwork}
                        onChange={(e) => {
                            setWalletData({
                                ...wallet,
                                walletNetwork: e
                            })
                        }}
                    />
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        label={"نام صرافی"}
                        id={"exchange-name"}
                        name={"exchange-name"}
                        type={'text'}
                        value={wallet.walletExchangeName}
                        onChange={(e) => {
                            setWalletData({
                                ...wallet,
                                walletExchangeName: e
                            })
                        }}
                    />
                    <Field
                        className="pt-0 pb-0 modal-fields "
                        label={"آدرس کیف پول"}
                        id={"wallet-address"}
                        name={"wallet-address"}
                        type={'text'}
                        value={wallet.cardNumber}
                        onChange={(e) => {
                            setWalletData({
                                ...wallet,
                                cardNumber: e
                            })
                        }}
                    />
                </div>

                <div className="form-submit-btn" style={{opacity: isSubmitting ? 0.5 : 1}} onClick={async () => {
                    if (!isSubmitting) {
                        setIsSubmitting(true);
                        isEditing ? await onEdit(_wallet._id) : await onSubmit()
                    }
                }}>
                    {isEditing ? "ویرایش کارت" : "ایجاد کارت"}

                </div>

                {
                    isEditing ?
                        <div className="form-submit-btn remove-card-btn" style={{opacity: isSubmitting ? 0.5 : 1}}
                             onClick={async () => {
                                 if (!isSubmitting) {
                                     await onDelete(_wallet._id)
                                 }
                             }}>
                            حذف کارت

                        </div> :
                        <></>
                }

            </div>
        )
    }

    function onCreateNewCard() {
        setIsModalOpen(true)
        setModalComponent(<AddWalletComponent/>);
    }


    return (
        <React.Fragment>
            <ModalHelper
                open={isModalOpen}
                onOpen={() => setIsModalOpen(true)}
                onClose={() => setIsModalOpen(false)}
                component={modalComponent}
            />

            <div className="nk-content ">
                <div className="container-fluid">




                    <div className="profile-info-container">
                        <div className="profile-image"><img src={ProfileImage} alt=""/></div>
                        <div className="profile-info">
                            <div className="profile-name">{profile.fullName} {profile?.kycDetails?.status === 'approved' ? <MdVerified size={20} color={"#4F9D81"}/> : ''} </div>
                            {
                                profile?.kycDetails?.status === 'approved' ? '' :
                                    <div className="profile-description">

                                        {
                                            profile?.roles?.includes('customer') ?
                                                <>
                                                    <div className={`kyc-status ${profile?.kycDetails?.status === 'pending' || profile?.kycDetails?.status === 'update_required'  || !profile?.kycDetails?.status ? "yellow-color" : (profile?.kycDetails?.status === 'approved' ? "green-color" : "")}`}>
                                                        {profile?.kycDetails?.status === 'pending' || !profile?.kycDetails?.status  ? "حساب کاربری شما نیاز به احراز هویت دارد" : ""}
                                                        {profile?.kycDetails?.status === 'approved' ? "احراز هویت شما با موفقیت انجام شده است" : ""}
                                                    </div>
                                                    {
                                                        profile?.kycDetails?.status === 'pending' || profile?.kycDetails?.status === 'update_required' || !profile?.kycDetails?.status  ?
                                                            <button className="btn btn-outline yes-or-no-btn yellow-bg-color"
                                                                    onClick={()=>{
                                                                        if(id)
                                                                            navigate(`/kyc/${id}`)
                                                                        else
                                                                            navigate('/kyc')
                                                                    }}
                                                            >
                                                                {id ? "مشاهده اطلاعات کاربر" : "احراز هویت"}

                                                            </button> : ''
                                                    }
                                                </> : 'ادمین'
                                        }

                                    </div>
                            }
                        </div>
                    </div>

                    <div className="tab-buttons">
                        <div className="tab-buttons-container">
                            <button onClick={() => handleTabClick('tab1')}
                                    className={activeTab === 'tab1' ? 'active' : ''}>اطلاعات کاربری
                            </button>
                            {
                                profileState.roles.includes('customer') || id ?
                                    <button onClick={() => handleTabClick('tab2')}
                                            className={activeTab === 'tab2' ? 'active' : ''}>ولت ها
                                    </button> :
                                    <></>
                            }
                            {
                                profileState.roles.includes('customer') || id ?
                                    <button onClick={() => handleTabClick('tab3')}
                                            className={activeTab === 'tab3' ? 'active' : ''}>کد معرف
                                    </button> :
                                    <></>
                            }


                        </div>
                    </div>


                    <div className="tab-content">
                        {activeTab === 'tab1' &&
                            <div>
                                {
                                    isProfileLoading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <UserProfile/>
                                }
                            </div>
                        }



                        {activeTab === 'tab2' &&
                            <div>
                                {
                                    isCardsLoading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <UserCards myCards={cards}/>
                                }
                            </div>
                        }
                        {activeTab === 'tab3' &&
                            <div>
                                {
                                    isCardsLoading ?
                                        <div className="pt-5 pb-5 d-flex justify-center align-center">
                                            <LoadingState/>
                                        </div> :
                                        <ReferralCode/>
                                }
                            </div>
                        }

                    </div>


                </div>
            </div>
        </React.Fragment>

    );
};

export default Profile;
