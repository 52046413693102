import {Doughnut} from "react-chartjs-2";
import React from "react";
import {useTranslation} from "react-i18next";
import {CategoryScale,ArcElement, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip} from "chart.js";
ChartJS.register(LineElement,ArcElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);


export default function SymbolsDoughnutChartComponent({trades}) {
    const {t, i18n} = useTranslation();


    const calculateSymbolCounts = (trades) => {
        const counts = {};
        trades.forEach(trade => {
            const symbol = trade.symbol;
            if (counts[symbol]) {
                counts[symbol]++;
            } else {
                counts[symbol] = 1;
            }
        });
        return counts;
    };


    const getColorsFromPaintBucket = (count) => {
        const PaintBucket = [
            "#37A0F4",
            "#FF7D61",
            "#D2906B",
            "#B298E9",
            "#9DC896",
            "#FFA268",
            "#3FC1C3",
            "#947CFF",
            "#FFD74C",
            "#CD64FF",
        ];

        return Array.from({ length: count }, (_, i) => PaintBucket[i % PaintBucket.length]);
    };
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const generateColors = (count) => {
        const colors = [];
        for (let i = 0; i < count; i++) {
            colors.push(getRandomColor());
        }
        return colors;
    };


    const symbolCounts = calculateSymbolCounts(trades);

    // Prepare data for Doughnut chart
    const labels = Object.keys(symbolCounts);
    const dataValues = Object.values(symbolCounts);
    const colors = getColorsFromPaintBucket(labels.length);

    const data = {
        labels: labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: colors,
                borderColor: "#25252B",
                borderWidth: 3, // Set border width to simulate spacing
            },
        ],
        weight: 0.2
    };


    const options = {
        cutout: '90%',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.label}: ${context.raw}`;
                    },
                },
            },
        },
    };


    return (
        <table border="1" cellPadding="10" className="w-100 mb-3">
            <thead>
            <tr>
                <th className="d-flex flex-row justify-content-between align-center">
                    <span>نماد ها</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <div style={{width: '100%', height: '290px'}}>
                    <div style={{width: '100%', height: '100%', padding: '3%'}}>
                        <Doughnut data={data} options={options}/>
                    </div>
                </div>
            </tr>

            </tbody>
        </table>
    )

}
