import React, {useEffect, useState} from "react";
import {EcommerceAppMenu, FxPilotMenu, ServicesAppMenu, SupportAppMenu, WebsiteAppMenu} from "../../menu/MenuData";
import {useLocation, useNavigate} from "react-router-dom";
import {LOGO_SVG} from "../../../shared/svg"; // Icons for mobile menu


const FxPilotAppSidebar = () => {
    const [activeMenu, setActiveMenu] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menu, setMenu] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const handleMenuClick = (menuIndex) => {
        setActiveMenu(menuIndex === activeMenu ? null : menuIndex);
    };
    useEffect(() => {
        // Find the active menu item based on the current path
        const currentPath = location.pathname;
        const setMenuItems = () => {
            if(process.env.APPLICATION === 'fxpilot')
                setMenu(FxPilotMenu);
        }
        const findActiveMenu = () => {
            for (let i = 0; i < menu.length; i++) {
                const menuItem = menu[i];
                if (menuItem.subMenu) {
                    for (let j = 0; j < menuItem.subMenu.length; j++) {
                        if (menuItem.subMenu[j].link === currentPath) {
                            setActiveMenu(i); // Set active menu based on parent menu index
                            return;
                        }
                    }
                }
                if (menuItem.link === currentPath) {
                    setActiveMenu(i);
                    return;
                }
            }
            setActiveMenu(null);
        };
        setMenuItems();
        findActiveMenu();
    }, [location.pathname]);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            {/* Mobile menu toggle button */}
            <button className="menu-toggle" onClick={toggleMenu}>
                {isMenuOpen ? 'بستن منو' : 'بازکردن منو'}
            </button>

            {/* Sidebar Menu */}
            <div className={`fxplt-vertical-menu ${isMenuOpen ? 'open' : ''}`}>
                <ul className="fxplt-vertical-base-ul">
                    {menu.map((menu, index) => (
                        <li
                            key={index}
                            // className={activeMenu === index ? 'active' : ''}
                            className={'main'}
                            onClick={() => handleMenuClick(index)}
                        >
                            <div className="fxplt-vertical-menu-header">
                                <h6>{menu.text}</h6>
                            </div>
                            {menu.subMenu && (
                                // <ul className={`submenu ${activeMenu === index ? 'show' : ''}`}>
                                <ul className={`submenu ${'show'}`}>
                                    {menu.subMenu.map((subItem, subIndex) => (
                                        <li
                                            key={subIndex}
                                            className={`menu-item ${subItem.className || ''} ${subItem.link === location.pathname ? 'active' : ''}`}
                                            onClick={() => {
                                                navigate(subItem.link)
                                            }}
                                        >
                                            <div className="fxplt-logo">
                                                {subItem.icon}
                                            </div>
                                            <a > {subItem.text}</a>

                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default FxPilotAppSidebar;