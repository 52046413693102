import {Col, Row} from "../../../../components/grid/Grid";
import {Field} from "../../../../components/fouladyar/field/field";
import React, {useEffect, useState} from "react";
import {FormUtils} from "../../../../shared/forms";
import {useSelector} from "react-redux";

export function FormGenerator({fields, onFieldChange}){



    const auth = useSelector((state) => state.auth);
    const [cols, set_cols] = useState([])
    const [rows, set_rows] = useState([])
    const [data, setData] = useState({

    })

    useEffect(() => {
        set_cols([])
        set_rows([])

        async function fetchDataAndRender() {
            const rows = [];
            for (const [rowIndex, row] of fields.entries()) {

                for (const [colIndex, col] of row.entries()) {
                    const selectOptions = col.chooseOptionsFromApi ?
                        (await FormUtils.loadOptionsFromApi(auth, col.path, col.key, col.chooseOptionsLabelFrom, col.chooseOptionsValueFrom) || null) :
                        (col.options || null)

                    set_cols(prevState => ([
                        ...prevState,
                        {
                            ...col,
                            options: selectOptions,
                            rowLength: row.length,
                            rowIndex: rowIndex
                        }
                    ]))
                }


                set_rows(prevState => ([
                    ...prevState,
                    {
                        rowIndex: rowIndex,
                        rowLength: row.length
                    }
                ]))
            }



        }

        fetchDataAndRender();
    }, []);

    return(
        <div className="fxplt-form-container">
            {
                !rows ? '' :
                    rows.map((row, rIndex)=>{

                        return(
                            <Row key={`row-${rIndex}`} className={`fxplt-field-item-container`}>
                                {
                                    !cols ? '' :
                                        cols.map((col, cIndex) => {
                                            return(
                                                <>
                                                    {
                                                        rIndex !== col.rowIndex ? '' :
                                                            (
                                                                <Col  className={`fxplt-field-item ${col.type}`} key={`col-${cIndex}-${col.rowIndex}`}>
                                                                    <div className="fxplt-field-item-input">
                                                                        <Field
                                                                            id={col.slug}
                                                                            name={col.slug}
                                                                            label={col.title}
                                                                            isRequired={col.isRequired}
                                                                            disabled={col.disabled || false}
                                                                            isJalali={col.isJalali}
                                                                            options={col.options}
                                                                            format={col.format}
                                                                            treeSection={col.treeSection}
                                                                            treeMode={col.treeMode}
                                                                            onChange={(value) => onFieldChange({ [col.slug]: value })}
                                                                            value={data[col.slug]}
                                                                            type={col.type}
                                                                            className={'post-image-uploader'}
                                                                            fileUploadType={'post'}
                                                                            multiple={false}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            )

                                                    }
                                                </>

                                            )
                                        })
                                }
                            </Row>
                        )
                    })
            }



        </div>
    )
}