export const tableStatics = {
    title: "مقالات",
    description: "",
    addNewItemButtonLink: '/product-add'
}
export const formStatics = {
    title: "ایجاد مقاله",
    editTitle: "ویرایش مقاله",
    description: "از اینجا میتوانید اقدام به ایجاد مقاله کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش مقاله کنید",
    submitText: "ایجاد مقاله",
    editSubmitText: "ویرایش مقاله",
}
export const EmptyStateObject = {
    title: "مقاله یافت نشد",
    content: "لطفا از طریق لینک زیر وارد صفحه اضافه کردن مقاله شده و مقالات مورد نظر خود را اضافه کنید",
    buttonText: "افزودن مقاله جدید",
    buttonOnClickUrl: "/app/website/post-add"
}

import CampaignImage from "../../../assets/images/mrpropfund/campaign-background.png";

export const TableActionsStructure = [
    {
        title: "افزودن مقاله",
        description: "مقالات مورد نظر خود را با توجه به دسته بندی های ایجاد شده، اضافه و کامل کنید.",
        image: CampaignImage,
        link: '/post-add'
    }
]

export const filterStructure = [
    {
        title: "عنوان مقاله",
        slug: 'title',
        type: "text",
    },
    {
        title: "دسته بندی",
        slug: 'categoryId',
        type: "select",
        chooseOptionsFromApi: true,
        path: '/api/categories?section=post',
        key: 'categories',
        chooseOptionsLabelFrom: 'name',
        chooseOptionsValueFrom: '_id',
        options: [],
    },
/*    {
        title: "نویسنده",
        slug: 'author',
        disabled: true,
        type: "text",
    },*/
    {
        title: "تاریخ ایجاد",
        slug: 'createdAt',
        disabled: true,
        type: "date",
    },
    {
        title: "وضعیت",
        slug: 'status',
        type: "select",
        options: [
            {label: 'منتشر شده', value: 'published'},
            {label: 'در دست انتشار', value: 'waiting_for_publish'},
        ],
    },
]

export const tableStructure = [
    {
        title: "عنوان مقاله",
        slug: 'title',
        useFarsiNumber: true
    },
    {
        title: "دسته بندی",
        slug: 'categoryName',
        useFarsiNumber: true
    },
/*    {
        title: "نویسنده",
        slug: 'author',
        useFarsiNumber: true
    },*/
    {
        title: "تاریخ ایجاد",
        slug: 'createdAt',
        useJalaliFormat: true,
        useFarsiNumber: true
    },
    {
        title: "وضعیت",
        slug: 'status',
        useTranslate: true
    },

    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "ویرایش مقاله",
                slug: "edit-post",

                useRoute: true,
                route: "/app/website/post-edit/:_id"
            },
            {
                title: "حذف مقاله",
                slug: 'delete-post',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف مقاله',
                modalTitle: 'حذف مقاله',
                modalContent: 'ایا از حذف این مقاله اطمینان دارید؟ با حذف این مقاله داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/post/:_id',
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "عنوان مقاله",
            slug: 'post category',
            type: "text",
            placeholder: "عنوان مقاله را وارد کنید",
            isRequired: true,
        }
    ],
    [

        {
            title: "دسته بندی مقاله",
            slug: 'category',
            type: "select",
            chooseOptionsFromApi: true,
            path: '/api/categories?section=posts',
            key: 'courses',
            chooseOptionsLabelFrom: 'title',
            chooseOptionsValueFrom: 'id',
            options: [],

            placeholder: "عنوان دوره را انتخاب کنید",
            isRequired: true,
        },
    ],
]
