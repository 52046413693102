import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Wizard from "../../../components/fouladyar/wizard";
import {Field} from "../../../components/fouladyar/field/field";
import {Step1} from "./applicationFormSteps/step1";
import {Step2} from "./applicationFormSteps/step2";
import {Step3} from "./applicationFormSteps/step3";
import axios from "axios";
import {ErrorToaster, SuccessToaster} from "../../../shared/toaster";
import {handleUpload} from "../../../shared/file/file";
import {generateUniqueNumber} from "../../../shared/generateUniqueNumber";


function ApplicationForm({...props}) {

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isEditing = location.pathname.includes("identify/edit");
    const [isLoading, setIsLoading] = useState(false);
    const auth = useSelector((state) => state.auth);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [payableValue, setPayableValue] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [userDataOnServer, setUserDataOnServer] = useState({
        fullName: '',
        phoneNumber: '',
        birthdate: '',
        targetCountry: '',
        exchange: '',
        description: '',
        uniqueNumber: '',
        city: '',
        userImage: {
            image: '',
            selectedFile: null
        }
    })
    const [userData, setUserData] = useState({
        fullName: '',
        phoneNumber: '',
        birthdate: '',
        targetCountry: '',
        exchange: '',
        description: '',
        uniqueNumber: '',
        city: '',
        userImage: {
            image: '',
            selectedFile: null
        }
    })
    const [steps, setSteps] = useState([
        {
            name: 'اطلاعات پایه',
            slug: 'Step 1',
            component: Step1,
            getData: () => ({
                data: userData,
                onChange: (e) => {
                    setUserData(prevState => {
                        return {
                            ...prevState,
                            ...e
                        }
                    })
                },
            }),
            value: '',
            status: 'incomplete'
        },
        {
            name: 'آپلود عکس',
            slug: 'Step 2',
            component: Step2, // Reference to the component
            getData: () => ({
                data: userData,
                onChange: (e) => {
                    setUserData((prevState) => ({
                        ...prevState,
                        userImage: e,
                    }));
                },
            }),
            value: '',
            status: 'incomplete'
        },
        {
                    name: 'مدارک فیزیکی',
                    slug: 'Step 3',
                    component: Step3, // Reference to the component
                    getData: () => ({
                        data: userData,
                        onChange: (e) => {
                            setUserData((prevState) => ({
                                ...prevState,
                                ...e,
                            }));
                        },
                    }),
                    value: '',
                    status: 'incomplete'
                },
        /*                {
                    name: 'پرداخت',
                    slug: 'Step 4',
                    component: <Step3
                        data={userData}
                        onChange={(e) => {
                            setUserData(prevState => {
                                return {
                                    ...prevState,
                                    ...e
                                }
                            })
                        }}
                    />,
                    value: '',
                    status: 'incomplete'
                },*/
    ])



    useEffect(()=>{
        if(userData){
            setSteps(steps.map(step => {


                if(step.slug === 'Step 1'){
                    return{
                        ...step,
                        getData: () => ({
                            data: userDataOnServer,
                            onChange: (e) => {
                                setUserData(prevState => {
                                    return {
                                        ...prevState,
                                        ...e
                                    }
                                })
                            },
                        }),
                    }
                }



                if(step.slug === 'Step 2'){
                    return{
                        ...step,
                        getData: () => ({
                            data: userDataOnServer.userImage,
                            onChange: (e) => {
                                setUserData(prevState => {
                                    return{
                                        ...prevState,
                                        userImage: e,
                                    }
                                });
                            },
                        }),
                    }
                }





                return step

            }))
        }
    }, [userData])

    async function onSaveDraft() {
        try {

            let image;
            if(userData?.userImage?.selectedFile){
                image = await handleUpload(userData?.userImage?.selectedFile[0]);
                setUserData(prevState => {
                    return{
                        ...prevState,
                        userImage: {
                            ...prevState.userImage,
                            image: image
                        }
                    }
                })
            }

            // setStartToUpload(false)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/orders/kyc-request`,
                {
                    payableValue: 1000000,
                    paymentMethod: 'wallet',
                    extras: {
                        ...userData,
                        uniqueNumber: generateUniqueNumber(),
                        userImage: image || ''
                    },
                    discount: 0,
                    campaigns: [],
                    addons: []
                }, {
                    headers: {
                        "authorization": `Bearer ${auth.token}`
                    }
                }
            );

            console.log(res.data)
            if (res.data.statusCode === 200) {
                navigate('/kyc')
            }

            setIsSubmitting(false)

            SuccessToaster({ message: 'ذخیره سازی با موفیت انجام شد' });
            // setIsSaving(false)
        }catch (e) {
            ErrorToaster(e);
            // setIsSaving(false)
        }

    }

    async function onEditDraft() {
        try {

            let image;
            if(userData?.userImage?.selectedFile){
                image = await handleUpload(userData?.userImage?.selectedFile[0]);
                setUserData(prevState => {
                    return{
                        ...prevState,
                        userImage: {
                            ...prevState.userImage,
                            image: image
                        }
                    }
                })
            }

            // setStartToUpload(false)
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/kyc-request`,
                {
                    payableValue: 1000000,
                    paymentMethod: 'wallet',
                    extras: {
                        ...userData,
                        uniqueNumber: generateUniqueNumber(),
                        userImage: image || ''
                    },
                    discount: 0,
                    campaigns: [],
                    addons: []
                }, {
                    headers: {
                        "authorization": `Bearer ${auth.token}`
                    }
                }
            );

            console.log(res.data)
            if (res.data.statusCode === 200) {
                navigate('/kyc')
            }

            setIsSubmitting(false)

            SuccessToaster({ message: 'ذخیره سازی با موفیت انجام شد' });
            // setIsSaving(false)
        }catch (e) {
            ErrorToaster(e);
            // setIsSaving(false)
        }

    }


    async function initializeData() {

        try {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/my-kyc-orders/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('data', res)

            if (res.data.statusCode === 200 || !res.data.statusCode) {
                const order = res.data.data;
                const data = {
                    fullName: order.fullName,
                    phoneNumber: order.phoneNumber,
                    birthdate: order.birthdate,
                    targetCountry: order.targetCountry,
                    exchange: order.exchange,
                    description: order.description,
                    uniqueNumber: order.uniqueNumber,
                    city: order.city,
                    userImage: {
                        image: order.userImage.original.path,
                        selectedFile: null
                    }
                }
                setUserData(data)
                setUserDataOnServer(data)
            }

            setIsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsLoading(false)
        }
    }


    useEffect(()=> {
        if(isEditing){
            console.log('isEditing', isEditing)
            initializeData()
        }
    }, [])


    return (

        <div className="App">
            <Wizard
                steps={steps}
                currentStep={currentStep}
                setCurrentStep={(e) => setCurrentStep(e)}
            />

            {
                currentStep === 2 ?
                    (
                        isEditing  ?
                            <button className="btn green-button kyc-submit-button w-100" type="button" onClick={async () => {
                                await onSaveDraft()
                            }}>
                                ویرایش درخواست
                            </button>:
                            <button className="btn green-button kyc-submit-button w-100" type="button" onClick={async () => {
                                await onSaveDraft()
                            }}>
                                ثبت درخواست
                            </button>
                    ) :

                    <button className="btn green-button kyc-submit-button w-100" type="button" onClick={() => {
                        setCurrentStep(currentStep + 1)
                    }}>
                        مرحله بعدی
                    </button>
            }
        </div>

    );
};


export default ApplicationForm;
