export const tableStatics = {
    title: "سری ها",
    description: "",
    addNewItemButtonLink: '/product-add'
}
export const formStatics = {
    title: "ایجاد سری",
    editTitle: "ویرایش سری",
    description: "از اینجا میتوانید اقدام به ایجاد سری کنید",
    editDescription: "از اینجا میتوانید اقدام به ویرایش سری کنید",
    submitText: "ایجاد سری",
    editSubmitText: "ویرایش سری",
}
export const EmptyStateObject = {
    title: "سری یافت نشد",
    content: "لطفا از طریق لینک زیر وارد صفحه اضافه کردن سری شده و سری ها مورد نظر خود را اضافه کنید",
    buttonText: "افزودن سری جدید",
    buttonOnClickUrl: "/app/website/post-add"
}

import CampaignImage from "../../../assets/images/mrpropfund/campaign-background.png";

export const TableActionsStructure = [
    {
        title: "افزودن سری",
        description: "سری ها مورد نظر خود را با توجه به دسته بندی های ایجاد شده، اضافه و کامل کنید.",
        image: CampaignImage,
        link: '/post-add'
    }
]

export const filterStructure = [
    {
        title: "عنوان سری",
        slug: 'title',
        type: "text",
    },
    {
        title: "دسته بندی",
        slug: 'categoryId',
        type: "select",
        chooseOptionsFromApi: true,
        path: '/api/categories?section=post',
        key: 'categories',
        chooseOptionsLabelFrom: 'name',
        chooseOptionsValueFrom: '_id',
        options: [],
    },
/*    {
        title: "نویسنده",
        slug: 'author',
        disabled: true,
        type: "text",
    },*/
    {
        title: "تاریخ ایجاد",
        slug: 'createdAt',
        disabled: true,
        type: "date",
    },
    {
        title: "وضعیت",
        slug: 'status',
        type: "select",
        options: [
            {label: 'منتشر شده', value: 'published'},
            {label: 'در دست انتشار', value: 'waiting_for_publish'},
        ],
    },
]

export const tableStructure = [
    {
        title: "عنوان",
        slug: 'title',
        useFarsiNumber: true
    },
    {
        title: "تعداد مقاله",
        slug: 'numberOfPosts',
        useFarsiNumber: true
    },
/*    {
        title: "نویسنده",
        slug: 'author',
        useFarsiNumber: true
    },*/
    {
        title: "تاریخ ایجاد",
        slug: 'createdAt',
        useJalaliFormat: true,
        useFarsiNumber: true
    },
    {
        title: "عملیات",
        useActionsButton: true, //use delete-item class to automatic implement the removing item.
        slug: '',
        actions: [
            {
                title: "ویرایش سری",
                slug: "edit-series",

                useRoute: true,
                route: "/app/website/post-series-edit/:_id"
            },
            {
                title: "حذف سری",
                slug: 'delete-series',
                useYesOrNoModal: true,
                modalCancelText: 'بستن پنجره',
                modalSubmitText: 'حذف سری',
                modalTitle: 'حذف سری',
                modalContent: 'ایا از حذف این سری اطمینان دارید؟ با حذف این سری داده ها دیگر در دسترس نخواهند بود',
                modalType: "delete",
                modalPath: '/api/series/:_id',
            },
        ]
    },
]

export const formStructure = [
    [
        {
            title: "عنوان",
            slug: 'post category',
            type: "text",
            placeholder: "عنوان سری را وارد کنید",
            isRequired: true,
        }
    ],
    [

        {
            title: "دسته بندی سری",
            slug: 'category',
            type: "select",
            chooseOptionsFromApi: true,
            path: '/api/categories?section=posts',
            key: 'courses',
            chooseOptionsLabelFrom: 'title',
            chooseOptionsValueFrom: 'id',
            options: [],

            placeholder: "عنوان دوره را انتخاب کنید",
            isRequired: true,
        },
    ],
]
