import {Line} from "react-chartjs-2";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import annotationPlugin from "chartjs-plugin-annotation";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip
} from "chart.js";

ChartJS.register(BarElement, LineElement, BarController, CategoryScale, annotationPlugin, LinearScale, PointElement, Tooltip, Legend);

export default function TradesByDayOfWeekLineChartComponent({trades}) {


    const {t, i18n} = useTranslation();

    const [period, setPeriod] = useState('month'); //week | month


    function countTradesByDayOfWeek() {
        // Step 1: Initialize the object with all days of the week (0-6), each set to 0
        const tradesByDay = {
            0: 0, // Sunday
            1: 0, // Monday
            2: 0, // Tuesday
            3: 0, // Wednesday
            4: 0, // Thursday
            5: 0, // Friday
            6: 0  // Saturday
        };

        // Step 2: Iterate through the trades and count them by day of the week
        trades.forEach(trade => {
            const dayOfWeek = new Date(trade.exitTime).getUTCDay();
            tradesByDay[dayOfWeek]++;
        });

        return {tradesByDay};
    }

    function getDayName(dayValue) {
        // Array mapping numeric values to day names
        const dayNames = [
            'Sunday',    // 0
            'Monday',    // 1
            'Tuesday',   // 2
            'Wednesday', // 3
            'Thursday',  // 4
            'Friday',    // 5
            'Saturday'   // 6
        ];

        // Check if the dayValue is within the valid range (0-6)
        if (dayValue < 0 || dayValue > 6) {
            throw new Error('Invalid day value. It must be between 0 and 6.');
        }

        // Return the corresponding day name
        return dayNames[dayValue];
    }

    const {tradesByDay} = countTradesByDayOfWeek();


    const data = {
        labels: Object.keys(tradesByDay).map(item => t(getDayName(item))),
        datasets: [
            {
                type: 'bar', // Bar dataset
                label: 'تعداد معاملات',
                data: Object.values(tradesByDay),
                backgroundColor: '#B298E9', // Red color
                stack: 'Stack 0',
                barThickness: 4, // Adjust bar thickness
            },
        ],
    };


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: 'rgb(140,140,140)',
                    font: {
                        size: 11,
                        family: 'peyda',
                    },
                },
                grid: {
                    display: false, // Hide vertical grid lines
                },
                title: {
                    stacked: true,
                }
            },
            y: {
                title: {
                    stacked: true,
                }
            },
        },
    };


    return (
        <table border="1" cellPadding="10" className="w-100 mb-3">
            <thead>
            <tr>
                <th className="d-flex flex-row justify-content-between align-center">

                    <span>تعداد معاملات (براساس روزهای هفته)</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <div style={{width: '100%', height: '290px'}}>
                    <Line data={data} options={options}/>
                </div>
            </tr>

            </tbody>
        </table>
    )

}
