export const Brokers = [
    { label: 'بای‌بیت', value: 'bingx' },
    { label: 'کوینکس', value: 'coinex' },
    // { label: 'بایننس', value: 'binance' },
    { label: 'کوکوین', value: 'kucoin' },
    { label: 'کراکن', value: 'karken' },
    { label: 'اوککس', value: 'okx' },
    { label: 'بای‌بیت', value: 'bybit' },
    { label: 'توبیت', value: 'toobit' },
    { label: 'فانددنکست', value: 'fundednext' },
    // { label: 'کوین‌بیس', value: 'coinbase' },
    // { label: 'کراکن', value: 'kraken' },
    // { label: 'جمینای', value: 'gemini' },
    // { label: 'بیت‌فینکس', value: 'bitfinex' },
    // { label: 'هوبی', value: 'huobi' },
    // { label: 'ای‌تورو', value: 'eToro' },r
    // { label: 'روبین‌هود', value: 'robinhood' },
    // { label: 'کریپتو دات کام', value: 'crypto.com' },
];
