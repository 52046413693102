import React, {useEffect, useState} from 'react';
import './tree.css';

// Recursive component to render a node and its children
export function TreeNode({node, onAddNode, selectMode, onSelectNode}) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };




    return (
        <div className={`tree-node ${selectMode ? 'selectable-tree-node' : ''}`} key={node.slug}>
            <div className="tree-node-content">
                {/* Clickable expand/collapse icon and node label */}
                <span className="tree-node-icon" onClick={toggleExpand}>
                    {node.children && (
                        <span>{isExpanded ? '−' : '+'}</span> // Plus/minus icons
                    )}
                </span>
                <span className="tree-node-label">{node.name}</span>

                {
                    selectMode ?
                        <div className="node-actions-container">
                            <button className="btn tree-node-btn tree-select-node-button" type="button" onClick={() => {
                                onSelectNode(node)
                            }}>
                                انتخاب
                            </button>
                        </div> :
                        <div className="node-actions-container">
                            <button className="tree-remove-node-button btn tree-node-btn " type="button"
                                    onClick={() => {
                                        onAddNode({node: node, isEdit: true})
                                    }}>
                                ویرایش
                            </button>
                            <button className="btn tree-node-btn tree-add-node-button" type="button" onClick={() => {
                                onAddNode({node: node, isEdit: false})
                            }}>
                                افزودن
                            </button>
                        </div>
                }

            </div>

            {/* Recursively render children if the node is expanded */}
            {isExpanded && node.children && (
                <div className="tree-node-children">
                    {node.children.map((child) => (
                        <TreeNode
                            key={child._id}
                            node={child}
                            onAddNode={(e) => onAddNode(e)}
                            selectMode={selectMode}
                            onSelectNode={(e) => onSelectNode(e)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

// Function to build the tree structure from flat data
export const buildTree = (data) => {
    const map = {};
    const roots = [];

    data.forEach((item) => {
        // Normalize the ID and parentId
        const id = item._id;
        const parentId = item.parentId ? item.parentId : null;

        // Initialize the map for current node
        if (!map[id]) {
            map[id] = {...item, children: []};
        } else {
            map[id] = {...map[id], ...item};
        }

        const currentNode = map[id];

        // If parentId is null, it's a root node
        if (!parentId) {
            roots.push(currentNode);
        } else {
            // If parent exists in the map, add current node as a child
            if (!map[parentId]) {
                map[parentId] = {children: []}; // Create empty parent if it doesn't exist yet
            }
            map[parentId].children.push(currentNode); // Add current node as a child to its parent
        }
    });

    return roots;
};

// Main Index component
const Tree = ({data, onAddNode}) => {
    const [treeData, setTreeDate] = useState(null);
    useEffect(() => {
        if (data) {
            const result = buildTree(data);
            setTreeDate(result) // Build the tree from flat data
        }
    }, [data])


    return (
        <>
            <div className="tree">
                {treeData?.map((node) => (
                    <TreeNode key={node._id} node={node} onAddNode={(e) => onAddNode(e)}/>
                ))}
            </div>
        </>
    );
};

export default Tree;
