import React, {useEffect, useState} from "react";
import {FormIsLoading} from "../../../components/fouladyar/form";
import {useSelector} from "react-redux";
import {formStatics} from "./index";
import axios from "axios";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ErrorToaster} from "../../../shared/toaster";
import {useTranslation} from "react-i18next";
import {Field} from "../../../components/fouladyar/field/field";
import {Button, Col, Row} from "reactstrap";
import ImageFileUpload from "../../../components/fouladyar/image-file-upload";
import {IoClose} from "react-icons/io5";
import {handleUpload} from "../../../shared/file/file";
import ImageFileUploadV2 from "../../components/fxpilot/imageUploaderV2";


function PostAdd({...props}) {

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const location = useLocation();
    const formData = new FormData();
    const isEditing = location.pathname.includes("post-edit");
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const [isLoading, setIsloading] = useState(isEditing ? true : false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [files, setFiles] = useState([]);
    const [categories, setCategories] = useState([])
    const [form, setForm] = useState({
        title: "",
        slug: "",
        hidden: "",
        content: "",
        categoryId: "",
        image: undefined,
        status: "published",
    })

    useEffect(() => {
        getPostsCategories()
    }, []);


    async function loadData() {

        try {
            setIsloading(true);
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/post/${id}`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })


            console.log('posts', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                const data = res.data.data;
                setForm({
                    title: data.title,
                    slug: data.slug,
                    hidden: data.hidden,
                    content: data.content,
                    image: data.image,
                    categoryId: data.categoryId,
                    status: data.status,
                })

            }
            setIsloading(false);
        } catch (e) {
            ErrorToaster(e, t)
            setIsloading(false);
        }


    }


    useEffect(() => {
        if (isEditing)
            loadData();
    }, []);


    async function getPostsCategories() {
        try {
            // setIsCardsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories?section=post`, {
                headers: {authorization: `Bearer ${auth.token}`}
            })

            console.log('categories', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                setCategories(res.data.data.categories)

            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            // setIsProductsLoading(false)
        }
    }


    async function submit() {
        try {
            setIsSubmitting(true)

            let res;
            let image = undefined ;
            console.log('form', form)

            if(files.length > 0){
                image = await handleUpload(files[0], formData);
            }


            if (isEditing)
                res = await axios.put(
                    `${process.env.REACT_APP_API_URL}/api/post/${id}`,
                    {
                        ...form,
                        image: image?.original._id,
                        thumbnail: image?.thumbnail?._id
                    },
                    {headers: {authorization: `Bearer ${auth.token}`}})
            else
                res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/post`,
                    {
                        ...form,
                        image: image?.original._id,
                        thumbnail: image?.thumbnail?._id
                    },
                    {headers: {authorization: `Bearer ${auth.token}`}}
                )

            console.log('post', res.data)
            if (res.data.statusCode === 200 || !res.data.statusCode) {
                navigate('/app/website/post-list')
                setIsSubmitting(false)
            }
            // setIsCardsLoading(false)
        } catch (e) {
            ErrorToaster(e, t)
            setIsSubmitting(false)
        }
    }




  /*  const handleUpload = async () => {

        try {
            const file = files[0];


            if (!file) {
                alert("Please select a file");
                return;
            }

            formData.append('file', file);
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/file/multer/upload`,
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    console.log(`Upload Progress: ${progress}%`);
                    // Optionally update UI with progress
                    // e.g., setUploadProgress(progress)
                },
            });

            console.log('prev-res', res.data)
            if(res.status === 200){
                return res.data.data;
            }


        } catch (error) {
            ErrorToaster(error, t)
            console.error("Error uploading file:", error);

        }
    };*/


    return (

        <>
            {
                isLoading ?
                    <FormIsLoading
                        statics={formStatics}
                        isEditing={isEditing}
                    />
                    :
                    <div className="outlet-container">
                        <div className="form-container">
                            <div className="form-container quill-text-editor">

                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                عنوان مقاله
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"title"}
                                                name={"title"}
                                                type={'text'}
                                                value={form.title}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        title: e,
                                                        // slug: e.replace(/\s+/g, '-')
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                شناسه
                                            </div>
                                            <div className="field-item-description">
                                                شناسه تماما باید با حروف انگلیسی و خط فاصله نوشته شود. از این مقدار برای آدرس پست نیز استفاده میشود. در صورتی که میخواهید این پست در بخش مقالات نمایش داده نشود آن را مخفی کنید.
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"slug"}
                                                name={"slug"}
                                                type={'text'}
                                                value={form.slug}
                                                onChange={(e) => {
                                                    // Replace any character that is not an English letter, number, dash, or underscore with ''
                                                    const slug = e
                                                        .replace(/[^a-zA-Z0-9-_]/g, '') // Allow only English letters, numbers, dashes, and underscores
                                                        .replace(/\s+/g, '-') // Replace spaces with dashes
                                                        .toLowerCase(); // Convert to lowercase (optional if needed)



                                                    setForm(prevState => {
                                                       return{
                                                           ...prevState,
                                                           slug: slug
                                                       }
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                label={"مخفی کردن در بخش مقالات"}
                                                id={"hidden"}
                                                name={"hidden"}
                                                type={'checkbox'}
                                                value={form.hidden}
                                                onChange={(e) => {
                                                    setForm(prevState => {
                                                       return{
                                                           ...prevState,
                                                           hidden: e
                                                       }
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>

                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                دسته بندی
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"category"}
                                                name={"category"}
                                                type={'select'}
                                                options={[
                                                    ...categories?.map(item => {
                                                        return ({value: item._id, label: item.name})
                                                    })
                                                ]}
                                                value={form.categoryId}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        categoryId: e
                                                    })
                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>

                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>
                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                آپلود عکس
                                            </div>
                                        </div>
                                        {
                                            form.image ?
                                                <div className='form-field-input'>
                                                    <div className="remove-image-btn" onClick={() => {
                                                        setForm(prevState => {
                                                            return {
                                                                ...prevState,
                                                                image: undefined
                                                            }
                                                        })
                                                    }}>
                                                        <IoClose  size={20} color={"#fff"}/>
                                                    </div>
                                                    <img src={`${process.env.REACT_APP_LIARA_STORAGE}/${form?.image?.path}`}  alt="post-image"/>
                                                </div>
                                             : ''
                                        }

                                        {
                                            form.image ? '' :
                                                <div className='form-field-input'>
                                                    <ImageFileUploadV2
                                                        onChange={(e) => {
                                                            setFiles(e);
                                                            console.log(e)
                                                        }}
                                                    />
                                                </div>
                                        }

                                    </Col>
                                </Row>

                                <Row className={`field-item-container`}>
                                    <Col className={`field-item `}>

                                        <div className="field-item-title-and-desc">
                                            <div className="field-item-title">
                                                محتوای پست
                                            </div>
                                        </div>
                                        <div className='form-field-input'>
                                            <Field
                                                className="w-100"
                                                id={"status"}
                                                name={"status"}
                                                type={'editor'}
                                                value={form.content}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        content: e
                                                    })
                                                }}
                                                onBlur={() => {

                                                }}
                                            />
                                        </div>

                                    </Col>
                                </Row>


                            </div>

                        </div>

                        <div className="spacer"/>

                        <Button className={`green-button w-100 ${isSubmitting ? 'disabled' : ''}`} size="md"
                                type="submit" onClick={async () => {

                                    if (!isSubmitting)
                                        await submit()
                        }}>
                            {
                                isEditing ? "ویرایش مقاله" : "ایجاد مقاله"
                            }

                        </Button>

                    </div>
            }
        </>


    );
};

export default PostAdd;
